import React, { FC } from 'react';
import { Box, InfoTooltip, Typography } from 'components/atoms';
import { runThreeTimes } from 'utils/runThreeTimes';

interface IProps {
    title: string;
    data: any;
    info?: string;
    isTotal?: boolean;
}

const AccordionContent: FC<IProps> = ({ title, data, info }) => {
    return (
        <>
            <Box display='flex' justifyContent='center' position='relative' height='40px' alignItems='center'>
                <Box>
                    <Typography.Body4>{title}</Typography.Body4>
                </Box>
                {info && (
                    <Box position='absolute' right='10px'>
                        <InfoTooltip firstText={info} />
                    </Box>
                )}
            </Box>
            <Box display='flex' gap={4}>
                {runThreeTimes.map((item) => (
                    <Box
                        key={item}
                        width='33.333%'
                        height='40px'
                        bgcolor={'primary.greyBackground'}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                    >
                        {data[item] && <Typography.Body5>{data[item]}</Typography.Body5>}
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default AccordionContent;
