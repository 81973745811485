import { GetProject } from 'models/CalculatorModel.model';

export const getProjectData = (projectData: GetProject) => {
    const {
        power,
        panels_number,
        light_hours,
        annual_dc_generation,
        cost_estimation,
        annual_repayment_estimation,
        repayment_estimation_25,
        investment_return_time,
        expected_annual_return,
        carbon_emissions,
        electric_vehicle_charging_cycles,
        electricity_consumption,
        economic_model,
        entrepreneur_economic_model,
        electricity_production,
        entrepreneur_total_income,
        avg_annual_repayment_estimation,
        avg_rent,
        signing_bonus,
        rent_percent,
        vat_included,
        settlement_tariff,
    } = projectData;

    const purchaseData = {
        cost_estimation,
        avg_annual_repayment_estimation,
        repayment_estimation_25,
        investment_return_time,
        expected_annual_return,
        economic_model,
        electricity_production,
        vat_included,
        settlement_tariff,
        annual_dc_generation,
    };

    const rentData = {
        cost_estimation,
        annual_repayment_estimation,
        repayment_estimation_25,
        investment_return_time,
        expected_annual_return,
        entrepreneur_economic_model,
        electricity_production,
        entrepreneur_total_income,
        avg_rent,
        signing_bonus,
        rent_percent,
        annual_dc_generation,
    };

    const systemData = {
        power,
        panels_number,
        light_hours,
        annual_dc_generation,
        vat_included,
    };

    const environmentalData = {
        carbon_emissions,
        electric_vehicle_charging_cycles,
        electricity_consumption,
    };

    return { purchaseData, rentData, systemData, environmentalData };
};
