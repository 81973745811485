import React, { FC } from 'react';
import { Box, Typography } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useTheme } from '@mui/material';
import ReadMore from 'components/atoms/ReadMore';

interface IProps {
    title: string;
    text: string;
}

const PricingHeader: FC<IProps> = ({ title, text }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    return (
        <Box>
            <Box mb={{ md: '15px', xs: '5px' }}>
                {isDesktop ? (
                    <Typography.XLargeTitle color={theme.palette.primary.purple} font={theme.fontFamilies.xBold}>
                        {title}
                    </Typography.XLargeTitle>
                ) : (
                    <Typography.Body2 color={theme.palette.primary.purple}>{title}</Typography.Body2>
                )}
            </Box>
            <Box width={{ md: '100%' }}>
                {isDesktop ? (
                    <ReadMore maxLetters={300} text={text}>
                        <Typography.Body2>{text}</Typography.Body2>
                    </ReadMore>
                ) : (
                    <ReadMore maxLetters={115} text={text}>
                        <Typography.Body5>{text}</Typography.Body5>
                    </ReadMore>
                )}
            </Box>
        </Box>
    );
};

export default PricingHeader;
