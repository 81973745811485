import { FC } from 'react';
import { Box } from 'components/atoms';
import appLogo from 'assets/images/app-logo.svg';

interface IProps {
    onClick: () => void;
}

const AppLogo: FC<IProps> = ({ onClick }) => (
    <Box
        component={'img'}
        src={appLogo}
        alt='logo'
        sx={{
            cursor: 'pointer',
            width: { xs: '148px', md: '220px' },
            height: { xs: '36px', md: 'auto' },
        }}
        onClick={onClick}
    />
);

export default AppLogo;
