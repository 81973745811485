import { createTheme } from '@mui/material';
import { palette } from './colors';
import { components } from './components';
import { extraThemeOptions } from './extraThemeOptions';
import { typography } from './typography';

export const spacingSizeArray = [0, 1, 2, 4, 8, 10, 12, 16, 18, 20, 22, 30, 40];

export const theme = createTheme(
    {
        direction: 'rtl',
        palette,
        components,
        typography,
        spacing: (value: number | string) => {
            if (value === 'auto') {
                return 'auto';
            }

            if (spacingSizeArray.includes(value as number)) {
                return value;
            }
            throw new Error(`Spacing with ${value} value is not from the spacing system`);
        },
    },
    extraThemeOptions
);
