import { useMediaQuery, useTheme } from '@mui/material';
import { Box, Link, Typography } from 'components/atoms';
import React, { FC } from 'react';

interface IProps {
    data: {
        text: string;
        linkText: string;
        onClick?: () => void;
    };
    colorLink?: string;
    isSmall?: boolean;
}

const TextWithLink: FC<IProps> = ({ data, colorLink, isSmall }) => {
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const theme = useTheme();

    return (
        <>
            {isDesktop ? (
                <Box display='flex' alignItems='center' justifyContent='center'>
                    <Typography.ThinTitle>{data.text}</Typography.ThinTitle>
                    &nbsp;
                    <Link onClick={() => data.onClick?.()} textDecorationColor={colorLink}>
                        <Typography.Body2 color={colorLink}>{data.linkText}</Typography.Body2>
                    </Link>
                </Box>
            ) : (
                <>
                    {!isSmall ? (
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <Typography.Body3 font={theme.fontFamilies.regular}>{data.text}</Typography.Body3>
                            &nbsp;
                            <Link onClick={() => data.onClick?.()} textDecorationColor={colorLink}>
                                <Typography.Body3 color={colorLink}>{data.linkText}</Typography.Body3>
                            </Link>
                        </Box>
                    ) : (
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <Typography.Body5 font={theme.fontFamilies.regular}>{data.text}</Typography.Body5>
                            &nbsp;
                            <Link onClick={() => data.onClick?.()} textDecorationColor={colorLink}>
                                <Typography.Body5 color={colorLink}>{data.linkText}</Typography.Body5>
                            </Link>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

export default TextWithLink;
