interface IStorageItem {
    data: any;
}

class SessionStorageService {
    // get persistent data
    get(key: string) {
        const item = sessionStorage.getItem(key);
        const storageItem = this.recoverStorageItem(item);
        return storageItem ? storageItem.data : undefined;
    }

    // set persistent data
    set(key: string, data: any) {
        const storageItemAsStr = JSON.stringify({ data });
        sessionStorage.setItem(key, storageItemAsStr);
    }

    clear(key: string) {
        sessionStorage.removeItem(key);
    }

    // recover StorageItem from storage string
    private recoverStorageItem(storageItemAsStr: string | null): IStorageItem | null {
        if (storageItemAsStr === null) {
            return null;
        } else {
            return JSON.parse(storageItemAsStr);
        }
    }
}

export default new SessionStorageService();
