type ThemeBorderRadiusKeys = 'xSmall' | 'small' | 'medium' | 'large' | 'regular' | 'roundedSides' | 'xLarge' | 'full';
type ThemeFontWeightsKeys = 'small' | 'normal' | 'medium' | 'bold' | 'xBold';
type ThemeFontSizesKeys = 'xSmall' | 'small' | 'normal' | 'medium' | 'large' | 'xLarge' | 'xxLarge' | 'xxxLarge';
type ThemeLineHeightsKeys = 'xSmall' | 'small' | 'normal' | 'medium' | 'large' | 'xLarge';
type ThemeFontFamiliesKeys = 'light' | 'regular' | 'bold' | 'xBold';
type ThemeBorderRadius = Record<ThemeBorderRadiusKeys, `${number}px` | `${number}%`>;
type ThemeFontWeights = Record<ThemeFontWeightsKeys, number>;
type ThemeFontSizes = Record<ThemeFontSizesKeys, `${number}px`>;
type ThemeLineHeights = Record<ThemeLineHeightsKeys, `${number}px`>;
type ThemeFontFamilies = Record<ThemeFontFamiliesKeys, string>;

export interface ExtraThemeOptions {
    borderRadius: ThemeBorderRadius;
    fontWeights: ThemeFontWeights;
    fontSizes: ThemeFontSizes;
    lineHeights: ThemeLineHeights;
    fontFamilies: ThemeFontFamilies;
}
// these props can be access from theme (ex: theme.borderRadius.small)

export const extraThemeOptions: ExtraThemeOptions = {
    borderRadius: {
        xSmall: '4px',
        small: '6px',
        medium: '12px',
        regular: '15px',
        large: '20px',
        roundedSides: '23px',
        xLarge: '30px',
        full: '50%',
    },
    fontWeights: {
        small: 300,
        normal: 400,
        medium: 500,
        bold: 700,
        xBold: 900,
    },
    fontSizes: {
        xSmall: '10px',
        small: '12px',
        normal: '14px',
        medium: '16px',
        large: '18px',
        xLarge: '24px',
        xxLarge: '28px',
        xxxLarge: '36px',
    },
    lineHeights: {
        xSmall: '12px',
        small: '14px',
        normal: '16px',
        medium: '18px',
        large: '20px',
        xLarge: '28px',
    },
    fontFamilies: {
        light: 'OpenSansHebrew-Light',
        regular: 'OpenSansHebrew-Regular',
        bold: 'OpenSansHebrew-Bold',
        xBold: 'OpenSansHebrew-ExtraBold',
    },
};
