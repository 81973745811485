import React, { FC, ReactNode } from 'react';
import { Box, Typography } from 'components/atoms';
import CardWithInfo from 'components/molecules/CardWithInfo';
import { Control } from 'react-hook-form';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useTheme } from '@mui/material';
import ControllerTextField from 'components/organisms/ControllerTextField';
import { TextFieldType } from 'components/atoms/TextField';

interface IProps {
    isEdit: boolean;
    control: Control;
    name: string;
    title: string;
    value: string;
    specialChar?: string;
    children?: any;
    rules?: Object;
    type?: TextFieldType;
    component?: ReactNode;
    step?: number;
    info?: {
        firstText?: string;
        secondTitle?: string;
        firstTitle?: string;
        secondText?: string;
    };
}

const PricingCardWithInfo: FC<IProps> = ({
    isEdit,
    control,
    name,
    title,
    value,
    specialChar,
    children,
    rules,
    type,
    component,
    step,
    info,
}) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();

    return (
        <CardWithInfo
            border={false}
            title={title}
            firstTitle={info?.firstTitle}
            firstText={info?.firstText}
            secondTitle={info?.secondTitle}
            secondText={info?.secondText}
        >
            <Box display='flex'>
                {!isEdit ? (
                    <>
                        {isDesktop ? (
                            <Box display='flex' alignItems={children && 'center'} width='100%' gap={10}>
                                {children}
                                <Typography.Body2 font={theme.fontFamilies.light}>
                                    {value}
                                    {specialChar}
                                </Typography.Body2>
                            </Box>
                        ) : (
                            <Box display='flex' flexDirection='column' alignItems={children && 'center'} width='100%' gap={20}>
                                {children}
                                <Typography.Body3 font={theme.fontFamilies.light}>
                                    {value}
                                    {specialChar}
                                </Typography.Body3>
                            </Box>
                        )}
                    </>
                ) : (
                    <>
                        {!isDesktop && children}
                        <Box display='flex' alignItems={children && 'center'}>
                            {isDesktop && children}
                            <Box display='flex' columnGap={'4px'} alignItems={'center'}>
                                {specialChar && specialChar}
                                {component ? (
                                    component
                                ) : (
                                    <ControllerTextField control={control} name={name} rules={rules} type={type} step={step} />
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </CardWithInfo>
    );
};

export default PricingCardWithInfo;
