import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { UploadImageReq } from 'models/api/management';

export const uploadFile = async (
    itemId: string | undefined,
    file: unknown,
    field: string,
    mutate: ({ formData, config }: UploadImageReq) => Promise<AxiosResponse<any, any>>,
    config?: AxiosRequestConfig
) => {
    const formData = new FormData();
    formData.append('file', file as File);
    formData.append('field', field);
    itemId && formData.append('item_id', itemId);
    return await mutate({ formData, config });
};
