import { Icon } from 'components/atoms';
import { RatingContractorCategories } from 'models/OffersData';
import { EmployeeTypes } from 'models/api/common';
import { Constractor, IEntrepreneurQuotationDetails, QuotationDetails } from 'models/api/offers';
import { addCommaToNumber } from 'utils/addCommaToNumber';
import { renderCorrectIconYesNo, renderCorrectIconEmployeeType, renderPaymentTermsIconYesNo } from 'utils/renderCorrectIcon';
import { Symbols, addSymbol } from 'utils/symbols';
import texts from 'texts.json';
import { parseToNumber } from 'utils/parseToNumber';
import { isVatIncludes } from 'utils/isVatIncludes';
import { getEntrepreneurTermsOfPayment } from 'utils/entrepreneurTermsOfPayment.util';

const {
    offers: {
        contractor: {
            accordions: { offerDetails, paymentTerms, aboutCompany },
        },
        entrepreneur: {
            accordions: { offerDetails: entrepreneurOfferDetails, paymentTerms: entrepreneurPaymentTerms },
        },
        accordionSwiper: { roofType, projectPower },
    },
} = texts;

export const headerDataProcessing = (quotation: any) => {
    return {
        id: quotation.id,
        rate: quotation.rate || null,
        item_id: quotation.item_id,
    };
};

export const buildAccordionOfferDetailsData = (data: QuotationDetails | IEntrepreneurQuotationDetails, isContractor: boolean) => {
    const quotationDetails = data as QuotationDetails;
    const entrepreneurQuotationDetails = data as IEntrepreneurQuotationDetails;
    const isVat = isVatIncludes(quotationDetails.vat_included);
    return isContractor
        ? {
              header: [
                  {
                      title: offerDetails.expectedAnnualReturn.title,
                      value: `${addCommaToNumber(+quotationDetails.result_irr || 0)}%`,
                      icon: <Icon.Coins />,
                  },
                  {
                      title: offerDetails.investmentReturnTime.title,
                      value: `${quotationDetails.investment_return_time || ''} שנים`,
                      icon: <Icon.Time width='33px' height='33px' fill='primary.black' />,
                  },
                  {
                      title: offerDetails.totalPrice.title,
                      value: addSymbol(addCommaToNumber(parseToNumber(quotationDetails.final_price))),
                      isVat: isVat,
                  },
              ],
              details: [
                  {
                      title: offerDetails.costs.title,
                      categories: null,
                      info: offerDetails.costs.info,
                      isVat: isVat,
                      items: [
                          {
                              name: offerDetails.costs.items.pricePerKw,
                              first: addSymbol(isVat ? quotationDetails.price_per_kw_vat : quotationDetails.price_per_kw),
                          },
                          {
                              name: offerDetails.totalPrice.title,
                              first: addSymbol(isVat ? quotationDetails.total_price_vat : quotationDetails.total_price),
                          },
                          {
                              name: offerDetails.costs.items.additionsPrice,
                              first: addSymbol(isVat ? quotationDetails.additions_price_vat : quotationDetails.additions_price),
                          },
                          //   { name: 'עלות אגרות חח"י', first: addSymbol(quotationDetails) },
                      ],
                  },
                  {
                      title: offerDetails.equipment.title,
                      categories: [offerDetails.equipment.categories.company, offerDetails.equipment.categories.warranty],
                      info: offerDetails.equipment.info,
                      items: [
                          {
                              name: offerDetails.equipment.items.recommendedPanelType,
                              first: quotationDetails.price_list_quotation?.recommended_panel_type || '',
                              second: `${quotationDetails.price_list_quotation?.panels_warranty_years || ''} שנים`,
                          },
                          {
                              name: offerDetails.equipment.items.selectedConverterCompany,
                              first: quotationDetails.selected_converter_company || '',
                              second: '10 שנים',
                          },
                          {
                              name: offerDetails.equipment.items.recommendedConstruction,
                              first: quotationDetails.price_list_quotation?.recommended_construction || '',
                              second: `${quotationDetails.price_list_quotation?.construction_warranty_years || ''} שנים`,
                          },
                          {
                              name: offerDetails.equipment.items.installationWarrantyYears,
                              first: 'החברה המציעה', // for now
                              second: `${quotationDetails.price_list_quotation?.installation_warranty_years || ''} שנים`,
                          },
                      ],
                  },
                  {
                      title: offerDetails.maintenance.title,
                      categories: [offerDetails.maintenance.categories.perOneKw, offerDetails.maintenance.categories.total],
                      info: offerDetails.maintenance.info,
                      isVat: isVat,
                      items: [
                          {
                              name: offerDetails.maintenance.items.monitoring,
                              first: addSymbol(quotationDetails.price_list_quotation?.monitoring),
                              second: addSymbol(quotationDetails.total_monitoring),
                          },
                          {
                              name: offerDetails.maintenance.items.maintenance_sprayers,
                              first: addSymbol(quotationDetails.price_list_quotation?.maintenance_sprayers),
                              second: addSymbol(quotationDetails.total_maintenance_sprayers),
                          },
                          {
                              name: offerDetails.maintenance.items.full_maintenance,
                              first: addSymbol(quotationDetails.price_list_quotation?.full_maintenance),
                              second: addSymbol(quotationDetails.total_full_maintenance),
                          },
                      ],
                  },
              ],
          }
        : {
              header: [
                  {
                      title: entrepreneurOfferDetails.agreementTime,
                      value: `25 שנה`,
                      icon: <Icon.Time width='32px' height='32px' fill='black' />,
                  },
                  {},
                  {
                      title: entrepreneurOfferDetails.incomeAllLifeOfSystem,
                      value: `${entrepreneurQuotationDetails.total_income} ${Symbols.SHEKEL}`,
                  },
              ],
              details: [
                  {
                      title: entrepreneurOfferDetails.rentFromIncome,
                      value: `${Symbols.PERCENT} ${entrepreneurQuotationDetails.rent_percent}`,
                  },
                  {
                      title: entrepreneurOfferDetails.averageMonthlyIncome,
                      value: `${Symbols.SHEKEL}${entrepreneurQuotationDetails.avg_rent}`,
                  },
              ],
          };
};

export const buildAccordionOfferPaymentTermsData = (data: QuotationDetails | IEntrepreneurQuotationDetails, isContractor: boolean) => {
    const quotationDetails = data as QuotationDetails;
    const entrepreneurQuotationDetails = data as IEntrepreneurQuotationDetails;
    if (isContractor) {
        return [
            {
                title: paymentTerms.advancePayment,
                value: `${Symbols.SHEKEL}${quotationDetails?.advance_payment || ''}`,
                expectedReturn: quotationDetails?.price_list_quotation?.advance_payment,
            },
            {
                title: paymentTerms.permitAndConstruction,
                value: `${Symbols.SHEKEL}${quotationDetails?.permit_and_construction || ''}`,
                expectedReturn: quotationDetails?.price_list_quotation?.permit_and_construction,
            },
            {
                title: paymentTerms.panelsAndConverters,
                value: `${Symbols.SHEKEL}${quotationDetails?.panels_and_converters || ''}`,
                expectedReturn: quotationDetails?.price_list_quotation?.panels_and_converters,
            },
            {
                title: paymentTerms.finishAndTest,
                value: `${Symbols.SHEKEL}${quotationDetails?.finish_and_test || ''}`,
                expectedReturn: quotationDetails?.price_list_quotation?.finish_and_test,
            },
            {
                title: paymentTerms.systemConnection,
                value: `${Symbols.SHEKEL}${quotationDetails?.system_connection || ''}`,
                expectedReturn: quotationDetails?.price_list_quotation?.system_connection,
            },
        ];
    } else {
        const { isMonthly, isQuarterly, isYearly } = getEntrepreneurTermsOfPayment(entrepreneurQuotationDetails.terms_of_payment);
        return [
            {
                title: entrepreneurPaymentTerms.signingBonus,
                expectedReturn: null,
                value: `${entrepreneurQuotationDetails?.signing_bonus}${Symbols.SHEKEL}`,
            },
            {
                title: entrepreneurPaymentTerms.monthly,
                expectedReturn: null,
                value: renderPaymentTermsIconYesNo(isMonthly),
            },
            {
                title: entrepreneurPaymentTerms.quarter,
                expectedReturn: null,
                value: renderPaymentTermsIconYesNo(isQuarterly),
            },
            {
                title: entrepreneurPaymentTerms.yearly,
                expectedReturn: null,
                value: renderPaymentTermsIconYesNo(isYearly),
            },
        ];
    }
};

export const buildAccordionOfferAboutCompanyData = ({ constractor }: any) => {
    const company: Constractor = constractor || {};
    return {
        about: company.about,
        experienceData: [
            {
                title: aboutCompany.yearsInTheField,
                value: `${company?.years_in_the_field || ''} שנים`,
            },
            {
                title: aboutCompany.similarProjects,
                value: company?.similar_projects || '',
            },
            {
                title: aboutCompany.constractorTotalSystems,
                value: `${company?.constractor_total_systems || ''} קוו"ט`,
            },
            {
                title: aboutCompany.totalSystemsMaintainedKwp,
                value: `${company?.total_systems_maintained_kwp || ''} קוו"ט`,
            },
        ],
        professionalTeamData: [
            {
                title: aboutCompany.professionalTeam.electricalEngineer.title,
                icon: renderCorrectIconEmployeeType(company?.electrical_engineer),
                text: EmployeeTypes[company?.electrical_engineer],
                info: aboutCompany.professionalTeam.electricalEngineer.info,
            },
            {
                title: aboutCompany.professionalTeam.electrician.title,
                icon: renderCorrectIconEmployeeType(company?.electrician),
                text: EmployeeTypes[company?.electrician],
                info: aboutCompany.professionalTeam.electrician.info,
            },
            {
                title: aboutCompany.professionalTeam.engineeringConstructor.title,
                icon: renderCorrectIconEmployeeType(company?.engineering_constructor),
                text: EmployeeTypes[company?.engineering_constructor],
                info: aboutCompany.professionalTeam.engineeringConstructor.info,
            },
            {
                title: aboutCompany.professionalTeam.maintenance.title,
                icon: renderCorrectIconEmployeeType(company?.maintenance),
                text: EmployeeTypes[company?.maintenance],
                info: aboutCompany.professionalTeam.maintenance.info,
            },
            {
                title: aboutCompany.professionalTeam.projectManager.title,
                icon: renderCorrectIconEmployeeType(company?.project_manager),
                text: EmployeeTypes[company?.project_manager],
                info: aboutCompany.professionalTeam.projectManager.info,
            },
            {
                title: aboutCompany.professionalTeam.planning.title,
                icon: renderCorrectIconEmployeeType(company?.planning),
                text: EmployeeTypes[company?.planning],
                info: aboutCompany.professionalTeam.planning.info,
            },
            {
                title: aboutCompany.professionalTeam.monitoring.title,
                icon: renderCorrectIconEmployeeType(company?.monitoring),
                text: EmployeeTypes[company?.monitoring],
                info: aboutCompany.professionalTeam.monitoring.info,
            },
        ],
        qualityData: [
            {
                title: aboutCompany.qualityData.iso9001.title,
                boldTitle: 'ISO 9001',
                icon: renderCorrectIconYesNo(company?.iso9001),
                info: aboutCompany.qualityData.iso9001.info,
            },
            {
                title: aboutCompany.qualityData.regConst160.title,
                boldTitle: '160',
                icon: renderCorrectIconYesNo(company?.reg_const_160),
                info: aboutCompany.qualityData.regConst160.info,
            },
            {
                title: aboutCompany.qualityData.regConst191.title,
                boldTitle: '191',
                icon: renderCorrectIconYesNo(company?.reg_const_191),
                info: aboutCompany.qualityData.regConst191.info,
            },
            {
                title: aboutCompany.qualityData.defenseMinistrySupplier.title,
                boldTitle: '',
                icon: renderCorrectIconYesNo(company?.defense_ministry_supplier),
                info: aboutCompany.qualityData.defenseMinistrySupplier.info,
            },
            {
                title: aboutCompany.qualityData.worksAtHeights.title,
                boldTitle: '',
                icon: renderCorrectIconYesNo(company?.works_at_heights),
                info: aboutCompany.qualityData.worksAtHeights.info,
            },
        ],
    };
};

export const buildAccordionOfferRatingContractorData = (constractor: any) => {
    const company: Constractor = constractor || {};

    return {
        categories: [
            {
                name: RatingContractorCategories.Professionalism,
                value: company.professionalism_avg || '',
                ratersNumber: company.professionalism_count || '',
            },
            {
                name: RatingContractorCategories.Service,
                value: company.service_quality_avg || '',
                ratersNumber: company.service_quality_count || '',
            },

            {
                name: RatingContractorCategories.Timeliness,
                value: company.schedule_avg || '',
                ratersNumber: company.schedule_count || '',
            },
            {
                name: RatingContractorCategories.Safety,
                value: company.safety_rules_avg || '',
                ratersNumber: company.safety_rules_count || '',
            },
            {
                name: RatingContractorCategories.Execution,
                value: company.execution_avg || '',
                ratersNumber: company.execution_count || '',
            },
            {
                name: RatingContractorCategories.Overall,
                value: company.general_satisfaction_avg || '',
                ratersNumber: company.general_satisfaction_count || '',
            },
        ],
        recommendations:
            company?.recommendations?.map((recommendation: any) => ({
                name: recommendation.recommender_name || '',
                text: recommendation.description || '',
                categories: [
                    {
                        name: RatingContractorCategories.Professionalism,
                        value: recommendation.professionalism || '',
                    },
                    {
                        name: RatingContractorCategories.Service,
                        value: recommendation.service_quality || '',
                    },
                    {
                        name: RatingContractorCategories.Timeliness,
                        value: recommendation.schedule || '',
                    },
                    {
                        name: RatingContractorCategories.Safety,
                        value: recommendation.safety_rules || '',
                    },
                    {
                        name: RatingContractorCategories.Execution,
                        value: recommendation.execution || '',
                    },
                    {
                        name: RatingContractorCategories.Overall,
                        value: recommendation.general_satisfaction || '',
                    },
                ],
            })) || [],
    };
};

export const buildAccordionOfferSwiperData = (
    similar_roof_type_images: QuotationDetails['similar_roof_type_images'],
    similar_project_power_images: QuotationDetails['similar_project_power_images']
) => ({
    roofType: {
        title: roofType,
        images: similar_roof_type_images?.map((item) => item),
    },
    projectPower: {
        title: projectPower,
        images: similar_project_power_images?.map((item) => item),
    },
});
