import { Autocomplete as MuiAutocomplete, styled, TextField, Popper, PopperProps } from '@mui/material';
import React, { FC, ReactNode, SyntheticEvent } from 'react';
import { Icon } from 'components/atoms';
import { theme } from 'theme';
import { ISearchByAddressOption } from 'models/MapData';

const sx = {
    listBox: {
        direction: 'rtl',
        color: theme.palette.primary.black,
        '& .MuiAutocomplete-option': {
            justifyContent: 'flex-end',
            '&.MuiAutocomplete-option[aria-selected="true"]': {
                backgroundColor: theme.palette.primary.goldenYellow,
            },
        },
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.primary.scrollbarBackground,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.purple,
            borderRadius: '20px',
        },
    },
};

interface IAutocompleteBase {
    options: ISearchByAddressOption[] | any[] | null;
    value: ISearchByAddressOption | any | null;
    onChange: (event: SyntheticEvent<Element, Event>, value: ISearchByAddressOption | any | null) => void;
    placeholder?: string;
    onInputChange?: (event: SyntheticEvent<Element, Event>, value: string | null) => void;
    getOptionLabel?: (option: ISearchByAddressOption | any) => string;
    isOptionEqualToValue?: (option: ISearchByAddressOption, value: any) => boolean;
    loading?: boolean;
    renderOption?: (props: any, option: any) => ReactNode;
    inputValue?: string;
    freeSolo?: boolean;
    disabled?: boolean;
    open?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
}

const AutocompleteBase: any = styled(MuiAutocomplete)<IAutocompleteBase>(({ theme, open }) => ({
    '& .MuiOutlinedInput-root': {
        padding: '1.5rem',
        fontSize: theme.fontSizes.xLarge,
        fontFamily: theme.fontFamilies.light,
        borderRadius: theme.borderRadius.large,
        fontWeight: theme.fontWeights.small,
        [theme.breakpoints.down('sm')]: {
            padding: '5px',
            fontSize: theme.fontSizes.medium,
        },
        '& input::placeholder': {
            opacity: 0.7,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.borderGrey,
        },
        '&.Mui-focused': {
            borderRadius: open ? `${theme.borderRadius.large} ${theme.borderRadius.large} 0 0` : theme.borderRadius.large,
            '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.primary.borderGrey}`,
            },
        },
        '& .MuiAutocomplete-endAdornment': {
            marginRight: '20px',
        },
    },
}));

const Autocomplete: FC<IAutocompleteBase> = ({
    options,
    value,
    onChange,
    placeholder,
    onInputChange,
    getOptionLabel,
    isOptionEqualToValue,
    loading,
    renderOption,
    inputValue,
    freeSolo = false,
    disabled = false,
    open,
    onOpen,
    onClose,
}) => {
    return (
        <AutocompleteBase
            disabled={disabled}
            value={value}
            onChange={onChange}
            options={options}
            renderInput={(params: any) => <TextField {...params} InputLabelProps={{ shrink: true }} placeholder={placeholder} />}
            filterOptions={(x: any) => x}
            popupIcon={<Icon.DownArrow />}
            // noOptionsText={!loading ? 'אין תוצאות' : 'טוען...'}
            loadingText={'טוען...'}
            PopperComponent={(props: any) => <AutocompletePopper {...props} />}
            ListboxProps={{
                sx: sx.listBox,
            }}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            onInputChange={onInputChange}
            isOptionEqualToValue={isOptionEqualToValue}
            loading={loading}
            inputValue={inputValue}
            freeSolo={freeSolo}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            disablePortal={true}
        />
    );
};

export default Autocomplete;

const AutocompletePopperBase = styled(Popper)<PopperProps>(({ theme }) => ({
    '& .MuiAutocomplete-paper': {
        borderRadius: `0 0 ${theme.borderRadius.large} ${theme.borderRadius.large}`,
        border: `1px solid ${theme.palette.primary.borderGrey}`,
        padding: '25px',
        boxShadow: 'none',
    },
}));

const AutocompletePopper: FC<PopperProps> = (props) => {
    return <AutocompletePopperBase {...props} />;
};
