import { ChartOptions, TooltipItem, Chart } from 'chart.js';
import './chart.css';
import { addCommaToNumber } from 'utils/addCommaToNumber';

const getOrCreateTooltip = (chart: Chart) => {
    let tooltipEl = chart?.canvas?.parentNode?.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        const span = document.createElement('span');
        tooltipEl.appendChild(span);
        chart?.canvas?.parentNode?.appendChild(tooltipEl);
    }

    tooltipEl.classList.add('tooltip');
    return tooltipEl;
};

const externalTooltipHandler = (context: any) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }

    // Set Text
    if (tooltip.body) {
        const [sum] = tooltip.title;
        const span = tooltipEl.querySelector('span');

        // Remove old children
        while (span?.firstChild) {
            span.firstChild.remove();
        }
        const newSpan = tooltipEl.querySelector('span');
        const text = document.createTextNode(addCommaToNumber(Math.floor(sum)).toString());
        newSpan?.appendChild(text);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.fontFamily = 'OpenSansHebrew-Bold';
    tooltipEl.style.fontSize = '14px';
    tooltipEl.style.color = '#1d1d1b';
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
};

export const options: ChartOptions<'bar'> = {
    interaction: {
        mode: 'index',
        axis: 'y',
    },
    transitions: {
        pan: {
            animation: {
                duration: 0,
            },
        },
    },
    plugins: {
        title: {
            display: true,
        },
        zoom: {
            pan: {
                enabled: false,
                mode: 'x' as any,
                scaleMode: 'x' as any,
                threshold: 5,
            },
        },
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            position: 'top' as 'average',
            external: externalTooltipHandler,
            callbacks: {
                title: function (this: any, tooltipItems: TooltipItem<'bar'>[]): string | void | string[] {
                    return tooltipItems.reduce((acc: any, item: TooltipItem<'bar'>) => acc + item.raw, 0);
                },
            },
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    // scales: {
    //     x: {
    //         stacked: true,
    //         title: {
    //             display: true,
    //             text: '',
    //         },
    //     },
    //     y: {
    //         stacked: true,
    //         title: {
    //             display: true,
    //             text: '',
    //         },
    //     },
    // },
};
