import { ChartData } from 'chart.js';
import { parseToNumber, valuesToNumber } from 'utils/parseToNumber';
import { Symbols } from 'utils/symbols';
import texts from 'texts.json';
import { IElectricityProductionItem } from 'models/common';
import {
    PurchaseEconomicModelObject,
    IRentEconomicModelObject,
    IPurchaseEconomicModel,
    IRentEconomicModel,
} from 'models/economicModel.model';

const { economicModel, entrepreneurEconomicModel } = texts;

export const economicModelTableCategories = (isVatIncluded: boolean) => {
    const title = economicModel.incomeLessOperationCost;
    if (!isVatIncluded) {
        title.info.secondTitle = '';
    }
    return [
        {
            title: economicModel.year,
            name: 'year',
        },
        {
            title: economicModel.grossIncome,
            name: 'gross_income',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title,
            name: 'income_less_operating_cost',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: economicModel.financingPayments,
            name: 'financing_payments',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: economicModel.netProfit,
            name: 'net_profit',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: economicModel.totalReturn,
            name: 'total_return',
            specialChar: `${Symbols.PERCENT}`,
        },
        {
            title: economicModel.equityReturn,
            name: 'equity_return',
            specialChar: `${Symbols.PERCENT}`,
        },
    ];
};

export const economicEntrepreneurModelTableCategories = [
    {
        title: economicModel.year,
        name: 'year',
    },
    {
        title: entrepreneurEconomicModel.income,
        name: 'income',
        specialChar: `${Symbols.SHEKEL}`,
    },
];

export const economicModelChartData = (data: PurchaseEconomicModelObject[]): ChartData<'bar', number[]> => {
    const { operating_cost, financing_payments, net_profit } = texts.economicModel.chart;
    return {
        labels: data.map((item) => item.year),
        datasets: [
            {
                label: operating_cost,
                data: data.map((item) => parseToNumber(item.operating_cost)),
                backgroundColor: '#d2d2d2',
                barThickness: 8,
            },
            {
                label: financing_payments,
                data: data.map((item) => parseToNumber(item.financing_payments)),
                backgroundColor: '#ffeab2',
                barThickness: 8,
            },
            {
                label: net_profit,
                data: data.map((item) => parseToNumber(item.net_profit)),
                backgroundColor: '#ac4385',
                borderRadius: 15,
                barThickness: 8,
            },
        ],
    };
};

export const economicModelMobileHeadCategories = [
    {
        title: economicModel.year,
        name: 'year',
    },
    {
        title: economicModel.grossIncome,
        name: 'gross_income',
        specialChar: `${Symbols.SHEKEL}`,
    },
    {
        title: economicModel.equityReturn,
        name: 'equity_return',
        specialChar: `${Symbols.PERCENT}`,
    },
];

export const economicModelMobileCategories = (isVatIncluded: boolean) => {
    const title = economicModel.incomeLessOperationCost;
    if (!isVatIncluded) {
        title.info.secondTitle = '';
    }
    return [
        {
            title,
            name: 'income_less_operating_cost',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: economicModel.financingPayments,
            name: 'financing_payments',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: economicModel.netProfit,
            name: 'net_profit',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: economicModel.totalReturn,
            name: 'total_return',
            specialChar: `${Symbols.PERCENT}`,
        },
    ];
};

export const economicModelData = (data: IPurchaseEconomicModel, isVatIncluded: boolean) => {
    return {
        data: data.years,
        total: data.total,
        npvRow: data.npv,
        categories: economicModelTableCategories(isVatIncluded),
        mobileHeadTitles: economicModelMobileHeadCategories,
        mobileCategories: economicModelMobileCategories(isVatIncluded),
        chart: economicModelChartData(data.years as PurchaseEconomicModelObject[]),
    };
};

export const entrepreneurEconomicModelData = (data: IRentEconomicModel) => {
    return {
        data: data,
        total: data.total,
        categories: economicEntrepreneurModelTableCategories,
        mobileHeadTitles: economicEntrepreneurModelTableCategories,
        mobileCategories: economicEntrepreneurModelTableCategories,
        chart: null,
    };
};

export const buildElectricityConnectionData = (data: any) => {
    if (!data) return [] as IElectricityProductionItem[];
    const { ac_apr, ac_aug, ac_dec, ac_feb, ac_jan, ac_jul, ac_jun, ac_mar, ac_may, ac_nov, ac_oct, ac_sep } = data;
    const electricity_production = [
        { month: '1', value: ac_jan },
        { month: '2', value: ac_feb },
        { month: '3', value: ac_mar },
        { month: '4', value: ac_apr },
        { month: '5', value: ac_may },
        { month: '6', value: ac_jun },
        { month: '7', value: ac_jul },
        { month: '8', value: ac_aug },
        { month: '9', value: ac_sep },
        { month: '10', value: ac_oct },
        { month: '11', value: ac_nov },
        { month: '12', value: ac_dec },
    ];
    return electricity_production;
};

export const buildPurchaseEconomicModelData = (economicModel: any[]) =>
    economicModel?.length
        ? economicModel?.reduce(
              (acc: any, curr: any) => {
                  if (curr.year === 'Total') {
                      acc.total = curr;
                  } else if (curr.year === 'NPV') {
                      if (curr) {
                          acc.npv = curr;
                      }
                  } else {
                      acc.years.push(curr);
                  }
                  return acc;
              },
              { years: [] as any[], total: {} as any, npv: {} as any }
          )
        : null;
