import { useTheme } from '@mui/material';
import TextWithLink from 'components/molecules/TextWithLink';
import { Box } from 'components/atoms';
import { AuthContext } from 'contexts/AuthContext';
import texts from 'texts.json';
import { useContext } from 'react';
import { Dialogs } from 'models/api/auth';

const SignUpHeader = () => {
    const { signUpCompany, signedUpUser } = texts.signUp.header;

    const theme = useTheme();
    const { setShowDialog } = useContext(AuthContext);

    return (
        <Box textAlign='center' display='flex' flexDirection='column' gap='8px' mt={'16px'}>
            <TextWithLink
                data={{
                    text: signedUpUser.text,
                    linkText: signedUpUser.linkText,
                    onClick: () => setShowDialog(Dialogs.LOGIN),
                }}
                colorLink={theme.palette.primary.purple}
            />
            <TextWithLink
                data={{
                    text: signUpCompany.text,
                    linkText: signUpCompany.linkText,
                    onClick: () => window.open(signUpCompany.link, '_blank', 'noopener noreferrer'),
                }}
                colorLink={theme.palette.primary.purple}
            />
        </Box>
    );
};

export default SignUpHeader;
