import { Box, Pagination } from 'components/atoms';
import MyOfferCard from 'components/molecules/MyOfferCard';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { IMyOffer } from 'models/myOffers';
import { FC, useState } from 'react';

const ITEMS_PER_PAGE = 25;

interface IProps {
    offers: IMyOffer[];
    isDownloadQuotation?: boolean;
    totalItems?: number;
    mainButtonHandler: (offer: IMyOffer) => void;
    secondaryButtonHandler?: (offer: IMyOffer) => void;
}

const MyProjects: FC<IProps> = ({ offers, mainButtonHandler, secondaryButtonHandler, isDownloadQuotation, totalItems = 0 }) => {
    const isDesktop = useIsDesktop();

    const [page, setPage] = useState(1);

    const paginatedData = offers?.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
    const count = Math.ceil(totalItems / ITEMS_PER_PAGE);
    const data = isDesktop ? paginatedData : offers;

    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={'20px'} mt={'50px'}>
            {data?.map((offer) => (
                <MyOfferCard
                    key={offer.item_id}
                    offer={offer}
                    mainButtonHandler={() => mainButtonHandler(offer)}
                    secondaryButtonHandler={() => secondaryButtonHandler?.(offer)}
                    isDownloadQuotation={isDownloadQuotation}
                />
            ))}
            {count && isDesktop ? (
                <Box dir='ltr' sx={{ display: 'flex', justifyContent: 'center', my: '16px' }}>
                    <Pagination count={count} page={page} onChange={(e, value) => setPage(value)} />
                </Box>
            ) : null}
        </Box>
    );
};

export default MyProjects;
