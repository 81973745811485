import { PATH } from 'paths';
import { useLocation } from 'react-router-dom';

const useGetCalculatorBackPath = () => {
    const path = useLocation().pathname;

    switch (path) {
        case `/${PATH.CALCULATOR}/${PATH.ADDRESS}/${PATH.GET_ADDRESS}`:
        case `/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.MARKING}`: {
            return `/${PATH.CALCULATOR}/${PATH.ADDRESS}`;
        }
        case `/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.PLACEMENT_ELEMENTS}`: {
            return `/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.MARKING}`;
        }
        case `/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.TYPE}`: {
            return `/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.PLACEMENT_ELEMENTS}`;
        }
        case `/${PATH.CALCULATOR}/${PATH.QUESTIONNAIRE}/${PATH.ROOF_ACCESS}`: {
            return `/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.TYPE}`;
        }
        case `/${PATH.CALCULATOR}/${PATH.QUESTIONNAIRE}/${PATH.FUNDING}`: {
            return `/${PATH.CALCULATOR}/${PATH.QUESTIONNAIRE}/${PATH.ROOF_ACCESS}`;
        }
        default: {
            return `/${PATH.CALCULATOR}/${PATH.ADDRESS}`;
        }
    }
};

export default useGetCalculatorBackPath;
