import React, { FC, ReactNode } from 'react';
import { Box, Tab, Tabs } from 'components/atoms';
import { useTheme } from '@mui/material';
import { ContractTypeModel } from 'models/common';

interface IProps {
    data: {
        value: string;
        label: string;
        children: ReactNode;
        info?: string;
    }[];
    isResult?: boolean;
    tab: string;
    setTab: (tab: ContractTypeModel) => void;
}

const TabsCalculator: FC<IProps> = ({ data, isResult, tab, setTab }) => {
    const theme = useTheme();

    const renderInput = () => data.find((item) => item.value === tab)?.children;

    return (
        <Box width='100%' pb='16px' pt='50px'>
            <Box width={isResult ? { md: '35%', xs: '100%' } : '100%'} mx='auto'>
                <Tabs
                    value={tab}
                    onChange={(event: React.SyntheticEvent, newValue: string) => setTab(newValue as ContractTypeModel)}
                    color={isResult ? theme.palette.primary.purple : null}
                >
                    {data.map((item) => (
                        <Tab key={item.value} value={item.value} label={item.label} isResult={isResult} info={item.info} />
                    ))}
                </Tabs>
            </Box>
            <Box marginTop={'23px'}>{renderInput()}</Box>
        </Box>
    );
};

export default TabsCalculator;
