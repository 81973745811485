import React, { FC, ReactNode, SyntheticEvent } from 'react';
import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    SxProps,
    styled,
    Theme,
} from '@mui/material';
import { Icon, Box } from 'components/atoms';

export interface IAccordionProps {
    children: NonNullable<ReactNode>;
    expanded?: boolean;
    bgcolor?: string;
    unmountOnExit?: boolean;
    onChange?: (event: SyntheticEvent, isExpanded: boolean) => void;
}

interface ISummaryProps {
    children: ReactNode;
    isOpenSummaryStyle?: boolean;
    isIcon?: boolean;
}

interface IDetailsProps {
    children: ReactNode;
    sx?: SxProps<Theme>;
}

const AccordionBase = styled(MuiAccordion, {
    shouldForwardProp: (prop) => prop !== 'bgcolor',
})<IAccordionProps>(({ theme, bgcolor }) => ({
    boxShadow: 'none',
    borderRadius: theme.borderRadius.large,
    padding: '0 30px 0 30px',
    backgroundColor: bgcolor ? bgcolor : theme.palette.primary.white,
    '&:before': {
        all: 'unset',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0',
    },
}));

const Accordion: FC<IAccordionProps> = ({ children, expanded, onChange, bgcolor, unmountOnExit = true }) => {
    return (
        <AccordionBase
            square={true}
            disableGutters={true}
            TransitionProps={{ unmountOnExit }}
            expanded={expanded}
            onChange={onChange}
            bgcolor={bgcolor}
        >
            {children}
        </AccordionBase>
    );
};

export default Accordion;

const AccordionSummaryBase = styled(MuiAccordionSummary, {
    shouldForwardProp: (prop: string) => prop !== 'isOpenSummaryStyle' && prop !== 'isIcon',
})<ISummaryProps>(({ theme, isOpenSummaryStyle }) => ({
    height: '95px',
    fontSize: '24px',
    fontFamily: theme.fontFamilies.bold,
    color: theme.palette.primary.purple,
    borderRadius: theme.borderRadius.large,
    '&.Mui-focusVisible': {
        backgroundColor: 'unset',
    },
    '&.Mui-expanded': {
        backgroundColor: isOpenSummaryStyle ? theme.palette.primary.greyBackground : '',
        borderRadius: isOpenSummaryStyle ? `${theme.borderRadius.large} ${theme.borderRadius.large} 0 0` : theme.borderRadius.large,
    },
    [theme.breakpoints.down('md')]: {
        padding: '0 16px',
        height: '72px',
        fontSize: '16px',
    },
    [theme.breakpoints.up('md')]: { '& .MuiAccordionSummary-content': { margin: 0, paddingTop: '24px', height: '100%' } },
}));

export const AccordionSummary: FC<ISummaryProps> = ({ children, isOpenSummaryStyle, isIcon = true }) => (
    <AccordionSummaryBase isOpenSummaryStyle={isOpenSummaryStyle} isIcon={isIcon} expandIcon={isIcon ? <Icon.DownArrow /> : null}>
        {children}
    </AccordionSummaryBase>
);

const AccordionDetailsBase = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '0 0 30px 0',
}));

export const AccordionDetails: FC<IDetailsProps> = ({ children, sx }) => <AccordionDetailsBase sx={sx}>{children}</AccordionDetailsBase>;

const AccordionComparisonTableBase = styled(MuiAccordion)(({ theme }) => ({
    borderRadius: theme.borderRadius.medium,
    overflow: 'hidden',
    boxShadow: 'none',
    '&:before': {
        all: 'unset',
    },
}));

export const AccordionComparisonTable: FC<IAccordionProps> = ({ children, onChange }) => {
    return (
        <AccordionComparisonTableBase onChange={onChange} square={true} disableGutters={true}>
            {children}
        </AccordionComparisonTableBase>
    );
};

const AccordionSummaryComparisonTableBase: any = styled(MuiAccordionSummary)<ISummaryProps>(({ theme }) => ({
    '&.MuiAccordionSummary-root': {
        padding: 0,
        minHeight: 'unset',
    },
    '& .MuiAccordionSummary-content': { margin: 0 },
    '& .MuiAccordionSummary-expandIconWrapper': {
        position: 'absolute',
        top: '17px',
        left: '20px',
        transform: 'none !important',
    },
}));

export const AccordionSummaryComparisonTable: FC<ISummaryProps> = ({ children }) => (
    <AccordionSummaryComparisonTableBase expandIcon={<CustomExpandIcon />}>
        <Box width='100%'>{children}</Box>
    </AccordionSummaryComparisonTableBase>
);

const AccordionDetailsComparisonTableBase: any = styled(MuiAccordionDetails)<IDetailsProps>(({ theme }) => ({
    padding: 0,
}));

export const AccordionDetailsComparisonTable: FC<IDetailsProps> = ({ children }) => (
    <AccordionDetailsComparisonTableBase>{children}</AccordionDetailsComparisonTableBase>
);

// solution from https://github.com/mui/material-ui/issues/18326
const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                '.Mui-expanded & > .collapsIconWrapper': {
                    display: 'none',
                },
                '.expandIconWrapper': {
                    display: 'none',
                },
                '.Mui-expanded & > .expandIconWrapper': {
                    display: 'block',
                },
            }}
        >
            <Box className='expandIconWrapper'>
                <Icon.CloseButton />
            </Box>
            <Box className='collapsIconWrapper'>
                <Icon.OpenButton />
            </Box>
        </Box>
    );
};
