import React, { FC, useContext } from 'react';
import { Box, Select, Title, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { SelectValues } from 'models/OffersData';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { ContractTypeModel } from 'models/common';
import texts from 'texts.json';
import { AuthContext } from 'contexts/AuthContext';
import OffersModelTypeButtons from 'components/molecules/OffersModelTypeButtons';

interface IProps {
    changeOffersModel: (model: ContractTypeModel) => void;
    offersModel: ContractTypeModel;
    sortValue: SelectValues;
    setSortValue: (value: SelectValues) => void;
    buyOffersCount?: number;
    rentOffersCount?: number;
    selectOptions: { value: SelectValues; label: string }[];
}

const OffersListHeader: FC<IProps> = ({
    offersModel,
    changeOffersModel,
    sortValue,
    setSortValue,
    buyOffersCount = 0,
    rentOffersCount = 0,
    selectOptions,
}) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const {
        listHeader: { subTitle },
    } = texts.offers;

    const { user } = useContext(AuthContext);

    const title = `${user?.user.fullname}, להלן ${buyOffersCount + rentOffersCount} הצעות מחיר עבורך`;

    return (
        <Box>
            <Box mb='40px'>
                <Box mb='17px'>
                    {isDesktop ? (
                        <Title text={title} />
                    ) : (
                        <Typography.Body2 color={theme.palette.primary.purple} font={theme.fontFamilies.xBold}>
                            {title}
                        </Typography.Body2>
                    )}
                </Box>
                {
                    <Box sx={{ width: { md: '80%', xs: '100%' } }}>
                        {isDesktop ? <Typography.Body2>{subTitle}</Typography.Body2> : <Typography.Body4>{subTitle}</Typography.Body4>}
                    </Box>
                }
            </Box>
            <Box display={{ md: 'flex', xs: 'block' }} justifyContent='space-between' alignItems='center' mb={{ xs: '15px', md: '22px' }}>
                <OffersModelTypeButtons
                    offersModel={offersModel}
                    buyOffersCount={buyOffersCount}
                    rentOffersCount={rentOffersCount}
                    onClick={changeOffersModel}
                />
                <Box display='flex' alignItems='center' justifyContent='center' gap={{ md: 8, xs: 12 }} m={{ md: 0, xs: '25px 0' }}>
                    <Typography.Body5>סידור הצעות לפי:</Typography.Body5>
                    <Box width={{ md: '195px', xs: '60%' }}>
                        <Select
                            options={selectOptions}
                            onChange={(e) => {
                                setSortValue(e.target.value);
                            }}
                            value={sortValue}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default OffersListHeader;
