import React, { FC } from 'react';
import { Box, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';

interface IProps {
    title: string;
    vat: string;
}

const AccordionHeader: FC<IProps> = ({ title, vat }) => {
    const theme = useTheme();
    return (
        <Box bgcolor='primary.lightPurple' height='50px' display='flex' alignItems='center' justifyContent='center' columnGap={'4px'}>
            <Typography.Body3 font={theme.fontFamilies.xBold}>{title}</Typography.Body3>
            {vat && <Typography.XSmall>{vat}</Typography.XSmall>}
        </Box>
    );
};

export default AccordionHeader;
