import React, { FC } from 'react';
import { styled, Tab as MuiTab } from '@mui/material';
import InfoTooltip from 'components/atoms/InfoTooltip';

interface ITabBase {
    value: string;
    label: string;
    isResult?: boolean;
    info?: string;
}

const TabBase = styled(MuiTab, {
    shouldForwardProp: (prop: string) => prop !== 'isResult',
})<ITabBase>(({ theme, isResult }) => ({
    '&.MuiTab-root': {
        color: isResult ? theme.palette.primary.purple : theme.palette.primary.black,
        fontSize: isResult ? theme.fontSizes.large : theme.fontSizes.normal,
        fontFamily: theme.fontFamilies.bold,
        minHeight: '30px',
        height: '30px',
        // width: '50%',
        borderBottom: `1px solid ${theme.palette.primary.grey}`,
        gap: 10,
        [theme.breakpoints.down('md')]: {
            fontSize: theme.fontSizes.normal,
        },
    },
    '&.Mui-Autocompleted': {
        fontFamily: theme.fontFamilies.bold,
    },
}));

const Tab: FC<ITabBase> = ({ value, label, isResult, info, ...props }) => {
    return (
        <TabBase
            value={value}
            label={label}
            isResult={isResult}
            icon={info && <InfoTooltip firstText={info} />}
            iconPosition='end'
            {...props}
        />
    );
};

export default Tab;
