import { EntrepreneurPriceListRanges } from 'models/PricingEntrepreneur.model';
import { PriceListStatuses } from 'models/api/common';
import { EconomicModel, EntrepreneurPriceList } from 'models/api/pricingEntrepreneur';
import { parseToNumber, valuesToNumber } from 'utils/parseToNumber';
import { Symbols } from 'utils/symbols';
import { IPricingPanelData } from 'components/molecules/pricing/pricingEntrepreneur/PricingEntrepreneurRanges';
import { ChartData } from 'chart.js';
import texts from 'texts.json';
import { IDataCategories } from 'models/economicModel.model';
import { ICompanySettings } from 'models/api/companySettings.model';

export const buildEntrepreneurPriceListData = (data: EntrepreneurPriceList[]): EntrepreneurPriceListRanges[] => {
    return data
        .map((priceList) => ({
            id: priceList.item_id,
            min: parseToNumber(priceList.from_cost),
            max: parseToNumber(priceList.to_cost),
            requested_annual_return: parseToNumber(priceList.requested_annual_return),
            equity: parseToNumber(priceList.equity),
            loan_interest_rate: parseToNumber(priceList.loan_interest_rate),
            loan_period: parseToNumber(priceList.loan_period),
            discount_percent: parseToNumber(priceList.discount_percent),
            operating_cost: parseToNumber(priceList.operating_cost),
            max_additions_percent: parseToNumber(priceList.max_additions_percent),
            signing_bonus_percent: parseToNumber(priceList.signing_bonus_percent),
            terms_of_payment: priceList.terms_of_payment,
            status: priceList.status === PriceListStatuses.PRICE_LIST_STATUS_ACTIVE ? true : false,
            property_types: priceList?.property_types || [],
            additions: {
                iskurit_roof_replacement: parseToNumber(priceList.iskurit_roof_replacement),
                asbestos_dismantling_and_evacuation: parseToNumber(priceList.asbestos_dismantling_and_evacuation),
                increasing_electrical_connection_3_80: parseToNumber(priceList.increasing_electrical_connection_3_80),
                increasing_electrical_connection_3_160: parseToNumber(priceList.increasing_electrical_connection_3_160),
                increasing_electrical_connection_3_250: parseToNumber(priceList.increasing_electrical_connection_3_250),
                light_construction_roof_reinforcement: parseToNumber(priceList.asbestos_dismantling_and_evacuation),
            },
        }))
        .sort((a, b) => a.min - b.min);
};

export const entrepreneurAccordionLabels = (priceListMinMax?: ICompanySettings) => {
    const {
        requested_annual_return,
        equity,
        loan_interest_rate,
        loan_period,
        discount_percent,
        operating_cost,
        max_additions_percent,
        terms_of_payment,
        signing_bonus_percent,
    } = texts.pricing.pricingEntrepreneur.range;
    if (!priceListMinMax) {
        return [];
    }
    return [
        {
            title: requested_annual_return,
            name: 'requested_annual_return',
            specialChar: Symbols.PERCENT,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(priceListMinMax?.requested_annual_return_min),
                    message: `${texts.common.minErrorText}-${priceListMinMax?.requested_annual_return_min}`,
                },
                max: {
                    value: parseToNumber(priceListMinMax?.requested_annual_return_max),
                    message: `${texts.common.maxErrorText}-${priceListMinMax?.requested_annual_return_max}`,
                },
            },
        },
        {
            title: equity,
            name: 'equity',
            specialChar: Symbols.PERCENT,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(priceListMinMax?.equity_min),
                    message: `${texts.common.minErrorText}-${priceListMinMax?.equity_min}`,
                },
                max: {
                    value: parseToNumber(priceListMinMax?.equity_max),
                    message: `${texts.common.maxErrorText}-${priceListMinMax?.equity_max}`,
                },
            },
        },
        {
            title: loan_interest_rate,
            name: 'loan_interest_rate',
            specialChar: Symbols.PERCENT,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(priceListMinMax?.loan_interest_rate_min),
                    message: `${texts.common.minErrorText}-${priceListMinMax?.loan_interest_rate_min}`,
                },
                max: {
                    value: parseToNumber(priceListMinMax?.loan_interest_rate_max),
                    message: `${texts.common.maxErrorText}-${priceListMinMax?.loan_interest_rate_max}`,
                },
            },
        },
        {
            title: loan_period,
            name: 'loan_period',
            specialChar: ' שנים',
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(priceListMinMax?.loan_period_min),
                    message: `${texts.common.minErrorText}-${priceListMinMax?.loan_period_min}`,
                },
                max: {
                    value: parseToNumber(priceListMinMax?.loan_period_max),
                    message: `${texts.common.maxErrorText}-${priceListMinMax?.loan_period_max}`,
                },
            },
        },
        {
            title: discount_percent,
            name: 'discount_percent',
            specialChar: Symbols.PERCENT,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(priceListMinMax?.discount_percent_min),
                    message: `${texts.common.minErrorText}-${priceListMinMax?.discount_percent_min}`,
                },
                max: {
                    value: parseToNumber(priceListMinMax?.discount_percent_max),
                    message: `${texts.common.maxErrorText}-${priceListMinMax?.discount_percent_max}`,
                },
            },
        },
        {
            title: operating_cost,
            name: 'operating_cost',
            specialChar: Symbols.SHEKEL,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(priceListMinMax?.operating_cost_min),
                    message: `${texts.common.minErrorText}-${priceListMinMax?.operating_cost_min}`,
                },
                max: {
                    value: parseToNumber(priceListMinMax?.operating_cost_max),
                    message: `${texts.common.maxErrorText}-${priceListMinMax?.operating_cost_max}`,
                },
            },
        },
        {
            title: max_additions_percent,
            name: 'max_additions_percent',
            specialChar: Symbols.PERCENT,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(priceListMinMax?.max_additions_percent_min),
                    message: `${texts.common.minErrorText}-${priceListMinMax?.max_additions_percent_min}`,
                },
                max: {
                    value: parseToNumber(priceListMinMax?.max_additions_percent_max),
                    message: `${texts.common.maxErrorText}-${priceListMinMax?.max_additions_percent_max}`,
                },
            },
        },
        {
            title: terms_of_payment,
            name: 'terms_of_payment',
            rules: {
                required: 'חובה להזין ערך',
            },
        },
        {
            title: signing_bonus_percent,
            name: 'signing_bonus_percent',
            specialChar: Symbols.PERCENT,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(priceListMinMax?.signing_bonus_percent_min),
                    message: `${texts.common.minErrorText}-${priceListMinMax?.signing_bonus_percent_min}`,
                },
                max: {
                    value: parseToNumber(priceListMinMax?.signing_bonus_percent_max),
                    message: `${texts.common.maxErrorText}-${priceListMinMax?.signing_bonus_percent_max}`,
                },
            },
        },
    ];
};

export const entrepreneurSimulatorDetails = () => {
    const { installation_power, construction_cost, additions_cost, signing_bonus, rent_percent, tariff, result_irr, npv } =
        texts.pricing.pricingEntrepreneur.simulator.details;
    return [
        {
            title: installation_power,
            name: 'installation_power',
        },
        {
            title: construction_cost,
            name: 'construction_cost',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: additions_cost,
            name: 'additions_cost',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: signing_bonus,
            name: 'signing_bonus',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: rent_percent,
            name: 'rent_percent',
            specialChar: `${Symbols.PERCENT}`,
        },
        {
            title: tariff,
            name: 'tariff',
            specialChar: `${Symbols.AGORA}`,
        },
        {
            title: result_irr,
            name: 'result_irr',
            specialChar: `${Symbols.PERCENT}`,
        },
        {
            title: npv,
            name: 'npv',
            specialChar: `${Symbols.SHEKEL}`,
        },
    ];
};

export const entrepreneurCostRanges = (priceListMinMax?: ICompanySettings) => {
    if (!priceListMinMax) {
        return [];
    }
    return {
        from: {
            required: 'חובה להזין ערך',
            min: {
                value: parseToNumber(priceListMinMax?.from_cost_min),
                message: `${texts.common.minErrorText}-${priceListMinMax?.from_cost_min}`,
            },
            max: {
                value: priceListMinMax?.from_cost_max,
                message: `${texts.common.maxErrorText}-${priceListMinMax?.from_cost_max}`,
            },
        },
        to: {
            required: 'חובה להזין ערך',
            min: {
                value: parseToNumber(priceListMinMax?.to_cost_min),
                message: `${texts.common.minErrorText}-${priceListMinMax?.to_cost_min}`,
            },
            max: {
                value: parseToNumber(priceListMinMax?.to_cost_max),
                message: `${texts.common.maxErrorText}-${priceListMinMax?.to_cost_max}`,
            },
        },
    };
};

export const buildAdditionsData = ({ data: { additions, id }, fieldIndex }: IPricingPanelData, additionsMinMax?: ICompanySettings) => {
    const {
        increasing_electrical_connection_3_250,
        iskurit_roof_replacement,
        asbestos_dismantling_and_evacuation,
        increasing_electrical_connection_3_80,
        increasing_electrical_connection_3_160,
        light_construction_roof_reinforcement,
    } = texts.pricing.pricingEntrepreneur.additions.priceList;
    if (!additionsMinMax) {
        return { id: id, fieldIndex, data: [] };
    }
    return {
        id: id,
        fieldIndex,
        data: [
            {
                title: iskurit_roof_replacement,
                value: additions.iskurit_roof_replacement,
                name: 'iskurit_roof_replacement',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(additionsMinMax?.iskurit_roof_replacement_min),
                        message: `${texts.common.minErrorText}-${additionsMinMax?.iskurit_roof_replacement_min}`,
                    },
                    max: {
                        value: parseToNumber(additionsMinMax?.iskurit_roof_replacement_max),
                        message: `${texts.common.maxErrorText}-${additionsMinMax?.iskurit_roof_replacement_max}`,
                    },
                },
            },
            {
                title: asbestos_dismantling_and_evacuation,
                value: additions.asbestos_dismantling_and_evacuation,
                name: 'asbestos_dismantling_and_evacuation',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(additionsMinMax?.asbestos_dismantling_and_evacuation_min),
                        message: `${texts.common.minErrorText}-${additionsMinMax?.asbestos_dismantling_and_evacuation_min}`,
                    },
                    max: {
                        value: parseToNumber(additionsMinMax?.asbestos_dismantling_and_evacuation_max),
                        message: `${texts.common.maxErrorText}-${additionsMinMax?.asbestos_dismantling_and_evacuation_max}`,
                    },
                },
            },
            {
                title: increasing_electrical_connection_3_80,
                value: additions.increasing_electrical_connection_3_80,
                name: 'increasing_electrical_connection_3_80',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(additionsMinMax?.increasing_electrical_connection_3_80_min),
                        message: `${texts.common.minErrorText}-${additionsMinMax?.increasing_electrical_connection_3_80_min}`,
                    },
                    max: {
                        value: parseToNumber(additionsMinMax?.increasing_electrical_connection_3_80_max),
                        message: `${texts.common.maxErrorText}-${additionsMinMax?.increasing_electrical_connection_3_80_max}`,
                    },
                },
            },
            {
                title: increasing_electrical_connection_3_160,
                value: additions.increasing_electrical_connection_3_160,
                name: 'increasing_electrical_connection_3_160',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(additionsMinMax?.increasing_electrical_connection_3_160_min),
                        message: `${texts.common.minErrorText}-${additionsMinMax?.increasing_electrical_connection_3_160_min}`,
                    },
                    max: {
                        value: parseToNumber(additionsMinMax?.increasing_electrical_connection_3_160_max),
                        message: `${texts.common.maxErrorText}-${additionsMinMax?.increasing_electrical_connection_3_160_max}`,
                    },
                },
            },
            {
                title: increasing_electrical_connection_3_250,
                value: additions.increasing_electrical_connection_3_250,
                name: 'increasing_electrical_connection_3_250',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(additionsMinMax?.increasing_electrical_connection_3_250_min),
                        message: `${texts.common.minErrorText}-${additionsMinMax?.increasing_electrical_connection_3_250_min}`,
                    },
                    max: {
                        value: parseToNumber(additionsMinMax?.increasing_electrical_connection_3_250_max),
                        message: `${texts.common.maxErrorText}-${additionsMinMax?.increasing_electrical_connection_3_250_max}`,
                    },
                },
            },
            {
                title: light_construction_roof_reinforcement,
                value: additions.light_construction_roof_reinforcement,
                name: 'light_construction_roof_reinforcement',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(additionsMinMax?.light_construction_roof_reinforcement_min),
                        message: `${texts.common.minErrorText}-${additionsMinMax?.light_construction_roof_reinforcement_min}`,
                    },
                    max: {
                        value: parseToNumber(additionsMinMax?.light_construction_roof_reinforcement_max),
                        message: `${texts.common.maxErrorText}-${additionsMinMax?.light_construction_roof_reinforcement_max}`,
                    },
                },
            },
        ],
    };
};

export const entrepreneurChartData = (data: EconomicModel[]): ChartData<'bar', number[]> => {
    const { rent, financing_payments, net_profit, operation_cost } = texts.pricing.pricingEntrepreneur.simulator.chart;

    return {
        labels: data.map((item) => item.year),
        datasets: [
            {
                label: operation_cost,
                data: data.map((item) => parseToNumber(item.operating_cost)),
                backgroundColor: '#d2d2d2',
                barThickness: 8,
            },
            {
                label: financing_payments,
                data: data.map((item) => parseToNumber(item.financing_payments)),
                backgroundColor: '#fcd004',
                barThickness: 8,
            },
            {
                label: rent,
                data: data.map((item) => parseToNumber(item.rent)),
                backgroundColor: '#ffeab2',
                barThickness: 8,
            },
            {
                label: net_profit,
                data: data.map((item) => parseToNumber(item.net_profit)),
                backgroundColor: '#ac4385',
                borderRadius: 15,
                barThickness: 8,
            },
        ],
    };
};

export const entrepreneurTableTotalRows = (items: EconomicModel[]) => {
    const itemsToNumber = valuesToNumber(items);
    const averageSum = (acc: number, item: number) => acc + (item - acc) / items.length;
    return itemsToNumber?.reduce(
        (acc, item) => {
            return {
                gross_income: acc.gross_income + item.gross_income,
                operating_cost: acc.operating_cost + item.operating_cost,
                rent: averageSum(acc.rent, item.rent),
                financing_payments: averageSum(acc.financing_payments, item.financing_payments),
                net_profit: acc.net_profit + item.net_profit,
                equity_return: averageSum(acc.equity_return, item.equity_return),
                total_return: averageSum(acc.total_return, item.total_return),
            };
        },
        {
            gross_income: 0,
            operating_cost: 0,
            rent: 0,
            financing_payments: 0,
            net_profit: 0,
            equity_return: 0,
            total_return: 0,
        }
    );
};

export const entrepreneurSimulatorTableDataCategories = () => {
    const { year, gross_income, operating_cost, rent, financing_payments, net_profit, total_return, equity_return } =
        texts.pricing.pricingEntrepreneur.simulator.table;
    return [
        {
            title: year,
            name: 'year',
            specialChar: ``,
        },
        {
            title: gross_income,
            name: 'gross_income',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: operating_cost,
            name: 'operating_cost',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: rent,
            name: 'rent',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: financing_payments,
            name: 'financing_payments',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: net_profit,
            name: 'net_profit',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: total_return,
            name: 'total_return',
            specialChar: `${Symbols.PERCENT}`,
        },
        {
            title: equity_return,
            name: 'equity_return',
            specialChar: `${Symbols.PERCENT}`,
        },
    ];
};

export const entrepreneurSimulatorMobileDataCategories = (): IDataCategories[] => {
    const { operating_cost, rent, financing_payments, net_profit, total_return } = texts.pricing.pricingEntrepreneur.simulator.table;
    return [
        {
            title: operating_cost,
            name: 'operating_cost',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: rent,
            name: 'rent',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: financing_payments,
            name: 'financing_payments',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: net_profit,
            name: 'net_profit',
            specialChar: `${Symbols.SHEKEL}`,
        },
        {
            title: total_return,
            name: 'total_return',
            specialChar: `${Symbols.PERCENT}`,
        },
    ];
};

export const headTitlesMobile = () => {
    const { year, gross_income, equity_return } = texts.pricing.pricingEntrepreneur.simulator.table;

    return [
        {
            title: year,
            name: 'year',
            specialChar: ``,
        },
        {
            title: gross_income,
            name: 'gross_income',
            specialChar: `${Symbols.SHEKEL}`,
        },

        {
            title: equity_return,
            name: 'equity_return',
            specialChar: `${Symbols.PERCENT}`,
        },
    ];
};
