import React, { FC, Fragment } from 'react';
import { Box, Divider, Grid, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { isEven } from 'utils/isEven';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { pricingTransformersSimulatorExampleData as data } from 'data/PricingExampleData';
import { Symbols } from 'utils/symbols';

interface IProps {}

const mobileColumns = [3, 2, 2, 3];

const categories = [
    {
        name: 'quantity',
        title: `יח'`,
    },
    {
        name: 'totalKW',
        title: `סה"כ הספק`,
    },
    {
        name: 'price',
        title: `עלות ${Symbols.SHEKEL}`,
    },
];

const PricingTransformersSimulatorDetails: FC<IProps> = () => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    return (
        <Box display='flex' justifyContent='space-between' flexDirection={{ md: 'row', xs: 'column' }} gap={{ xs: '20px', md: 0 }}>
            {isDesktop && (
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    gap='36px'
                    width='125px'
                    pt='145px'
                    bgcolor='primary.yellowBackground'
                    sx={{ borderTop: `3px solid ${theme.palette.primary.goldenYellow}` }}
                >
                    {/* run converters.length times */}
                    {[...Array(data[0].converters.length)].map(() => (
                        <Typography.Body3>הספק ממיר</Typography.Body3>
                    ))}
                    <Typography.Body3>סה"כ ממירים {'\n'} (AC)</Typography.Body3>
                </Box>
            )}
            {data.map((item, index) => (
                <Box
                    key={index}
                    sx={{ borderTop: `3px solid ${theme.palette.primary.goldenYellow}` }}
                    width={{ md: '400px', xs: '100%' }}
                    mx={{ xs: 'auto', md: 'unset' }}
                >
                    <Box
                        height={{ xs: '50px', md: '55px' }}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        bgcolor='primary.lightPurple'
                    >
                        <Typography.Body2>אופציה {index + 1}</Typography.Body2>
                    </Box>
                    <Box bgcolor='primary.white'>
                        <Grid container columns={{ md: 12, xs: 12 }} mx='auto' p={{ md: '25px', xs: '10px' }}>
                            {!isDesktop && <Grid item xs={2}></Grid>}
                            <Grid item xs={3} md={4} mb='40px'>
                                {isDesktop ? (
                                    <Typography.Body3>{item.name}</Typography.Body3>
                                ) : (
                                    <Typography.Body6 font={theme.fontFamilies.bold}>{item.name}</Typography.Body6>
                                )}
                            </Grid>
                            {categories.map((category, catIndex) => (
                                <Grid item key={catIndex} md={!isEven(catIndex) ? 4 : 2} xs={mobileColumns[catIndex + 1]}>
                                    {isDesktop ? (
                                        <Typography.Body3>{category.title}</Typography.Body3>
                                    ) : (
                                        <Typography.Body6 font={theme.fontFamilies.bold}>{category.title}</Typography.Body6>
                                    )}
                                </Grid>
                            ))}
                            {item.converters.map((converter, converterIndex) => {
                                return (
                                    <Fragment key={converterIndex}>
                                        {!isDesktop && (
                                            <Grid item xs={2}>
                                                <Typography.Body6 font={theme.fontFamilies.bold}>הספק ממיר</Typography.Body6>
                                            </Grid>
                                        )}

                                        {Object.values(converter).map((value, valueIndex) => {
                                            const text = valueIndex === 3 ? `${Symbols.SHEKEL}${value}` : value;
                                            return (
                                                <Grid
                                                    item
                                                    key={valueIndex}
                                                    md={isEven(valueIndex) ? 4 : 2} /* xs={valueIndex !== 1 ? 3 : 1} */
                                                    xs={mobileColumns[valueIndex]}
                                                >
                                                    {isDesktop ? (
                                                        <Typography.Body3 font={theme.fontFamilies.light}>{text}</Typography.Body3>
                                                    ) : (
                                                        <Typography.Body5 font={theme.fontFamilies.light}>{text}</Typography.Body5>
                                                    )}
                                                    <Box my='15px'>
                                                        <Divider />
                                                    </Box>
                                                </Grid>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })}
                            {!isDesktop && (
                                <Grid item xs={5}>
                                    <Typography.Body6 font={theme.fontFamilies.bold}>סה"כ ממירים (AC)</Typography.Body6>
                                </Grid>
                            )}
                            {Object.values(item.total).map((value, valueIndex) => {
                                const text = valueIndex === 3 ? `${Symbols.SHEKEL}${value}` : value;
                                if (!isDesktop && valueIndex === 0) return null;
                                return (
                                    <Grid item key={valueIndex} md={isEven(valueIndex) ? 4 : 2} xs={mobileColumns[valueIndex]}>
                                        {isDesktop ? (
                                            <Typography.Body3 font={theme.fontFamilies.light}>{text}</Typography.Body3>
                                        ) : (
                                            <Typography.Body5 font={theme.fontFamilies.light}>{text}</Typography.Body5>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default PricingTransformersSimulatorDetails;
