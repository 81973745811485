import React, { FC, useEffect, useState } from 'react';
import { Box, Divider } from 'components/atoms';
import { useLocation, useOutletContext, useParams } from 'react-router';
import AccordionOfferDetails from 'components/organisms/contractor/AccordionOfferDetails';
import OfferHeader from 'components/organisms/contractor/OfferHeader';
import SwiperContractor from 'components/organisms/contractor/SwiperContractor';
import AccordionTermsOfPayment from 'components/organisms/contractor/AccordionTermsOfPayment';
import AccordionAboutCompany from 'components/organisms/contractor/AccordionAboutCompany';
import AccordionRatingOffers from 'components/organisms/contractor/AccordionRatingOffers';
import { useGetEntrepreneurQuotationDetails, useGetQuotationDetails } from 'api/queries/offers';
import Button from 'components/atoms/Button';
import { useTheme } from '@mui/material';
import { IEntrepreneurQuotationDetails, QuotationDetails } from 'models/api/offers';
import {
    buildAccordionOfferAboutCompanyData,
    buildAccordionOfferDetailsData,
    buildAccordionOfferPaymentTermsData,
    buildAccordionOfferRatingContractorData,
    buildAccordionOfferSwiperData,
    headerDataProcessing,
} from 'data/OfferData';
import { IAccordionOfferDetailsData } from 'models/OffersData';
import { IOfferAbout, IOfferPaymentTerms } from 'models/OfferData.model';
import { ContractTypeModel } from 'models/common';
import { PATH } from 'paths';
import { isVatIncludes } from 'utils/isVatIncludes';

const Offer: FC = () => {
    const {
        handleElectQuotation,
    }: {
        handleElectQuotation: (quotationId: string, modelType: ContractTypeModel) => void;
    } = useOutletContext();
    const theme = useTheme();
    const { offerId } = useParams<Record<string, string | undefined>>();
    const isContractor = useLocation().pathname.includes('contractor');
    const { data: contractorQuotationData } = useGetQuotationDetails(offerId, !!isContractor && !!offerId);
    const { data: entrepreneurQuotation } = useGetEntrepreneurQuotationDetails(offerId, !isContractor && !!offerId);

    const [quotation, setQuotation] = useState<QuotationDetails | IEntrepreneurQuotationDetails | null>(null);
    const contractorQuotation = contractorQuotationData?.data.item;

    useEffect(() => {
        if (isContractor) {
            setQuotation(contractorQuotation as QuotationDetails);
        } else {
            setQuotation(entrepreneurQuotation?.data.item as IEntrepreneurQuotationDetails);
        }
    }, [contractorQuotation, entrepreneurQuotation?.data.item, isContractor]);

    if (!quotation) {
        return null;
    }

    const headerData = headerDataProcessing(quotation);
    const swiperData = buildAccordionOfferSwiperData(quotation.similar_roof_type_images, quotation.similar_project_power_images);
    const detailsData = buildAccordionOfferDetailsData(quotation, isContractor) as IAccordionOfferDetailsData;
    const termsOfPaymentData = buildAccordionOfferPaymentTermsData(quotation, isContractor) as IOfferPaymentTerms[];
    const aboutData =
        ((quotation as QuotationDetails)?.constractor && (buildAccordionOfferAboutCompanyData(quotation) as IOfferAbout)) || {};
    const ratingData = isContractor
        ? buildAccordionOfferRatingContractorData((quotation as QuotationDetails)?.constractor)
        : buildAccordionOfferRatingContractorData((quotation as IEntrepreneurQuotationDetails)?.entrepreneur);
    const isVatIncluded = isVatIncludes(contractorQuotation?.vat_included);

    return (
        <Box width={{ md: '1138px' }} mx={{ md: 'auto', xs: '20px' }} pb={{ md: '10px' }}>
            <Box mt='10px'>
                <Button.Back
                    color={theme.palette.primary.purple}
                    text='חזרה'
                    link={isContractor ? `/${PATH.CONTRACTOR_OFFERS}` : `/${PATH.ENTREPRENEUR_OFFERS}`}
                />
            </Box>
            <OfferHeader data={headerData} handleElectQuotation={handleElectQuotation} isContractor={isContractor} />
            <Box mb='40px'>
                <Divider />
                <Divider />
            </Box>
            <Box display='flex' flexDirection='column' gap={20} mb='88px'>
                <SwiperContractor data={swiperData} />
                <AccordionOfferDetails data={detailsData} isContractor={isContractor} isVatIncluded={isVatIncluded} />
                <AccordionTermsOfPayment data={termsOfPaymentData} isContractor={isContractor} isVatIncluded={isVatIncluded} />
                <AccordionAboutCompany
                    data={aboutData}
                    isContractor={isContractor}
                    entrepreneurData={(quotation as IEntrepreneurQuotationDetails)?.entrepreneur?.about || ''}
                />
                <AccordionRatingOffers data={ratingData} isContractor={isContractor} />
            </Box>
        </Box>
    );
};

export default Offer;
