import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ROUTES } from 'api/apiRoutes';
import { axiosGet, axiosPost } from 'api/axios';
import { AxiosResponse } from 'axios';
import { buildPurchaseEconomicModelData } from 'data/EconomicModelData';
import { Response, SetCreateRes } from 'models/api/common';
import {
    CreateEntrepreneurPriceListParam,
    GetEntrepreneurPriceListRes,
    GetEntrepreneurSimulatorRes,
    SetEntrepreneurPriceListReq,
    SetEntrepreneurChosenContractorPriceListReq,
    EntrepreneurSimulator,
} from 'models/api/pricingEntrepreneur';
import { getToken } from 'utils/getToken';

export const useGetEntrepreneurPriceLists = (enabled = true) => {
    const { data, refetch } = useQuery(
        [API_ROUTES.GET_ENTREPRENEUR_PRICE_LISTS],
        (): Promise<AxiosResponse<GetEntrepreneurPriceListRes, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_ENTREPRENEUR_PRICE_LISTS}`),
        { enabled }
    );
    return { data, refetch };
};

export const useCreateEntrepreneurPriceList = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.CREATE_ENTREPRENEUR_PRICE_LIST],
        (item: CreateEntrepreneurPriceListParam): Promise<AxiosResponse<SetCreateRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.CREATE_ENTREPRENEUR_PRICE_LIST}`, {
                item,
            })
    );
    return { mutate, data };
};

export const useSetEntrepreneurPriceList = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.SET_ENTREPRENEUR_PRICE_LIST],
        ({ item, item_id }: SetEntrepreneurPriceListReq): Promise<AxiosResponse<SetCreateRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.SET_ENTREPRENEUR_PRICE_LIST}`, {
                item,
                item_id,
            })
    );
    return { mutate, data };
};

export const useGetEntrepreneurSimulator = (): { mutate: any; data: { item: EntrepreneurSimulator } } => {
    const { mutate, data } = useMutation(
        [API_ROUTES.GET_ENTREPRENEUR_SIMULATOR],
        (investment: string): Promise<AxiosResponse<GetEntrepreneurSimulatorRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_ENTREPRENEUR_SIMULATOR}`, {
                item: {
                    investment,
                },
            })
    );
    const transformedData = data?.data.item && {
        ...data?.data.item,
        economic_model: buildPurchaseEconomicModelData(data.data.item.economic_model),
    };
    return { mutate, data: { item: transformedData || ({} as EntrepreneurSimulator) } };
};

export const useDeleteEntrepreneurPriceList = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.DELETE_ENTREPRENEUR_PRICE_LIST],
        (itemId: string): Promise<AxiosResponse<Response, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.DELETE_ENTREPRENEUR_PRICE_LIST}`, {
                item_id: itemId,
            })
    );
    return { mutate, data };
};

export const useSetEntrepreneurChosenContractorPriceList = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.SET_ENTREPRENEUR_CHOSEN_CONSTRUCTION_PRICE_LIST],
        ({ item, itemId }: SetEntrepreneurChosenContractorPriceListReq): Promise<AxiosResponse<Response, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.SET_ENTREPRENEUR_CHOSEN_CONSTRUCTION_PRICE_LIST}`, {
                item_id: itemId,
                item: {
                    chosen_construction_price_list: item,
                },
            })
    );
    return { mutate, data };
};
