import React, { FC } from 'react';
import { Box, Checkbox, Typography } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    checkboxLabel: string;
}

const PricingAccordionSectionHeader: FC<IProps> = ({ onCheckboxChange, checked, checkboxLabel }) => {
    const isDesktop = useIsDesktop();

    return (
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={{ md: '10px', xs: '25px' }}>
            <Box display='flex' gap={10} alignItems='center'>
                <Checkbox checked={checked} onChange={onCheckboxChange} />
                {isDesktop ? <Typography.Body2>{checkboxLabel}</Typography.Body2> : <Typography.Body4>{checkboxLabel}</Typography.Body4>}
            </Box>
        </Box>
    );
};

export default PricingAccordionSectionHeader;
