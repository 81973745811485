import { Box, Button, TextField } from 'components/atoms';
import { AppLogo } from 'components/atoms';
import { TextFieldSize, TextFieldType } from 'components/atoms/TextField';
import { FC, useState } from 'react';

interface IProps {
    onPasswordHandler: (password: string) => void;
}
const SignIn: FC<IProps> = ({ onPasswordHandler }) => {
    const [value, setValue] = useState('');
    return (
        <Box display='flex' flexDirection='column' height='100vh' alignItems='center' justifyContent='center' rowGap={'400px'}>
            <AppLogo onClick={() => {}} />
            <Box display={'flex'} flexDirection='column' width={'400px'} rowGap={'20px'}>
                <TextField
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    size={TextFieldSize.Large}
                    placeholder='סיסמה'
                    type={TextFieldType.Password}
                />
                <Button.Contained onClick={() => onPasswordHandler(value)}>כנס</Button.Contained>
            </Box>
        </Box>
    );
};

export default SignIn;
