import { Icon } from 'components/atoms';
import { IOffersMainCard } from 'models/OffersData';
import { ClientEntrepreneurQuotation, ClientQuotation } from 'models/api/offers';
import { Symbols } from 'utils/symbols';
import texts from 'texts.json';
import { isVatIncludes } from 'utils/isVatIncludes';
// TODOimage types
export const buildContractorQuotationCardData = (quotations: ClientQuotation[]): IOffersMainCard[] => {
    const { leftSection, mainSection, rightSection } = texts.offers.contractor.quotationCard;
    const { costs, total } = leftSection;
    return quotations?.map((quotation: ClientQuotation) => {
        const isVat = isVatIncludes(quotation.project.vat_included);

        return {
            id: quotation.id,
            item_id: quotation.item_id,
            company_id_rate: quotation.company_id_rate,
            price_list_rate: quotation.price_list_rate,
            rate: quotation.rate,
            vat_included: isVat,
            rightSection: {
                images: quotation.similar_roof_type_images,
                text: rightSection.resultIrr.label,
                value: quotation.result_irr,
                specialChar: Symbols.PERCENT,
            },
            leftSection: {
                costs: [
                    {
                        title: costs.pricePerKw.label,
                        value: `${isVat ? quotation.price_per_kw_vat : quotation.price_per_kw} ${Symbols.SHEKEL}`,
                    },
                    {
                        title: costs.additionsPrice.label,
                        value: `${isVat ? quotation.additions_price_vat : quotation.additions_price} ${Symbols.SHEKEL}`,
                    },
                ],
                total: {
                    title: total.label,
                    value: `${quotation.final_price} ${Symbols.SHEKEL}`,
                },
            },
            mainSection: [
                {
                    icon: <Icon.PowerSupply />,
                    text: mainSection.converter.label,
                    boldText: quotation.price_list_quotation.converters_company,
                },
                {
                    icon: <Icon.SolarPanel width='16px' height='16px' />,
                    text: mainSection.panel.label,
                    boldText: quotation.price_list_quotation.recommended_panel_type,
                },
                {
                    icon: <Icon.Time />,
                    text: mainSection.yearsInTheField.label,
                    boldText: quotation.years_in_the_field,
                },
            ],
        };
    });
};
export const buildEntrepreneurQuotationCardData = (quotations: ClientEntrepreneurQuotation[]): IOffersMainCard[] => {
    const { leftSection, mainSection, rightSection } = texts.offers.entrepreneur.quotationCard;
    const { costs, total } = leftSection;
    return quotations?.map((quotation: ClientEntrepreneurQuotation) => {
        return {
            id: quotation?.id,
            item_id: quotation.item_id,
            company_id_rate: null,
            price_list_rate: null,
            rate: null,
            rightSection: {
                images: quotation.similar_roof_type_images || [],
                text: rightSection.rentPercent.label,
                value: quotation.rent_percent,
                specialChar: Symbols.PERCENT,
            },
            leftSection: {
                costs: [
                    {
                        title: costs.avgRentMonth.label,
                        value: `${quotation.avg_rent_month} ${Symbols.SHEKEL}`,
                    },
                    {
                        title: costs.avgRent.label,
                        value: `${quotation.avg_rent} ${Symbols.SHEKEL}`,
                    },
                ],
                total: {
                    title: total.label,
                    value: `${quotation.total_income} ${Symbols.SHEKEL}`,
                },
            },
            mainSection: [
                {
                    icon: <Icon.Calendar width='16px' height='16px' fill='#aa4283' />,
                    text: mainSection.contractPeriod.label,
                    boldText: '25 שנה',
                },
                {
                    icon: <Icon.Finance />,
                    text: mainSection.signingBonus.label,
                    boldText: `${quotation.signing_bonus}${Symbols.SHEKEL}`,
                },
                {
                    icon: <Icon.Time />,
                    text: mainSection.yearsInTheField.label,
                    boldText: quotation.years_in_the_field,
                },
            ],
        };
    });
};
