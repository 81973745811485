import React, { FC } from 'react';
import { Box, Button, Divider, Icon, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { Orientation } from 'components/atoms/Divider';
import { ButtonSize } from 'components/atoms/Button';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { IOfferHeaderData } from 'models/OfferData.model';
import { ContractTypeModel } from 'models/common';
import texts from 'texts.json'

interface IProps {
    data: IOfferHeaderData;
    handleElectQuotation: (quotationId: string, modelType: ContractTypeModel) => void;
    isContractor?: boolean;
}

const OfferHeader: FC<IProps> = ({ data, handleElectQuotation, isContractor }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const modelType = isContractor ? ContractTypeModel.PURCHASE : ContractTypeModel.RENT;

    const headerText = isContractor ? `הצעה לרכישה מס' ${data.id}` : `הצעה לשכירות מס' ${data.id}`;
    return (
        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} py='40px' gap={10}>
            <Box width={{ md: 'auto', xs: '100%' }} textAlign={{ md: 'unset', xs: 'center' }} my='auto'>
                {isDesktop ? (
                    <Typography.Body1 color={theme.palette.primary.purple} font={theme.fontFamilies.xBold}>
                        {headerText}
                    </Typography.Body1>
                ) : (
                    <Typography.Body2 color={theme.palette.primary.purple} font={theme.fontFamilies.xBold}>
                        {headerText}
                    </Typography.Body2>
                )}
            </Box>
            {isContractor && (
                <Box display='flex' mx={{ xs: 'auto', md: 'unset' }} gap={10} alignItems='center'>
                    {isDesktop && (
                        <Box display='flex' height='18px' mx='4px'>
                            <Divider orientation={Orientation.Vertical} />
                        </Box>
                    )}
                    <Icon.Sun fill={theme.palette.primary.goldenYellow} />
                    {data?.rate && (
                        <Box>
                            {isDesktop ? (
                                <Typography.Body1>{data.rate}</Typography.Body1>
                            ) : (
                                <Typography.Body2>{data.rate}</Typography.Body2>
                            )}
                        </Box>
                    )}
                    <Box>
                        <Typography.Body5>{texts.offers.contractor.header.rateText}</Typography.Body5>
                    </Box>
                    {/* <Button.Text onClick={() => {}}>
                    <Typography.Body4>לדירוג המלא {'>'}</Typography.Body4>
                </Button.Text> */}
                </Box>
            )}
            <Box m={{ xs: '0 auto 0 auto', md: '0 0 0 auto' }} width='250px'>
                <Button.Outlined
                    color={theme.palette.primary.purple}
                    borderColor={theme.palette.primary.sunflowerYellow}
                    font={theme.fontFamilies.xBold}
                    size={isDesktop ? ButtonSize.REGULAR : ButtonSize.SMALL}
                    borderRadius={theme.borderRadius.roundedSides}
                    backgroundColor={'transparent'}
                    onClick={() => {
                        handleElectQuotation(data.item_id, modelType);
                    }}
                >
                    רוצה להתקדם עם הצעה זו
                </Button.Outlined>
            </Box>
        </Box>
    );
};

export default OfferHeader;
