import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ToastType } from 'components/atoms/Toast';
import { enqueueSnackbar } from 'notistack';

const getError = (error: any, defaultError?: string) => {
        console.error(error);

    if (error instanceof AxiosError && error?.response?.status !== 0) {
        const apiError = error.response?.data?.message || error.response?.statusText || 'Error';
        const customError = defaultError || apiError;
        enqueueSnackbar(customError, {
            variant: 'toast',
            type: ToastType.ERROR,
        });
        console.error(error.message);
    } else {
        enqueueSnackbar('Error', {
            variant: 'toast',
            type: ToastType.ERROR,
        });
    }
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            networkMode: 'always',
        },
        mutations: {
            networkMode: 'online',
        },
    },

    queryCache: new QueryCache({
        onError: (error: unknown, query) => {
            getError(error);
        },
    }),
    mutationCache: new MutationCache({
        onError: (error: unknown, variables, context, mutation) => {
            getError(error, mutation?.meta?.defaultError as string);
        },
    }),
});

export default queryClient;
