import React, { FC, FormEvent } from 'react';
import AuthDialog from 'components/molecules/AuthDialog';
import { Box, Button, TextField, Typography } from 'components/atoms';
import { TextFieldSize } from 'components/atoms/TextField';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import LoginFooter from 'components/organisms/authentication/LoginFooter';
import { ButtonSize, ButtonType } from 'components/atoms/Button';
import { validatePhoneNumber } from 'utils/validatePhoneNumber';
// import { validatePhoneNumber } from 'utils/validatePhoneNumber';
import texts from 'texts.json';

interface IProps {
    showDialog: boolean;
    phoneNumber: string;
    setPhoneNumber: (phoneNumber: string) => void;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    onClose: () => void;
}

const Login: FC<IProps> = ({ showDialog, onSubmit, phoneNumber, setPhoneNumber, onClose }) => {
    const { title, button, subTitle } = texts.login;
    const DialogData = {
        header: {
            title,
        },
        footerElement: <LoginFooter />,
    };

    const isDesktop = useIsDesktop();
    const size = isDesktop ? TextFieldSize.Medium : TextFieldSize.Standard;
    const theme = useTheme();

    return (
        <AuthDialog data={DialogData} open={showDialog} onClose={onClose} isCloseIcon={false}>
            <Box
                component='form'
                display='flex'
                flexDirection='column'
                gap={{ xs: '16px', md: '20px' }}
                textAlign='center'
                onSubmit={onSubmit}
            >
                <Typography.Body2 font={theme.fontFamilies.regular}>{subTitle}</Typography.Body2>
                <Box width='300px' mx='auto'>
                    <TextField
                        inputProps={{ inputMode: 'numeric' }}
                        size={size}
                        placeholder='מספר הטלפון הנייד שלך'
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        value={phoneNumber}
                        direction='rtl'
                    />
                </Box>
                <Box width='300px' mx='auto'>
                    <Button.Contained disabled={!validatePhoneNumber(phoneNumber)} type={ButtonType.SUBMIT} size={ButtonSize.REGULAR}>
                        <Typography.Body2 color={theme.palette.primary.white} font={theme.fontFamilies.regular}>
                            {button}
                        </Typography.Body2>
                    </Button.Contained>
                </Box>
            </Box>
        </AuthDialog>
    );
};

export default Login;
