import { Divider as MuiDivider, styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

export enum Orientation {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
}

interface IProps {
    orientation?: Orientation;
    children?: ReactNode;
    borderColor?: string;
}

const DividerBase: any = styled(MuiDivider, {
    shouldForwardProp: (prop) => prop !== 'borderColor',
})<IProps>(({ theme, borderColor }) => ({
    borderColor: borderColor ? borderColor : theme.palette.primary.divider,
    columnGap: '18px',
}));

const Divider: FC<IProps> = ({ orientation, children, borderColor }) => {
    return (
        <DividerBase orientation={orientation} flexItem={true} borderColor={borderColor}>
            {children}
        </DividerBase>
    );
};

export default Divider;
