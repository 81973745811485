import React, { useRef, useEffect, ChangeEvent, KeyboardEvent, Dispatch, SetStateAction } from 'react';
import { Box } from 'components/atoms';
import { useTheme } from '@mui/material';

interface InputsVerificationCodeProps {
    length?: number;
    inputValues: string[];
    setInputValues: Dispatch<SetStateAction<string[]>>;
}

const InputsVerificationCode: React.FC<InputsVerificationCodeProps> = ({ inputValues, setInputValues, length = 4 }) => {
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const theme = useTheme();

    const inputStyle = {
        width: '50px',
        height: '50px',
        borderRadius: theme.borderRadius.small,
        fontSize: theme.fontSizes.xxLarge,
        textAlign: 'center',
        border: `1px solid ${theme.palette.primary.black}`,
    };

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setInputValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[index] = value;

            return updatedValues;
        });

        if (value !== '' && index < length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && index > 0 && inputValues[index] === '') {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const renderInputs = () => {
        const inputs = [];
        for (let i = 0; i < length; i++) {
            inputs.push(
                <Box
                    component='input'
                    key={i}
                    value={inputValues[i]}
                    inputMode='numeric'
                    maxLength={1}
                    onChange={(event) => handleInputChange(i, event)}
                    onKeyDown={(event) => handleKeyDown(i, event)}
                    ref={(el) => (inputRefs.current[i] = el as HTMLInputElement)}
                    sx={inputStyle}
                />
            );
        }
        return inputs;
    };

    return <Box display='flex' mx='auto' gap='20px' dir='ltr'>{renderInputs()}</Box>;
};

export default InputsVerificationCode;
