import React, { FC, ReactNode } from 'react';
import { Box, Typography } from 'components/atoms';

interface IProps {
    title: string;
    result: string;
    icon?: ReactNode;
}

const RenderEnvironmentalData: FC<IProps> = ({ title, result, icon }) => {
    return (
        <Box display='flex' alignItems='center' columnGap='14px'>
            {icon}
            <Typography.Body5>{result}</Typography.Body5>
        </Box>
    );
};

export default RenderEnvironmentalData;
