import React, { FC } from 'react';
import { Box, Button, Typography } from 'components/atoms';
import { renderText } from 'utils/renderText';
import { useTheme } from '@mui/material';
import CalculatorVideoSection from 'components/molecules/CalculatorVideoSection';

const sx = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    children: {
        mx: '20px',
    },
};

interface IProps {
    title: string;
    children?: React.ReactNode;
    text?: string;
    media?: string;
    buttonText?: string;
    disabled?: boolean;
    onClick?: () => void;
}

const ContentSectionMobile: FC<IProps> = ({ children, title, text, media, buttonText, disabled = false, onClick }) => {
    const theme = useTheme();

    return (
        <Box sx={sx.root}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{ textAlign: 'center' }} pb={'45px'}>
                <Box mb={'25px'}>
                    <Typography.Body2 font={theme.fontFamilies.xBold}>{renderText(title)}</Typography.Body2>
                </Box>

                {text && (
                    <Box sx={sx.children}>
                        <Typography.Body5>{renderText(text)}</Typography.Body5>
                    </Box>
                )}
                {children && <Box sx={sx.children}>{children}</Box>}
                {media && <CalculatorVideoSection src={media} />}
                {/* this onClick only for setShowMapSection and setButtonFooterData for show mapSection */}
            </Box>
            {onClick && (
                <Box width='100%' position={'fixed'} bottom={0} zIndex={1000}>
                    <Button.Mobile disabled={disabled} onClick={onClick}>
                        {buttonText}
                    </Button.Mobile>
                </Box>
            )}
        </Box>
    );
};

export default ContentSectionMobile;
