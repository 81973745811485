import { useTheme } from '@mui/material';
import { Box, Checkbox, Typography } from 'components/atoms';
import PropertyTypeCheckboxLabel from 'components/molecules/PropertyTypeCheckboxLabel';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { PricingContractorRangesData } from 'models/PricingData';
import { EPropertyType } from 'models/propertyType.model';
import { FC, useEffect } from 'react';
import { FieldErrorsImpl, useFormContext } from 'react-hook-form';
import { getPropertyTypes } from 'utils/getPropertyTypes';

interface IProps {
    isEdit: boolean;
    index: number;
}

const PropertyType: FC<IProps> = ({ isEdit, index }) => {
    const { register, watch, setValue, formState } = useFormContext();
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { checkboxesProps } = getPropertyTypes();

    const fieldName = `rangesData.${index}.property_types`;
    const properties = watch(fieldName, [] as EPropertyType[]);
    const { errors } = formState;

    const error = (
        errors as Partial<
            FieldErrorsImpl<{
                [x: string]: PricingContractorRangesData[];
            }>
        >
    )?.rangesData?.[index as any]?.property_types?.message;

    const onChange = (value: EPropertyType) => {
        if (properties.includes(value)) {
            setValue(
                fieldName,
                properties.filter((prop: EPropertyType) => prop !== value),
                { shouldDirty: true }
            );
        } else {
            setValue(fieldName, [...properties, value], { shouldDirty: true });
        }
    };

    useEffect(() => {
        if (isEdit) {
            register(fieldName, {
                shouldUnregister: true,
                required: 'חובה לבחור סוג נכס',
            });
        }
    }, [fieldName, isEdit, register]);

    return (
        <Box display={'flex'} flexDirection={'column'} mb={'40px'} ml={'8px'}>
            <Box display={'flex'} columnGap={isDesktop ? '20px' : '30px'} justifyContent={isDesktop ? '' : 'center'}>
                {checkboxesProps.map((prop) => (
                    <Box
                        key={prop.value}
                        display={'flex'}
                        flexDirection={isDesktop ? 'row' : 'column'}
                        alignItems={'center'}
                        columnGap={'8px'}
                    >
                        <Checkbox
                            size={30}
                            checked={properties?.includes(prop.value)}
                            onChange={() => onChange(prop.value)}
                            borderColor={theme.palette.primary.borderGrey}
                            disabled={!isEdit}
                        />
                        <PropertyTypeCheckboxLabel label={prop.label} />
                    </Box>
                ))}
            </Box>
            <Typography.Body4 color={theme.palette.primary.red}>{error}</Typography.Body4>
        </Box>
    );
};

export default PropertyType;
