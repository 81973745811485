import React, { FC } from 'react';
import { Box, Button, Dialog, Icon, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import texts from 'texts.json';
import { IElectQuotation } from 'models/OffersData';
import { ReactComponent as DownloadPdf } from 'assets/images/download_pdf.svg';
import { ReactComponent as OrFullBody } from 'assets/images/or_full_body_image.svg';
import {
    Wrapper,
    TitleWrapper,
    CardBody,
    DataWrapper,
    DownloadButtonWrapper,
    ImageWrapper,
    CardHeader,
} from 'components/organisms/offers/offersList/downloadQuotationModalComponents';

interface IProps {
    open: boolean;
    electDetails: IElectQuotation;
    onClose: () => void;
    onQuotationDownload: () => void;
}

const DownloadQuotationModal: FC<IProps> = ({ electDetails, onQuotationDownload, open, onClose }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { footerText, titleText, subTitleText, pdfDownloadText, cardTitle } = texts.offers.electQuotationModal;

    const { details, id } = electDetails;
    const contractorDataUI = [
        {
            label: 'שם:',
            value: details?.name,
        },
        {
            label: 'אימייל:',
            value: details?.email,
        },
        {
            label: 'טלפון:',
            value: details?.phone,
        },
    ];

    return (
        <Dialog open={open} onClose={onClose} isFullScreen={!isDesktop} isCloseIcon={false}>
            <Wrapper>
                <TitleWrapper>
                    <span>{`${titleText} ${id}`}</span>
                    <Box alignSelf={{ md: 'flex-start', xs: 'flex-end' }} p={{ xs: '10px', md: 'unset' }}>
                        <Button.Icon onClick={onClose}>
                            <Icon.Close width='19px' height='19px' />
                        </Button.Icon>
                    </Box>
                </TitleWrapper>
                <Box width={isDesktop ? '85%' : 'auto'}>
                    <CardHeader>
                        <span>{subTitleText}</span>
                    </CardHeader>
                    <CardBody>
                        <DataWrapper isBold>{cardTitle}</DataWrapper>
                        <Box display={'flex'} justifyContent={'space-between'} mt={'20px'}>
                            <Box>
                                {contractorDataUI.map((item, index) => (
                                    <DataWrapper key={index}>
                                        <span>{item.label}</span>&nbsp;
                                        <span>{item.value}</span>
                                    </DataWrapper>
                                ))}
                            </Box>
                            {isDesktop && (
                                <DownloadButtonWrapper>
                                    <Button.Icon onClick={onQuotationDownload}>
                                        <Box display={'flex'} alignItems={'center'} columnGap={'8px'} textAlign={'left'}>
                                            <DownloadPdf />
                                            <span>{pdfDownloadText}</span>
                                        </Box>
                                    </Button.Icon>
                                </DownloadButtonWrapper>
                            )}
                        </Box>
                    </CardBody>
                    {!isDesktop && (
                        <DownloadButtonWrapper>
                            <Button.Icon onClick={onQuotationDownload}>
                                <Box display={'flex'} alignItems={'center'} columnGap={'8px'} textAlign={'left'}>
                                    <DownloadPdf width={isDesktop ? '' : 29} height={isDesktop ? '' : 38} />
                                    <span>{pdfDownloadText}</span>
                                </Box>
                            </Button.Icon>
                        </DownloadButtonWrapper>
                    )}
                </Box>
                <Box
                    ml={{ md: '20px', xs: 'unset' }}
                    mt={'18px'}
                    textAlign={{ xs: 'center', md: 'unset' }}
                    px={{ xs: '40px', md: 'unset' }}
                >
                    {isDesktop ? (
                        <Typography.Body2 font={theme.fontFamilies.bold}>{footerText}</Typography.Body2>
                    ) : (
                        <Typography.Body4 font={theme.fontFamilies.bold}>{footerText}</Typography.Body4>
                    )}
                </Box>
                <ImageWrapper>
                    <OrFullBody width={isDesktop ? 175 : 88} height={isDesktop ? 381 : 191} />
                </ImageWrapper>
            </Wrapper>
        </Dialog>
    );
};

export default DownloadQuotationModal;
