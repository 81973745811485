import { Box, InfoTooltip, Paper, Typography } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import React, { FC, ReactNode } from 'react';

interface IProps {
    children: ReactNode;
    firstText?: string;
    secondTitle?: string;
    firstTitle?: string;
    secondText?: string;
    border?: boolean;
    title?: string;
    isSpaceBetween?: boolean;
}
// check what happens when user click on info button.

const CardWithInfo: FC<IProps> = ({ children, firstText, border, firstTitle, secondTitle, secondText, title, isSpaceBetween = false }) => {
    const isDesktop = useIsDesktop();
    return (
        <Paper.WithInfo border={border}>
            <Box display='flex' height='100%' position='relative' flexDirection={'column'} rowGap={'8px'}>
                <Box
                    display={'flex'}
                    columnGap={'8px'}
                    justifyContent={isDesktop ? (isSpaceBetween ? 'space-between' : 'unset') : 'space-between'}
                >
                    {title && <Typography.Body4>{title}</Typography.Body4>}
                    <InfoTooltip firstTitle={firstTitle} firstText={firstText} secondTitle={secondTitle} secondText={secondText} />
                </Box>
                {children}
            </Box>
        </Paper.WithInfo>
    );
};

export default CardWithInfo;
