import { useQuery } from '@tanstack/react-query';
import { API_ROUTES } from 'api/apiRoutes';
import { axiosGet } from 'api/axios';
import { AxiosResponse } from 'axios';
import { IGetMyOffersResponse } from 'models/api/myOffers';
import { getToken } from 'utils/getToken';

export const useGetClientProjects = (enabled?: boolean) => {
    const { data } = useQuery(
        [API_ROUTES.GET_CLIENT_PROJECTS],
        (): Promise<AxiosResponse<IGetMyOffersResponse, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_CLIENT_PROJECTS}`),
        { enabled }
    );
    return { data: data?.data };
};

export const useGetAgentProjects = (enabled?: boolean) => {
    const { data } = useQuery(
        [API_ROUTES.GET_AGENT_PROJECTS],
        (): Promise<AxiosResponse<IGetMyOffersResponse, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_AGENT_PROJECTS}`),
        { enabled }
    );
    return { data: data?.data };
};

export const useGetCompanyProjects = (enabled?: boolean) => {
    const { data } = useQuery(
        [API_ROUTES.GET_COMPANY_PROJECTS],
        (): Promise<AxiosResponse<IGetMyOffersResponse, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_COMPANY_PROJECTS}`),
        { enabled }
    );
    return { data: data?.data };
};

export const useGetCompanyQuotations = (enabled?: boolean) => {
    const { data } = useQuery(
        [API_ROUTES.GET_COMPANY_QUOTATIONS],
        (): Promise<AxiosResponse<IGetMyOffersResponse, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_COMPANY_QUOTATIONS}`),
        { enabled }
    );
    return { data: data?.data };
};

export const useGetCompanyEntrepreneurQuotations = (enabled?: boolean) => {
    const { data } = useQuery(
        [API_ROUTES.GET_COMPANY_ENTREPRENEUR_QUOTATIONS],
        (): Promise<AxiosResponse<IGetMyOffersResponse, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_COMPANY_ENTREPRENEUR_QUOTATIONS}`),
        { enabled }
    );
    return { data: data?.data };
};
