import React, { FC, ReactNode } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from 'components/atoms';

interface IProps {
    title: string;
    children: ReactNode;
}

const PricingContractorAccordionDetailsMobile: FC<IProps> = ({ title, children }) => {
    return (
        <Accordion>
            <AccordionSummary>
                <Box px='10px'>
                    <Typography.Body2>{title}</Typography.Body2>
                </Box>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export default PricingContractorAccordionDetailsMobile;
