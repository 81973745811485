import { styled } from '@mui/material';
import { useGetCompanyEntrepreneurQuotations, useGetCompanyQuotations } from 'api/queries/myOffers';
import { Box } from 'components/atoms';
import OffersModelTypeButtons from 'components/molecules/OffersModelTypeButtons';
import MyProjects from 'components/organisms/myOffers/MyProjects';
import SortQuotations from 'components/organisms/myOffers/SortQuotations';
import useDownloadProjectQuotations from 'hooks/useDownloadProjectQuotations.hook';
import { IDownloadCompanyFileFieldsReq } from 'models/api/common';
import { ContractTypeModel } from 'models/common';
import { IMyOffer } from 'models/myOffers';
import { useMemo, useState } from 'react';

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    rowGap: 'unset',
    paddingTop: '40px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        rowGap: '20px',
    },
}));

const MyCompanyProjects = () => {
    const [offersModel, setOffersModel] = useState(ContractTypeModel.PURCHASE);
    const [projects, setProjects] = useState<IMyOffer[]>([]);

    const isPurchaseModel = offersModel === ContractTypeModel.PURCHASE;

    const { data: quotations } = useGetCompanyQuotations();
    const { data: entrepreneurQuotations } = useGetCompanyEntrepreneurQuotations();
    const { onDownloadProjectQuotation } = useDownloadProjectQuotations(offersModel);

    const offers = useMemo(
        () => (isPurchaseModel ? quotations?.items : entrepreneurQuotations?.items),
        [entrepreneurQuotations?.items, isPurchaseModel, quotations?.items]
    );

    const onQuotationDownload = (offer: IMyOffer) => {
        onDownloadProjectQuotation(offer.item_id, IDownloadCompanyFileFieldsReq.QUOTATION_PDF);
    };

    const onFullReportDownload = (offer: IMyOffer) => {
        onDownloadProjectQuotation(offer.item_id, IDownloadCompanyFileFieldsReq.EXPANDED_QUOTATION_PDF);
    };

    const totalItems = isPurchaseModel ? quotations?.itemsFound || 0 : entrepreneurQuotations?.itemsFound || 0;

    return (
        <>
            <Wrapper>
                <Box width={{ xs: '100%', md: 'unset' }}>
                    <OffersModelTypeButtons
                        offersModel={offersModel}
                        buyOffersCount={quotations?.itemsFound}
                        rentOffersCount={entrepreneurQuotations?.itemsFound}
                        onClick={setOffersModel}
                    />
                </Box>
                <SortQuotations projects={offers} setProjects={setProjects} />
            </Wrapper>

            <MyProjects
                totalItems={totalItems}
                offers={projects}
                isDownloadQuotation
                mainButtonHandler={onQuotationDownload}
                secondaryButtonHandler={onFullReportDownload}
            />
        </>
    );
};

export default MyCompanyProjects;
