import React, { FC } from 'react';
import { Box, Button, Icon, Typography } from 'components/atoms';

interface IProps {
    title: string;
    content: string;
    onBackClick: () => void;
}

const DescriptionWithBack: FC<IProps> = ({ title, content, onBackClick }) => {
    return (
        <Box sx={{ direction: 'ltr' }}>
            <Box display='flex' alignItems='center' columnGap='4px' mb={'20px'}>
                <Button.Text onClick={onBackClick}>
                    <Icon.BackAppBar />
                    <Typography.Body4>חזרה</Typography.Body4>
                </Button.Text>
            </Box>
            <Box mb={'14px'} display='flex' alignItems='end' justifyContent='center' gap='6px'>
                <Typography.Body4>{title}</Typography.Body4>
                <Icon.Help />
            </Box>
            <Box height='100%' textAlign='start'>
                <Typography.Body5>{content}</Typography.Body5>
            </Box>
        </Box>
    );
};

export default DescriptionWithBack;
