export const parseToNumber = (value: string | number): number => {
    if (typeof value === 'string') {
        return parseFloat(value.replaceAll(',', '')) || 0;
    }
    return value || 0;
};

export const valuesToNumber = (array: unknown[]) => {
    return array.map((obj: any) => {
        const { year, item_id, ...rest } = obj;
        return Object.fromEntries(Object.entries(rest).map(([key, value]) => [key, parseToNumber(value as string)]));
    });
};
