import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, Swiper, SwiperSlide, Typography, Box } from 'components/atoms';
import RatingCard from 'components/molecules/contractor/RatingCard';
import { useTheme } from '@mui/material';
import CardReview from 'components/molecules/contractor/CardReview';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { IOfferRating } from 'models/OfferData.model';

interface IProps {
    data: IOfferRating;
    isContractor: boolean;
}

const AccordionRatingOffers: FC<IProps> = ({ data, isContractor }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const titleText = isContractor ? 'דירוג לקוחות החברה המציעה' : 'דירוג החברה היזמית';

    return (
        <Accordion>
            <AccordionSummary>
                {isDesktop ? (
                    <Typography.Body1 color={theme.palette.primary.purple}>{titleText}</Typography.Body1>
                ) : (
                    <Typography.Body3 color={theme.palette.primary.purple}>{titleText}</Typography.Body3>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box px={{ xs: '16px' }}>
                    <Grid container spacing={10} mb={{ md: '8px', xs: '25px' }}>
                        {data.categories.map((category) => (
                            <Grid item md={2} xs={6} key={category.name}>
                                <Card.GreyCard>
                                    <RatingCard
                                        ratersNumber={+category.ratersNumber}
                                        rating={+category.value}
                                        text={category.name}
                                        isAverage
                                    />
                                </Card.GreyCard>
                            </Grid>
                        ))}
                    </Grid>
                    {isDesktop ? (
                        data?.recommendations?.map((recommendation: any, index: number) => (
                            <CardReview recommendation={recommendation} key={index} />
                        ))
                    ) : (
                        <Swiper.SwiperRegular>
                            {data?.recommendations?.map((recommendation: any, index: number) => (
                                <SwiperSlide key={index}>
                                    <CardReview recommendation={recommendation} key={index} />
                                </SwiperSlide>
                            ))}
                        </Swiper.SwiperRegular>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionRatingOffers;
