import { Box } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC, MouseEvent, forwardRef } from 'react';
import OrganuzTip from 'assets/icons/icon-organuz.svg';

interface IProps {
    ref?: any;
    onClick: (param: MouseEvent<HTMLDivElement>) => void;
}

const OrganuzButton: FC<IProps> = forwardRef(({ onClick }, ref) => {
    const isDesktop = useIsDesktop();
    const iconSize = isDesktop ? 73 : 48;

    return (
        <Box
            ref={ref}
            sx={{
                width: iconSize,
                height: iconSize,
                border: 'none',
                backgroundImage: `url(${OrganuzTip})`,
                backgroundSize: 'cover',
                cursor: 'pointer',
                backgroundPosition: 'bottom',
            }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(e);
            }}
        >
            <Box
                display='flex'
                alignItems={'center'}
                justifyContent={'center'}
                sx={(theme) => ({
                    border: `4px solid ${theme.palette.primary.purple}`,
                    borderRadius: '50%',
                    width: iconSize,
                    height: iconSize,
                })}
            ></Box>
        </Box>
    );
});

export default OrganuzButton;
