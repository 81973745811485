import React, { FC, useState } from 'react';
import { AccordionComparisonTable, AccordionDetailsComparisonTable, AccordionSummaryComparisonTable } from 'components/atoms/Accordion';
import AccordionHeader from 'components/molecules/offersComparisonTable/AccordionHeader';
import AccordionContent from 'components/molecules/offersComparisonTable/AccordionContent';
import { IBuiltComparisonData, IComparisonTableCategory } from 'models/OffersData';
import { useIsDesktop } from 'hooks/useIsDesktop';
import AccordionHeaderDesktop from 'components/molecules/offersComparisonTable/AccordionHeaderDesktop';
import AccordionContentDesktop from 'components/molecules/offersComparisonTable/AccordionContentDesktop';
import texts from 'texts.json';

interface IProps {
    offersForCompare: IBuiltComparisonData[];
    category: IComparisonTableCategory;
    columns: number;
}

const AccordionMain: FC<IProps> = ({ offersForCompare, category, columns }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isDesktop = useIsDesktop();
    const offersCategoryData = offersForCompare.map((offer: any) => offer.data[category.name]);
    const offersCategoryTotal = offersCategoryData.map((item) => item?.total);
    const vatIncluded = texts.vat.vatIncluded;
    const vat = category.isVat ? vatIncluded : '';
    return (
        <AccordionComparisonTable
            onChange={(e, isExpanded) => {
                setIsExpanded(isExpanded);
            }}
        >
            <AccordionSummaryComparisonTable>
                {isDesktop ? (
                    <AccordionHeaderDesktop
                        title={category.title}
                        vat={vat}
                        isExpanded={isExpanded}
                        data={offersCategoryTotal}
                        columns={columns}
                    />
                ) : (
                    <AccordionHeader title={category.title} vat={vat} />
                )}
                {!isExpanded && !isDesktop && <AccordionContent data={offersCategoryTotal} title='סה"כ ניקוד' isTotal />}
            </AccordionSummaryComparisonTable>
            <AccordionDetailsComparisonTable>
                {category.subCategories.map((subCategory, index) => {
                    const values = offersCategoryData.map(
                        (item: any) => item?.data.find((item: any) => item.name === subCategory.name)?.value
                    );
                    return isDesktop ? (
                        <AccordionContentDesktop
                            data={values}
                            title={subCategory.title}
                            info={subCategory.info}
                            key={index}
                            columns={columns}
                        />
                    ) : (
                        <AccordionContent data={values} title={subCategory.title} info={subCategory.info} key={index} />
                    );
                })}
            </AccordionDetailsComparisonTable>
        </AccordionComparisonTable>
    );
};

export default AccordionMain;
