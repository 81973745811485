import React, { FC } from 'react';
import { Box, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import texts from 'texts.json';
import ImagesSwiper from 'components/molecules/pricing/ImagesSwiper';
import { IOfferSwipper } from 'models/OfferData.model';
interface IProps {
    data: IOfferSwipper;
}

const SwiperContractor: FC<IProps> = ({ data }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const roofType = 'roofType';
    const projectPower = 'projectPower';
    const [images, setImages] = React.useState<string>(roofType);

    return (
        <Box display='flex' width='100%' justifyContent='space-between'>
            {!isDesktop && (
                <Box width={'100%'} borderRadius={theme.borderRadius.large} overflow='hidden' display='flex' flexDirection='column'>
                    <Box display='flex' height={'168px'}>
                        <ImagesSwiper
                            onSlideChangeTransitionStart={(swiper) => {
                                if (swiper.isEnd) {
                                    setImages((prev) => (prev === roofType ? projectPower : roofType));
                                    swiper.slideToLoop(0, 500, false);
                                    swiper?.updateSlides();
                                }
                                if (swiper.isBeginning) {
                                    setImages((prev) => (prev === roofType ? projectPower : roofType));
                                }
                            }}
                            images={data[images as keyof IOfferSwipper].images}
                            defaultImage={data[roofType] ? texts.offers.defaultImageRoofType : texts.offers.defaultImageProjectPower}
                        />
                    </Box>
                    <Box py='20px' bgcolor='primary.white' textAlign='center'>
                        <Typography.Body4>{data[images as keyof IOfferSwipper].title}</Typography.Body4>
                    </Box>
                </Box>
            )}
            {isDesktop &&
                Object.entries(data).map(([key, value], index) => {
                    if (!isDesktop && index === 1) return null;
                    const isRoofType = key === roofType;
                    return (
                        <Box
                            width={'49%'}
                            borderRadius={theme.borderRadius.large}
                            overflow='hidden'
                            key={index}
                            display='flex'
                            flexDirection='column'
                        >
                            <Box display='flex' height={'264px'}>
                                <ImagesSwiper
                                    images={value.images}
                                    defaultImage={isRoofType ? texts.offers.defaultImageRoofType : texts.offers.defaultImageProjectPower}
                                />
                            </Box>
                            <Box py='20px' bgcolor='primary.white' textAlign='center'>
                                <Typography.Body2>{value.title}</Typography.Body2>
                            </Box>
                        </Box>
                    );
                })}
        </Box>
    );
};

export default SwiperContractor;
