import React, { ChangeEvent, FC, MouseEvent, ReactNode, useState } from 'react';
import { AccordionSummary, Box, Checkbox, Switch } from 'components/atoms';
import { Controller, useFormContext } from 'react-hook-form';
import { useIsDesktop } from 'hooks/useIsDesktop';
import PricingCopyPastePanel from 'components/molecules/pricing/PricingCopyPastePanel';

interface IProps {
    children: ReactNode;
    copyPaste?: boolean;
    switchName?: string;
    onSwitchChangeHandler?: () => void;
    onCopy?: () => void;
    onPaste?: () => void;
    onDuplicate?: () => void;
}

const PricingAccordionSummary: FC<IProps> = ({ children, copyPaste, switchName, onSwitchChangeHandler, onCopy, onPaste, onDuplicate }) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const isDesktop = useIsDesktop();
    const { control } = useFormContext();

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (
        <AccordionSummary>
            <Box display='flex' width='100%' justifyContent='space-between' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <Box display='flex' alignItems={{ xs: 'center', md: 'unset' }}>
                    <Box mr='10px'>
                        <Checkbox
                            onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) => {}}
                            checked={true}
                            onClick={(e: MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
                        />
                    </Box>
                    {children}
                </Box>
                <Box display='flex'>
                    {isHovering && isDesktop && copyPaste && (
                        <Box display='flex' gap='20px' alignItems='start'>
                            <PricingCopyPastePanel onCopy={onCopy} onDuplicate={onDuplicate} onPaste={onPaste} />
                        </Box>
                    )}
                    <Box m={{ md: '0 20px 0 40px', xs: '0 5px 0 auto' }}>
                        <Controller
                            name={switchName || ''}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Switch
                                    checked={value}
                                    onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                        onChange(checked);
                                        onSwitchChangeHandler && onSwitchChangeHandler();
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </Box>
        </AccordionSummary>
    );
};

export default PricingAccordionSummary;
