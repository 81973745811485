import CircularProgress from '@mui/material/CircularProgress';

const CircularLoader = () => {
    return (
        <CircularProgress
            variant='indeterminate'
            sx={{
                color: (theme) => theme.palette.primary.purple,
            }}
            size={40}
            thickness={4}
        />
    );
};

export default CircularLoader;
