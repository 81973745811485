import { ClientEntrepreneurQuotation, ClientQuotation, ElectQuotationDetails, ImageObject } from 'models/api/offers';
import { IElectricityProductionItem, IRoofPreview } from 'models/common';
import { IPurchaseEconomicModel, IRentEconomicModel } from 'models/economicModel.model';
import { ReactNode } from 'react';

export enum SelectValues {
    PRICE_AND_RATING = 'PRICE_AND_RATING',
    RATING = 'RATING',
    PRICE = 'PRICE',
}

export interface IComparisonTableCategory {
    name: string;
    title: string;
    isVat?: boolean;
    subCategories: {
        name: string;
        title: string;
        info: string;
    }[];
}

interface IDataValue {
    name: string;
    value: string;
}

interface IDataWithTotal {
    data: IDataValue[];
    total: string;
}

export interface IBuiltComparisonData {
    offerNumber: number;
    offerId: string;
    contractorId: string;
    data: {
        footer: IDataValue;
        header: IDataValue[];
        costs: IDataWithTotal;
        qualityAndWarranty: IDataWithTotal;
        paymentTerms: IDataWithTotal;
        additionsPrice: IDataWithTotal;
    };
}

export interface IAccordionOfferDetailsData {
    header: {
        title: string;
        value: string;
        icon?: JSX.Element;
        isVat?: boolean;
    }[];
    details: IAccordionOfferDetailsDataItem[];
}

export interface IAccordionOfferDetailsDataItem {
    title: string;
    value?: string;
    info: string;
    categories?: string[];
    isVat?: boolean;
    items?: [
        {
            name: string;
            first: string;
            second: string;
        }
    ];
}

export interface IOfferHeaderData {
    title: string;
    value: string;
    icon?: ReactNode;
}

export enum RatingContractorCategories {
    Professionalism = 'איכות \nההתקנה',
    Service = 'רמת\n השירות',
    Timeliness = 'עמידה בלוחות\n זמנים',
    Safety = 'שמירה על כללי\n בטיחות',
    Execution = 'התחייבות להצעת מחיר ראשונית',
    Overall = 'שביעות רצון\n כללית',
}

export interface IElectQuotation {
    itemId: string;
    id: number;
    details: ElectQuotationDetails;
}

export interface IOffersMainCard {
    id?: number;
    item_id: string;
    company_id_rate: string | null;
    price_list_rate: string | null;
    rate: string | null;
    vat_included?: boolean;
    rightSection: IOffersCardRightSection;
    leftSection: IOffersCardLeftSection;
    mainSection: IOffersCardMainSection[];
}

export interface IOffersCardRightSection {
    images: ImageObject[];
    text: string;
    value: string;
    specialChar: string;
}
export interface IOffersCardLeftSection {
    costs: {
        title: string;
        value: string;
    }[];
    total: {
        title: string;
        value: string;
    };
}

export interface IOffersCardMainSection {
    icon: React.ReactNode;
    text: string;
    boldText: string;
}

export interface IProjectQuotation {
    item_id: string;
    power: string;
    panels_number: string;
    light_hours: string;
    annual_ac_generation: string;
    annual_dc_generation: string;
    average_tariff: string;
    annual_repayment_estimation: string;
    repayment_estimation_25: string;
    client_quotations: ClientQuotation[];
    client_entrepreneur_quotations: ClientEntrepreneurQuotation[];
    economic_model: IPurchaseEconomicModel;
    entrepreneur_economic_model: IRentEconomicModel;
    avg_rent: string;
    entrepreneur_total_income: string;
    electricity_production: IElectricityProductionItem[];
    vat_included: boolean;
    settlement_tariff: string;
    roof_preview: IRoofPreview;
    default_entrepreneur_quotation: string;
    default_quotation: string;
}
