import React, { FC } from 'react';
import PricingTransformersTypes from 'components/molecules/pricing/pricingTransformers/PricingTransformersTypes';
import PricingHeader from 'components/molecules/pricing/PricingHeader';
import PricingTransformersSimulator from 'components/molecules/pricing/pricingTransformers/PricingTransformersSimulator';
import texts from 'texts.json';

interface IProps {}

const PricingTransformers: FC<IProps> = () => {
    return (
        <>
            <PricingHeader title='מחשבון ממירים' text={texts.pricing.pricingTransformers.description} />
            <PricingTransformersTypes />
            <PricingTransformersSimulator />
        </>
    );
};

export default PricingTransformers;
