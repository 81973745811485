import { styled } from '@mui/material';
import React, { FC } from 'react';
import { Box } from './index';
import { Icon } from 'components/atoms';

interface IProps {
    step: number;
    activeStep: number;
}

const StepBase = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'step' && prop !== 'activeStep',
})<IProps>(({ theme, step, activeStep }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px auto',
    fontFamily: theme.fontFamilies.bold,
    color: step <= activeStep ? theme.palette.primary.white : theme.palette.primary.black,
    backgroundColor: step <= activeStep ? theme.palette.primary.purple : theme.palette.primary.white,
    width: '36px',
    height: '36px',
    boxShadow: '0 0 40px #ccc',
    borderRadius: theme.borderRadius.full,
    [theme.breakpoints.down('sm')]: {
        width: '26px',
        height: '26px',
        margin: '0 auto',
        fontSize: '14px',
    },
}));

const Step: FC<IProps> = ({ step, activeStep }) => {
    return (
        <StepBase step={step} activeStep={activeStep}>
            {step < activeStep ? <Icon.Check /> : step}
        </StepBase>
    );
};

export default Step;
