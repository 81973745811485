import { Icon } from 'components/atoms';
import { ApiResponseYesNo, EmployeeTypes } from 'models/api/common';

export const renderCorrectIconEmployeeType = (value: keyof typeof EmployeeTypes) => {
    const isEmployee = 'CONSTRACTOR_STAFF_TYPE_EMPLOYEE' as keyof typeof EmployeeTypes;
    const isSubConstractor = 'CONSTRACTOR_STAFF_TYPE_SUBCONSTRACTOR' as keyof typeof EmployeeTypes;

    switch (value) {
        case isEmployee:
            return <Icon.CheckBgGreen />;
        case isSubConstractor:
            return <Icon.CheckBgYellow />;
        default:
            return <Icon.XBgRed />;
    }
};

export const renderCorrectIconYesNo = (value: keyof typeof ApiResponseYesNo) => {
    const isYes = 'YN_ANSWER_YES' as keyof typeof ApiResponseYesNo;
    const isNo = 'YN_ANSWER_NO' as keyof typeof ApiResponseYesNo;

    switch (value) {
        case isYes:
            return <Icon.CheckBgGreen />;
        case isNo:
            return <Icon.XBgRed />;
        default:
            return <Icon.XBgRed />;
    }
};

export const renderPaymentTermsIconYesNo = (value: boolean) => {
    return value ? <Icon.CheckBgGreen /> : <Icon.XBgRed />;
};