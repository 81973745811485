import React, { FC } from 'react';
import { Box, Divider, Icon, Typography } from 'components/atoms';
import { useMediaQuery, useTheme } from '@mui/material';
import { Orientation } from 'components/atoms/Divider';
import AccordionMain from 'components/molecules/offersComparisonTable/AccordionMain';
import OffersComparisonTableHeader from 'components/molecules/offersComparisonTable/OffersComparisonTableHeader';
import OffersComparisonTableFooter from 'components/molecules/offersComparisonTable/OffersComparisonTableFooter';
import { Symbols } from 'utils/symbols';
import { SystemDetails } from 'models/api/offers';
import { comparisonTableCategories } from 'data/offersData';
import { IBuiltComparisonData } from 'models/OffersData';

interface IProps {
    offersForCompare: IBuiltComparisonData[];
    systemDetails: SystemDetails;
    isVatIncluded: boolean;
    removeOfferFromCompare: (offerId: string) => void;
    handleElectQuotation: (quotationId: string) => void;
    setOpen: () => void;
}

const ComparisonTable: FC<IProps> = ({
    offersForCompare,
    systemDetails,
    removeOfferFromCompare,
    handleElectQuotation,
    setOpen,
    isVatIncluded,
}) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const iconSize = isDesktop ? '25px' : '20px';

    const totalColumnsToRender = 3;
    const columns = offersForCompare.length * 3 + 2; // 2 for first column, 3 for each offer
    const columnWidth = 27.572727;
    const placeholderColumns = totalColumnsToRender - offersForCompare.length || false;

    const systemDetailsLabels = [
        {
            title: 'המערכת שלי',
            value: `${systemDetails.power} קוו"ט על ${systemDetails.roof_types}`,
            icon: <Icon.Charging fill={theme.palette.primary.purple} width={iconSize} height={iconSize} />,
        },
        {
            title: 'הכנסה שנתית מוערכת',
            value: `${systemDetails.annual_repayment_estimation} ${Symbols.SHEKEL} (${systemDetails.light_hours} שעות אור בשנה)`,
            icon: <Icon.Graph width={iconSize} height={iconSize} />,
        },
    ];

    return (
        <Box mt={'25px'} width={{ xs: '95%', md: 'unset' }} mx={{ xs: 'auto', md: '159px' }} textAlign={{ xs: 'center', md: 'start' }}>
            <Box display='flex' gap={{ md: '35px', xs: '15px' }} flexDirection={{ md: 'row', xs: 'column' }} mb={{ md: 0, xs: '25px' }}>
                {isDesktop ? (
                    <Typography.XLargeTitle color={theme.palette.primary.purple}>
                        {offersForCompare.length} הצעות רכישה להשוואה
                    </Typography.XLargeTitle>
                ) : (
                    <Typography.Body2 color={theme.palette.primary.purple}>{offersForCompare.length} הצעות קבלנות להשוואה</Typography.Body2>
                )}
                {systemDetailsLabels.map((item, index) => (
                    <Box key={index} display='flex' alignItems='center' gap={10} mx={{ xs: 'auto', md: '0' }}>
                        {isDesktop && (
                            <Box display='flex' alignItems='center' gap={10}>
                                {item.icon}
                            </Box>
                        )}
                        <Box display='flex' flexDirection='column'>
                            <Box display='flex' gap={'6px'} mx={{ xs: 'auto', md: 0 }}>
                                {!isDesktop && <Box>{item.icon}</Box>}
                                <Typography.Body5 color={isDesktop ? theme.palette.primary.purple : theme.palette.primary.black}>
                                    {item.title}
                                </Typography.Body5>
                            </Box>
                            <Typography.Body4 color={theme.palette.primary.purple}>{item.value}</Typography.Body4>
                        </Box>
                    </Box>
                ))}
            </Box>
            {isDesktop && (
                <Box m='28px 0 70px 0'>
                    <Divider orientation={Orientation.Horizontal} />
                </Box>
            )}
            <OffersComparisonTableHeader
                offersForCompare={offersForCompare}
                removeOfferFromCompare={removeOfferFromCompare}
                setOpen={setOpen}
            />
            <Box display='flex' width='100%'>
                <Box display='flex' flexDirection='column' gap={2} mt={{ md: 0, xs: '10px' }} flex={1}>
                    {comparisonTableCategories(isVatIncluded).map((category, index) => (
                        <AccordionMain offersForCompare={offersForCompare} key={index} category={category} columns={columns} />
                    ))}
                </Box>
                {!!placeholderColumns && isDesktop && (
                    <Box width={columnWidth * placeholderColumns + '%'} display='flex' pl='20px' gap={20}>
                        {[...Array(placeholderColumns)].map((_, index) => (
                            <Box
                                width='414px'
                                height='100%'
                                bgcolor='primary.greyBackground'
                                borderRadius={theme.borderRadius.large}
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                flexDirection='column'
                                key={index}
                                sx={{ cursor: 'pointer' }}
                                onClick={setOpen}
                            >
                                <Typography.Body4>ניתן לבחור</Typography.Body4>
                                <Typography.Body1>הצעה נוספת להשוואה</Typography.Body1>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
            <OffersComparisonTableFooter
                offersForCompare={offersForCompare}
                handleElectQuotation={handleElectQuotation}
                onCloseComparisonTable={setOpen}
            />
        </Box>
    );
};

export default ComparisonTable;
