import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box } from 'components/atoms';
import OffersListHeader from 'components/organisms/offers/offersList/OffersListHeader';
import { IBuiltComparisonData, IOffersMainCard, IProjectQuotation, SelectValues } from 'models/OffersData';
import OffersMainCard from 'components/molecules/offersMainCard/OffersMainCard';
import {
    buildEntrepreneurQuotationCardData,
    buildContractorQuotationCardData,
} from 'components/molecules/offersMainCard/offersMainCardData';
import { ContractTypeModel } from 'models/common';
import texts from 'texts.json';
import { sortOffers } from 'data/offersData';

interface IProps {
    quotations: IProjectQuotation;
    offersForCompare: IBuiltComparisonData[];
    offersModel: ContractTypeModel;
    setOffersModel: (model: ContractTypeModel) => void;
    addOfferForCompare: (offerId: string) => void;
    removeOfferFromCompare: (offerId: string) => void;
    handleElectQuotation: (quotationId: string, modelType: ContractTypeModel) => void;
    resetOffersForCompare: () => void;
}

const OffersList: FC<IProps> = ({
    quotations,
    addOfferForCompare,
    removeOfferFromCompare,
    offersForCompare,
    handleElectQuotation,
    resetOffersForCompare,
    offersModel,
    setOffersModel,
}) => {
    const [sortValue, setSortValue] = useState(SelectValues.PRICE_AND_RATING);
    const [offers, setOffers] = useState<IOffersMainCard[]>([]);

    const {
        sortOptions: { priceAndRating, rating, price },
    } = texts.offers;

    const selectOptions =
        offersModel === ContractTypeModel.PURCHASE
            ? [
                  { value: SelectValues.PRICE_AND_RATING, label: priceAndRating.label },
                  { value: SelectValues.RATING, label: rating.label },
                  { value: SelectValues.PRICE, label: price.label },
              ]
            : [{ value: SelectValues.PRICE, label: price.label }];

    const changeOffersModel = (model: ContractTypeModel) => {
        setOffersModel(model);
        resetOffersForCompare();
    };

    useEffect(() => {
        if (offersModel === ContractTypeModel.RENT) {
            setSortValue(SelectValues.PRICE);
        }
    }, [offersModel]);

    useEffect(() => {
        if (offersModel === ContractTypeModel.PURCHASE) {
            const sortedOffers = sortOffers(quotations?.client_quotations, sortValue);
            const cardData = buildContractorQuotationCardData(sortedOffers);
            setOffers(cardData);
        } else {
            const cardData = buildEntrepreneurQuotationCardData(quotations.client_entrepreneur_quotations);
            setOffers(cardData);
        }
    }, [offersModel, quotations.client_entrepreneur_quotations, quotations.client_quotations, sortValue]);

    return (
        <Box width={{ md: 1138 }} mx={{ xs: '20px', md: '40px' }} py='50px'>
            <OffersListHeader
                offersModel={offersModel}
                changeOffersModel={changeOffersModel}
                sortValue={sortValue}
                setSortValue={setSortValue}
                buyOffersCount={quotations?.client_quotations?.length}
                rentOffersCount={quotations?.client_entrepreneur_quotations?.length}
                selectOptions={selectOptions}
            />
            <Box display='flex' flexDirection='column' gap={16}>
                {offers?.map((offer: IOffersMainCard, index: number) => (
                    <Fragment key={index}>
                        <OffersMainCard
                            offer={offer}
                            offersModel={offersModel}
                            addOfferForCompare={addOfferForCompare}
                            removeOfferFromCompare={removeOfferFromCompare}
                            offersForCompare={offersForCompare}
                            handleElectQuotation={handleElectQuotation}
                            sortValue={sortValue}
                            selectOptions={selectOptions}
                        />
                    </Fragment>
                ))}
            </Box>
        </Box>
    );
};

export default OffersList;
