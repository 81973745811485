import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Icon } from 'components/atoms';
import MapSection from 'pages/calculator/MapSection';
import CalculatorSection from 'pages/calculator/CalculatorSection';
import { useLocation } from 'react-router-dom';
import { PATH } from 'paths';
import { MapContext } from 'contexts/MapContext';
import { Grid, styled } from '@mui/material';
import ContactUsModal from 'components/organisms/contactUs/ContactUsModal';
import { MOBILE_APP_BAR_HEIGHT } from 'theme/sizes';
import { useIsDesktop } from 'hooks/useIsDesktop';

const ButtonWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: 50,
    left: 20,
}));

const CalculatorPage = () => {
    const location = useLocation();
    const [activeStep, setActiveStep] = useState(1);
    const [contactUsModal, setContactUsModal] = useState(false);
    const isDesktop = useIsDesktop();
    const { showMapSection, footerButtonData, mapLoading } = useContext(MapContext) || ({} as any);

    useEffect(() => {
        if (location.pathname.includes(PATH.ADDRESS)) {
            setActiveStep(1);
        }
        if (location.pathname.includes(PATH.ROOF)) {
            setActiveStep(2);
        }
        if (location.pathname.includes(PATH.QUESTIONNAIRE)) {
            setActiveStep(3);
        }
    }, [location.pathname]);

    const iconSize = isDesktop ? '73px' : '48px';
    return (
        <Grid container display={isDesktop ? 'flex' : ''} height='100%'>
            <Grid item xs={12} md={5} height={'100%'} display={isDesktop ? 'block' : showMapSection ? 'none' : 'block'}>
                <CalculatorSection activeStep={activeStep} isLoading={mapLoading} />
            </Grid>
            <Grid item xs={12} md={7} height={{ md: '100%', xs: `calc(100dvh - ${MOBILE_APP_BAR_HEIGHT}px)` }}>
                <MapSection activeStep={activeStep} mapLoading={mapLoading} />
                {!isDesktop && showMapSection && (
                    <Box width='100%'>
                        <Button.Mobile disabled={!footerButtonData.text} onClick={footerButtonData.onClick}>
                            {footerButtonData.text ? footerButtonData.text : 'טוען...'}
                        </Button.Mobile>
                    </Box>
                )}
            </Grid>
            {!isDesktop && showMapSection ? null : (
                <ButtonWrapper>
                    <Button.Icon onClick={() => setContactUsModal(true)}>
                        <Icon.ContactUs width={iconSize} height={iconSize} />
                    </Button.Icon>
                </ButtonWrapper>
            )}
            {contactUsModal && <ContactUsModal open={contactUsModal} onClose={() => setContactUsModal(false)} />}
        </Grid>
    );
};

export default CalculatorPage;
