import React, { FC } from 'react';
import { Box, Divider, Grid, Icon, Typography } from 'components/atoms';
import OffersComparisonTableHeaderCard from 'components/molecules/offersComparisonTable/OffersComparisonTableHeaderCard';
import { Orientation } from 'components/atoms/Divider';
import { useTheme } from '@mui/material';
import { runThreeTimes } from 'utils/runThreeTimes';
import { IBuiltComparisonData } from 'models/OffersData';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    offersForCompare: IBuiltComparisonData[];
    removeOfferFromCompare: (offerId: string) => void;
    setOpen: () => void;
}

const OffersComparisonTableHeader: FC<IProps> = ({ offersForCompare, removeOfferFromCompare, setOpen }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    return (
        <Box>
            <Grid
                minHeight={{ md: 'auto', xs: '200px' }}
                container
                border={{ md: 'none', xs: `1px solid ${theme.palette.primary.grey}` }}
                borderRadius={theme.borderRadius.medium}
                columns={{ md: 11, xs: 12 }}
            >
                {isDesktop && (
                    <Grid item md={2} p='20px 10px' alignSelf='end'>
                        <Typography.Body4 font={theme.fontFamilies.xBold}>פרטי ההצעה:</Typography.Body4>
                    </Grid>
                )}
                {runThreeTimes.map((item, index) => (
                    <Grid item xs={4} md={3} display='flex' key={index} textAlign='center' justifyContent='center'>
                        <Box p='15px 5px'>
                            {offersForCompare[item] ? (
                                <OffersComparisonTableHeaderCard
                                    offer={offersForCompare[item]}
                                    removeOfferFromCompare={removeOfferFromCompare}
                                />
                            ) : (
                                !isDesktop && (
                                    <Box display='flex' flexDirection='column' justifyContent='center' height='100%' onClick={setOpen}>
                                        <>
                                            <Box>
                                                <Icon.Plus />
                                            </Box>
                                            <Box>
                                                <Typography.Body5 font={theme.fontFamilies.light}>ניתן לבחור הצעה נוספת</Typography.Body5>
                                            </Box>
                                        </>
                                    </Box>
                                )
                            )}
                        </Box>
                        {!isDesktop && <Divider orientation={Orientation.Vertical} />}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OffersComparisonTableHeader;
