import { Icon } from 'components/atoms';
import { ClientQuotation, ClientQuotationCompare, GetProjectQuotationRes } from 'models/api/offers';
import { IComparisonTableCategory, IProjectQuotation, SelectValues } from 'models/OffersData';
import { Symbols } from 'utils/symbols';
import texts from 'texts.json';
import { buildElectricityConnectionData, buildPurchaseEconomicModelData } from 'data/EconomicModelData';
import { parseToNumber } from 'utils/parseToNumber';
import { isVatIncludes } from 'utils/isVatIncludes';
import { theme } from 'theme';

//TODO use addSymbol func
const {
    offers: {
        contractor: {
            comparisonTable: { costs, qualityAndWarranty, paymentTerms, additionsPrice },
        },
    },
} = texts;

export const comparisonTableCategories = (isVat: boolean): IComparisonTableCategory[] => [
    {
        title: costs.title,
        isVat: isVat,
        name: 'costs',
        subCategories: [
            { title: costs.pricePerKw.title, name: 'price_per_kw', info: costs.pricePerKw.info },
            { title: costs.additionsPrice.title, name: 'additions_price', info: costs.additionsPrice.info },
            { title: costs.finalPrice.title, name: 'final_price', info: costs.finalPrice.info },
        ],
    },
    {
        title: qualityAndWarranty.title,
        name: 'qualityAndWarranty',
        subCategories: [
            { title: qualityAndWarranty.yearsInTheField.title, name: 'years_in_the_field', info: qualityAndWarranty.yearsInTheField.info },
            {
                title: qualityAndWarranty.installationWarrantyYears.title,
                name: 'installation_warranty_years',
                info: qualityAndWarranty.installationWarrantyYears.info,
            },
            { title: qualityAndWarranty.finalRate.title, name: 'final_rate', info: qualityAndWarranty.finalRate.info },
        ],
    },
    {
        title: paymentTerms.title,
        name: 'paymentTerms',
        isVat: isVat,
        subCategories: [
            { title: paymentTerms.advancePayment.title, name: 'advance_payment', info: paymentTerms.advancePayment.info },
            {
                title: paymentTerms.permitAndConstruction.title,
                name: 'permit_and_construction',
                info: paymentTerms.permitAndConstruction.info,
            },
            { title: paymentTerms.panelsAndConverters.title, name: 'panels_and_converters', info: paymentTerms.panelsAndConverters.info },
            {
                title: paymentTerms.finishAndTest.title,
                name: 'finish_and_test',
                info: paymentTerms.finishAndTest.info,
            },
            {
                title: paymentTerms.systemConnection.title,
                name: 'system_connection',
                info: paymentTerms.systemConnection.info,
            },
        ],
    },
    {
        title: additionsPrice.title,
        name: 'additionsPrice',
        isVat: isVat,
        subCategories: [
            {
                title: additionsPrice.additionsPricePerKw.title,
                name: 'additions_price_per_kw',
                info: additionsPrice.additionsPricePerKw.info,
            },
            { title: additionsPrice.additionsPrice.title, name: 'additions_price', info: additionsPrice.additionsPrice.info },
        ],
    },
];

export const buildOffersComparisonData = (item?: ClientQuotationCompare) => {
    if (!item) {
        return;
    }
    const isVat = isVatIncludes(item.project.vat_included);
    const pricePerKw = `${isVat ? item.price_per_kw_vat : item.price_per_kw} ${Symbols.SHEKEL}`;
    const additionsPrice = `${isVat ? item.additions_price_vat : item.additions_price} ${Symbols.SHEKEL}`;
    return {
        offerNumber: item.id,
        offerId: item.item_id,
        contractorId: item.constactor,
        data: {
            footer: { name: 'qualityAndPrice', value: item.rate },
            header: [
                {
                    name: 'result_irr',
                    value: `${item.result_irr}%`,
                },
                {
                    name: 'investment_return_time',
                    value: `${item.investment_return_time} שנים`,
                },
            ],
            costs: {
                data: [
                    { name: 'price_per_kw', value: pricePerKw },
                    { name: 'additions_price', value: additionsPrice },
                    { name: 'final_price', value: `${item.final_price} ${Symbols.SHEKEL}` },
                ],
                total: item.price_list_rate,
            },
            qualityAndWarranty: {
                data: [
                    { name: 'years_in_the_field', value: `${item.years_in_the_field} שנים` },
                    { name: 'installation_warranty_years', value: `${item.price_list_quotation.installation_warranty_years} שנים` },
                    { name: 'final_rate', value: item.final_rate },
                ],
                total: item.company_id_rate,
            },
            paymentTerms: {
                data: [
                    { name: 'advance_payment', value: `${item.advance_payment} ${Symbols.SHEKEL}` },
                    { name: 'permit_and_construction', value: `${item.permit_and_construction} ${Symbols.SHEKEL}` },
                    { name: 'panels_and_converters', value: `${item.panels_and_converters} ${Symbols.SHEKEL}` },
                    { name: 'finish_and_test', value: `${item.finish_and_test} ${Symbols.SHEKEL}` },
                    { name: 'system_connection', value: `${item.system_connection} ${Symbols.SHEKEL}` },
                ],
                total: item.terms_of_payment_rate,
            },
            additionsPrice: {
                data: [
                    { name: 'additions_price_per_kw', value: `${item.additions_price_per_kw} ${Symbols.SHEKEL}` },
                    { name: 'additions_price', value: additionsPrice },
                ],
                total: item.additions_price_list_rate,
            },
        },
    };
};

export const buildOfferDetailsData = (data: Partial<IProjectQuotation>, isPurchaseModel: boolean) => {
    const {
        powerField,
        annualDcGenerationField,
        lightHoursField,
        panelsNumberField,
        averageTariffField,
        agreementTimeField,
        annualRepaymentRstimationField,
        repaymentEstimation25Field,
    } = texts.offers.leftSection;
    const {
        repayment_estimation_25,
        annual_repayment_estimation,
        average_tariff,
        annual_dc_generation,
        light_hours,
        panels_number,
        power,
        avg_rent,
        entrepreneur_total_income,
        settlement_tariff,
        vat_included,
    } = data;
    const { title: urbanTitle, info: urbanInfo } = texts.urbanTariff;
    const {
        greenEnergyRoute: { title: greenTitle, info: greenInfo },
    } = texts;
    return [
        {
            title: powerField,
            value: power,
            icon: <Icon.Charging />,
            helperText: vat_included
                ? {
                      value: { title: greenTitle, info: greenInfo },
                      color: theme.palette.primary.green,
                  }
                : null,
        },
        {
            title: panelsNumberField,
            value: panels_number,
            icon: <Icon.SolarPanel fill='black' />,
        },
        {
            title: lightHoursField,
            value: light_hours,
            icon: <Icon.Sun />,
        },
        {
            title: annualDcGenerationField,
            value: annual_dc_generation,
            icon: <Icon.Lighting />,
        },
        {
            title: averageTariffField,
            value: average_tariff,
            icon: <Icon.Tag />,
            helperText: settlement_tariff
                ? {
                      value: { title: urbanTitle, info: urbanInfo },
                      color: theme.palette.primary.gold,
                  }
                : null,
        },
        {
            title: agreementTimeField,
            value: '25 שנים',
            icon: <Icon.Signature />,
        },
        {
            title: annualRepaymentRstimationField.title,
            subTitle: annualRepaymentRstimationField.subTitle,
            value: isPurchaseModel ? annual_repayment_estimation : avg_rent,
            icon: <Icon.Calendar />,
        },
        {
            title: repaymentEstimation25Field.title,
            subTitle: repaymentEstimation25Field.subTitle,
            value: isPurchaseModel ? repayment_estimation_25 : entrepreneur_total_income,
            icon: <Icon.Graph width='58px' height='58px' fill='black' />,
        },
    ];
};

export const sortOffers = (offers: ClientQuotation[], sortBy: string) => {
    return offers?.sort((a, b) => {
        let sort = 0;
        switch (sortBy) {
            case SelectValues.PRICE_AND_RATING: {
                sort = +b.rate - +a.rate;
                break;
            }
            case SelectValues.PRICE: {
                sort = parseToNumber(a.final_price) - parseToNumber(b.final_price);
                break;
            }
            case SelectValues.RATING: {
                sort = +b.company_id_rate - +a.company_id_rate;
                break;
            }
        }
        return sort;
    });
};

export const processGetProjectQuotationsResData = (data?: GetProjectQuotationRes): IProjectQuotation => {
    if (!data) return {} as IProjectQuotation;
    const {
        ac_apr,
        ac_aug,
        ac_dec,
        ac_feb,
        ac_jan,
        ac_jul,
        ac_jun,
        ac_mar,
        ac_may,
        ac_nov,
        ac_oct,
        ac_sep,
        economic_model: economicModel,
        entrepreneur_economic_model: entrepreneurEconomicModel,
        vat_included: vatIncluded,
        ...rest
    } = data?.item;
    const vat_included = isVatIncludes(vatIncluded);
    const electricity_production = buildElectricityConnectionData(data?.item);
    const economic_model = buildPurchaseEconomicModelData(economicModel);
    const entrepreneur_economic_model = buildPurchaseEconomicModelData(entrepreneurEconomicModel);

    return { ...rest, economic_model, electricity_production, entrepreneur_economic_model, vat_included };
};