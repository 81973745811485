import { useTheme } from '@mui/material';
import { Typography } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC } from 'react';

interface IProps {
    label: string;
    isCalculator?: boolean;
}

const PropertyTypeCheckboxLabel: FC<IProps> = ({ label, isCalculator = false }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const labelColor = isCalculator ? theme.palette.primary.black : theme.palette.primary.purple;

    return isDesktop ? (
        <Typography.Body2 color={labelColor} font={theme.fontFamilies.bold}>
            {label}
        </Typography.Body2>
    ) : (
        <Typography.Body6 color={labelColor} font={theme.fontFamilies.bold}>
            {label}
        </Typography.Body6>
    );
};

export default PropertyTypeCheckboxLabel;
