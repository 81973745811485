import React, { FC, ReactNode } from 'react';
import { Box, Typography } from 'components/atoms';

interface IProps {
    children?: ReactNode;
    required?: boolean;
}

const FormLabel: FC<IProps> = ({ children, required = false }) => {
    return (
        <Typography.Body4>
            <Box display={'flex'}>
                {required && (
                    <Box component='span' color='primary.red'>
                        * &nbsp;
                    </Box>
                )}
                <Box>{children}</Box>
            </Box>
        </Typography.Body4>
    );
};

export default FormLabel;
