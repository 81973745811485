import React, { FC } from 'react';
import { Box, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';

interface IProps {
    char: string;
    label: string;
}

const ManagementAccordionSummary: FC<IProps> = ({ char, label }) => {
    const theme = useTheme();
    return (
        <Box display='flex' alignItems='center' gap='20px'>
            <Box
                bgcolor='primary.purple'
                color='primary.white'
                width='20px'
                height='20px'
                display='flex'
                alignItems='center'
                justifyContent='center'
                fontSize={theme.fontSizes.medium}
                fontFamily={theme.fontFamilies.bold}
                borderRadius={theme.borderRadius.xSmall}
            >
                {char}
            </Box>
            <Typography.Body2>{label}</Typography.Body2>
        </Box>
    );
};

export default ManagementAccordionSummary;
