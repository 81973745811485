import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC, FormEvent, useState } from 'react';
import { useTheme } from '@mui/material';
import { ButtonType, Box, Button, TextField, Typography, Select, Loader } from 'components/atoms';
import { TextFieldSize, TextFieldType } from 'components/atoms/TextField';
import AuthDialog from 'components/molecules/AuthDialog';
import { validatePhoneNumber } from 'utils/validatePhoneNumber';
import ContactUsHeader from 'components/organisms/contactUs/ContactUsHeader';
import { SelectSize } from 'components/atoms/Select';
import contactUsImage from 'assets/images/or-full-body.svg';
import { ContactMessageParams } from 'models/api/contact';
import texts from 'texts.json';
import { useCreateContactMessage } from 'api/queries/contact';
import { ToastType } from 'components/atoms/Toast';
import { enqueueSnackbar } from 'notistack';

interface IProps {
    open: boolean;
    onClose: () => void;
}

const initialContactUsData = {
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
};

const selectOptions = [
    { label: 'התחברות', value: '5' },
    { label: 'איתור נכס', value: 'איתור נכס' },
    { label: 'רכישת בדיקות נוספות', value: 'רכישת בדיקות נוספות' },
    { label: 'אחר', value: 'אחר' },
];

const ContactUsModal: FC<IProps> = ({ open, onClose }) => {
    const { title, description } = texts.contactUsModal;
    const DialogContactUs = {
        header: {
            title,
        },
        headerElement: <ContactUsHeader />,
    };

    const isDesktop = useIsDesktop();
    const size = isDesktop ? TextFieldSize.Medium : TextFieldSize.Standard;
    const theme = useTheme();
    const [contactUsData, setContactUsData] = useState<ContactMessageParams>(initialContactUsData);

    const { mutate: mutateContactUs, isLoading: isContactUsLoading } = useCreateContactMessage();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        mutateContactUs(contactUsData, {
            onSuccess: async (data) => {
                setContactUsData(initialContactUsData);
                onClose();
                enqueueSnackbar('תודה שפנית אלינו, נחזור אליך בהקדם.', {
                    variant: 'toast',
                    type: ToastType.SUCCESS,
                });
            },
        });
    };

    const isDisabled = () => {
        return (
            !contactUsData.name ||
            !validatePhoneNumber(contactUsData.phone) ||
            !contactUsData.email ||
            !contactUsData.subject ||
            !contactUsData.message ||
            isContactUsLoading
        );
    };

    return (
        <AuthDialog data={DialogContactUs} open={open} onClose={onClose} isFooter={true}>
            <Box
                component='form'
                display='flex'
                flexDirection='column'
                alignItems='center'
                onSubmit={handleSubmit}
                gap={{ xs: '16px', md: '12px' }}
                mb={'46px'}
            >
                {isDesktop ? (
                    <Typography.Body2 font={theme.fontFamilies.regular}>{description}</Typography.Body2>
                ) : (
                    <Typography.Body3 font={theme.fontFamilies.regular}>{description}</Typography.Body3>
                )}

                <Box
                    sx={{
                        position: 'absolute',
                        top: { md: 65, xs: 0 },
                        left: { md: 0, xs: -20 },
                        width: { md: 152, xs: 65 },
                        height: { md: 380, xs: 175 },
                    }}
                >
                    <img width={'auto'} height={'100%'} src={contactUsImage} alt='customer_center' />
                </Box>
                <TextField
                    size={size}
                    value={contactUsData.name}
                    onChange={(e) => setContactUsData((value) => ({ ...value, name: e.target.value }))}
                    placeholder='שם מלא'
                />
                <TextField
                    size={size}
                    value={contactUsData.phone}
                    onChange={(e) => setContactUsData((value) => ({ ...value, phone: e.target.value }))}
                    placeholder='טלפון נייד'
                />
                <TextField
                    size={size}
                    value={contactUsData.email}
                    onChange={(e) => setContactUsData((value) => ({ ...value, email: e.target.value }))}
                    placeholder='דואר אלקטרוני'
                    type={TextFieldType.Email}
                />
                <Select
                    fontSize={isDesktop ? theme.fontSizes.large : theme.fontSizes.normal}
                    borderRadius={theme.borderRadius.regular}
                    size={isDesktop ? SelectSize.Large : SelectSize.Regular}
                    options={selectOptions}
                    placeholder='נושא הפנייה'
                    value={contactUsData.subject}
                    isCenteredInputValue
                    onChange={(e) => setContactUsData((value) => ({ ...value, subject: e.target.value }))}
                />
                <TextField
                    multiline={true}
                    size={TextFieldSize.xLarge}
                    rows={4}
                    value={contactUsData.message}
                    onChange={(e) => setContactUsData((value) => ({ ...value, message: e.target.value }))}
                    placeholder='תוכן הפנייה'
                    textAlign='start'
                />
                <Button.Contained type={ButtonType.SUBMIT} disabled={isDisabled()}>
                    שליחת הפנייה
                </Button.Contained>
                {isContactUsLoading && (
                    <Box sx={{ position: 'absolute', top: '50%' }}>
                        <Loader />
                    </Box>
                )}
            </Box>
        </AuthDialog>
    );
};

export default ContactUsModal;
