import { Pagination as MuiPagination, styled } from '@mui/material';
import { FC } from 'react';

interface IProps {
    count: number;
    page: number;
    onChange: (e: any, value: number) => void;
}

const BasePagination = styled(
    MuiPagination,
    {}
)<IProps>(({ theme }) => ({
    '& .MuiPaginationItem-root': {
        color: theme.palette.primary.purple,
        borderRadius: '5px',
        border: `1px solid ${theme.palette.primary.borderGrey}`,
        fontFamily: theme.fontFamilies.bold,
        fontSize: '18px',
        '&.Mui-selected': {
            color: theme.palette.primary.white,
            backgroundColor: theme.palette.primary.purple,
        },
    },
}));

const Pagination: FC<IProps> = ({ count, page, onChange }) => {
    return (
        <BasePagination hideNextButton hidePrevButton count={count} variant='outlined' shape='rounded' page={page} onChange={onChange} />
    );
};

export default Pagination;
