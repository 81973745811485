import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from 'components/atoms';
import PriceSimulatorDetails from 'components/molecules/pricing/pricingEntrepreneur/PriceSimulatorDetails';
import PricingSimulatorHeader from 'components/molecules/pricing/PricingSimulatorHeader';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { EconomicModel, EntrepreneurSimulator } from 'models/api/pricingEntrepreneur';
import EconomicModelTemplate from 'components/templates/EconomicModelTemplate';
import texts from 'texts.json';
import {
    entrepreneurChartData,
    entrepreneurSimulatorTableDataCategories,
    entrepreneurSimulatorMobileDataCategories,
    headTitlesMobile,
} from 'data/PricingEntrepreneurDataProcessing';
import { useTheme } from '@mui/material';

interface IProps {
    simulatorData?: EntrepreneurSimulator;
    closeSimulator: boolean;
    getEntrepreneurSimulator: (value: string) => void;
}

const PriceSimulator: FC<IProps> = ({ getEntrepreneurSimulator, simulatorData, closeSimulator }) => {
    const [open, setOpen] = useState<boolean>(false);
    const isDesktop = useIsDesktop();
    const {
        header: { title, button_text, placeholder, secondaryTitle },
        table_header: { title: tableTitle },
    } = texts.pricing.pricingEntrepreneur.simulator;
    const chartLabels = { xAxisLabel: 'שנים', yAxisLabel: 'הכנסות ₪' };
    const theme = useTheme();

    const calculate = (value: number | null) => {
        getEntrepreneurSimulator(value?.toString() || '');
    };

    useEffect(() => {
        if (simulatorData && Object.keys(simulatorData).length) {
            setOpen(true);
        }
    }, [simulatorData]);

    useEffect(() => {
        if (open && closeSimulator) {
            setOpen(false);
        }
    }, [closeSimulator, open]);

    const secondaryTitleElement = (
        <Box>
            {isDesktop ? (
                <>
                    <Typography.Body2 font={theme.fontFamilies.bold}>{secondaryTitle}</Typography.Body2>
                    <Typography.Body2 font={theme.fontFamilies.regular}>{`${simulatorData?.total_construction_cost} ₪`}</Typography.Body2>
                </>
            ) : (
                <>
                    <Typography.Body2 font={theme.fontFamilies.bold}>{secondaryTitle}</Typography.Body2>
                    <Typography.Body2 font={theme.fontFamilies.regular}>{`${simulatorData?.total_construction_cost} ₪`}</Typography.Body2>
                </>
            )}
        </Box>
    );

    return (
        <Box mx={{ md: 'auto' }} bgcolor='primary.white' borderRadius={(theme) => theme.borderRadius.large} mb='140px' overflow='auto'>
            <Box position={isDesktop ? 'static' : 'fixed'} bottom={0} right={0} width='100%'>
                <PricingSimulatorHeader
                    title={title}
                    buttonText={button_text}
                    placeholder={placeholder}
                    open={open}
                    calculate={calculate}
                    secondaryTitle={simulatorData?.total_construction_cost && secondaryTitleElement}
                />
            </Box>
            {open && !!simulatorData && (
                <Box p={{ md: '20px 20px 60px 20px' }}>
                    {simulatorData.entrepreneur_simulator_error_message?.length && (
                        <Typography.Body3>{simulatorData.entrepreneur_simulator_error_message}</Typography.Body3>
                    )}
                    {simulatorData.economic_model && (
                        <EconomicModelTemplate
                            dialogBackgroundMobile={theme.palette.primary.lightPurple}
                            chartLabels={chartLabels}
                            mobileLabels={entrepreneurSimulatorMobileDataCategories()}
                            headMobileTitles={headTitlesMobile()}
                            open={open}
                            onClose={() => setOpen(false)}
                            chartDataSet={
                                simulatorData.economic_model?.years &&
                                entrepreneurChartData(simulatorData.economic_model.years as EconomicModel[])
                            }
                            tableTitle={tableTitle}
                            economicModel={simulatorData.economic_model?.years}
                            simulatorDetails={<PriceSimulatorDetails data={simulatorData} />}
                            tableDataCategories={entrepreneurSimulatorTableDataCategories()}
                            npvRow={simulatorData.economic_model?.npv as EconomicModel}
                            tableTotalRows={simulatorData.economic_model?.total as EconomicModel}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PriceSimulator;
