import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'components/atoms';
import Image from 'components/atoms/Image';
import { ImageObject } from 'models/api/offers';
import texts from 'texts.json';

interface IProps {
    images: ImageObject[];
    isMainCard?: boolean;
    defaultImage?: string;
    onSlideChangeTransitionStart?: (swiper: any) => void;
}

const ImagesSwiper: FC<IProps> = ({ images, isMainCard, defaultImage, onSlideChangeTransitionStart }) => {
    return images?.length > 0 ? (
        <Swiper.SwiperOffers onSlideChangeTransitionStart={onSlideChangeTransitionStart}>
            {images.map((item, index) => (
                <SwiperSlide key={index}>
                    <Image
                        alt='image'
                        src={item.image.src}
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '100%',
                        }}
                    />
                </SwiperSlide>
            ))}
        </Swiper.SwiperOffers>
    ) : (
        <Image
            alt='image'
            src={defaultImage ?? texts.offers.defaultImageRoofType}
            style={{
                width: '100%',
                height: 'auto',
                maxHeight: '100%',
            }}
        />
    );
};

export default ImagesSwiper;
