import React, { FC } from 'react';
import { Box, Button, Icon, Typography } from 'components/atoms';

interface IProps {
    handleSaveAsDraft: () => void;
}

const SaveAsDraftButton: FC<IProps> = ({ handleSaveAsDraft }) => {
    return (
        <Button.Text onClick={handleSaveAsDraft} backgroundColor='transparent'>
            <Box display='flex' alignItems='center' gap={10}>
                <Icon.Card />
                <Box
                    sx={{
                        textDecoration: 'underline',
                    }}
                >
                    <Typography.Body4>שמירה כטיוטה</Typography.Body4>
                </Box>
            </Box>
        </Button.Text>
    );
};

export default SaveAsDraftButton;
