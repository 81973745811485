import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ROUTES } from 'api/apiRoutes';
import { axiosGet, axiosPost } from 'api/axios';
import { AxiosResponse } from 'axios';
import { SetCreateRes } from 'models/api/common';
import {
    GetContractorPriceListsRes,
    GetContractorSimulatorRes,
    Item,
    ItemWithoutUsdRateManually,
    SetContractorPriceListReq,
} from 'models/api/pricingContractor';
import { getToken } from 'utils/getToken';

export const useGetContractorPriceLists = (enabled = true) => {
    const { data, refetch } = useQuery(
        [API_ROUTES.GET_CONTRACTOR_PRICE_LISTS],
        (): Promise<AxiosResponse<GetContractorPriceListsRes, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_CONTRACTOR_PRICE_LISTS}`),
        { enabled }
    );
    return { data, refetch };
};

export const useCreateContractorPriceList = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.CREATE_CONTRACTOR_PRICE_LIST],
        (item: Item | ItemWithoutUsdRateManually): Promise<AxiosResponse<SetCreateRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.CREATE_CONTRACTOR_PRICE_LIST}`, {
                item,
            })
    );
    return { mutate, data };
};

export const useSetContractorPriceList = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.SET_CONTRACTOR_PRICE_LIST],
        ({ priceListId, item }: SetContractorPriceListReq): Promise<AxiosResponse<SetCreateRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.SET_CONTRACTOR_PRICE_LIST}`, {
                item_id: priceListId,
                item,
            })
    );
    return { mutate, data };
};

export const useGetContractorSimulator = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.GET_CONSTRACTOR_SIMULATOR],
        (s_power: string): Promise<AxiosResponse<GetContractorSimulatorRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_CONSTRACTOR_SIMULATOR}`, {
                item: {
                    s_power,
                },
            })
    );
    return { mutate, data };
};

export const useDeleteContractorPriceList = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.DELETE_CONSTRACTOR_PRICE_LIST],
        (itemId: string): Promise<AxiosResponse<Response, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.DELETE_CONSTRACTOR_PRICE_LIST}`, {
                item_id: itemId,
            })
    );
    return { mutate, data };
};
