import { useTheme } from '@mui/material';
import { LegendItem } from 'chart.js';
import { Box, Typography } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC } from 'react';

const sx = (isWrapped: boolean) => ({
    legendWrapper: {
        display: 'flex',
        width: isWrapped ? 230 : '100%',
        flexWrap: isWrapped ? 'wrap' : 'nowrap',
        marginTop: '18px',
        justifyContent: isWrapped ? 'unset' : 'space-evenly',
    },
});

interface IProps {
    legendItems: LegendItem[];
}

const ChartLegend: FC<IProps> = ({ legendItems }) => {
    const theme = useTheme();
    const isWrapped = legendItems?.length >= 4;
    const isDesktop = useIsDesktop();

    return (
        <Box sx={sx(isWrapped || !isDesktop).legendWrapper} gap={isWrapped ? '20px' : '8px'}>
            {legendItems?.map((item: any, index: number) => (
                <Box
                    display='flex'
                    alignItems='center'
                    columnGap={'8px'}
                    width={isWrapped ? '100px' : 'fit-content'}
                    maxWidth={isWrapped ? 'unset' : 147}
                    key={`item-${index}`}
                >
                    <Box>
                        <Box sx={{ background: item.fillStyle, width: 20, height: 20, borderRadius: '5px' }} />
                    </Box>
                    <Typography.Body5 color={theme.palette.primary.black} font={theme.fontFamilies.light}>
                        {item.text}
                    </Typography.Body5>
                </Box>
            ))}
        </Box>
    );
};

export default ChartLegend;
