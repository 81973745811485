import React, { FC, Fragment, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ToggleButtonGroup,
    ToggleButton,
} from 'components/atoms';
import { useTheme } from '@mui/material';
import EditButtonSection from 'components/molecules/pricing/EditButtonSection';
import { useFormContext } from 'react-hook-form';
import ControllerTextField from 'components/organisms/ControllerTextField';
import { addCommaToNumber } from 'utils/addCommaToNumber';
import { buttonGroup, categories } from 'components/molecules/pricing/pricingTransformers/PricingTransformersAccordionDetails';
import CardWithInfo from 'components/molecules/CardWithInfo';

interface IProps {
    data: any;
    isEdit: boolean;
    setIsEdit: (value: string) => void;
    fieldIndex: number;
}

const PricingTransformersAccordionDetailsMobile: FC<IProps> = ({ data, isEdit, setIsEdit, fieldIndex }) => {
    const theme = useTheme();
    const { control, handleSubmit } = useFormContext();
    const [currencyType, setCurrencyType] = useState(buttonGroup[0]);

    return (
        <Box display='flex' flexDirection='column' gap='20px'>
            <Box ml='auto'>
                <EditButtonSection
                    dataId={data.id}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    onSave={() => handleSubmit((data) => console.log(data))()}
                />
            </Box>
            <Box textAlign='center'>
                <Box mb='5px'>
                    <Typography.Body2>{data.name}</Typography.Body2>
                </Box>
                <Box width='80%' mx='auto'>
                    <Typography.Body5>
                        לשינוי סוג המטבע לתמחור, יש לעבור למצב עריכה, המערכת תוציא אוטומטית מחירים לפי מטבע שקלי:
                    </Typography.Body5>
                </Box>
            </Box>
            <Box width='100%' height='40px'>
                <ToggleButtonGroup value={currencyType} onChange={(_, value: any) => setCurrencyType(value)} disabled={!isEdit}>
                    {buttonGroup.map((item) => (
                        <ToggleButton value={item} key={item.name}>
                            <Typography.Body4 color='inherit'>{item.title}</Typography.Body4>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
            <Box display='flex' flexDirection='column' gap={10}>
                {data.data?.map((item: any, index: number) => (
                    <Accordion key={index}>
                        <AccordionSummary>
                            <Box px='15px'>
                                <Typography.Body4>{item.KW}KW</Typography.Body4>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {categories.map((category, catIndex) => {
                                if (category.name === 'KW') return null;
                                return (
                                    <Fragment key={catIndex}>
                                        {catIndex !== 1 && <Divider />}
                                        <CardWithInfo border={false}>
                                            <Box display='flex' flexDirection='column'>
                                                <Box>
                                                    <Typography.Body4>{category.title}</Typography.Body4>
                                                </Box>
                                                <Box>
                                                    {!isEdit ? (
                                                        <Typography.Body3 font={theme.fontFamilies.light}>
                                                            {currencyType.symbol}
                                                            {addCommaToNumber(item[category.name])}
                                                        </Typography.Body3>
                                                    ) : (
                                                        <Box display='flex' alignItems='center'>
                                                            <ControllerTextField
                                                                control={control}
                                                                name={`transformersData.${fieldIndex}.data.${index}.${category.name}`}
                                                            />
                                                            &nbsp;
                                                            {currencyType.symbol}
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        </CardWithInfo>
                                    </Fragment>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
            <Box mb='30px'>
                <Button.Contained onClick={() => {}}>
                    <Typography.Body3 color={theme.palette.primary.white}>עלות רכיבי תקשורת</Typography.Body3>
                </Button.Contained>
            </Box>
        </Box>
    );
};

export default PricingTransformersAccordionDetailsMobile;
