import React, { FC } from 'react';
import { Box, FormLabel, Typography } from 'components/atoms';
import { Control } from 'react-hook-form';
import ControllerTextField from 'components/organisms/ControllerTextField';
import { TextFieldType } from 'components/atoms/TextField';

interface IProps {
    label: string;
    rules?: any;
    name: string;
    control: Control<any>;
    placeholder?: string;
    inputWidth?: string | number;
    disabled?: boolean;
    type?: TextFieldType;
    onBlur?: () => void;
}

const FormLabelWithInput: FC<IProps> = ({ label, rules, name, control, placeholder, inputWidth, disabled, onBlur, type }) => {
    return (
        <>
            <FormLabel required={rules?.required}>
                <Typography.Body4>{label}</Typography.Body4>
            </FormLabel>
            <Box width={inputWidth ? inputWidth : '100%'}>
                <ControllerTextField
                    control={control}
                    name={name}
                    rules={rules}
                    placeholder={placeholder}
                    disabled={disabled}
                    onBlur={onBlur}
                    type={type}
                />
            </Box>
        </>
    );
};

export default FormLabelWithInput;
