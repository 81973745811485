import React, { FC, FormEvent, useContext, useState } from 'react';
import Login from 'components/organisms/authentication/Login';
import VerificationCode from 'components/organisms/authentication/VerificationCode';
import { useLogin, useSignUp } from 'api/queries/auth';
import { enqueueSnackbar } from 'notistack';
import { ToastType } from 'components/atoms/Toast';
import { AuthContext } from 'contexts/AuthContext';
import SignUp from 'components/organisms/authentication/SignUp';
import { ApiResponseYesNo } from 'models/api/common';
import { Dialogs, SignUpParams } from 'models/api/auth';
import { cleanPhoneNumber } from 'utils/validatePhoneNumber';

const inputsLength = 4;

interface IProps {
    showDialog: Dialogs | null;
    setShowDialog: (dialog: Dialogs | null) => void;
}

const initialSignUpData = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    advertising_content: ApiResponseYesNo.YN_ANSWER_NO,
    regulations_approval: ApiResponseYesNo.YN_ANSWER_NO,
};

const LoginDialogs: FC<IProps> = ({ showDialog, setShowDialog }) => {
    const { handleLogin } = useContext(AuthContext);

    const { mutate: login } = useLogin();
    const { mutate: signUp, isLoading: isSignUpLoading } = useSignUp();

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [signUpData, setSignUpData] = useState<SignUpParams>(initialSignUpData);
    const [inputValues, setInputValues] = useState<string[]>(Array(inputsLength).fill(''));
    const [keepLoggedIn, setKeepLoggedIn] = useState<boolean>(false);

    const handleLoginSubmit = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        login(
            {
                sms_phone: cleanPhoneNumber(phoneNumber || signUpData.phone),
            },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(Dialogs.VERIFICATION_CODE);
                },
            }
        );
    };

    const handleVerificationCodeSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        login(
            {
                sms_phone: cleanPhoneNumber(phoneNumber || signUpData.phone),
                sms_code: inputValues.join(''),
            },
            {
                onSuccess: async (data) => {
                    handleLogin(data.data, keepLoggedIn, () => {
                        enqueueSnackbar('!ההתחברות עברה בהצלחה', {
                            variant: 'toast',
                            type: ToastType.SUCCESS,
                        });
                        onClose();
                        setSignUpData(initialSignUpData);
                    });
                },
            }
        );
    };

    const handleSendAgain = () => {
        login(
            {
                sms_phone: cleanPhoneNumber(phoneNumber || signUpData.phone),
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('הקוד נשלח שנית', {
                        variant: 'toast',
                        type: ToastType.SUCCESS,
                    });
                },
            }
        );
    };

    const handleSignUpSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        signUp(signUpData, {
            onSuccess: () => {
                handleLoginSubmit();
                enqueueSnackbar('ההרשמה עברה בהצלחה!', {
                    variant: 'toast',
                    type: ToastType.SUCCESS,
                });
            },
        });
    };

    const onClose = () => setShowDialog(null);

    return (
        <>
            <Login
                showDialog={showDialog === Dialogs.LOGIN}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                onSubmit={handleLoginSubmit}
                onClose={() => {}}
            />
            <VerificationCode
                showDialog={showDialog === Dialogs.VERIFICATION_CODE}
                inputValues={inputValues}
                setInputValues={setInputValues}
                onSubmit={handleVerificationCodeSubmit}
                keepLoggedIn={keepLoggedIn}
                setKeepLoggedIn={setKeepLoggedIn}
                handleSendAgain={handleSendAgain}
                onClose={() => setShowDialog(Dialogs.LOGIN)}
            />
            <SignUp
                showDialog={showDialog === Dialogs.SIGN_UP}
                onClose={() => {
                    onClose();
                    setSignUpData(initialSignUpData);
                }}
                onSubmit={handleSignUpSubmit}
                setSignUpData={setSignUpData}
                signUpData={signUpData}
                isLoading={isSignUpLoading}
            />
        </>
    );
};

export default LoginDialogs;
