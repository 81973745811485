import { Components, Theme } from '@mui/material';

import { ExtraThemeOptions } from './extraThemeOptions';

// https://mui.com/material-ui/customization/theme-components/
declare module '@mui/material/styles' {
    interface Theme extends ExtraThemeOptions {}
}

export const components: Components<Omit<Theme, 'components'>> = {
    MuiTypography: {
        defaultProps: {
            variantMapping: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                body1: 'span',
                body2: 'span',
                'tab-text': 'span',
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                borderRadius: '20px',
            },
        },
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                width: '100%',
            },
        },
    },
};
