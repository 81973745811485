import { useMutation } from '@tanstack/react-query';
import { API_ROUTES } from 'api/apiRoutes';
import { axiosPost } from 'api/axios';
import { AxiosResponse } from 'axios';
import { SetCreateRes } from 'models/api/common';
import { ContactMessageParams } from 'models/api/contact';
import { DEV_AUTH_TOKEN } from 'utils/envUtils';
import { getToken } from 'utils/getToken';

export const useCreateContactMessage = () => {
    const { data, mutate, isLoading } = useMutation(
        [API_ROUTES.CREATE_CONTACT_MESSAGE],
        (body: ContactMessageParams): Promise<AxiosResponse<SetCreateRes>> =>
            axiosPost(`?action=token&token=${getToken() || DEV_AUTH_TOKEN}&call=${API_ROUTES.CREATE_CONTACT_MESSAGE}`, { item: body })
    );
    return { data, mutate, isLoading };
};
