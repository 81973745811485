export const getFlattenedProps = (obj: Object) => {
    const flattenedObj = {};
    Object.keys(obj).forEach((key) => {
        const val = obj[key as keyof typeof obj];
        if (typeof val === 'function') return;
        if (typeof val !== 'object' || val === null || Array.isArray(val)) {
            flattenedObj[key as keyof typeof flattenedObj] = val;
        } else {
            Object.keys(val).forEach((nestedKey) => {
                const nestedVal = val[nestedKey];
                if (typeof nestedVal !== 'object' || nestedVal === null || Array.isArray(nestedVal)) {
                    flattenedObj[nestedKey as keyof typeof flattenedObj] = nestedVal;
                }
            });
        }
    });
    return flattenedObj;
};
