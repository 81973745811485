import React, { FC, ReactNode } from 'react';
import { Box } from 'components/atoms';

interface IProps {
    children?: ReactNode;
    showBorder?: boolean;
}

const GreyCard: FC<IProps> = ({ children }) => {
    return (
        <Box
            padding={{ md: '19px', xs: '14px' }}
            height='100%'
            width='100%'
            bgcolor='primary.greyBackground'
            borderRadius={(theme) => theme.borderRadius.medium}
        >
            {children}
        </Box>
    );
};

const GreyBorderCard: FC<IProps> = ({ children, showBorder = true }) => (
    <Box
        border={(theme) => (showBorder ? `1px solid ${theme.palette.primary.grey}` : 'none')}
        borderRadius={(theme) => (showBorder ? theme.borderRadius.large : 'none')}
        overflow={showBorder ? 'hidden' : 'visible'}
    >
        {children}
    </Box>
);

const Card = {
    GreyCard,
    GreyBorderCard,
};

export default Card;
