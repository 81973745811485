import React, { ChangeEvent, FC, MouseEvent } from 'react';
import { Checkbox as CheckboxMui, styled } from '@mui/material';

interface IBpIcon {
    rounded?: boolean;
    borderColor?: string;
    size?: number;
    disabled?: boolean;
}

const bpIconDefaultSize = 19;
const BpCheckedIconDefaultSize = 18;

const getCheckBoxSize = (size: number | undefined) => {
    const bpIconSize = size ? size : bpIconDefaultSize;
    const bpCheckedIconSize = size ? size - 1 : BpCheckedIconDefaultSize;
    return {
        bpIconSize: { width: bpIconSize, height: bpIconSize },
        bpCheckedIconSize: { width: bpCheckedIconSize, height: bpCheckedIconSize },
    };
};

const BpIcon = styled('span', {
    shouldForwardProp: (prop) => prop !== 'rounded' && prop !== 'borderColor' && prop !== 'size' && prop !== 'disabled',
})<IBpIcon>(({ theme, rounded, borderColor, size, disabled }) => ({
    ...getCheckBoxSize(size).bpIconSize,
    borderRadius: rounded ? theme.borderRadius.full : theme.borderRadius.small,
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.grey} , inset 0 -1px 0 ${theme.palette.primary.grey}`,
    border: `1px solid ${borderColor ? borderColor : 'unset'}`,
    backgroundColor: disabled ? theme.palette.primary.greyBackground : '',
}));

const BpCheckedIcon = styled(BpIcon, {
    shouldForwardProp: (prop) => prop !== 'rounded' && prop !== 'borderColor' && prop !== 'size' && prop !== 'disabled',
})<IBpIcon>(({ theme, rounded, borderColor, size, disabled }) => ({
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.grey} , inset 0 -1px 0 ${theme.palette.primary.grey}`,
    borderRadius: rounded ? theme.borderRadius.full : theme.borderRadius.small,
    border: `1px solid ${borderColor ? borderColor : 'unset'}`,
    backgroundColor: disabled ? theme.palette.primary.greyBackground : '',

    '&:before': {
        display: 'block',
        ...getCheckBoxSize(size).bpCheckedIconSize,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%234D4D4D'/%3E%3C/svg%3E\")",
        content: '""',
    },
}));

const CheckboxBase = styled(CheckboxMui, {
    shouldForwardProp: (prop) => prop !== 'rounded',
})<ICheckbox>(({ rounded }) => ({
    '&.MuiCheckbox-root': {
        width: 'fit-content',
        padding: 0,
        '&:hover': {
            borderRadius: rounded ? '' : 'unset',
        },
    },
}));

interface ICheckbox {
    checked: boolean;
    value?: string;
    rounded?: boolean;
    disabled?: boolean;
    borderColor?: string;
    size?: number;
    onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const Checkbox: FC<ICheckbox> = ({ checked, onChange, onClick, value, rounded = false, disabled, borderColor, size }) => {
    return (
        <CheckboxBase
            checked={checked}
            onChange={onChange}
            icon={<BpIcon rounded={rounded} borderColor={borderColor} size={size} disabled={disabled} />}
            checkedIcon={<BpCheckedIcon rounded={rounded} borderColor={borderColor} size={size} disabled={disabled} />}
            onClick={onClick}
            value={value}
            disabled={disabled}
        />
    );
};

export default Checkbox;
