import React, { FC, Fragment, ReactNode } from 'react';
import { Box, Button, Icon, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import BoldTextWIthIcon from 'components/molecules/offersMainCard/BoldTextWIthIcon';
import { IBuiltComparisonData, IOffersMainCard, SelectValues } from 'models/OffersData';
import { useNavigate } from 'react-router-dom';
import { ContractTypeModel } from 'models/common';

interface IProps {
    offer: IOffersMainCard;
    offersModel: ContractTypeModel;
    offersForCompare: IBuiltComparisonData[];
    sortValue: SelectValues;
    selectOptions: { value: SelectValues; label: string }[];
    addOfferForCompare: (offerId: string) => void;
    removeOfferFromCompare: (offerId: string) => void;
    // handleElectQuotation: (quotationId: string) => void;
}

enum ActionName {
    ADD_TO_LIST = 'addToList',
    MORE_INFORMATION = 'moreInformation',
    REMOVE_FROM_LIST = 'removeFromList',
}

const actions = (inCompare: boolean, offersModel: ContractTypeModel) =>
    [
        offersModel === ContractTypeModel.PURCHASE
            ? {
                  name: !inCompare ? ActionName.ADD_TO_LIST : ActionName.REMOVE_FROM_LIST,
                  text: !inCompare ? 'הוספה להשוואה' : 'הסר מהשוואה',
                  icon: <Icon.Graph />,
                  isSelected: inCompare,
              }
            : null,
        {
            name: ActionName.MORE_INFORMATION,
            text: 'מידע נוסף',
            icon: <Icon.MoreInformation />,
        },
    ].filter(Boolean) as { name: ActionName; text: string; icon: ReactNode; isSelected?: boolean }[];

const OffersMainCardCenterSection: FC<IProps> = ({
    offer,
    addOfferForCompare,
    offersModel,
    removeOfferFromCompare,
    offersForCompare,
    // handleElectQuotation,
    sortValue,
    selectOptions,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { id: offerId, rate, company_id_rate, mainSection, item_id } = offer;

    const renderRate = (value: SelectValues): { value: string | null; label: string } => {
        switch (value) {
            case SelectValues.PRICE_AND_RATING:
                return { value: rate, label: 'משוקלל' };
            case SelectValues.RATING:
            case SelectValues.PRICE:
                return { value: company_id_rate, label: 'איכות' };
            default:
                return { value: rate, label: 'משוקלל' };
        }
    };

    const checkIfInCompare = (offerId: string): boolean => {
        return offersForCompare.some((item: IBuiltComparisonData) => item.offerId === offerId);
    };

    const handleActionClick = (actionName: ActionName) => {
        if (actionName === ActionName.MORE_INFORMATION) {
            return navigate(`${offer.item_id}`);
        } else if (actionName === ActionName.ADD_TO_LIST) {
            return addOfferForCompare(item_id);
        } else if (actionName === ActionName.REMOVE_FROM_LIST) {
            return removeOfferFromCompare(item_id);
        }
    };

    return (
        <Box p={{ md: '40px 30px', xs: '20px' }} display='flex' flexDirection='column' gap='26px'>
            <Box display='flex' gap={{ md: '45px', xs: '22px' }}>
                <Typography.Body2>הצעה מס' {offerId} </Typography.Body2>
                {offersModel === ContractTypeModel.PURCHASE && (
                    <Box display='flex' alignItems='center' gap='7px'>
                        <Icon.Star />
                        <Typography.Body2 font={theme.fontFamilies.regular}>{renderRate(sortValue)?.value}</Typography.Body2>
                        <Typography.Body4>{`(${renderRate(sortValue).label})`}</Typography.Body4>
                    </Box>
                )}
            </Box>
            <Box display={{ md: 'flex' }} gap='20px'>
                {mainSection.map((item: any, index: any) => (
                    <Fragment key={index}>
                        <BoldTextWIthIcon icon={item.icon} text={item.text} boldText={item.boldText} />
                    </Fragment>
                ))}
            </Box>
            <Box
                display='flex'
                gap={{ md: '12px', xs: '6px' }}
                mx={{ md: 0, xs: 'auto' }}
                flexWrap={{ xs: 'wrap' }}
                justifyContent={{ xs: 'center' }}
            >
                {actions(checkIfInCompare(offer.item_id), offersModel).map((item, index) => (
                    <Box width='154px' key={index}>
                        <Button.Outlined
                            disabled={item.name !== ActionName.MORE_INFORMATION && offersForCompare.length === 3 && !item.isSelected}
                            onClick={() => handleActionClick(item.name)}
                            borderColor={theme.palette.primary.goldenYellow}
                            borderRadius={theme.borderRadius.large}
                            backgroundColor={item.isSelected ? theme.palette.primary.goldenYellow : ''}
                        >
                            {item.icon}
                            &nbsp;
                            <Typography.Body4 color={theme.palette.primary.purple}>{item.text}</Typography.Body4>
                        </Button.Outlined>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default OffersMainCardCenterSection;
