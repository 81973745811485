import React, { FC } from 'react';
import { Box, Grid, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import RatingCard from 'components/molecules/contractor/RatingCard';
import { RatingContractorCategories } from 'models/OffersData';

interface IProps {
    recommendation: {
        name: string;
        text: string | null;
        categories: {
            name: RatingContractorCategories;
            value: string;
        }[];
    };
}

const CardReview: FC<IProps> = ({ recommendation }) => {
    const theme = useTheme();
    return (
        <Box
            borderRadius={theme.borderRadius.large}
            border={`1px solid ${theme.palette.primary.divider}`}
            p={{ xs: '10px', md: '20px' }}
            mb='10px'
        >
            <Box mb='4px'>
                <Typography.Body4>{recommendation.name}</Typography.Body4>
            </Box>
            <Box>
                <Typography.Body5>{recommendation.text}</Typography.Body5>
            </Box>
            <Grid container spacing={8}>
                {recommendation.categories.map((category, index: number) => (
                    <Grid item md={2} xs={6} key={index}>
                        <Box p='10px'>
                            <RatingCard rating={+category.value} text={category.name} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default CardReview;
