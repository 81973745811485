import React, { FC, ReactNode } from 'react';
import { Link as MuiLink, styled } from '@mui/material';

interface IProps {
    onClick?: () => void;
    children: ReactNode;
    textDecorationColor?: string;
    disabled?: boolean;
}

const LinkBase: any = styled(MuiLink, {
    shouldForwardProp: (prop) => prop !== 'textDecorationColor',
})<IProps>(({ theme, textDecorationColor }) => ({
    verticalAlign: 'baseline',
    textDecorationColor: textDecorationColor ? textDecorationColor : theme.palette.primary.black,
    '&:hover': {
        textDecorationColor: textDecorationColor ? textDecorationColor : theme.palette.primary.black,
    },
}));

const Link: FC<IProps> = ({ onClick, children, textDecorationColor, disabled }) => {
    return (
        <LinkBase onClick={onClick} disabled={disabled} component='button' textDecorationColor={textDecorationColor} type='button'>
            {children}
        </LinkBase>
    );
};

export default Link;
