import React, { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import AuthDialog from 'components/molecules/AuthDialog';
import { Typography, Box, Button, Checkbox } from 'components/atoms';
import { useTheme } from '@mui/material';
import InputsVerificationCode from 'components/molecules/auth/InputsVerificationCode';
import { ButtonSize, ButtonType } from 'components/atoms/Button';

interface IProps {
    showDialog: boolean;
    inputValues: string[];
    setInputValues: Dispatch<SetStateAction<string[]>>;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    keepLoggedIn: boolean;
    setKeepLoggedIn: Dispatch<SetStateAction<boolean>>;
    handleSendAgain: () => void;
    onClose: () => void;
}

const VerificationCode: FC<IProps> = ({
    showDialog,
    inputValues,
    setInputValues,
    onSubmit,
    keepLoggedIn,
    setKeepLoggedIn,
    handleSendAgain,
    onClose,
}) => {
    const DialogData = {
        header: {
            title: 'הזנת קוד אימות',
        },
        footer: {
            element: null,
            text: 'הקוד לא הגיע?',
            onClick: handleSendAgain,
            linkText: 'שלחו שנית',
        },
    };

    const theme = useTheme();

    return (
        <AuthDialog data={DialogData} open={showDialog} onClose={onClose}>
            <Box
                component='form'
                display='flex'
                flexDirection='column'
                gap='25px'
                textAlign='center'
                width='300px'
                mx='auto'
                onSubmit={onSubmit}
            >
                <Typography.Body2 font={theme.fontFamilies.regular}>שלחנו קוד אימות, האם קיבלת?</Typography.Body2>
                <Typography.Body2 font={theme.fontFamilies.regular}>יש להקליד את הקוד שקיבלתם בהודעה:</Typography.Body2>
                <InputsVerificationCode inputValues={inputValues} setInputValues={setInputValues} />
                <Box display='flex' alignItems='center' gap='10px' justifyContent='center'>
                    <Checkbox onChange={() => setKeepLoggedIn((prev) => !prev)} checked={keepLoggedIn} />
                    <Typography.Body2 font={theme.fontFamilies.regular}>זכרו אותי</Typography.Body2>
                </Box>
                <Button.Contained disabled={inputValues.some((value) => value === '')} type={ButtonType.SUBMIT} size={ButtonSize.REGULAR}>
                    <Typography.Body2 color={theme.palette.primary.white} font={theme.fontFamilies.regular}>
                        אישור והתחברות
                    </Typography.Body2>
                </Button.Contained>
            </Box>
        </AuthDialog>
    );
};

export default VerificationCode;
