import { Icon } from 'components/atoms';
import { Fragment, ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';

export const renderLinkInText = (text: string, linkLabel: string, link: string) => {
    return text.replace('LINK', `<a href="${link}" target=”_blank rel="noreferrer noopener" style="color:#ac4385" >${linkLabel}</a>`);
};

export const renderText = (text: string): ReactElement[] => {
    return text.split('\n').map((item, index) => (
        <Fragment key={index}>
            {item}
            <br />
        </Fragment>
    ));
};

export const markingReplaceStringsToIcons = (text: string): string => {
    const iconWrapper = (children: string) =>
        `<div style=display:inline-flex;justify-content:center;align-items:center;color:white;background-color:#aa4283;width:24px;height:24px;border-radius:5px;>${children}</div>`;

    return text.replace(/{icon-(plus|minus|rotate)}/g, (match, icon) =>
        iconWrapper(
            icon === 'plus'
                ? ReactDOMServer.renderToString(<Icon.Plus key={match} fill='white' stroke='white' />)
                : icon === 'minus'
                ? ReactDOMServer.renderToString(<Icon.Minus key={match} />)
                : ReactDOMServer.renderToString(<Icon.Rotate key={match} />)
        )
    );
};
