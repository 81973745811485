import { Typography } from 'components/atoms';
import { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import texts from 'texts.json';
import InfoModal from 'components/molecules/InformationModal';
import { Theme } from '@mui/material/styles';

const ScreenResolutionModal = () => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const isScreenBetween600And1600 = useMediaQuery((theme: Theme) => theme.breakpoints.between(900, 1600));

    useEffect(() => {
        setOpen(isScreenBetween600And1600);
    }, [isScreenBetween600And1600]);

    if (!open) {
        return null;
    }
    return (
        <InfoModal isCloseIcon open={open} onClose={() => setOpen(false)}>
            <Box sx={{ width: '100%', marginTop: '46px', textAlign: 'center' }}>
                <Typography.Body2 color={theme.palette.primary.black}>{texts.screenResolutionModal.text}</Typography.Body2>
            </Box>
        </InfoModal>
    );
};

export default ScreenResolutionModal;
