/* eslint-disable max-lines */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Typography } from 'components/atoms';
import PricingAccordion from 'components/organisms/pricing/PricingAccordion';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { PRODUCTION } from 'utils/envUtils';
import RangeTitle from 'components/molecules/pricing/pricingEntrepreneur/RangeTitle';
import PricingEntrepreneurAccordionDetails from 'components/molecules/pricing/pricingEntrepreneur/PricingEntrepreneurAccordionDetails';
import PricingAccordionSectionHeader from 'components/molecules/pricing/PricingAccordionSectionHeader';
import { Symbols } from 'utils/symbols';
import { CreateEntrepreneurPriceListParam, EntrepreneurPriceList, SetEntrepreneurPriceListReq } from 'models/api/pricingEntrepreneur';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { buildAdditionsData, buildEntrepreneurPriceListData, entrepreneurCostRanges } from 'data/PricingEntrepreneurDataProcessing';
import { EntrepreneurPriceListRanges } from 'models/PricingEntrepreneur.model';
import { PriceListStatuses } from 'models/api/common';
import AddOnPricingPanel from 'components/molecules/addOnPricingPanel/AddOnPricingPanel';
import InfoModal from 'components/molecules/InformationModal';
import { useGetCompanySettings } from 'api/queries/common';
import texts from 'texts.json';
export interface IPricingPanelData {
    fieldIndex: number;
    data: EntrepreneurPriceListRanges;
}

interface IProps {
    items: EntrepreneurPriceList[];
    setEntrepreneurPriceList: (params: SetEntrepreneurPriceListReq) => void;
    createEntrepreneurPriceList: (params: CreateEntrepreneurPriceListParam) => void;
    deleteEntrepreneurPriceList: (id: string) => void;
    isEdit: string;
    setIsEdit: (id: string) => void;
}

const PricingEntrepreneurRanges: FC<IProps> = ({
    items,
    createEntrepreneurPriceList,
    setEntrepreneurPriceList,
    deleteEntrepreneurPriceList,
    isEdit,
    setIsEdit,
}) => {
    const isDesktop = useIsDesktop();
    const { data: settings } = useGetCompanySettings();
    const [pricingPanelData, setPricingPanelData] = useState<IPricingPanelData | null>(null);
    const [copyPasteState, setCopyPasteState] = useState<EntrepreneurPriceListRanges | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleUpdateRange = (id: string) => {
        methods.handleSubmit(
            () => {
                updateRange(id);
            },
            (errors: any) => console.log(errors)
        )();
    };

    const methods = useForm<{ rangesData: EntrepreneurPriceListRanges[] }>({
        defaultValues: {
            rangesData: [],
        },
        mode: 'onChange',
    });
    const { isDirty } = methods.formState;

    const { append, remove } = useFieldArray({
        keyName: 'key',
        control: methods.control,
        name: 'rangesData',
    });

    const watchFieldArray = methods.watch('rangesData');

    const resetForm = useCallback(
        (items: EntrepreneurPriceList[]) => {
            if (items) {
                methods.reset({
                    rangesData: buildEntrepreneurPriceListData(items),
                });
                if (pricingPanelData) {
                    setPricingPanelData({
                        fieldIndex: pricingPanelData.fieldIndex,
                        data: watchFieldArray[pricingPanelData.fieldIndex],
                    });
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [methods]
    );

    useEffect(() => {
        resetForm(items);
    }, [items, resetForm]);

    const updateRange = (rangeId: string) => {
        if (!isDirty) return; // if no changes, do not update
        const range = items.some((item) => item.item_id === rangeId); // check if range is not new
        const { min, max, status, id, ...otherData }: any = watchFieldArray.find((item) => item.id === rangeId);

        const data = {
            ...otherData,
            from_cost: min,
            to_cost: max,
            status: status ? PriceListStatuses.PRICE_LIST_STATUS_ACTIVE : PriceListStatuses.PRICE_LIST_STATUS_INACTIVE,
        };
        if (range) {
            return setEntrepreneurPriceList({ item: { ...data }, item_id: rangeId });
        }
        return createEntrepreneurPriceList(data);
    };

    const onSwitchChangeHandler = (field: EntrepreneurPriceListRanges) => {
        const range = items?.some((item) => item.item_id === field.id); // check if range is not new
        if (range) {
            return setEntrepreneurPriceList({
                item_id: field.id,
                item: {
                    status: field.status ? PriceListStatuses.PRICE_LIST_STATUS_ACTIVE : PriceListStatuses.PRICE_LIST_STATUS_INACTIVE,
                },
            });
        }
        return;
    };

    const onCopy = (rangeID: string) => {
        setCopyPasteState(watchFieldArray.find((field) => field.id === rangeID) || null);
    };

    const onPaste = async (rangeID: string) => {
        const rangeIndex = watchFieldArray.findIndex((field) => field.id === rangeID);
        if (rangeIndex === -1 || !copyPasteState) return;
        const { id, min, max, ...otherData } = copyPasteState;
        setIsEdit(rangeID);
        await methods.setValue(`rangesData.${rangeIndex}`, { id: rangeID, min: 0, max: 0, ...otherData }); // without await, the field is not focused
        methods.setFocus(`rangesData.${rangeIndex}.min`);
    };

    const onDuplicate = (rangeID: string) => {
        if (isEdit) {
            setOpen(true);
            return;
        }
        const rangeIndex = watchFieldArray.findIndex((field) => field.id === rangeID);
        if (rangeIndex === -1) return;
        const { id, min, max, ...otherData } = watchFieldArray[rangeIndex];
        const newRangeId = crypto.randomUUID();
        append({ id: newRangeId, min: 0, max: 0, ...otherData }, { shouldFocus: true });
        setIsEdit(newRangeId);
    };

    const onRangeRemove = (id: string, index: number) => {
        const isExistRange = items.some((item) => item.item_id === id);
        if (!isExistRange) {
            remove(index);
        } else {
            return deleteEntrepreneurPriceList(id);
        }
    };

    const onEdit = (id: string) => {
        if (isEdit && !!id) {
            setOpen(true);
        } else {
            setIsEdit(id);
        }
    };

    return (
        <FormProvider {...methods}>
            <Box mb={{ md: '40px' }}>
                <PricingAccordionSectionHeader
                    checked={true}
                    onCheckboxChange={() => {}}
                    checkboxLabel={texts.pricing.pricingEntrepreneur.range.title}
                />
                <Box display='flex' flexDirection='column' gap='10px' mb='20px'>
                    {watchFieldArray.length > 0 &&
                        watchFieldArray.map((field: any, index: number) => (
                            <PricingAccordion
                                data={field}
                                fieldIndex={index}
                                key={field.id}
                                isEdit={isEdit}
                                onCopy={onCopy}
                                onPaste={onPaste}
                                onDuplicate={onDuplicate}
                                onSwitchChangeHandler={() => onSwitchChangeHandler(field)}
                                switchName={`rangesData.${index}.status`}
                                setIsEdit={onEdit}
                                summaryElement={
                                    <RangeTitle
                                        rules={entrepreneurCostRanges(settings?.data.item)}
                                        size={isDesktop ? 'regular' : 'small'}
                                        data={field}
                                        fieldIndex={index}
                                        isEdit={isEdit === field.id}
                                        currencySymbol={Symbols.SHEKEL}
                                    />
                                }
                                detailsElement={
                                    <PricingEntrepreneurAccordionDetails
                                        onCopy={() => onCopy(field.id)}
                                        onPaste={() => onPaste(field.id)}
                                        onDuplicate={() => onDuplicate(field.id)}
                                        data={field}
                                        removeRange={(id) => onRangeRemove(id, index)}
                                        isEdit={isEdit === field.id}
                                        setIsEdit={onEdit}
                                        fieldIndex={index}
                                        showRange={isDesktop ? false : true}
                                        updateRange={updateRange}
                                        setPricingPanelData={setPricingPanelData}
                                        isItemToDelete={watchFieldArray.length > 1}
                                    />
                                }
                            />
                        ))}
                </Box>
            </Box>
            {pricingPanelData && (
                <AddOnPricingPanel
                    open={!!pricingPanelData}
                    data={pricingPanelData && buildAdditionsData(pricingPanelData, settings?.data.item)}
                    onClose={() => setPricingPanelData(null)}
                    control={methods.control}
                    isContractor={false}
                    onSaveHandler={() => handleUpdateRange(pricingPanelData.data.id)}
                />
            )}
            {!PRODUCTION && <DevTool control={methods.control} />}
            {open && (
                <InfoModal open={open} onClose={() => setOpen(false)}>
                    <Typography.Body1>לא ניתן לערוך טווח בעת עריכת טווח אחר</Typography.Body1>
                </InfoModal>
            )}
        </FormProvider>
    );
};

export default PricingEntrepreneurRanges;
