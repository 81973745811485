import React, { FC, useState } from 'react';
import { Box, Button, Dialog, Icon, Typography } from 'components/atoms';
import { Drawer } from 'components/atoms';
import { useTheme } from '@mui/material';
import { ButtonSize } from 'components/atoms/Button';
import ComparisonTable from 'components/organisms/offers/offersList/ComparisonTable';
import { ProjectComparison } from 'models/api/offers';
import { IBuiltComparisonData } from 'models/OffersData';
import { ContractTypeModel } from 'models/common';
import { isVatIncludes } from 'utils/isVatIncludes';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    comparisonData?: ProjectComparison;
    offersForCompare: IBuiltComparisonData[];
    removeOfferFromCompare: (offerId: string) => void;
    handleElectQuotation: (quotationId: string, modelType: ContractTypeModel) => void;
}

const OffersFoldablePanel: FC<IProps> = ({ comparisonData, offersForCompare, removeOfferFromCompare, handleElectQuotation }) => {
    const [open, setOpen] = useState(false);
    const isDesktop = useIsDesktop();
    const isVatIncluded = isVatIncludes(comparisonData?.vat_included);
    const theme = useTheme();
    if (!comparisonData) {
        return null;
    }
    const { client_quotations_compare, ...systemDetails } = comparisonData;
    const comparisonTableElement = (
        <ComparisonTable
            setOpen={() => setOpen(false)}
            offersForCompare={offersForCompare}
            systemDetails={systemDetails}
            removeOfferFromCompare={removeOfferFromCompare}
            handleElectQuotation={(data) => handleElectQuotation(data, ContractTypeModel.PURCHASE)}
            isVatIncluded={isVatIncluded}
        />
    );

    return (
        <Box>
            <Box position='fixed' bottom='0' right='0' width='100%' zIndex={999}>
                <Button.Mobile
                    size={ButtonSize.LARGE}
                    color={theme.palette.primary.purple}
                    backgroundColor={theme.palette.primary.white}
                    onClick={() => setOpen(true)}
                >
                    <Box display='flex' gap='15px' alignItems='center'>
                        <Typography.Body1 color={theme.palette.primary.purple}>
                            נבחרו {offersForCompare.length} הצעות להשוואה
                        </Typography.Body1>
                        <Box sx={{ transform: 'rotate(180deg)' }} display='flex' alignItems='center'>
                            <Icon.DownArrow fill={theme.palette.primary.purple} />
                        </Box>
                    </Box>
                </Button.Mobile>
            </Box>
            <Box>
                {isDesktop ? (
                    <Drawer.Vertical open={open} onClose={() => setOpen(false)}>
                        {comparisonTableElement}
                    </Drawer.Vertical>
                ) : (
                    <Dialog open={open} onClose={() => setOpen(false)} transition={true} isFullScreen>
                        {comparisonTableElement}
                    </Dialog>
                )}
            </Box>
        </Box>
    );
};

export default OffersFoldablePanel;
