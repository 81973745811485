import React, { FC, ReactNode, useState } from 'react';
import { Box, Button, TextField, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { ButtonSize } from 'components/atoms/Button';
import { TextFieldSize, TextFieldType } from 'components/atoms/TextField';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    open: boolean;
    inModal?: boolean;
    title: string;
    buttonText: string;
    placeholder?: string;
    secondaryTitle?: ReactNode;
    calculate: (value: number | null) => void;
}

const PricingSimulatorHeader: FC<IProps> = ({ open, calculate, inModal, title, buttonText, placeholder, secondaryTitle }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const [calculateValue, setCalculateValue] = useState<string>('');

    return (
        <Box
            display='flex'
            flexDirection={{ md: 'row', xs: 'column' }}
            alignItems={{ md: 'center' }}
            justifyContent={{ xs: 'center', md: 'unset' }}
            bgcolor='primary.lightPurple'
            py={{ md: '26px', xs: '30px' }}
            px={{ md: '30px' }}
            gap={{ md: '20px', xs: '6px' }}
            borderRadius={(theme) =>
                isDesktop ? (open ? `${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0` : theme.borderRadius.medium) : 'none'
            }
        >
            <Box mx={{ xs: 'auto', md: 0 }} mt={inModal ? '10px' : 0}>
                {isDesktop ? <Typography.Body2>{title}</Typography.Body2> : <Typography.Body3>{title}</Typography.Body3>}
            </Box>
            <Box
                display={'flex'}
                flexDirection={{ md: 'row', xs: 'column' }}
                mx={{ xs: 'auto', md: 0 }}
                rowGap={'10px'}
                alignItems={{ md: 'center', xs: 'unset' }}
                columnGap={'20px'}
            >
                <Box display='flex' alignItems='center' gap='20px'>
                    <Box width={{ md: '330px', xs: 'auto' }}>
                        <TextField
                            placeholder={placeholder}
                            type={TextFieldType.Number}
                            textAlign='start'
                            value={calculateValue}
                            onChange={(e) => {
                                e.preventDefault();
                                setCalculateValue(e.target.value);
                            }}
                            step={0.01}
                            size={isDesktop ? TextFieldSize.Medium : TextFieldSize.Standard}
                        />
                    </Box>
                    <Box width='140px'>
                        <Button.Contained
                            disabled={!calculateValue}
                            size={isDesktop ? ButtonSize.REGULAR : ButtonSize.MEDIUM}
                            onClick={() => calculate(parseFloat(calculateValue))}
                        >
                            {isDesktop ? (
                                <Typography.Body2 color={theme.palette.primary.white}>{buttonText}</Typography.Body2>
                            ) : (
                                <Typography.Body3 color={theme.palette.primary.white}>{buttonText}</Typography.Body3>
                            )}
                        </Button.Contained>
                    </Box>
                </Box>
                {secondaryTitle && secondaryTitle}
            </Box>
        </Box>
    );
};

export default PricingSimulatorHeader;
