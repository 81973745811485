import React, { FC, useState } from 'react';
import { Box, Checkbox, Typography } from 'components/atoms';
import PricingAccordion from 'components/organisms/pricing/PricingAccordion';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { PRODUCTION } from 'utils/envUtils';
import { pricingTransformersExampleData } from 'data/PricingExampleData';
import PricingTransformersAccordionDetails from 'components/molecules/pricing/pricingTransformers/PricingTransformersAccordionDetails';
import PricingTransformersAccordionDetailsMobile from 'components/molecules/pricing/pricingTransformers/PricingTransformersAccordionDetailsMobile';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {}

const PricingTransformersTypes: FC<IProps> = () => {
    // eslint-disable-next-line
    const [transformersData, setTransformersData] = useState<any>(pricingTransformersExampleData);
    const [isEdit, setIsEdit] = useState<string>('');
    const isDesktop = useIsDesktop();

    const methods = useForm({
        defaultValues: {
            transformersData,
        },
        mode: 'onChange',
    });

    const { fields } = useFieldArray({
        keyName: 'key',
        control: methods.control,
        name: 'transformersData',
    });

    const watchFieldArray = methods.watch('transformersData');
    // for now, watchFieldArray is the updated array - without calling to api to get the updated data
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        };
    });

    const summaryElement = (name: string) => (
        <Box>{isDesktop ? <Typography.Body2>{name}</Typography.Body2> : <Typography.Body2>{name}</Typography.Body2>}</Box>
    );

    return (
        <FormProvider {...methods}>
            <Box my={{ md: '40px', xs: '20px' }}>
                <Box mb={{ md: '10px', xs: '25px' }}>
                    <Box display='flex' gap={10} alignItems='center'>
                        <Checkbox checked={true} onChange={() => {}} />
                        {isDesktop ? <Typography.Body2>ממירים</Typography.Body2> : <Typography.Body4>ממירים</Typography.Body4>}
                    </Box>
                </Box>
                <Box display='flex' flexDirection='column' gap='10px' mb='20px'>
                    {fields.length > 0 &&
                        controlledFields?.map((field: any, index) => (
                            <PricingAccordion
                                data={field}
                                fieldIndex={index}
                                key={field.id}
                                setIsEdit={setIsEdit}
                                isEdit={isEdit}
                                summaryElement={summaryElement(field.name)}
                                detailsElement={
                                    isDesktop ? (
                                        <PricingTransformersAccordionDetails
                                            data={field}
                                            isEdit={isEdit === field.id}
                                            setIsEdit={setIsEdit}
                                            fieldIndex={index}
                                        />
                                    ) : (
                                        <PricingTransformersAccordionDetailsMobile
                                            data={field}
                                            isEdit={isEdit === field.id}
                                            setIsEdit={setIsEdit}
                                            fieldIndex={index}
                                        />
                                    )
                                }
                            />
                        ))}
                </Box>
            </Box>
            {!PRODUCTION && <DevTool control={methods.control} />}
        </FormProvider>
    );
};

export default PricingTransformersTypes;
