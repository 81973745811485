import React, { FC } from 'react';
import { Divider, Grid, Box } from 'components/atoms';
import { useTheme } from '@mui/material';
import OffersMainCardCenterSection from 'components/molecules/offersMainCard/OffersMainCardCenterSection';
import OffersMainCardLeft from 'components/molecules/offersMainCard/OffersMainCardLeft';
import { Orientation } from 'components/atoms/Divider';
import OffersMainCardRight from 'components/molecules/offersMainCard/OffersMainCardRight';
import { IBuiltComparisonData, IOffersMainCard, SelectValues } from 'models/OffersData';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { ContractTypeModel } from 'models/common';

interface IProps {
    offer: IOffersMainCard;
    offersModel: ContractTypeModel;
    offersForCompare: IBuiltComparisonData[];
    sortValue: SelectValues;
    selectOptions: { value: SelectValues; label: string }[];
    addOfferForCompare: (offerId: string) => void;
    removeOfferFromCompare: (offerId: string) => void;
    handleElectQuotation: (quotationId: string, modelType: ContractTypeModel) => void;
}

const OffersMainCard: FC<IProps> = ({
    offer,
    offersModel,
    addOfferForCompare,
    removeOfferFromCompare,
    offersForCompare,
    handleElectQuotation,
    sortValue,
    selectOptions,
}) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();

    return (
        <Grid
            container
            width={{ md: '1138px' }}
            height={{ xs: 'auto', md: '204px' }}
            mx={{ xs: 'auto', md: '0' }}
            borderRadius={theme.borderRadius.xLarge}
            bgcolor={theme.palette.primary.white}
            columns={11}
            overflow='hidden'
        >
            <Grid item xs={11} md={3}>
                <OffersMainCardRight rightSection={offer.rightSection} />
            </Grid>
            <Grid item xs={11} md={5}>
                <OffersMainCardCenterSection
                    offer={offer}
                    offersModel={offersModel}
                    addOfferForCompare={addOfferForCompare}
                    removeOfferFromCompare={removeOfferFromCompare}
                    offersForCompare={offersForCompare}
                    sortValue={sortValue}
                    selectOptions={selectOptions}
                />
            </Grid>
            <Grid item xs={11} md={3} display='flex' py={{ md: '38px', xs: 0 }}>
                <Box display='flex' height='140px' my='auto'>
                    {isDesktop && <Divider orientation={Orientation.Vertical} />}
                </Box>
                <Box width={{ md: '246px', xs: '90%' }} mx='auto'>
                    {!isDesktop && (
                        <Box m='4px 0px 24px 0px'>
                            <Divider orientation={Orientation.Horizontal} />
                        </Box>
                    )}
                    <OffersMainCardLeft offer={offer} handleElectQuotation={(data) => handleElectQuotation(data, offersModel)} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default OffersMainCard;
