import React, { FC, useState } from 'react';
import { Box, Dialog } from 'components/atoms';
import PricingTransformersSimulatorHeader, {
    selectOptions,
} from 'components/molecules/pricing/pricingTransformers/PricingTransformersSimulatorHeader';
import PricingTransformersSimulatorDetails from 'components/molecules/pricing/pricingTransformers/PricingTransformersSimulatorDetails';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {}

const PricingTransformersSimulator: FC<IProps> = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [calculateValue, setCalculateValue] = useState<number | null>(null);
    const [selectValue, setSelectValue] = useState<string>(selectOptions[0].value);
    const isDesktop = useIsDesktop();

    const calculate = () => {
        setOpen(true);
    };

    return (
        <Box mx={{ md: 'auto' }} bgcolor='primary.white' borderRadius={(theme) => theme.borderRadius.large} mb='140px' overflow='auto'>
            <Box position={isDesktop ? 'static' : 'fixed'} bottom={0} right={0} width='100%'>
                <PricingTransformersSimulatorHeader
                    open={open}
                    calculate={calculate}
                    calculateValue={calculateValue}
                    setCalculateValue={setCalculateValue}
                    selectValue={selectValue}
                    setSelectValue={(value: string) => setSelectValue(value)}
                />
            </Box>
            {open && (
                <Box p={{ md: '20px 0 60px 0' }} px={{ md: '20px' }} bgcolor='primary.divider'>
                    {isDesktop ? (
                        <PricingTransformersSimulatorDetails />
                    ) : (
                        <Dialog open={open} onClose={() => setOpen(false)} isFullScreen>
                            <PricingTransformersSimulatorHeader
                                inModal={true}
                                open={open}
                                calculate={calculate}
                                calculateValue={calculateValue}
                                setCalculateValue={setCalculateValue}
                                selectValue={selectValue}
                                setSelectValue={(value) => setSelectValue(value)}
                            />
                            <Box display='flex' flexDirection='column' gap={20} p='20px' bgcolor='primary.divider'>
                                <Box mx={{ xs: 'auto', md: 0 }}>
                                    <PricingTransformersSimulatorDetails />
                                </Box>
                            </Box>
                        </Dialog>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PricingTransformersSimulator;
