import { EPropertyType } from 'models/propertyType.model';
import texts from 'texts.json';

export const getPropertyTypes = () => {
    const { title, types } = texts.calculator.stepAddress.address.propertyType;

    const checkboxesProps = Object.values(EPropertyType).map((checkbox) => ({
        label: types[checkbox],
        value: checkbox,
    }));

    return { checkboxesProps, title };
};
