import React, { FC, Fragment, SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Autocomplete, Box } from 'components/atoms';
import { ISearchByAddressOption } from 'models/MapData';
import { MapContext } from 'contexts/MapContext';
import useDebounce from 'hooks/useDebounce';

const SearchByAddress: FC = () => {
    const { searchByAddress, calculator, fetchAddressList, mapLoading } = useContext(MapContext);
    const [options, setOptions] = useState<ISearchByAddressOption[] | any[]>([]);
    const [inputValue, setInputValue] = useState<string | null>('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState<boolean>(false);

    const debouncedInputValue: string | null = useDebounce(inputValue, 500);

    useEffect(() => {
        if (calculator?.address) {
            setInputValue(calculator.address);
        }
    }, [calculator?.address]);

    const fetchAddressListHandler = useCallback(async () => {
        const response: ISearchByAddressOption[] | null = await fetchAddressList(debouncedInputValue);
        if (response) {
            setLoading(false);
            setOptions(response);
        } else {
            setLoading(false);
            setOptions([{ noResults: true }]);
        }
    }, [debouncedInputValue, fetchAddressList]);

    const onChange = (event: SyntheticEvent<Element, Event>, value: ISearchByAddressOption | null) => {
        if (value && value.ResultLable) {
            searchByAddress({ X: value?.X, Y: value?.Y, ResultLable: value?.ResultLable });
        }
    };

    useEffect(() => {
        if (debouncedInputValue) {
            setLoading(true);
            setOptions([]);

            if (debouncedInputValue?.length >= 3) {
                fetchAddressListHandler();
            }
        }
    }, [debouncedInputValue, fetchAddressListHandler]);

    return (
        <Autocomplete
            disabled={mapLoading}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            freeSolo
            onChange={onChange}
            options={options}
            value={calculator?.address || ''}
            onInputChange={(e, value) => setInputValue(value)}
            isOptionEqualToValue={(option: ISearchByAddressOption, value: ISearchByAddressOption | any) => {
                if (value.noResults) {
                    return false;
                }
                return option?.ResultType === value?.ResultType;
            }}
            loading={loading}
            getOptionLabel={(option: ISearchByAddressOption) => option?.ResultLable || ''}
            renderOption={(props: any, option: ISearchByAddressOption | any) => {
                return (
                    <Fragment key={option.noResults ? 1 : option.ObjectID}>
                        {option.noResults ? (
                            <Box sx={{ textAlign: 'left' }}>אין תוצאות</Box>
                        ) : (
                            option && <li {...props}>{option.ResultLable}</li>
                        )}
                    </Fragment>
                );
            }}
            inputValue={inputValue || ''}
        />
    );
};

export default SearchByAddress;
