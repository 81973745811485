import { Slider as MuiSlider, styled } from '@mui/material';
import { FC } from 'react';

interface IProps {
    value: number;
    onChange: (event: Event, value: number | number[]) => void;
}

const GraphSlider = styled(MuiSlider)(({ theme }) => ({
    color: theme.palette.primary.scrollbarBackground,
    height: 8,
    width: '70%',
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 6,
        width: 120,
        backgroundColor: theme.palette.primary.purple,
        border: '1px solid currentColor',
        borderRadius: 3,
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'unset',
        },
        '&:before': {
            display: 'none',
        },
    },
}));

const Slider: FC<IProps> = ({ value, onChange }) => {
    return <GraphSlider min={-460} max={460} track={false} step={92} value={value} onChange={onChange} />;
};

export default Slider;
