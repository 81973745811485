import localStorageService from 'services/localStorage.service';
import sessionStorageService from 'services/sessionStorage.service';

class Storage {
    private localStorageService: typeof localStorageService;
    private sessionStorageService: typeof sessionStorageService;

    constructor() {
        this.localStorageService = localStorageService;
        this.sessionStorageService = sessionStorageService;
    }

    set(key: string, value: any, useLocalStorage: boolean = false) {
        if (useLocalStorage) {
            this.localStorageService.set(key, value);
        } else {
            this.sessionStorageService.set(key, value);
        }
    }

    get(key: string) {
        const sessionStorageItem = this.sessionStorageService.get(key);
        if (sessionStorageItem) {
            return sessionStorageItem;
        } else {
            return this.localStorageService.get(key);
        }
    }

    clear(key: string) {
        this.sessionStorageService.clear(key);
        this.localStorageService.clear(key);
    }
}

export default new Storage();
