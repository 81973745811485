const STORAGE_VERSION = '0.1';
const EXPIRATION_DAYS = 90;
const EXPIRATION_DAYS_IN_MS = EXPIRATION_DAYS * 24 * 60 * 60 * 1000;

interface IStorageItemMeta {
    expiration: number;
    storageVersion: string;
}

interface IStorageItem {
    data: any;
    meta: IStorageItemMeta;
}

class LocalStorageService {
    // get persistent data
    get(key: string) {
        const item = localStorage.getItem(key);
        const storageItem = this.recoverStorageItem(item);
        return storageItem ? storageItem.data : undefined;
    }

    // set persistent data
    set(key: string, data: any) {
        const storageItem = this.createStorageItem(data);
        const storageItemAsStr = JSON.stringify(storageItem);
        localStorage.setItem(key, storageItemAsStr);
    }

    clear(key: string) {
        localStorage.removeItem(key);
    }

    // create new storage item
    private createStorageItem(data: any): IStorageItem {
        return {
            data,
            meta: {
                expiration: this.getExpirationTime(),
                storageVersion: STORAGE_VERSION,
            },
        };
    }

    // recover StorageItem from storage string
    private recoverStorageItem(storageItemAsStr: string | null): IStorageItem | null {
        if (storageItemAsStr === null) {
            return null;
        } else {
            const storageItem = JSON.parse(storageItemAsStr);
            return this.isValidStorageItem(storageItem) ? storageItem : null;
        }
    }

    private isValidStorageItem(storageItem: IStorageItem): boolean {
        const isObject = storageItem instanceof Object;
        const validVersion = isObject && storageItem?.meta?.storageVersion === STORAGE_VERSION;
        return validVersion && Date.now() < storageItem?.meta?.expiration;
    }

    private getExpirationTime(): number {
        return Date.now() + EXPIRATION_DAYS_IN_MS;
    }
}

export default new LocalStorageService();
