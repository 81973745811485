import { useMediaQuery, useTheme } from '@mui/material';
import { Box, Button, Paper } from 'components/atoms';
import ContentSection from 'components/molecules/ContentSection';
import { useNavigate } from 'react-router-dom';
import Description from 'components/molecules/Description';
import texts from 'texts.json';
import { ApiResponseYesNo } from 'models/api/common';
import ContentSectionMobileQuestionnaire from 'components/molecules/ContentSectionMobileQuestionnaire';
import { ButtonSize } from 'components/atoms/Button';
import { FC, useContext } from 'react';
import { MapContext } from 'contexts/MapContext';

const RoofAccess: FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { setCalculator, calculator } = useContext(MapContext);

    const handleRoofAccessChange = (roofAccess: ApiResponseYesNo) => {
        calculator && setCalculator({ ...calculator, roofAccess, step: 3 });
    };

    const handleClick = () => {
        navigate({
            pathname: texts.calculator.stepQuestionnaire.roofAccess.button.link,
        });
    };

    const yesButtonBackgroundColor =
        calculator?.roofAccess === ApiResponseYesNo.YN_ANSWER_YES ? theme.palette.primary.goldenYellow : theme.palette.primary.white;

    const noButtonBackgroundColor =
        calculator?.roofAccess === ApiResponseYesNo.YN_ANSWER_NO ? theme.palette.primary.goldenYellow : theme.palette.primary.white;

    return (
        <Box
            width='100%'
            height='100%'
            sx={{
                overflow: {
                    xs: 'hidden',
                    md: 'visible',
                },
            }}
        >
            {isDesktop ? (
                <ContentSection
                    title={texts.calculator.stepQuestionnaire.roofAccess.title}
                    text={texts.calculator.stepQuestionnaire.roofAccess.text}
                    buttonText={texts.calculator.stepQuestionnaire.roofAccess.button.text}
                    onClick={handleClick}
                    disabled={!calculator?.roofAccess}
                >
                    <Box display='flex' gap='11px' width='55%' margin='0 auto'>
                        <Button.Outlined
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            onClick={() => handleRoofAccessChange(ApiResponseYesNo.YN_ANSWER_YES)}
                            backgroundColor={yesButtonBackgroundColor}
                        >
                            {texts.calculator.stepQuestionnaire.roofAccess.YN_buttons.yes}
                        </Button.Outlined>
                        <Button.Outlined
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            backgroundColor={noButtonBackgroundColor}
                            onClick={() => handleRoofAccessChange(ApiResponseYesNo.YN_ANSWER_NO)}
                        >
                            {texts.calculator.stepQuestionnaire.roofAccess.YN_buttons.no}
                        </Button.Outlined>
                    </Box>
                    <Box height={300} mt={'42px'} sx={{ direction: 'rtl' }}>
                        <Paper.Calculator>
                            <Description
                                title={texts.calculator.stepQuestionnaire.roofAccess.descriptionTitle}
                                description={texts.calculator.stepQuestionnaire.roofAccess.descriptionText}
                            />
                        </Paper.Calculator>
                    </Box>
                </ContentSection>
            ) : (
                <ContentSectionMobileQuestionnaire
                    linkText={texts.calculator.stepQuestionnaire.roofAccess.descriptionTitle}
                    title={texts.calculator.stepQuestionnaire.roofAccess.title}
                    text={texts.calculator.stepQuestionnaire.roofAccess.text}
                    descriptionTitle={texts.calculator.stepQuestionnaire.roofAccess.descriptionTitle}
                    descriptionContent={texts.calculator.stepQuestionnaire.roofAccess.descriptionText}
                    onClick={handleClick}
                    buttonText={texts.calculator.stepQuestionnaire.roofAccess.button.text}
                    disabled={!calculator?.roofAccess}
                >
                    <Box display='flex' flexDirection='column' gap='12px' width='80%' m=' 20px auto'>
                        <Button.Outlined
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            size={ButtonSize.MEDIUM}
                            font={theme.fontFamilies.bold}
                            onClick={() => handleRoofAccessChange(ApiResponseYesNo.YN_ANSWER_YES)}
                            backgroundColor={yesButtonBackgroundColor}
                        >
                            {texts.calculator.stepQuestionnaire.roofAccess.YN_buttons.yes}
                        </Button.Outlined>
                        <Button.Outlined
                            color={theme.palette.primary.purple}
                            size={ButtonSize.MEDIUM}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            onClick={() => handleRoofAccessChange(ApiResponseYesNo.YN_ANSWER_NO)}
                            backgroundColor={noButtonBackgroundColor}
                        >
                            {texts.calculator.stepQuestionnaire.roofAccess.YN_buttons.no}
                        </Button.Outlined>
                    </Box>
                </ContentSectionMobileQuestionnaire>
            )}
        </Box>
    );
};

export default RoofAccess;
