import { useMediaQuery, useTheme } from '@mui/material';
import { Box } from 'components/atoms';
import ContentSection from 'components/molecules/ContentSection';
import ContentSectionMobile from 'components/molecules/ContentSectionMobile';
import { MapContext } from 'contexts/MapContext';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import texts from 'texts.json';

const PlacementElements: FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { setFooterButtonData, setShowMapSection } = useContext(MapContext);

    const handleClick = () => {
        navigate(texts.calculator.stepRoof.placementElements.button.link);
    };

    const handleMobileClick = () => {
        setShowMapSection(true);
        setFooterButtonData({
            text: texts.calculator.stepRoof.placementElements.button.text,
            onClick: handleClick,
        });
    };

    return (
        <Box width='100%' height='100%'>
            {isDesktop ? (
                <ContentSection
                    onClick={handleClick}
                    buttonText={texts.calculator.stepAddress.address.button.text}
                    title={texts.calculator.stepRoof.placementElements.title}
                    text={texts.calculator.stepRoof.placementElements.text}
                    media={texts.calculator.stepRoof.placementElements.media.video}
                />
            ) : (
                <ContentSectionMobile
                    onClick={handleMobileClick}
                    buttonText={texts.calculator.stepAddress.address.button.text}
                    title={texts.calculator.stepRoof.placementElements.title}
                    text={texts.calculator.stepRoof.placementElements.text}
                    media={texts.calculator.stepRoof.placementElements.media.video}
                />
            )}
        </Box>
    );
};

export default PlacementElements;
