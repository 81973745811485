import { CSSProperties } from 'react';
import { Palette } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { extraThemeOptions } from './extraThemeOptions';
import { fontFamily, openSansHebrewBold, openSansHebrewRegular } from './fonts';

// MUI default Typography https://mui.com/material-ui/react-typography/#component
// usage example: <Typography variant="form-text">Hello</Typography>
declare module '@mui/material/styles' {
    interface TypographyVariantsOptions {
        h1: CSSProperties;
        h2: CSSProperties;
        h3: CSSProperties;
        h4: CSSProperties;
        h5: CSSProperties;
        h6: CSSProperties;
        'tab-text': CSSProperties;
        'thin-title': CSSProperties;
        'large-title': CSSProperties;
        'xLarge-title': CSSProperties;
        xSmall: CSSProperties;
        xLarge: CSSProperties;
    }
}

// flags for allowing usage of variant props (overrides defaults true value)
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        'tab-text': true;
        'thin-title': true;
        'huge-title': true;
        'xHuge-title': true;
        xSmall: true;
        xLarge: true;
    }
}

export const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
    fontFamily: fontFamily,
    h1: {
        fontSize: extraThemeOptions.fontSizes.xLarge,
        fontWeight: extraThemeOptions.fontWeights.bold,
        fontFamily: openSansHebrewBold,
    },
    h2: {
        fontSize: extraThemeOptions.fontSizes.large,
        fontFamily: openSansHebrewBold,
        fontWeight: extraThemeOptions.fontWeights.normal,
    },
    h3: {
        fontSize: extraThemeOptions.fontSizes.medium,
        fontWeight: extraThemeOptions.fontWeights.normal,
        fontFamily: openSansHebrewBold,
    },
    h4: {
        fontSize: extraThemeOptions.fontSizes.normal,
        fontWeight: extraThemeOptions.fontWeights.bold,
        fontFamily: openSansHebrewBold,
    },
    h5: {
        fontSize: extraThemeOptions.fontSizes.normal,
        fontWeight: extraThemeOptions.fontWeights.normal,
        fontFamily: openSansHebrewRegular,
    },
    h6: {
        fontSize: extraThemeOptions.fontSizes.small,
        fontWeight: extraThemeOptions.fontWeights.normal,
        fontFamily: openSansHebrewRegular,
    },
    'tab-text': {
        fontWeight: extraThemeOptions.fontWeights.normal,
        fontSize: extraThemeOptions.fontSizes.normal,
        fontFamily: openSansHebrewRegular,
    },
    'thin-title': {
        fontWeight: extraThemeOptions.fontWeights.normal,
        fontSize: extraThemeOptions.fontSizes.large,
        fontFamily: openSansHebrewRegular,
        lineHeight: extraThemeOptions.lineHeights.large,
    },
    'large-title': {
        fontWeight: extraThemeOptions.fontWeights.bold,
        fontSize: extraThemeOptions.fontSizes.xxLarge,
        fontFamily: openSansHebrewBold,
    },
    'xLarge-title': {
        fontWeight: extraThemeOptions.fontWeights.bold,
        fontSize: extraThemeOptions.fontSizes.xxxLarge,
        fontFamily: openSansHebrewBold,
    },
    xSmall: {
        fontSize: extraThemeOptions.fontSizes.xSmall,
        fontWeight: extraThemeOptions.fontWeights.normal,
        fontFamily: openSansHebrewRegular,
    },
    xLarge: {
        fontSize: extraThemeOptions.fontSizes.xLarge,
        fontWeight: extraThemeOptions.fontWeights.normal,
        fontFamily: openSansHebrewRegular,
    },
};
