import React, { FC, Fragment } from 'react';
import { Box, Divider, Grid } from 'components/atoms';
import { Control } from 'react-hook-form';
import { contractorAccordionEquipmentLabels } from 'data/PricingContractorDataPrecessing';
import PricingCardWithInfo from 'components/molecules/pricing/PricingCardWithInfo';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { TextFieldType } from 'components/atoms/TextField';
import { PricingContractorRangesData } from 'models/PricingData';
import ControllerSelect from 'components/organisms/ControllerSelect';
import { buildPricingAccordionData } from 'data/pricing';
import { useGetCompanySettings } from 'api/queries/common';
import { buildTypeLinksSelectOptions } from 'utils/common';
import { ICompanySettings } from 'models/api/companySettings.model';

interface IProps {
    data: PricingContractorRangesData['equipmentAndWarranty'];
    fieldIndex: number;
    control: Control;
    isEdit: boolean;
}

enum SelectFields {
    recommended_panel_type = 'recommended_panel_type',
    recommended_construction = 'recommended_construction',
    converters_company = 'converters_company',
}

const optionFieldsMap = (settings?: ICompanySettings) => {
    return {
        recommended_panel_type: buildTypeLinksSelectOptions(settings?.panel_type_links),
        recommended_construction: buildTypeLinksSelectOptions(settings?.construction_type_links),
        converters_company: buildTypeLinksSelectOptions(settings?.converter_company_links),
    };
};

const PricingContractorAccordionEquipmentAndWarranty: FC<IProps> = ({ data, fieldIndex, control, isEdit }) => {
    const { data: settings } = useGetCompanySettings();
    const equipmentData = buildPricingAccordionData(data, contractorAccordionEquipmentLabels(settings?.data.item));
    const isDesktop = useIsDesktop();

    return (
        <Grid container>
            {equipmentData.map((item: any, index: number) => {
                const isRecommended =
                    item.name === SelectFields.recommended_construction ||
                    item.name === SelectFields.recommended_panel_type ||
                    item.name === SelectFields.converters_company;
                return (
                    <Fragment key={index}>
                        {isDesktop && index === 3 && <Grid item md={6}></Grid>}
                        <Grid item md={2} xs={12} order={!isDesktop && item.order}>
                            {!isDesktop && index !== 0 && (
                                <Box width='95%' m='10px auto'>
                                    <Divider />
                                </Box>
                            )}
                            <PricingCardWithInfo
                                control={control}
                                isEdit={isEdit}
                                info={item.title.info}
                                title={item.title.label}
                                rules={item.rules}
                                value={
                                    !isRecommended
                                        ? item.value
                                        : optionFieldsMap(settings?.data?.item)[item.name as keyof typeof SelectFields]?.find(
                                              (recommended) => recommended.value === item.value
                                          )?.label
                                }
                                name={`rangesData.${fieldIndex}.equipmentAndWarranty.${item.name}`}
                                type={isNaN(item.value) ? TextFieldType.Text : TextFieldType.Number}
                                component={
                                    isRecommended &&
                                    settings?.data && (
                                        <ControllerSelect
                                            control={control}
                                            name={`rangesData.${fieldIndex}.equipmentAndWarranty.${item.name}`}
                                            options={optionFieldsMap(settings?.data?.item)[item.name as keyof typeof SelectFields] || []}
                                            rules={item.rules}
                                        />
                                    )
                                }
                            />
                        </Grid>
                    </Fragment>
                );
            })}
        </Grid>
    );
};

export default PricingContractorAccordionEquipmentAndWarranty;
