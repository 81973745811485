import { styled, useTheme } from '@mui/material';
import { OrganuzButton, Box, Button, Icon, Typography, Checkbox } from 'components/atoms';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from 'assets/images/tip-popup-arrow.svg';
import { useGetTip } from 'hooks/useGetTip.hook';
import Popper from '@mui/material/Popper';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { TIP_MODAL_STORAGE_KEY } from 'consts/storage.const';
import { useLocation } from 'react-router-dom';

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    left: 25,
    bottom: 40,
    [theme.breakpoints.down('md')]: {
        left: 15,
    },
}));

const PopUpWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: 298,
    height: 170,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    margin: 30,
    marginBottom: 8,
    backgroundColor: theme.palette.primary.white,
    borderRadius: 30,
    border: `3px solid ${theme.palette.primary.purple}`,
    padding: '16px 30px 12px 30px',
    boxShadow: theme.shadows[3],
}));

const TipPopUP = () => {
    const theme = useTheme();
    const path = useLocation().pathname;
    const buttonRef = useRef<HTMLDivElement>(null);
    const { tip, title, notShowAgain } = useGetTip();
    const [isTipHidden, setIsTipHidden] = useLocalStorage(TIP_MODAL_STORAGE_KEY, false);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (buttonRef?.current && !isTipHidden && !!tip) {
            setAnchorEl(buttonRef?.current);
        } else {
            setAnchorEl(null);
        }
    }, [isTipHidden, path, tip]);

    return (
        <Wrapper>
            <Popper disablePortal open={Boolean(anchorEl) && !!tip} anchorEl={anchorEl} placement='top-start'>
                <PopUpWrapper>
                    <Typography.Body1 color={theme.palette.primary.purple} font={theme.fontFamilies.bold}>
                        {title}
                    </Typography.Body1>
                    <Typography.Body2 color={theme.palette.primary.black} font={theme.fontFamilies.regular}>
                        {tip}
                    </Typography.Body2>
                    <Box display={'flex'} columnGap={'6px'}>
                        <Checkbox
                            checked={isTipHidden}
                            onChange={(e, checked) => setIsTipHidden(checked)}
                            borderColor={theme.palette.primary.purple}
                        />
                        <Typography.Body4 color={theme.palette.primary.black} font={theme.fontFamilies.light}>
                            {notShowAgain}
                        </Typography.Body4>
                    </Box>
                    <Box sx={{ position: 'absolute', top: 14, right: 14 }}>
                        <Button.Icon onClick={() => setAnchorEl(null)}>
                            <Icon.CloseFilled />
                        </Button.Icon>
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: -24, left: 45 }}>
                        <Arrow />
                    </Box>
                </PopUpWrapper>
            </Popper>
            {tip && <OrganuzButton ref={buttonRef} onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)} />}
        </Wrapper>
    );
};

export default TipPopUP;
