import { PATH } from 'paths';
import { useLocation } from 'react-router-dom';
import texts from 'texts.json';

export const useGetTip = () => {
    const path = useLocation().pathname;
    const tip: { [key: string]: string } = texts.tipModal.content;
    const text = {
        title: texts.tipModal.title,
        notShowAgain: texts.tipModal.notShowAgain,
        tip: '',
    };
    switch (true) {
        case path.endsWith(PATH.GET_ADDRESS):
            text.tip = tip[PATH.ADDRESS];
            break;
        case path.endsWith(PATH.MARKING):
            text.tip = tip[PATH.MARKING];
            break;
        case path.endsWith(PATH.PLACEMENT_ELEMENTS):
            text.tip = tip[PATH.PLACEMENT_ELEMENTS];
            break;
        case path.endsWith(PATH.TYPE):
            text.tip = tip[PATH.TYPE];
            break;
        default:
            text.tip = '';
            break;
    }

    return { ...text };
};
