import { Box, Button, Typography } from 'components/atoms';
import { FC } from 'react';
import { useTheme } from '@mui/material';
import texts from 'texts.json';
import { useIsDesktop } from 'hooks/useIsDesktop';
import InfoModal from 'components/molecules/InformationModal';
import { useGetRemainingProjects } from 'api/queries/common';

interface IProps {
    open: boolean;
    onCancel: (e: any, reason?: any, isNoMoreSearches?: boolean) => void;
    onApprove: () => void;
    onPurchaseNewSearches: () => void;
}
const RemainingSearchesModal: FC<IProps> = ({ open, onApprove, onCancel, onPurchaseNewSearches }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { data: remainingSearches } = useGetRemainingProjects(open);

    if (!remainingSearches?.data) return null;

    const { remaining_projects, max_projects } = remainingSearches.data.item;
    const isRemainingProjects = remaining_projects > 0;
    const { youHaveLeftMore, remainingSearchesFrom, text, toContinue } = texts.calculator.stepAddress.address.remainingSearchesModal;

    return open && remainingSearches?.data ? (
        <InfoModal
            disableEscapeKeyDown
            open={open}
            onClose={(e, reason) => onCancel(e, reason, !isRemainingProjects)}
            isCloseIcon={!isRemainingProjects}
        >
            <Box display='flex' flexDirection={'column'} alignItems={'center'} rowGap={'12px'} mt='22px' mb='29px'>
                {isDesktop ? (
                    <Box display='flex' columnGap={'7px'}>
                        <Typography.Body2>{youHaveLeftMore}</Typography.Body2>
                        <Typography.Body2 color={theme.palette.primary.purple}>{remaining_projects}</Typography.Body2>
                        <Typography.Body2>{remainingSearchesFrom}</Typography.Body2>
                        <Typography.Body2 color={theme.palette.primary.purple}>{max_projects}</Typography.Body2>
                    </Box>
                ) : (
                    <Box
                        display='flex'
                        columnGap={'7px'}
                        flexWrap={'wrap'}
                        justifyContent={'center'}
                        sx={{
                            '& > span': {
                                whiteSpace: 'nowrap',
                            },
                        }}
                    >
                        <Typography.Body4>{youHaveLeftMore}</Typography.Body4>
                        <Typography.Body4 color={theme.palette.primary.purple}>{remaining_projects}</Typography.Body4>
                        <Typography.Body4>{remainingSearchesFrom}</Typography.Body4>
                        <Typography.Body4 color={theme.palette.primary.purple}>{max_projects}</Typography.Body4>
                    </Box>
                )}
                <Box width={{ md: 300, xs: '80%' }} textAlign='center'>
                    {isDesktop ? (
                        <Typography.Body2 font={theme.fontFamilies.regular}>{text}</Typography.Body2>
                    ) : (
                        <Typography.Body5>{text}</Typography.Body5>
                    )}
                </Box>
                {isRemainingProjects && (
                    <Box mt={'18px'}>
                        {isDesktop ? <Typography.Body2>{toContinue}</Typography.Body2> : <Typography.Body4>{toContinue}</Typography.Body4>}
                    </Box>
                )}
            </Box>
            {isRemainingProjects ? (
                <Box display={'flex'} columnGap={'60px'}>
                    <Button.Contained onClick={onApprove}>כן</Button.Contained>
                    <Button.Contained onClick={onCancel}>לא</Button.Contained>
                </Box>
            ) : (
                <Button.Text onClick={onPurchaseNewSearches}>
                    <Typography.Body4 textUnderline color={theme.palette.primary.purple}>
                        לרכישת בדיקות נוספות
                    </Typography.Body4>
                </Button.Text>
            )}
        </InfoModal>
    ) : null;
};

export default RemainingSearchesModal;
