import PaperResultsPurchase from 'components/molecules/PaperResultsPurchase';
import TabsCalculator from 'components/molecules/TabsCalculator';
import { ContractTypeModel, IElectricityProductionData } from 'models/common';
import React, { FC, useState } from 'react';
import EconomicAndElectricityProduction from 'components/organisms/EconomicAndElectricityProduction';
import { buildPurchaseData, buildRentData } from 'data/PaymentModelData';
import texts from 'texts.json';
import { CompanyType, Item } from 'models/api/management';
import { Box, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';

interface IProps {
    purchaseData: any;
    rentData: any;
    isCompany: boolean;
    companyDetails?: Item;
    selectedTab?: ContractTypeModel;
    setSelectedTab: (value: ContractTypeModel) => void;
}

const PaymentModel: FC<IProps> = ({ purchaseData, rentData, isCompany, companyDetails, selectedTab, setSelectedTab }) => {
    const theme = useTheme();
    const [economicModel, setEconomicModel] = useState<any>(null);
    const [electricityProduction, setElectricityProduction] = useState<IElectricityProductionData | null>(null);

    const {
        paymentModel: { purchase, rent, basedOnMarketPrice },
        subTitle,
    } = texts.results;

    const constructorTabData = {
        value: ContractTypeModel.PURCHASE,
        label: purchase.title,
        children: <PaperResultsPurchase data={buildPurchaseData(purchaseData, setElectricityProduction, setEconomicModel)} />,
        info: purchase.info,
    };

    const entrepreneurTabData = {
        value: ContractTypeModel.RENT,
        label: rent.title,
        children: <PaperResultsPurchase data={buildRentData(rentData, setElectricityProduction, setEconomicModel)} />,
        info: rent.info,
    };

    let tabsData = [constructorTabData, entrepreneurTabData];

    if (isCompany) {
        const isConstractor = companyDetails?.type.some((item) => item === CompanyType.CONSTRACTOR);
        const isEntrepreneur = companyDetails?.type.some((item) => item === CompanyType.ENTREPRENEUR);
        if (!isConstractor) {
            tabsData = [entrepreneurTabData];
        }
        if (!isEntrepreneur) {
            tabsData = [constructorTabData];
        }
    }

    const tab = selectedTab || tabsData[0].value;

    return (
        <>
            <Box display={'flex'} justifyContent={'center'}>
                <Typography.Body1 color={theme.palette.primary.purple} font={theme.fontFamilies.bold}>
                    {subTitle}
                </Typography.Body1>
            </Box>
            <TabsCalculator data={tabsData} isResult={true} tab={tab} setTab={setSelectedTab} />
            <Box display={'flex'} mb={'6px'}>
                <Typography.Body4 color={theme.palette.primary.black} font={theme.fontFamilies.regular}>
                    {basedOnMarketPrice}
                </Typography.Body4>
            </Box>
            <EconomicAndElectricityProduction
                isVatIncluded={purchaseData.vat_included}
                modelType={tab}
                electricityProduction={electricityProduction}
                economicModel={economicModel}
                setElectricityProduction={() => setElectricityProduction(null)}
                setEconomicModel={() => setEconomicModel(null)}
            />
        </>
    );
};

export default PaymentModel;
