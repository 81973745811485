export const simulatorData = {
    item_id: '',
    installation_power: '140',
    construction_cost: '200,000',
    additions_cost: '20,000',
    signing_bonus: '10,000',
    rent_percent: '30',
    tariff: '33',
    result_irr: '15.5',
    npv: '1,573,695',
    economic_model: [
        {
            item_id: '64b3b528b989b111f904ac3d',
            year: '1',
            gross_income: '779.51',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,060.45',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3f',
            year: '2',
            gross_income: '774.05',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,065.80',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3d',
            year: '3',
            gross_income: '779.51',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,060.45',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3f',
            year: '4',
            gross_income: '774.05',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,065.80',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3d',
            year: '5',
            gross_income: '779.51',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,060.45',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3f',
            year: '6',
            gross_income: '774.05',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,065.80',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3d',
            year: '7',
            gross_income: '779.51',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,060.45',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3f',
            year: '8',
            gross_income: '774.05',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,065.80',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3d',
            year: '9',
            gross_income: '779.51',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,060.45',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3f',
            year: '10',
            gross_income: '774.05',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,065.80',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3d',
            year: '11',
            gross_income: '779.51',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,060.45',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3f',
            year: '12',
            gross_income: '774.05',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,065.80',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3d',
            year: '13',
            gross_income: '779.51',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,060.45',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3f',
            year: '14',
            gross_income: '774.05',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,065.80',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3d',
            year: '15',
            gross_income: '779.51',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,060.45',
            total_return: '0.73',
            equity_return: '1.03',
        },
        {
            item_id: '64b3b528b989b111f904ac3f',
            year: '16',
            gross_income: '774.05',
            operating_cost: '1,551.48',
            rent: '15,483',
            financing_payments: '1,272.88',
            net_profit: '2,065.80',
            total_return: '0.73',
            equity_return: '1.03',
        },
    ],
    entrepreneur_simulator_error_message: '',
};

export const pricingTransformersExampleData = [
    {
        id: '1',
        name: 'SMA',
        data: [
            {
                KW: 500,
                priceWithWarrantyFiveYears: 5000,
                priceWithWarrantyTenYears: 7000,
                priceWithWarrantyFifteenYears: 8000,
                priceWithWarrantyTwentyYears: 10000,
            },
            {
                KW: 100,
                priceWithWarrantyFiveYears: 4000,
                priceWithWarrantyTenYears: 7500,
                priceWithWarrantyFifteenYears: 8060,
                priceWithWarrantyTwentyYears: 4307800,
            },
        ],
    },
    {
        id: '2',
        name: 'HUAWEI',
        data: [
            {
                KW: 500,
                priceWithWarrantyFiveYears: 5000,
                priceWithWarrantyTenYears: 7000,
                priceWithWarrantyFifteenYears: 8000,
                priceWithWarrantyTwentyYears: 10000,
            },
            {
                KW: 100,
                priceWithWarrantyFiveYears: 4000,
                priceWithWarrantyTenYears: 7500,
                priceWithWarrantyFifteenYears: 8060,
                priceWithWarrantyTwentyYears: 4307800,
            },
        ],
    },
    {
        id: '3',
        name: 'GOODWE',
        data: [
            {
                KW: 500,
                priceWithWarrantyFiveYears: 5000,
                priceWithWarrantyTenYears: 7000,
                priceWithWarrantyFifteenYears: 8000,
                priceWithWarrantyTwentyYears: 10000,
            },
            {
                KW: 100,
                priceWithWarrantyFiveYears: 4000,
                priceWithWarrantyTenYears: 7500,
                priceWithWarrantyFifteenYears: 8060,
                priceWithWarrantyTwentyYears: 4307800,
            },
        ],
    },
    {
        id: '4',
        name: 'Sungrow',
        data: [
            {
                KW: 500,
                priceWithWarrantyFiveYears: 5000,
                priceWithWarrantyTenYears: 7000,
                priceWithWarrantyFifteenYears: 8000,
                priceWithWarrantyTwentyYears: 10000,
            },
            {
                KW: 100,
                priceWithWarrantyFiveYears: 4000,
                priceWithWarrantyTenYears: 7500,
                priceWithWarrantyFifteenYears: 8060,
                priceWithWarrantyTwentyYears: 4307800,
            },
        ],
    },
];

export const pricingTransformersSimulatorExampleData = [
    {
        name: 'SANGROW',
        converters: [
            {
                KW: 40,
                quantity: 2,
                totalKW: 80,
                price: '23,000',
            },
            {
                KW: 50,
                quantity: 1,
                totalKW: 50,
                price: '15,000',
            },
        ],
        total: {
            KW: '',
            quantity: '4',
            totalKW: '130',
            price: '38,000',
        },
    },
    {
        name: 'SANGROW',
        converters: [
            {
                KW: 40,
                quantity: 2,
                totalKW: 80,
                price: '23,000',
            },
            {
                KW: 50,
                quantity: 1,
                totalKW: 50,
                price: '15,000',
            },
        ],
        total: {
            KW: '',
            quantity: '4',
            totalKW: '130',
            price: '38,000',
        },
    },
    {
        name: 'SANGROW',
        converters: [
            {
                KW: 40,
                quantity: 2,
                totalKW: 80,
                price: '23,000',
            },
            {
                KW: 50,
                quantity: 1,
                totalKW: 50,
                price: '15,000',
            },
        ],
        total: {
            KW: '',
            quantity: '4',
            totalKW: '130',
            price: '38,000',
        },
    },
];

export const pricingContractorSimulatorExampleData = {
    costs: {
        laborCost: '28,000',
        blackMaterial: '5,600',
        electricEquipment: '55%',
        construction: '16,800',
        transportation: '4,000',
        panels: '62,832',
        converters: '22,400',
        unexpectedExpenses: '2,240',
        profit: '84,000',
        profitability: '15%',
        totalCost: '234,272',
        totalForOneKW: '4,183 (DC)',
    },
    equipmentAndWarranty: {
        recommendedConverter: 'SUNGROW',
        converterWarranty: '10',
        recommendedConstruction: 'NAME',
        constructionWarranty: '5',
        recommendedPanel: 'HANWHA',
        panelWarranty: '25',
        installationWarranty: '3',
    },
    paymentTerms: {
        advancePayment: '2% (6,820₪)',
        electricityCompanyApproval: '10% (34,100₪)',
        installation: '55% (187,550₪)',
        systemConnection: '10% (34,100₪)',
        end: '13% (44,300₪)',
        total: '100%',
    },
};

export const pricingContractorExampleData = [
    {
        id: '1',
        min: 4,
        max: 25,
        costs: {
            subcontractor: '28,000',
            blackMaterial: '5,600',
            electricEquipment: '55%',
            construction: '16,800',
            transportation: '4,000',
            profitability: '15%',
            converters: '22,400',
            panels: '62,832',
            dollarRate: '3.5',
            totalForOneKW: '4,183',
        },
        equipmentAndWarranty: {
            recommendedPanel: [
                { id: 1, label: 'HANWHA' },
                { id: 2, label: 'Panel_2' },
                { id: 3, label: 'Panel_3' },
            ],
            recommendedConverter: [
                { id: 1, label: 'SUNGROW' },
                { id: 2, label: 'SUNGROW_2' },
                { id: 3, label: 'SUNGROW_3' },
            ],
            recommendedConstruction: [
                { id: 1, label: 'Cons_1' },
                { id: 2, label: 'Cons_2' },
                { id: 3, label: 'Cons_3' },
            ],
            panelWarranty: '25',
            converterWarranty: '10',
            constructionWarranty: '5',
            installationWarranty: '3',
        },
        paymentTerms: {
            advancePayment: '2%',
            electricityCompanyApproval: '10%',
            installation: '55%',
            systemConnection: '10%',
            end: '13%',
        },
    },
    {
        id: '2',
        min: 26,
        max: 40,
        costs: {
            subcontractor: '28,000',
            blackMaterial: '5,600',
            electricEquipment: '55%',
            construction: '16,800',
            transportation: '4,000',
            profitability: '15%',
            converters: '22,400',
            panels: '62,832',
            dollarRate: '3.5',
            totalForOneKW: '4,183',
        },
        equipmentAndWarranty: {
            recommendedPanel: [
                { id: 1, label: 'HANWHA' },
                { id: 2, label: 'Panel_2' },
                { id: 3, label: 'Panel_3' },
            ],
            recommendedConverter: [
                { id: 1, label: 'SUNGROW' },
                { id: 2, label: 'SUNGROW_2' },
                { id: 3, label: 'SUNGROW_3' },
            ],
            recommendedConstruction: [
                { id: 1, label: 'Cons_1' },
                { id: 2, label: 'Cons_2' },
                { id: 3, label: 'Cons_3' },
            ],
            panelWarranty: '25',
            converterWarranty: '10',
            constructionWarranty: '5',
            installationWarranty: '3',
        },
        paymentTerms: {
            advancePayment: '2%',
            electricityCompanyApproval: '10%',
            installation: '55%',
            systemConnection: '10%',
            end: '13%',
        },
    },
];
