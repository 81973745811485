import { styled } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '950px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    backgroundColor: theme.palette.primary.greyBackground2,
    [theme.breakpoints.down('md')]: {
        width: 'auto',
        borderRadius: '30px',
    },
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
    marginLeft: '20px',
    '& span': {
        fontSize: 36,
        fontFamily: theme.fontFamilies.bold,
        color: theme.palette.primary.purple,
        [theme.breakpoints.down('md')]: {
            width: '80%',
            fontSize: 26,
            textAlign: 'center',
        },
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        marginLeft: 'unset',
        alignItems: 'center',
    },
}));

export const CardHeader = styled('div')(({ theme }) => ({
    width: '100%',
    borderRadius: '30px 30px 0 0',
    backgroundColor: theme.palette.primary.purple,
    padding: '38px 32px',
    fontSize: 20,
    fontFamily: theme.fontFamilies.bold,
    color: theme.palette.primary.white,
    [theme.breakpoints.down('md')]: {
        padding: '30px 18px',
        fontSize: 15,
    },
}));

export const CardBody = styled('div')(({ theme }) => ({
    borderRadius: '0 0 30px 30px',
    backgroundColor: theme.palette.primary.white,
    padding: '32px 40px 50px 30px',
    [theme.breakpoints.down('md')]: {
        padding: '22px 20px 58px',
        margin: '',
    },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 142,
    right: 28,
    [theme.breakpoints.down('md')]: {
        top: 358,
        right: 24,
    },
}));

export const DataWrapper = styled('div')<{ isBold?: boolean }>(({ theme, isBold }) => {
    const fontFamily = isBold ? theme.fontFamilies.bold : theme.fontFamilies.regular;
    return {
        display: 'flex',
        alignItems: 'center',
        fontSize: 26,
        fontFamily: fontFamily,
        '& span:first-child': {
            fontFamily: theme.fontFamilies.bold,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 17,
            fontFamily: fontFamily,
        },
    };
});

export const DownloadButtonWrapper = styled('div')(({ theme }) => ({
    height: 'fit-content',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 30px 8px 34px',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.yellowBackground2,
    '& > * span': {
        fontSize: 20,
        fontFamily: theme.fontFamilies.bold,
        color: theme.palette.primary.black,
        whiteSpace: 'break-spaces',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 13,
        },
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        marginTop: '12px',
    },
}));
