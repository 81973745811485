import { useGetCompanySettings } from 'api/queries/common';
import { Box, Checkbox, FormLabel, Link, Typography } from 'components/atoms';
import { IConfirmDetails, IRegulationsOpened } from 'models/management';
import { Dispatch, FC, SetStateAction } from 'react';
import texts from 'texts.json';

interface IProps {
    isRegulationsOpened: React.MutableRefObject<IRegulationsOpened>;
    isDisabled: boolean;
    isConfirmedDetails: IConfirmDetails;
    setIsConfirmedDetails: Dispatch<SetStateAction<IConfirmDetails>>;
    setOpenDocumentsModal: (value: string | null | undefined) => void;
    onSubscriptionSectionOpen: () => void;
}

const sx = {
    sectionWrapper: {
        display: 'flex',
        mb: { md: '25px', xs: '15px' },
        gap: 10,
    },
};

const SignCheckboxes: FC<IProps> = ({
    setOpenDocumentsModal,
    isRegulationsOpened,
    isDisabled,
    setIsConfirmedDetails,
    isConfirmedDetails,
    onSubscriptionSectionOpen,
}) => {
    const { privacy, joiningAgreement, confirmationOfInformation } = texts.management.accordions.sign.regulations;
    const { data: settings } = useGetCompanySettings();
    const { regulations_text, accession_agreement_text, website_privacy_text } = settings?.data.item || {};
    const isRegulationModalOpened = isRegulationsOpened.current;

    return (
        <Box display='flex' flexDirection={'column'}>
            <Box sx={sx.sectionWrapper}>
                <FormLabel required={true} />
                <Checkbox
                    checked={isConfirmedDetails.privacy}
                    onChange={() => setIsConfirmedDetails((prev) => ({ ...prev, privacy: !prev.privacy }))}
                    rounded
                    disabled={isDisabled || !isRegulationModalOpened.website_privacy_text || !isRegulationModalOpened.regulations_text}
                />
                <Typography.Body4>
                    {privacy.first} &nbsp;
                    <Link
                        onClick={() => {
                            setOpenDocumentsModal(website_privacy_text);
                            isRegulationModalOpened.website_privacy_text = true;
                        }}
                    >
                        <Typography.Body4>{privacy.privacyLink}</Typography.Body4>
                    </Link>
                    &nbsp;{privacy.second}&nbsp;
                    <Link
                        onClick={() => {
                            setOpenDocumentsModal(regulations_text);
                            isRegulationModalOpened.regulations_text = true;
                        }}
                    >
                        <Typography.Body4>{privacy.termsOfUseLink}</Typography.Body4>
                    </Link>
                    &nbsp;
                    {privacy.third}
                </Typography.Body4>
            </Box>
            <Box sx={sx.sectionWrapper}>
                <FormLabel required={true} />
                <Checkbox
                    checked={isConfirmedDetails.joiningAgreement}
                    onChange={() => setIsConfirmedDetails((prev) => ({ ...prev, joiningAgreement: !prev.joiningAgreement }))}
                    rounded
                    disabled={!isRegulationModalOpened.accession_agreement_text}
                />
                <Typography.Body4>
                    {joiningAgreement.first}&nbsp;
                    <Link onClick={onSubscriptionSectionOpen}>
                        <Typography.Body4>{joiningAgreement.subscribersLink}</Typography.Body4>
                    </Link>
                    &nbsp; {joiningAgreement.second}&nbsp;
                    <Link
                        onClick={() => {
                            setOpenDocumentsModal(accession_agreement_text);
                            isRegulationModalOpened.accession_agreement_text = true;
                        }}
                    >
                        <Typography.Body4>{joiningAgreement.serviceProvidersLink}</Typography.Body4>
                    </Link>
                    &nbsp;
                    {joiningAgreement.third}
                </Typography.Body4>
            </Box>
            <Box sx={sx.sectionWrapper}>
                <FormLabel required={true} />
                <Checkbox
                    checked={isConfirmedDetails.confirmationOfInformation}
                    onChange={() =>
                        setIsConfirmedDetails((prev) => ({ ...prev, confirmationOfInformation: !prev.confirmationOfInformation }))
                    }
                    rounded
                />
                <Typography.Body4>{confirmationOfInformation}</Typography.Body4>
            </Box>
        </Box>
    );
};

export default SignCheckboxes;
