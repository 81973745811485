import React, { FC } from 'react';
import { Box, Button, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { ButtonSize } from 'components/atoms/Button';
import { IOffersMainCard } from 'models/OffersData';
import texts from 'texts.json';

const sx = {
    display: 'flex',
    '& :first-of-type': {
        xs: {
            width: '50%',
        },
        md: {
            width: '65%',
        },
    },
    justifyContent: 'space-between',
};

interface IProps {
    offer: IOffersMainCard;
    handleElectQuotation: (quotationId: string) => void;
}

const OffersMainCardLeft: FC<IProps> = ({ offer, handleElectQuotation }) => {
    const theme = useTheme();
    const { leftSection, item_id } = offer;
    const vatIncluded = texts.vat.vatIncluded;
    return (
        <Box display='flex' flexDirection='column' gap='16px'>
            <Box>
                {leftSection.costs.map((cost: any) => (
                    <Box key={cost.title} sx={sx}>
                        <Typography.Body5>
                            {cost.title}
                            {offer.vat_included && <Typography.XSmall>{` ${vatIncluded}`}</Typography.XSmall>}
                        </Typography.Body5>
                        <Typography.Body4>{cost.value}</Typography.Body4>
                    </Box>
                ))}
            </Box>
            <Box alignItems='end' display='flex' justifyContent='space-between'>
                <Typography.Body4>
                    {leftSection.total.title}
                    {offer.vat_included && <Typography.XSmall>{` ${vatIncluded}`}</Typography.XSmall>}
                </Typography.Body4>
                <Typography.Body1 color={theme.palette.primary.purple}>{leftSection.total.value}</Typography.Body1>
            </Box>
            <Box m={{ md: 0, xs: '4px 0 20px 0' }}>
                <Button.Contained
                    onClick={() => {
                        handleElectQuotation(item_id);
                    }}
                    size={ButtonSize.SMALL}
                >
                    <Typography.Body4 color={theme.palette.primary.white}>בחירת הצעה</Typography.Body4>
                </Button.Contained>
            </Box>
        </Box>
    );
};

export default OffersMainCardLeft;
