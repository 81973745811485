import queryClient from 'api/apiClient';
import { API_ROUTES } from 'api/apiRoutes';
import { useCreateProjectQuotations, useSetProjectClientName } from 'api/queries/offers';
import useDownloadProjectQuotations from 'hooks/useDownloadProjectQuotations.hook';
import { IDownloadCompanyFileFieldsReq } from 'models/api/common';
import { ContractTypeModel } from 'models/common';
import { useState } from 'react';

const useCompanyQuotationDownload = (
    projectId: string,
    contractType: ContractTypeModel,
    companyName: string | undefined,
    isProjectEditable: boolean,
    existProjectQuotations: { RENT: string; PURCHASE: string }
) => {
    const { mutate: createProjectQuotations } = useCreateProjectQuotations();
    const { mutate: setProjectClientName } = useSetProjectClientName();
    const { onDownloadProjectQuotation } = useDownloadProjectQuotations(contractType);

    const [quotations, setQuotations] = useState<{ RENT: string; PURCHASE: string }>({
        RENT: '',
        PURCHASE: '',
    });

    const onGetCompanyQuotations = (quotationType: IDownloadCompanyFileFieldsReq) => {
        createProjectQuotations(projectId, {
            onSuccess: async (response) => {
                const { elected_quotations, elected_entrepreneur_quotations } = response.data.item;
                const quotation = { RENT: elected_entrepreneur_quotations?.[0], PURCHASE: elected_quotations?.[0] };
                setQuotations(quotation);
                downloadQuotation(quotation[contractType], quotationType);
                queryClient.invalidateQueries({ queryKey: [API_ROUTES.GET_PROJECT_CALCULATOR] });
            },
        });
    };

    const downloadQuotation = (itemId: string, quotationType: IDownloadCompanyFileFieldsReq) => {
        onDownloadProjectQuotation(itemId, quotationType, companyName);
    };

    const onSetCompanyClientName = (name: string, quotationType: IDownloadCompanyFileFieldsReq) => {
        setProjectClientName(
            { itemId: projectId, clientName: name },
            {
                onSuccess: () => {
                    if (!isProjectEditable) {
                        downloadQuotation(existProjectQuotations[contractType], quotationType);
                    } else {
                        onGetCompanyQuotations(quotationType);
                    }
                },
            }
        );
    };

    const onDownloadCompanyQuotation = (clientName: string, quotationType: IDownloadCompanyFileFieldsReq) => {
        if (quotations[contractType]) {
            downloadQuotation(quotations[contractType], quotationType);
            return;
        }
        if (clientName) {
            onSetCompanyClientName(clientName, quotationType);
        } else {
            if (!isProjectEditable) {
                downloadQuotation(existProjectQuotations[contractType], quotationType);
            } else {
                onGetCompanyQuotations(quotationType);
            }
        }
    };

    return { onDownloadCompanyQuotation };
};

export default useCompanyQuotationDownload;
