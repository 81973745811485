import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, Box, Dialog } from 'components/atoms';
import PricingAccordionSummary from 'components/molecules/pricing/PricingAccordionSummary';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useFormContext } from 'react-hook-form';

interface IProps {
    data: any;
    fieldIndex: number;
    isEdit: string;
    summaryElement?: ReactNode;
    detailsElement?: ReactNode;
    switchName?: string;
    onSwitchChangeHandler?: () => void;
    onCopy?: (rangeID: string) => void;
    onPaste?: (rangeID: string) => void;
    onDuplicate?: (rangeID: string) => void;
    setIsEdit: (rangeID: string) => void;
}

const PricingAccordion: FC<IProps> = ({
    data,
    isEdit,
    summaryElement,
    detailsElement,
    switchName,
    onSwitchChangeHandler,
    onCopy,
    onPaste,
    onDuplicate,
    setIsEdit,
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const { reset } = useFormContext();

    useEffect(() => {
        if (isEdit === data.id) {
            setExpanded(true);
        }
    }, [isEdit, data.id]);

    return (
        <Accordion expanded={expanded} onChange={(_, isExpanded) => setExpanded(isExpanded)}>
            <PricingAccordionSummary
                copyPaste={true}
                onCopy={() => onCopy?.(data.id)}
                onPaste={() => onPaste?.(data.id)}
                onDuplicate={() => onDuplicate?.(data.id)}
                switchName={switchName}
                onSwitchChangeHandler={onSwitchChangeHandler}
            >
                {summaryElement}
            </PricingAccordionSummary>
            {isDesktop ? (
                <AccordionDetails>{detailsElement}</AccordionDetails>
            ) : (
                <Dialog
                    open={expanded}
                    onClose={() => {
                        setExpanded(false);
                        reset();
                        setIsEdit('');
                    }}
                    backgroundColor={theme.palette.primary.greyBackground}
                    isFullScreen
                >
                    <Box width='95%' mx='auto' mt='25px'>
                        {detailsElement}
                    </Box>
                </Dialog>
            )}
        </Accordion>
    );
};

export default PricingAccordion;
