import { Dispatch, FC, FormEvent } from 'react';
import AuthDialog from 'components/molecules/AuthDialog';
import { Box, Button, Checkbox, TextField, Typography, ButtonType, Loader } from 'components/atoms';
import { TextFieldSize, TextFieldType } from 'components/atoms/TextField';
import { useTheme } from '@mui/material';
import SignUpHeader from 'components/organisms/authentication/SignUpHeader';
import { useIsDesktop } from 'hooks/useIsDesktop';
import TextWithLink from 'components/molecules/TextWithLink';
import { ApiResponseYesNo } from 'models/api/common';
import { validatePhoneNumber } from 'utils/validatePhoneNumber';
import { SignUpParams } from 'models/api/auth';
import texts from 'texts.json';

interface IProps {
    showDialog: boolean;
    signUpData: SignUpParams;
    setSignUpData: Dispatch<React.SetStateAction<SignUpParams>>;
    isLoading: boolean;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    onClose: () => void;
}

export const checkboxWrapperStyle = {
    display: 'flex',
    columnGap: '10px',
};

const SignUp: FC<IProps> = ({ showDialog, onClose, onSubmit, signUpData, setSignUpData, isLoading }) => {
    const { title, description, button, checkBoxes } = texts.signUp;

    const DialogSignUpData = {
        header: {
            title,
        },
        headerElement: <SignUpHeader />,
    };

    const isDesktop = useIsDesktop();
    const size = isDesktop ? TextFieldSize.Medium : TextFieldSize.Standard;
    const theme = useTheme();

    const isDisabled = () => {
        return (
            !signUpData.first_name ||
            !signUpData.last_name ||
            !validatePhoneNumber(signUpData.phone) ||
            !signUpData.email ||
            signUpData.regulations_approval === ApiResponseYesNo.YN_ANSWER_NO
        );
    };

    return (
        <AuthDialog data={DialogSignUpData} open={showDialog} onClose={onClose} isFooter={true}>
            <Box
                component='form'
                display='flex'
                flexDirection='column'
                alignItems='center'
                onSubmit={onSubmit}
                gap={{ xs: '16px', md: '12px' }}
                mb={'52px'}
                sx={{ overflowx: 'auto' }}
            >
                <Typography.Body2 font={theme.fontFamilies.regular}>{description}</Typography.Body2>
                <TextField
                    size={size}
                    value={signUpData.first_name}
                    onChange={(e) => setSignUpData((value) => ({ ...value, first_name: e.target.value }))}
                    placeholder='שם פרטי'
                />
                <TextField
                    size={size}
                    value={signUpData.last_name}
                    onChange={(e) => setSignUpData((value) => ({ ...value, last_name: e.target.value }))}
                    placeholder='שם משפחה'
                />
                <TextField
                    size={size}
                    value={signUpData.phone}
                    onChange={(e) => setSignUpData((value) => ({ ...value, phone: e.target.value }))}
                    placeholder='טלפון נייד'
                    direction='rtl' // changes to ltr
                />
                <TextField
                    size={size}
                    value={signUpData.email}
                    onChange={(e) => setSignUpData((value) => ({ ...value, email: e.target.value }))}
                    placeholder='דואר אלקטרוני'
                    type={TextFieldType.Email}
                    direction='rtl' // changes to ltr
                />
                <Box>
                    <Box sx={checkboxWrapperStyle}>
                        <Checkbox
                            checked={signUpData.regulations_approval === ApiResponseYesNo.YN_ANSWER_YES}
                            onChange={(_, checked) =>
                                setSignUpData((value) => ({
                                    ...value,
                                    regulations_approval: checked ? ApiResponseYesNo.YN_ANSWER_YES : ApiResponseYesNo.YN_ANSWER_NO,
                                }))
                            }
                            rounded
                        />
                        <TextWithLink
                            data={{
                                text: checkBoxes.rules.text,
                                linkText: checkBoxes.rules.linkText,
                                onClick: () => window.open(checkBoxes.rules.link, '_blank', 'noopener noreferrer'),
                            }}
                            colorLink={theme.palette.primary.purple}
                        />
                    </Box>
                    <Box sx={checkboxWrapperStyle}>
                        <Checkbox
                            checked={signUpData.advertising_content === ApiResponseYesNo.YN_ANSWER_YES}
                            onChange={(_, checked) =>
                                setSignUpData((value) => ({
                                    ...value,
                                    advertising_content: checked ? ApiResponseYesNo.YN_ANSWER_YES : ApiResponseYesNo.YN_ANSWER_NO,
                                }))
                            }
                            rounded
                        />
                        <TextWithLink
                            data={{
                                text: checkBoxes.receivingMail.text,
                                linkText: '',
                            }}
                            colorLink={theme.palette.primary.purple}
                        />
                    </Box>
                </Box>
                <Button.Contained type={ButtonType.SUBMIT} disabled={isDisabled()}>
                    {button}
                </Button.Contained>
                {isLoading && (
                    <Box sx={{ position: 'absolute', top: '50%' }}>
                        <Loader />
                    </Box>
                )}
            </Box>
        </AuthDialog>
    );
};

export default SignUp;
