import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ROUTES } from 'api/apiRoutes';
import { axiosGet, axiosPost } from 'api/axios';
import { AxiosResponse } from 'axios';
import { IGetClientSettingsRes } from 'models/api/clientSettings.model';
import { IDownloadSettingsFilesFieldsReq, IRemainingSearchesRes } from 'models/api/common';
import { IGetCompanySettingsRes } from 'models/api/companySettings.model';
import { DEV_AUTH_TOKEN } from 'utils/envUtils';
import { getToken } from 'utils/getToken';

export const useGetEcho = () => {
    const { data } = useQuery([API_ROUTES.ECHO], () =>
        axiosPost('/', `action=token&token=${getToken()}&call=${API_ROUTES.ECHO}&testing=this is a test`)
    );
    return { data };
};

export const useGetRemainingProjects = (enabled = true) => {
    const { data, refetch } = useQuery(
        [API_ROUTES.GET_REMAINING_PROJECTS],
        (): Promise<AxiosResponse<IRemainingSearchesRes, any>> =>
            axiosPost('/', `action=token&token=${getToken() || DEV_AUTH_TOKEN}&call=${API_ROUTES.GET_REMAINING_PROJECTS}`),
        { enabled }
    );
    return { data, refetch };
};

export const useGetCompanySettings = (enabled?: boolean) => {
    const { data } = useQuery(
        [API_ROUTES.GET_COMPANY_SETTINGS],
        (): Promise<AxiosResponse<IGetCompanySettingsRes, any>> =>
            axiosPost('/', `action=token&token=${getToken() || DEV_AUTH_TOKEN}&call=${API_ROUTES.GET_COMPANY_SETTINGS}`),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            enabled,
        }
    );
    return { data };
};

export const useDownloadSettingsFiles = () => {
    const { mutate } = useMutation(
        (field: IDownloadSettingsFilesFieldsReq): Promise<AxiosResponse<ArrayBuffer, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.DOWNLOAD_SETTINGS_FILES}&field=${field}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf',
                },
            })
    );
    return { mutate };
};

export const useGetClientSettings = () => {
    const { data } = useQuery(
        [API_ROUTES.GET_CLIENT_SETTINGS],
        (): Promise<AxiosResponse<IGetClientSettingsRes, any>> =>
            axiosPost('/', `action=token&token=${getToken() || DEV_AUTH_TOKEN}&call=${API_ROUTES.GET_CLIENT_SETTINGS}`),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );
    return { data };
};
