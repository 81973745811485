import React, { FC } from 'react';
import { Box, Typography } from 'components/atoms';
import ControllerTextField from 'components/organisms/ControllerTextField';
import { addCommaToNumber } from 'utils/addCommaToNumber';
import { useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TextFieldType } from 'components/atoms/TextField';
import { PricingContractorRangesData } from 'models/PricingData';
import { EntrepreneurPriceListRanges } from 'models/PricingEntrepreneur.model';
import texts from 'texts.json';

interface IProps {
    size?: 'small' | 'regular';
    isEdit: boolean;
    data: PricingContractorRangesData | EntrepreneurPriceListRanges;
    rules?: any;
    fieldIndex: number;
    currencySymbol?: string;
}

const RangeTitle: FC<IProps> = ({ size = 'regular', isEdit = false, data, fieldIndex, currencySymbol, rules }) => {
    const theme = useTheme();
    const { control } = useFormContext();
    const minFieldName = `rangesData.${fieldIndex}.min`;
    const maxFieldName = `rangesData.${fieldIndex}.max`;
    const deps = [minFieldName, maxFieldName];

    // function to check if ranges overlap or min is bigger than max
    const validateRange = (value: any, formValues: any) => {
        const min = formValues['rangesData'][fieldIndex]['min'];
        const max = formValues['rangesData'][fieldIndex]['max'];
        const isNotOverlap = formValues.rangesData.every((range: any, index: number) => {
            return index === fieldIndex || range.min >= max || range.max <= min;
        });
        const isMinBiggerThanMax =
            +min > +max
                ? +value === +min
                    ? `${texts.common.maxErrorText}הערך המקסימלי`
                    : `${texts.common.minErrorText}הערך המינימלי`
                : null;
        const overlapError = isNotOverlap ? null : texts.common.overlapErrorText;
        return isMinBiggerThanMax || overlapError;
    };

    return (
        <>
            {!isEdit ? (
                <Box display='flex'>
                    <Box>
                        {size !== 'small' ? (
                            <Typography.Body2 font={theme.fontFamilies.bold}>
                                {currencySymbol}
                                {addCommaToNumber(data.min)}
                            </Typography.Body2>
                        ) : (
                            <Typography.Body5 font={theme.fontFamilies.bold}>
                                {currencySymbol}
                                {addCommaToNumber(data.min)}
                            </Typography.Body5>
                        )}
                    </Box>
                    <Box mx={8}>
                        <Typography.Body5 font={theme.fontFamilies.bold}>-</Typography.Body5>
                    </Box>
                    <Box>
                        {size !== 'small' ? (
                            <Typography.Body2 font={theme.fontFamilies.bold}>
                                {currencySymbol}
                                {addCommaToNumber(data.max)}
                            </Typography.Body2>
                        ) : (
                            <Typography.Body5 font={theme.fontFamilies.bold}>
                                {currencySymbol}
                                {addCommaToNumber(data.max)}
                            </Typography.Body5>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box display={'flex'} alignItems='start'>
                    <Box display='flex' alignItems='center'>
                        <Typography.Body2 font={theme.fontFamilies.bold}>{currencySymbol}</Typography.Body2>
                        &nbsp;
                        <ControllerTextField
                            rules={{
                                deps,
                                validate: validateRange,
                                ...rules?.from,
                            }}
                            control={control}
                            name={`rangesData.${fieldIndex}.min`}
                            type={TextFieldType.Number}
                        />
                    </Box>
                    <Box mx={8}>
                        <Typography.Body5 font={theme.fontFamilies.bold}>-</Typography.Body5>
                    </Box>
                    <Box display='flex' alignItems='center'>
                        <Typography.Body2 font={theme.fontFamilies.bold}>{currencySymbol}</Typography.Body2>
                        &nbsp;
                        <ControllerTextField
                            rules={{
                                deps,
                                validate: validateRange,
                                ...rules?.to,
                            }}
                            control={control}
                            name={`rangesData.${fieldIndex}.max`}
                            type={TextFieldType.Number}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

export default RangeTitle;
