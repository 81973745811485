import React, { FC, Fragment, useEffect } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Grid,
    Typography,
    FormLabel,
    Divider,
    Link,
    Icon,
    Button,
} from 'components/atoms';
import ManagementAccordionSummary from 'components/molecules/pricing/management/ManagementAccordionSummary';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormHelperText, useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { recommendationsLabels, roofTypeSelectOptions } from 'data/ManagementData';
import ControllerTextField from 'components/organisms/ControllerTextField';
import ControllerSelect from 'components/organisms/ControllerSelect';
import texts from 'texts.json';
interface IProps {
    disabledForm: boolean;
}

const recommendation = {
    item_id: '',
    project_power: '',
    roof_type: '',
    settlement: '',
    recommender_name: '',
    recommender_phone: '',
};

const minimumRecommendation = 5;

const ManagementAccordionRecommendations: FC<IProps> = ({ disabledForm }) => {
    const { title, fieldsTitle } = texts.management.accordions.recommendations;
    const { control, setError, clearErrors, formState, watch } = useFormContext();
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const fieldArrayName = 'recommendations_app';
    const { errors } = formState;

    const { fields, append, remove } = useFieldArray({
        control,
        name: fieldArrayName,
    });

    const existRecommendations = watch(fieldArrayName);

    const addRecommender = () => {
        append(recommendation);
    };

    useEffect(() => {
        if (existRecommendations?.length < minimumRecommendation) {
            setError(fieldArrayName, {
                type: 'validate',
                message: 'חובה להזין לפחות 5 ממליצים',
            });
        } else {
            clearErrors(fieldArrayName);
        }
    }, [clearErrors, existRecommendations, setError]);

    useEffect(() => {
        if (existRecommendations?.length !== undefined) {
            if ((existRecommendations?.length ?? 0) === 0) {
                addRecommendations();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existRecommendations?.length]);

    const deleteRecommender = (index: number) => {
        remove(index);
    };

    const addRecommendations = () => {
        append(Array(minimumRecommendation).fill(recommendation));
    };

    const errMessage = errors?.recommendations_app?.message as string;
    return (
        <Accordion unmountOnExit={false}>
            <AccordionSummary>
                <Box display={'flex'} columnGap={'8px'} alignItems={'center'}>
                    <ManagementAccordionSummary char='8' label={title} />
                    <FormHelperText error sx={{ fontFamily: theme.fontFamilies.bold }}>
                        {errMessage}
                    </FormHelperText>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box px={isDesktop ? '40px' : '20px'}>
                    <Card.GreyBorderCard showBorder={isDesktop}>
                        <Grid container columns={22} p={isDesktop ? '10px' : 0} alignItems='center' rowGap={isDesktop ? '25px' : '15px'}>
                            <Grid item md={1} xs={isDesktop ? 1 : 0}></Grid>
                            {isDesktop &&
                                recommendationsLabels.map((item) => (
                                    <Grid item md={4} xs={4} mb='-20px' key={item.name}>
                                        <FormLabel required>
                                            <Typography.Body4>{fieldsTitle[item.name as keyof typeof fieldsTitle]}</Typography.Body4>
                                        </FormLabel>
                                    </Grid>
                                ))}
                            <Grid item md={1} xs={isDesktop ? 1 : 0}></Grid>
                            {fields.map((field, fieldIndex) => (
                                <Fragment key={field.id}>
                                    {isDesktop ? (
                                        <Grid item md={1} xs={1}>
                                            <Typography.Body4>{fieldIndex + 1}</Typography.Body4>
                                        </Grid>
                                    ) : (
                                        fieldIndex !== 0 && (
                                            <Grid item xs={22} my='20px'>
                                                <Divider />
                                            </Grid>
                                        )
                                    )}
                                    {recommendationsLabels.map((item) => {
                                        const isRoofType = item.name === 'roof_type';
                                        return (
                                            <Grid item md={4} xs={isDesktop ? 4 : 22} key={item.name}>
                                                <Box width={isDesktop ? '165px' : '100%'}>
                                                    {!isDesktop && (
                                                        <FormLabel required>
                                                            <Typography.Body4>
                                                                {fieldsTitle[item.name as keyof typeof fieldsTitle]}
                                                            </Typography.Body4>
                                                        </FormLabel>
                                                    )}
                                                    {isRoofType ? (
                                                        <ControllerSelect
                                                            control={control}
                                                            name={`${fieldArrayName}.${fieldIndex}.${item.name}`}
                                                            options={roofTypeSelectOptions}
                                                            placeholder='סוג הגג לבחירה'
                                                            disabled={disabledForm}
                                                            rules={{
                                                                required: 'שדה חובה',
                                                            }}
                                                        />
                                                    ) : (
                                                        <ControllerTextField
                                                            type={item.type}
                                                            control={control}
                                                            name={`${fieldArrayName}.${fieldIndex}.${item.name}`}
                                                            disabled={disabledForm}
                                                            rules={
                                                                item?.rules
                                                                    ? item.rules
                                                                    : {
                                                                          required: 'שדה חובה',
                                                                      }
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                            </Grid>
                                        );
                                    })}
                                    <Grid item xs={1}>
                                        <Box visibility={disabledForm ? 'hidden' : 'visible'}>
                                            <Button.Icon onClick={() => deleteRecommender(fieldIndex)}>
                                                <Icon.Trash />
                                            </Button.Icon>
                                        </Box>
                                    </Grid>
                                </Fragment>
                            ))}
                        </Grid>
                        {!disabledForm && (
                            <Box
                                mt={{
                                    xs: '10px',
                                }}
                                my={{ md: '20px' }}
                                px={{ md: '20px' }}
                            >
                                <Link onClick={addRecommender} textDecorationColor={theme.palette.primary.purple}>
                                    <Typography.Body4 color={theme.palette.primary.purple}>+ עוד ממליץ</Typography.Body4>
                                </Link>
                            </Box>
                        )}
                    </Card.GreyBorderCard>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ManagementAccordionRecommendations;
