import { SnackbarProvider as SnackbarProviderNotistack } from 'notistack';
import { styled } from '@mui/material';
import Toast, { ToastType } from 'components/atoms/Toast';
import { ReactNode, FC } from 'react';

declare module 'notistack' {
    interface VariantOverrides {
        toast: {
            type: ToastType;
        };
    }
}

interface IProps {
    children: ReactNode | ReactNode[];
}

const StyledSnackbarProvider = styled(SnackbarProviderNotistack)({
    '& .MuiSnackbar-root': {},
});

const SnackbarProvider: FC<IProps> = ({ children }) => {
    return (
        <StyledSnackbarProvider
            Components={{
                toast: Toast,
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            preventDuplicate
        >
            {children}
        </StyledSnackbarProvider>
    );
};

export default SnackbarProvider;
