import React, { FC, Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, FormLabel, Grid, InfoTooltip, Typography } from 'components/atoms';
import ManagementAccordionSummary from 'components/molecules/pricing/management/ManagementAccordionSummary';
import { professionalsCheckboxes, professionalsLabels } from 'data/ManagementData';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material';
import ControllerCheckbox from 'components/molecules/pricing/ControllerCheckbox';
import { useIsDesktop } from 'hooks/useIsDesktop';
import texts from 'texts.json';
interface IProps {
    disabledForm: boolean;
}

const ManagementAccordionProfessionals: FC<IProps> = ({ disabledForm }) => {
    const { title, fieldsTitle, description } = texts.management.accordions.professionals;
    const { control } = useFormContext();
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    return (
        <Accordion unmountOnExit={false}>
            <AccordionSummary>
                <ManagementAccordionSummary char='4' label={title} />
            </AccordionSummary>
            <AccordionDetails>
                <Box px={isDesktop ? '40px' : '10px'}>
                    <Card.GreyBorderCard showBorder={isDesktop}>
                        <Box width={isDesktop ? '56%' : '100%'} p={isDesktop ? '40px' : 0}>
                            <Typography.Body4>{description}</Typography.Body4>
                            <Grid container mt='50px' rowGap={isDesktop ? '20px' : '30px'}>
                                <Grid item xs={isDesktop ? 3 : 6} md={3}></Grid>
                                {professionalsCheckboxes().map((item, index) => (
                                    <Grid item xs={isDesktop ? 3 : 2} md={3} key={index} textAlign='center'>
                                        <Typography.Body4>{item.label}</Typography.Body4>
                                    </Grid>
                                ))}
                                {professionalsLabels.map((item, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={isDesktop ? 3 : 6} md={3} display='flex' alignItems='baseline'>
                                            <FormLabel required={true}>
                                                {fieldsTitle[item.name as keyof typeof fieldsTitle].label}
                                            </FormLabel>{' '}
                                            &nbsp;
                                            <InfoTooltip
                                                firstText={fieldsTitle[item.name as keyof typeof fieldsTitle].info.firstText}
                                                secondText={fieldsTitle[item.name as keyof typeof fieldsTitle].info.secondText}
                                                fill={theme.palette.primary.purple}
                                                isUnderline
                                            />
                                        </Grid>
                                        {professionalsCheckboxes().map((checkbox, index) => (
                                            <Grid item xs={isDesktop ? 3 : 2} md={3} key={index} textAlign='center'>
                                                <ControllerCheckbox
                                                    control={control}
                                                    name={item.name}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    checkboxValue={checkbox.value}
                                                    disabled={disabledForm}
                                                />
                                            </Grid>
                                        ))}
                                    </Fragment>
                                ))}
                            </Grid>
                        </Box>
                    </Card.GreyBorderCard>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ManagementAccordionProfessionals;
