import React, { FC, ReactNode } from 'react';
import { ButtonGroup as MuiButtonGroup } from '@mui/material';
import { theme } from 'theme';

interface IProps {
    children: ReactNode;
    disabled?: boolean;
}

export const ButtonBackground = {
    active: theme.palette.primary.purple,
    notActive: theme.palette.primary.greyBackground,
};

const ButtonGroup: FC<IProps> = ({ children, disabled }) => {
    return (
        <MuiButtonGroup disabled={disabled} fullWidth>
            {children}
        </MuiButtonGroup>
    );
};

export default ButtonGroup;
