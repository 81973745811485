import { addCommaToNumber } from 'utils/addCommaToNumber';
import { Symbols } from 'utils/symbols';
import texts from 'texts.json';
import { IElectricityProductionData } from 'models/common';

export const buildPurchaseData = (
    purchaseData: any,
    setElectricityProduction: (data: IElectricityProductionData) => void,
    setEconomicModel: any
) => {
    const { costEstimation, avgAnnualRepaymentEstimation, repaymentEstimation25, investmentReturnTime, expectedAnnualReturn, buttons } =
        texts.results.paymentModel.purchase.labels;
    const vatIncluded = texts.vat.vatIncluded;
    const { title, info } = texts.urbanTariff;
    return {
        results: [
            {
                title: costEstimation.label,
                result: `${addCommaToNumber(purchaseData.cost_estimation)} ${Symbols.SHEKEL}`,
                helperText: purchaseData.vat_included ? vatIncluded : '',
            },
            {
                title: avgAnnualRepaymentEstimation.label,
                result: `${addCommaToNumber(purchaseData.avg_annual_repayment_estimation)} ${Symbols.SHEKEL}`,
                helperText: purchaseData.settlement_tariff ? title : '',
                info: purchaseData.settlement_tariff ? info : '',
            },
            {
                title: repaymentEstimation25.label,
                result: `${addCommaToNumber(purchaseData.repayment_estimation_25)} ${Symbols.SHEKEL}`,
            },
            {
                title: investmentReturnTime.label,
                result: `${addCommaToNumber(purchaseData.investment_return_time)} שנים`,
            },
            {
                title: expectedAnnualReturn.label,
                result: `${addCommaToNumber(purchaseData.expected_annual_return)}%`,
                titleInfo: expectedAnnualReturn.info,
            },
        ],
        buttons: [
            {
                title: buttons.electricityProduction.label,
                onClick: () =>
                    setElectricityProduction({
                        electricityProduction: purchaseData.electricity_production,
                        annualDcGeneration: purchaseData.annual_dc_generation,
                    }),
            },
            {
                title: buttons.economicModel.label,
                disabled: !purchaseData.economic_model,
                onClick: () => setEconomicModel(purchaseData.economic_model),
            },
        ],
    };
};

export const buildRentData = (
    rentData: any,
    setElectricityProduction: (data: IElectricityProductionData) => void,
    setEconomicModel: any
) => {
    const { signingBonus, avgRent, entrepreneurTotalIncome, rentPercent, buttons } = texts.results.paymentModel.rent.labels;
    return {
        results: [
            {
                title: signingBonus.label,
                result: `${addCommaToNumber(rentData?.signing_bonus) || ''} ${Symbols.SHEKEL}`,
            },
            {
                title: avgRent.label,
                result: `${addCommaToNumber(rentData?.avg_rent) || ''} ${Symbols.SHEKEL}`,
            },
            {
                title: entrepreneurTotalIncome.label,
                result: `${addCommaToNumber(rentData?.entrepreneur_total_income) || ''} ${Symbols.SHEKEL}`,
            },
            {
                title: rentPercent.label,
                result: `${addCommaToNumber(rentData?.rent_percent) || ''}${Symbols.PERCENT}`,
            },
            {
                title: '',
                result: ``,
            },
        ],
        buttons: [
            {
                title: buttons.electricityProduction.label,
                onClick: () =>
                    setElectricityProduction({
                        electricityProduction: rentData.electricity_production,
                        annualDcGeneration: rentData.annual_dc_generation,
                    }),
            },
            {
                title: buttons.entrepreneurEconomicModel.label,
                disabled: !rentData.entrepreneur_economic_model,
                onClick: () => setEconomicModel(rentData.entrepreneur_economic_model),
            },
        ],
    };
};
