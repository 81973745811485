import { Box, Loader } from 'components/atoms';
import ContentSection from 'components/molecules/ContentSection';
import SearchByPlot from 'components/molecules/SearchByPlot';
import SearchByAddress from 'components/molecules/SearchByAddress';
import TabsCalculator from 'components/molecules/TabsCalculator';
import { MapContext } from 'contexts/MapContext';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import texts from 'texts.json';
import { PATH } from 'paths';
import { useIsDesktop } from 'hooks/useIsDesktop';
import CalculatorPropertyType from 'components/molecules/CalculatorPropertyType';
import { EPropertyType } from 'models/propertyType.model';

const tabsData = [
    {
        value: 'SEARCH_BY_ADDRESS',
        label: texts.calculator.stepAddress.address.searchByAddress.title,
        children: <SearchByAddress />,
    },
    {
        value: 'SEARCH_BY_PLOT',
        label: texts.calculator.stepAddress.address.searchByPlot.title,
        children: <SearchByPlot />,
    },
];

const Address = () => {
    const match = useMatch('calculator/address');
    const location = useLocation();
    const isDesktop = useIsDesktop();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setShowMapSection, calculator, mapLoading, setCalculator } = useContext(MapContext);

    const [tab, setTab] = useState<string>(tabsData[0].value);

    useEffect(() => {
        if (match) {
            setCalculator(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (location.pathname === `/${PATH.CALCULATOR}/${PATH.ADDRESS}`) {
            setShowMapSection(false);
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        if (calculator?.plot) {
            setTab(tabsData[1].value);
        }
    }, [calculator?.plot]);

    return match ? (
        !isDesktop && mapLoading ? (
            <Loader />
        ) : (
            <ContentSection
                buttonText={texts.calculator.stepAddress.address.button.text}
                title={texts.calculator.stepAddress.address.title}
                onClick={() => navigate({ pathname: texts.calculator.stepAddress.address.button.link, search: searchParams.toString() })}
                disabled={mapLoading || !calculator?.propertyType || !calculator?.itemEast || !calculator?.itemNorth}
            >
                <Box width={{ md: '100%', xs: '90%' }} mx='auto'>
                    <TabsCalculator data={tabsData} tab={tab} setTab={setTab} />
                    {(calculator?.address || calculator?.plot) && (
                        <CalculatorPropertyType
                            propertyType={calculator?.propertyType || null}
                            setPropertyType={(propertyType: EPropertyType) => setCalculator({ ...calculator, propertyType })}
                        />
                    )}
                </Box>
            </ContentSection>
        )
    ) : (
        <Outlet />
    );
};

export default Address;
