import { Button } from 'components/atoms';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

interface IProps {
    name: string;
    control: Control;
    rules?: any;
    disabled: boolean;
    accept?: string;
}

const ControllerUploadFile: FC<IProps> = ({ name, control, rules, disabled, accept }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value } }) => (
                <Button.UploadFile
                    name={typeof value === 'string' ? value : value?.name}
                    accept={accept}
                    onChange={onChange}
                    disabled={disabled}
                />
            )}
        />
    );
};

export default ControllerUploadFile;
