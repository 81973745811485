import { useTheme } from '@mui/material';
import { Box, Button, Typography } from 'components/atoms';
import { AuthContext } from 'contexts/AuthContext';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { ContractTypeModel } from 'models/common';
import { FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import texts from 'texts.json';

interface IProps {
    disabled?: boolean;
    modelType: ContractTypeModel;
    onClick: () => void;
}
const DownloadFullQuotation: FC<IProps> = ({ onClick, disabled = false, modelType }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const [, entity] = useLocation().pathname.split('/');
    const { user } = useContext(AuthContext);

    const isCompany = !!user?.user.owner_company;

    const { title, rentTitle, purchaseTitle } = texts.fullReport;
    const isOffersPage = entity === 'offers';
    const isRent = modelType === ContractTypeModel.RENT;
    const agentTitle = isRent ? rentTitle : purchaseTitle;
    const buttonTitle = isCompany ? title : agentTitle;

    return (
        <Box sx={{ whiteSpace: 'nowrap' }}>
            <Button.Text onClick={onClick} disabled={disabled}>
                {isDesktop && !isOffersPage ? (
                    <Typography.Body1 textUnderline color={theme.palette.primary.purple}>
                        {buttonTitle}
                    </Typography.Body1>
                ) : (
                    <Typography.Body3 textUnderline font={theme.fontFamilies.regular} color={theme.palette.primary.purple}>
                        {buttonTitle}
                    </Typography.Body3>
                )}
            </Button.Text>
        </Box>
    );
};

export default DownloadFullQuotation;
