import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from 'components/atoms';
import ManagementAccordionSummary from './ManagementAccordionSummary';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useTheme } from '@mui/material';
import texts from 'texts.json';
import ManagementSubscriptionsTable from 'components/molecules/pricing/management/SubscriptionsTable';

interface IProps {
    disabledForm: boolean;
    openSubscription: boolean;
    setOpenSubscription: (value: boolean) => void;
}

const ManagementAccordionSubscriptions: FC<IProps> = ({ openSubscription, setOpenSubscription }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const text = texts.management.accordions.subscriptions.text;
    return (
        <Accordion unmountOnExit={false} expanded={openSubscription} onChange={() => setOpenSubscription(!openSubscription)}>
            <AccordionSummary>
                <ManagementAccordionSummary char='9' label='מנויים - נותני שירותים' />
            </AccordionSummary>
            <AccordionDetails>
                <Box width={isDesktop ? '67%' : '100%'} px={isDesktop ? '40px' : '20px'}>
                    <ul style={{ paddingInlineStart: '18px' }}>
                        {text.map((text, index) => (
                            <li style={{ paddingRight: '10px', listStyleType: index === 0 ? "'(1)'" : '' }}>
                                <Typography.DangerouslyHtml variant={isDesktop ? 'h2' : 'h5'} font={theme.fontFamilies.regular}>
                                    {text}
                                </Typography.DangerouslyHtml>
                            </li>
                        ))}
                    </ul>
                    <ManagementSubscriptionsTable />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ManagementAccordionSubscriptions;
