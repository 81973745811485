/* eslint-disable max-lines */
import React, { FC, Fragment, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    Divider,
    FormLabel,
    Grid,
    Icon,
    Link,
    Typography,
} from 'components/atoms';
import ManagementAccordionSummary from 'components/molecules/pricing/management/ManagementAccordionSummary';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import FormLabelWithInput from 'components/molecules/pricing/management/FormLabelWithInput';
import ControllerSelect from 'components/organisms/ControllerSelect';
import { roofTypeSelectOptions } from 'data/ManagementData';
import { FormHelperText, useTheme } from '@mui/material';
import UploadImage from 'components/atoms/pricing/UploadImage';
import { Orientation } from 'components/atoms/Divider';
import { loop } from 'utils/loop';
import {
    useCreateProjectImage,
    useDeleteProjectImage,
    useGetCompanyProjectImages,
    useSetProjectImage,
    useUploadProjectImage,
} from 'api/queries/management';
import { uploadFile } from 'utils/uploadFile';
import { PRODUCTION } from 'utils/envUtils';
import { DevTool } from '@hookform/devtools';
import { AxiosRequestConfig } from 'axios';
import texts from 'texts.json';
import { isFile } from 'utils/isFile';

interface IProps {
    disabledForm: boolean;
}

const minimumImages = 5;
const initImages = {
    item_id: '',
    image: {
        src: '',
        width: 0,
        height: 0,
    },
    power: '',
    roof_type: '',
};

const ManagementAccordionSystemImages: FC<IProps> = ({ disabledForm }) => {
    const { title, description, fieldsTitle } = texts.management.accordions.images;
    const { data, refetch } = useGetCompanyProjectImages();
    const { mutateAsync: mutateCreateProjectImage } = useCreateProjectImage();
    const { mutateAsync: mutateSetProjectImage } = useSetProjectImage();
    const { mutateAsync: mutateDeleteProjectImage } = useDeleteProjectImage();
    const { mutateAsync: mutateUploadProjectImage } = useUploadProjectImage();
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const fieldArrayName = 'images';
    const [, setProgress] = useState<number>(0);

    const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent) => {
            setProgress(Math.round((100 * progressEvent.loaded) / (progressEvent?.total || 100)));
        },
    };

    const companyProjectImages = data?.data.items;
    //main form context
    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const { control, reset, watch, setValue, register, resetField, getValues } = useForm({
        mode: 'onChange',
        defaultValues: {
            images: companyProjectImages,
        },
    });

    const { fields, append, remove } = useFieldArray({
        keyName: 'key',
        control,
        name: fieldArrayName,
    });

    const watchFieldArray = watch('images');

    useEffect(() => {
        reset({ images: companyProjectImages });
    }, [companyProjectImages, reset]);

    useEffect(() => {
        if (watchFieldArray && watchFieldArray.length < 5) {
            addImages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchFieldArray]);

    useEffect(() => {
        const img = watchFieldArray?.filter((image) => image.image?.src !== '');
        if (!img || img?.length < minimumImages) {
            setError(fieldArrayName, {
                type: 'validate',
                message: 'חובה להזין לפחות 5 תמונות',
            });
        } else {
            clearErrors(fieldArrayName);
        }
    }, [clearErrors, watchFieldArray, setError]);

    const onBlur = async (item: any, index: number) => {
        const {
            power,
            roof_type,
            image: { src },
            item_id,
        } = item;
        const isValid = ![power, roof_type, src].some((value) => value === '');

        if (!isValid) {
            return null;
        }

        if (!!item_id && item_id !== '') {
            // if image is not the same as the image from server - setProjectImage
            return mutateSetProjectImage(
                { item_id: item_id, item: { power, roof_type } },
                {
                    onSuccess: async (data) => {
                        if (isFile(src)) {
                            const file = await uploadFile(data.data.item.item_id, src, 'image', mutateUploadProjectImage, config);
                            resetField(`images.${index}`, { defaultValue: file?.data.item });
                        } else {
                            resetField(`images.${index}`, { defaultValue: data?.data.item });
                        }
                    },
                }
            );
        } else {
            return mutateCreateProjectImage(
                { power: item.power, roof_type: item.roof_type },
                {
                    onSuccess: async (data) => {
                        const file = await uploadFile(data.data.item.item_id, item.image.src, 'image', mutateUploadProjectImage, config);
                        resetField(`images.${index}`, { defaultValue: file?.data.item });
                    },
                }
            );
        }
    };

    const checkIfRenderDivider = (index: number) => {
        if (index === 0) {
            return false;
        }
        if (index % 5 === 0 && isDesktop) {
            return false;
        }
        return true;
    };

    const addImages = () => {
        const imagesNumberInRow = 5;
        const imagesLength = watchFieldArray?.length ?? 0;
        const remainder = imagesLength % imagesNumberInRow;
        const numberImagesToAdd = imagesNumberInRow - remainder;
        loop(numberImagesToAdd, () => {
            append(initImages, { focusIndex: companyProjectImages?.length ?? 0 + 1 });
        });
    };

    const deleteImage = async (index: number) => {
        const image = watchFieldArray?.[index];
        if (image?.item_id && image?.item_id !== '') {
            await mutateDeleteProjectImage(image?.item_id);
            remove(index);
            refetch();
        } else {
            remove(index);
        }
    };

    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray?.[index],
        };
    });
    const errMessage = errors?.images?.message as string;

    return (
        <Accordion unmountOnExit={false}>
            <AccordionSummary>
                <Box display={'flex'} columnGap={'8px'} alignItems={'center'}>
                    <ManagementAccordionSummary char='7' label={title} />
                    {!disabledForm && errMessage && (
                        <FormHelperText error sx={{ fontFamily: theme.fontFamilies.bold }}>
                            {errMessage}
                        </FormHelperText>
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box px={isDesktop ? '40px' : '20px'}>
                    <Card.GreyBorderCard showBorder={isDesktop}>
                        <Box p={isDesktop ? '40px' : ''}>
                            <Box display='flex' flexDirection='column' mb='30px'>
                                <Typography.Body4>{description.first}</Typography.Body4>
                                <Typography.Body5>{description.second}</Typography.Body5>
                            </Box>
                            <Grid container columns={10} justifyContent={'space-between'}>
                                {controlledFields.map((field, index) => {
                                    const { image } = field;

                                    return (
                                        <Fragment key={field.key}>
                                            {checkIfRenderDivider(index) && (
                                                <Box
                                                    display={isDesktop ? 'flex' : 'block'}
                                                    justifyContent='center'
                                                    my={isDesktop ? '0px' : '20px'}
                                                    mx={isDesktop ? '30px' : '0px'}
                                                    width={isDesktop ? 'fit-content' : '100%'}
                                                >
                                                    <Divider orientation={isDesktop ? Orientation.Vertical : Orientation.Horizontal} />
                                                </Box>
                                            )}
                                            <Grid
                                                item
                                                container
                                                columns={isDesktop ? 2 : 10}
                                                display='flex'
                                                width={isDesktop ? 'fit-content' : '100%'}
                                                mb={isDesktop ? '50px' : '0px'}
                                            >
                                                <Box
                                                    display='flex'
                                                    flexDirection='column'
                                                    gap='30px'
                                                    width={isDesktop ? '165px' : '100%'}
                                                    mx='auto'
                                                >
                                                    <Box>
                                                        <Box textAlign='end' visibility={disabledForm ? 'hidden' : 'visible'}>
                                                            <Button.Icon onClick={() => deleteImage(index)}>
                                                                <Icon.Trash width='20px' height='20px' />
                                                            </Button.Icon>
                                                        </Box>
                                                        <FormLabelWithInput
                                                            name={`images.${index}.power`}
                                                            label={fieldsTitle.projectSize}
                                                            control={control}
                                                            disabled={disabledForm}
                                                            rules={{
                                                                required: 'שדה חובה',
                                                            }}
                                                            onBlur={() => onBlur(getValues(`images.${index}`), index)}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <FormLabel required={true}>{fieldsTitle.roofType}</FormLabel>
                                                        <ControllerSelect
                                                            control={control}
                                                            name={`images.${index}.roof_type`}
                                                            options={roofTypeSelectOptions}
                                                            disabled={disabledForm}
                                                            rules={{
                                                                required: 'שדה חובה',
                                                            }}
                                                            onBlur={() => onBlur(getValues(`images.${index}`), index)}
                                                        />
                                                    </Box>
                                                    <Box display='flex' flexDirection='column' gap={4}>
                                                        <Box display='flex' gap={10}>
                                                            <FormLabel required={true}>{fieldsTitle.uploadImage}</FormLabel>
                                                            <Icon.File fill={theme.palette.primary.purple} />
                                                        </Box>
                                                        <UploadImage
                                                            watch={watch}
                                                            image={image?.src}
                                                            name={`images.${index}.image.src`}
                                                            disabled={disabledForm}
                                                            required={{ required: 'שדה חובה' }}
                                                            setValue={setValue}
                                                            register={register}
                                                            onBlur={() => onBlur(getValues(`images.${index}`), index)}
                                                            index={index}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Fragment>
                                    );
                                })}
                            </Grid>
                            {!disabledForm && (
                                <Box mt={{ md: '20px', xs: '10px' }}>
                                    <Link onClick={addImages} textDecorationColor={theme.palette.primary.purple}>
                                        <Typography.Body4 color={theme.palette.primary.purple}>+ עוד תמונות</Typography.Body4>
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </Card.GreyBorderCard>
                </Box>
            </AccordionDetails>
            {!PRODUCTION && <DevTool control={control} />}
        </Accordion>
    );
};

export default ManagementAccordionSystemImages;
