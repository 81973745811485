import { FC } from 'react';
import { Box, Title } from 'components/atoms';
import Stepper from 'pages/calculator/calculator_section/Stepper';
import texts from 'texts.json';
import { Outlet } from 'react-router-dom';

interface IProps {
    activeStep: number;
    isLoading?: boolean;
}

const CalculatorSection: FC<IProps> = ({ activeStep, isLoading }) => {
    return (
        <Box
            id='scrollable'
            display='flex'
            flexDirection='column'
            alignItems='center'
            width={'100%'}
            height='100%'
            sx={{ background: (theme) => theme.palette.primary.greyBackground, overflowY: 'auto', direction: 'rtl' }}
        >
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                width={{ md: '70%', xs: '100%' }}
                height={'100%'}
                paddingBottom={{ xs: !isLoading ? '45px' : 'unset', md: 'unset' }}
                sx={{
                    direction: 'ltr', // for scrollbar to be on the right side
                }}
            >
                <Box textAlign='center' padding='38px 0 28px 0'>
                    <Title text={texts.calculator.common.title} />
                </Box>
                <Stepper activeStep={activeStep} />
                <Outlet />
            </Box>
        </Box>
    );
};

export default CalculatorSection;
