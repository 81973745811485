import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Box, Card, Grid } from 'components/atoms';
import { useTheme } from '@mui/material';
import CardWithInfoAbout from 'components/molecules/contractor/CardWithInfoAbout';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { IOfferAbout } from 'models/OfferData.model';

interface IProps {
    data: IOfferAbout;
    entrepreneurData: string;
    isContractor: boolean;
}

const sx = {
    section: {
        mb: { xs: '32px', md: '25px' },
    },
    title: {
        mb: { xs: '14px', md: '6px' },
    },
};

const AccordionAboutCompany: FC<IProps> = ({ data, isContractor, entrepreneurData }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { experienceData, professionalTeamData, qualityData } = data;

    return (
        <Accordion>
            <AccordionSummary>
                {isDesktop ? (
                    <Typography.Body1 color={theme.palette.primary.purple}>אודות החברה</Typography.Body1>
                ) : (
                    <Typography.Body3 color={theme.palette.primary.purple}>אודות החברה</Typography.Body3>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box px={{ xs: '16px' }}>
                    {isContractor ? (
                        <>
                            <Box sx={sx.section}>
                                <Typography.Body5>{data?.about}</Typography.Body5>
                            </Box>
                            <Box sx={sx.section}>
                                <Box sx={sx.title}>
                                    <Typography.Body2>ניסיון</Typography.Body2>
                                </Box>
                                <Grid container columns={10} spacing={8}>
                                    {experienceData.map((item, index) => (
                                        <Grid item xs={5} md={2} height={{ md: 110, xs: 'auto' }} minHeight={{ xs: 110 }} key={index}>
                                            <Card.GreyCard>
                                                <Box display='flex' flexDirection='column' justifyContent='space-between' height={'100%'}>
                                                    <Typography.Body5>{item?.title}</Typography.Body5>
                                                    {isDesktop ? (
                                                        <Typography.Body1 color={theme.palette.primary.purple}>
                                                            {item?.value}
                                                        </Typography.Body1>
                                                    ) : (
                                                        <Typography.Body3 color={theme.palette.primary.purple}>
                                                            {item?.value}
                                                        </Typography.Body3>
                                                    )}
                                                </Box>
                                            </Card.GreyCard>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <Box sx={sx.section}>
                                <Box sx={sx.title}>
                                    <Typography.Body2>צוות מקצועי</Typography.Body2>
                                </Box>
                                <Grid container columns={14} spacing={8}>
                                    {professionalTeamData.map((item, index) => (
                                        <Grid item height={{ md: 104, xs: 'auto' }} minHeight={104} md={2} xs={7} key={index}>
                                            <CardWithInfoAbout icon={item.icon} title={item.title} text={item.text} info={item.info} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <Box sx={sx.section}>
                                <Box sx={sx.title}>
                                    <Typography.Body2>הסמכות איכות</Typography.Body2>
                                </Box>
                                <Grid container columns={10} spacing={8}>
                                    {qualityData.map((item, index) => (
                                        <Grid item height={{ md: 125, xs: 'auto' }} minHeight={125} md={2} xs={5} key={index}>
                                            <CardWithInfoAbout
                                                icon={item.icon}
                                                title={item.title}
                                                boldTitle={item.boldTitle}
                                                info={item.info}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </>
                    ) : (
                        <Box
                            width={{ md: '70%', xs: '95%' }}
                            mx={{
                                xs: 'auto',
                                md: 'unset',
                            }}
                        >
                            <Typography.Body5>{entrepreneurData}</Typography.Body5>
                        </Box>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionAboutCompany;
