import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ROUTES } from 'api/apiRoutes';
import { axiosGet, axiosPost } from 'api/axios';
import { AxiosResponse } from 'axios';
import { transformCompanyDetails } from 'data/ManagementData';
import { IDownloadCompanyFileFieldsReq, SetCreateRes } from 'models/api/common';
import {
    CreateProjectImageReq,
    CreateRecommendationReq,
    GetCompanyDetailsRes,
    SetRecommendationReq,
    SetProjectImageReq,
    SetCompanyDetailsReq,
    GetCompanyImagesRes,
    GetCompanyRecommendationsRes,
    UploadImageReq,
    IUploadProjectImageRes,
    IImage,
} from 'models/api/management';
import { getToken } from 'utils/getToken';
import { parseToNumber } from 'utils/parseToNumber';

export const useGetCompanyDetails = (enabled = true) => {
    const { data, refetch } = useQuery(
        [API_ROUTES.GET_COMPANY_DETAILS],
        async (): Promise<AxiosResponse<GetCompanyDetailsRes, any>> => {
            const response = await axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_COMPANY_DETAILS}`);
            return response.data && { ...response, data: { ...response.data, ...transformCompanyDetails(response.data.item) } };
        },
        { enabled, staleTime: Infinity, cacheTime: Infinity }
    );
    return { data, refetch };
};

export const useUploadCompanyDetails = () => {
    const { mutateAsync, data } = useMutation(
        [API_ROUTES.UPLOAD_COMPANY_DETAILS],
        ({ formData, config }: UploadImageReq): Promise<AxiosResponse<SetCreateRes, any>> => {
            return axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.UPLOAD_COMPANY_DETAILS}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                // ...config,
            });
        }
    );
    return { mutateAsync, data };
};

export const useCreateRecommendation = () => {
    const { mutateAsync, data } = useMutation(
        [API_ROUTES.CREATE_RECOMMENDATION],
        (body: CreateRecommendationReq): Promise<AxiosResponse<SetCreateRes, any>> => {
            return axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.CREATE_RECOMMENDATION}`, { item: body });
        }
    );
    return { mutateAsync, data };
};

export const useSetRecommendation = () => {
    const { mutateAsync, data } = useMutation(
        [API_ROUTES.SET_RECOMMENDATION],
        (body: SetRecommendationReq): Promise<AxiosResponse<SetCreateRes, any>> => {
            return axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.SET_RECOMMENDATION}`, body);
        }
    );
    return { mutateAsync, data };
};

export const useDeleteRecommendation = () => {
    const { mutateAsync, data } = useMutation(
        [API_ROUTES.DELETE_RECOMMENDATION],
        (item_id: string): Promise<AxiosResponse<SetCreateRes, any>> => {
            return axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.DELETE_RECOMMENDATION}`, { item_id: item_id });
        }
    );
    return { mutateAsync, data };
};

export const useCreateProjectImage = () => {
    const { mutateAsync, data } = useMutation(
        [API_ROUTES.CREATE_PROJECT_IMAGE],
        (body: CreateProjectImageReq): Promise<AxiosResponse<SetCreateRes, any>> => {
            return axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.CREATE_PROJECT_IMAGE}`, { item: body });
        }
    );
    return { mutateAsync, data };
};

export const useSetProjectImage = () => {
    const { mutateAsync, data } = useMutation(
        [API_ROUTES.SET_PROJECT_IMAGE],
        (body: SetProjectImageReq): Promise<AxiosResponse<IUploadProjectImageRes, any>> => {
            return axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.SET_PROJECT_IMAGE}`, body);
        }
    );
    return { mutateAsync, data };
};

export const useDeleteProjectImage = () => {
    const { mutateAsync, data } = useMutation(
        [API_ROUTES.DELETE_PROJECT_IMAGE],
        (item_id: string): Promise<AxiosResponse<SetCreateRes, any>> => {
            return axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.DELETE_PROJECT_IMAGE}`, { item_id: item_id });
        }
    );
    return { mutateAsync, data };
};

export const useUploadProjectImage = () => {
    const { mutateAsync, data } = useMutation(
        [API_ROUTES.UPLOAD_PROJECT_IMAGE],
        async ({ formData, config }: UploadImageReq): Promise<AxiosResponse<IUploadProjectImageRes, any>> => {
            const response = await axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.UPLOAD_PROJECT_IMAGE}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                // ...config,
            });
            return (
                response.data && {
                    ...response,
                    data: {
                        ...response.data,
                        item: { ...response.data.item, power: parseToNumber(response.data.item.power) },
                    },
                }
            );
        }
    );
    return { mutateAsync, data };
};

export const useSetCompanyDetails = () => {
    const { mutateAsync, data } = useMutation(
        [API_ROUTES.SET_COMPANY_DETAILS],
        async (body: { [key in keyof SetCompanyDetailsReq]?: any }): Promise<AxiosResponse<SetCreateRes, any>> => {
            const response = await axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.SET_COMPANY_DETAILS}`, { item: body });
            return (
                response.data && {
                    ...response,
                    data: {
                        ...response.data,
                        item: { ...response.data.item, power: parseToNumber(response.data.item.power) },
                    },
                }
            );
        }
    );
    return { mutateAsync, data };
};

export const useCompanyRegistrationReady = () => {
    const { mutateAsync, data } = useMutation([API_ROUTES.COMPANY_REGISTRATION_READY], (): Promise<AxiosResponse<SetCreateRes, any>> => {
        return axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.COMPANY_REGISTRATION_READY}`);
    });
    return { mutateAsync, data };
};

export const useGetCompanyRecommendations = (enabled = true) => {
    const { data, refetch } = useQuery(
        [API_ROUTES.GET_COMPANY_RECOMMENDATIONS],
        (): Promise<AxiosResponse<GetCompanyRecommendationsRes, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_COMPANY_RECOMMENDATIONS}`),
        { enabled }
    );
    return { data, refetch };
};

export const useGetCompanyProjectImages = (enabled = true) => {
    const { data, refetch } = useQuery(
        [API_ROUTES.GET_COMPANY_PROJECT_IMAGES],
        async (): Promise<AxiosResponse<GetCompanyImagesRes, any>> => {
            const response = await axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_COMPANY_PROJECT_IMAGES}`);
            return (
                response?.data && {
                    ...response,
                    data: {
                        items: response.data.items.map((item: IImage) => ({ ...item, power: parseToNumber(item.power) })) as IImage[],
                    },
                }
            );
        },
        { enabled }
    );
    return { data, refetch };
};

export const useDownloadCompanyFiles = () => {
    const { mutate } = useMutation(
        ({ itemId, field }: { itemId: string; field: IDownloadCompanyFileFieldsReq }): Promise<AxiosResponse<ArrayBuffer, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.DOWNLOAD_COMPANY_FILES}&item_id=${itemId}&field=${field}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf',
                },
            })
    );
    return { mutate };
};
