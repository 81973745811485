import { LoginRes } from 'models/api/auth';
import storageService from 'services/storage.service';
import { USER_STORAGE_KEY } from 'consts/storage.const';

export const getUser = (): LoginRes | undefined => {
    const user = storageService.get(USER_STORAGE_KEY);
    if (user) {
        return user;
    }
};
