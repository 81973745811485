import { FC, ReactElement, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Menu as MuiMenu } from '@mui/material';

interface IMenuBase {
    open: boolean;
    children: ReactElement[] | ReactElement | ReactNode | ReactNode[];
    onClose: () => void;
    anchorEl: ReactElement | null | HTMLElement;
    anchorOrigin?: any;
    transformOrigin?: any;
}

const MenuBase: any = styled(MuiMenu)<IMenuBase>(({ theme }) => ({
    '& .MuiPaper-root': {},
}));

const Menu: FC<IMenuBase> = ({ open, children, onClose, anchorEl, anchorOrigin, transformOrigin }) => {
    return (
        <MenuBase open={open} onClose={onClose} anchorEl={anchorEl} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
            {children}
        </MenuBase>
    );
};

export default Menu;
