import { useDownloadSettingsFiles } from 'api/queries/common';
import {
    useCreateContractorPriceList,
    useDeleteContractorPriceList,
    useGetContractorPriceLists,
    useGetContractorSimulator,
    useSetContractorPriceList,
} from 'api/queries/pricingContractor';
import { ToastType } from 'components/atoms/Toast';
import PricingContractorRanges from 'components/molecules/pricing/pricingContractor/PricingContractorRanges';
import PricingContractorSimulator from 'components/molecules/pricing/pricingContractor/PricingContractorSimulator';
import PricingHeader from 'components/molecules/pricing/PricingHeader';
import { AuthContext } from 'contexts/AuthContext';
import { IDownloadSettingsFilesFieldsReq } from 'models/api/common';
import { Item, SetContractorPriceListReq, USDExchangeRateTypes } from 'models/api/pricingContractor';
import { enqueueSnackbar } from 'notistack';
import React, { FC, useContext, useState } from 'react';
import texts from 'texts.json';
import { downloadFile } from 'utils/downloadFile';

interface IProps {}

const PricingContractor: FC<IProps> = () => {
    const { user } = useContext(AuthContext);
    const { data, refetch: getContractorPriceLists } = useGetContractorPriceLists();
    const { mutate: mutateSetContractorPriceList } = useSetContractorPriceList();
    const { mutate: mutateCreateContractorPriceList } = useCreateContractorPriceList();
    const { data: contractorSimulatorData, mutate: mutateGetContractorSimulator } = useGetContractorSimulator();
    const { mutate: mutateDeleteContractorPriceList } = useDeleteContractorPriceList();
    const { mutate: downloadSettingsFiles } = useDownloadSettingsFiles();
    const [isEdit, setIsEdit] = useState<string>('');
    const companyName = user?.user.fullname;

    const setContractorPriceList = ({ priceListId, item }: SetContractorPriceListReq) => {
        const usd_rate_type = item.usd_rate_type;
        const { usd_rate_manually, ...rest } = item;

        mutateSetContractorPriceList(
            { priceListId, item: usd_rate_type === USDExchangeRateTypes.USD_RATE_TYPE_EXCHANGE_RATE ? rest : item },
            {
                onSuccess: () => {
                    setIsEdit('');
                    enqueueSnackbar(texts.common.successText || '', {
                        variant: 'toast',
                        type: ToastType.SUCCESS,
                    });
                    getContractorPriceLists();
                },
            }
        );
    };

    const deleteContractorPriceList = (id: string) => {
        mutateDeleteContractorPriceList(id, {
            onSuccess: () => {
                enqueueSnackbar(texts.common.successText || '', {
                    variant: 'toast',
                    type: ToastType.SUCCESS,
                });
                getContractorPriceLists();
            },
        });
    };

    const createContractorPriceList = (item: Item) => {
        const usd_rate_type = item.usd_rate_type;
        const { usd_rate_manually, ...rest } = item;

        mutateCreateContractorPriceList(usd_rate_type === USDExchangeRateTypes.USD_RATE_TYPE_EXCHANGE_RATE ? rest : item, {
            onSuccess: () => {
                setIsEdit('');
                enqueueSnackbar(texts.common.successText || '', {
                    variant: 'toast',
                    type: ToastType.SUCCESS,
                });
                getContractorPriceLists();
            },
        });
    };

    const getContractorSimulator = (value: string) => {
        mutateGetContractorSimulator(value);
    };

    const downloadTechSpecifications = () => {
        downloadSettingsFiles(IDownloadSettingsFilesFieldsReq.FULL_TECHNICAL_SPECIFICATIONS, {
            onSuccess: (data) => downloadFile(data.data, `מפרט טכני ${companyName}.pdf`),
        });
    }

    return (
        <>
            <PricingHeader title={texts.pricing.pricingContractor.title} text={texts.pricing.pricingContractor.description} />
            {data?.data?.items && (
                <>
                    <PricingContractorRanges
                        items={data.data.items}
                        setContractorPriceList={setContractorPriceList}
                        createContractorPriceList={createContractorPriceList}
                        deleteContractorPriceList={deleteContractorPriceList}
                        downloadTechSpecifications={downloadTechSpecifications}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                    />
                    <PricingContractorSimulator
                        getContractorSimulator={getContractorSimulator}
                        contractorSimulatorData={contractorSimulatorData?.data.item}
                    />
                </>
            )}
        </>
    );
};

export default PricingContractor;
