import React, { FC, useState } from 'react';
import { Accordion, Box, AccordionSummary, AccordionDetails, Typography, Card, Pagination, InfoTooltip } from 'components/atoms';
import { useTheme } from '@mui/material';
import { EconomicModel } from 'models/api/pricingEntrepreneur';
import { IDataCategories } from 'models/economicModel.model';

const ITEMS_PER_PAGE = 8;
interface IProps {
    economicModel: EconomicModel[];
    title?: string;
    subTitle?: string;
    mobileLabels: IDataCategories[];
    headMobileTitles: IDataCategories[];
    isExpanded?: boolean;
    isIcon?: boolean;
    tableTotalRows?: any;
    npvRow?: any;
}

const EconomicModelTableMobile: FC<IProps> = ({
    isExpanded = false,
    economicModel,
    title,
    subTitle,
    tableTotalRows,
    mobileLabels,
    headMobileTitles,
    npvRow,
    isIcon,
}) => {
    const theme = useTheme();
    const [page, setPage] = useState(1);
    const paginatedData = economicModel?.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
    const totalPages = Math.ceil(economicModel?.length / ITEMS_PER_PAGE);
    const [expanded, setExpanded] = useState(isExpanded);

    return (
        <Box overflow='auto' height='100%'>
            <Accordion expanded={expanded} onChange={(e) => setExpanded(!expanded)}>
                <AccordionSummary>
                    <Box display={'flex'} flexDirection={'column'} rowGap={'6px'}>
                        <Typography.Body3 color={theme.palette.primary.purple}>{title}</Typography.Body3>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingBottom: 0 }}>
                    <Box display='flex' flexDirection='column' gap={10} p='0 16px 16px'>
                        {subTitle && <Typography.Body5 font={theme.fontFamilies.light}>{subTitle}</Typography.Body5>}
                        <Box display='flex' justifyContent='space-between' width='calc(100% - 20px)' px='10px'>
                            {headMobileTitles.map((item) => (
                                <Typography.Body4 key={item?.name} font={theme.fontFamilies.bold}>
                                    <Box display='flex' sx={{ maxWidth: 100, alignItems: 'baseline', columnGap: '5px' }}>
                                        {item?.title.label}
                                        <InfoTooltip
                                            firstTitle={item.title.info.firstTitle}
                                            firstText={item.title.info.firstText}
                                            secondTitle={item.title.info.secondTitle}
                                            secondText={item.title.info.secondText}
                                        />
                                    </Box>
                                </Typography.Body4>
                            ))}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
                            {paginatedData?.map((row, index) => {
                                return (
                                    <Card.GreyBorderCard key={index}>
                                        <Accordion expanded={isIcon}>
                                            <AccordionSummary isOpenSummaryStyle isIcon={isIcon}>
                                                <Box display='flex' justifyContent='space-between' width='calc(100% - 20px)'>
                                                    {headMobileTitles.map((item) => (
                                                        <Box key={item.name} display='flex' columnGap={'2px'}>
                                                            <Typography.Body5>{item?.specialChar}</Typography.Body5>
                                                            <Box sx={{ display: 'flex', direction: 'rtl' }}>
                                                                <Typography.Body5>{row[item.name as keyof typeof row]}</Typography.Body5>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ paddingBottom: 0 }}>
                                                <Box display='flex' justifyContent='space-around' p='12px'>
                                                    <Box display='flex' flexDirection='column' gap='22px'>
                                                        {mobileLabels.map((item) => (
                                                            <Typography.Body6 key={item.name} font={theme.fontFamilies.bold}>
                                                                <Box
                                                                    display='flex'
                                                                    sx={{
                                                                        maxWidth: 140,
                                                                        alignItems: 'baseline',
                                                                        columnGap: '5px',
                                                                        height: 40,
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    {item.title.label}
                                                                    <InfoTooltip
                                                                        firstTitle={item.title.info.firstTitle}
                                                                        firstText={item.title.info.firstText}
                                                                        secondTitle={item.title.info.secondTitle}
                                                                        secondText={item.title.info.secondText}
                                                                    />
                                                                </Box>
                                                            </Typography.Body6>
                                                        ))}
                                                    </Box>
                                                    <Box display='flex' flexDirection='column' gap='22px'>
                                                        {mobileLabels.map((item) => (
                                                            <Box display='flex' columnGap={'2px'} height={40}>
                                                                <Typography.Body5>{item?.specialChar}</Typography.Body5>
                                                                <Box sx={{ display: 'flex', direction: 'rtl' }}>
                                                                    <Typography.Body6 key={item.name}>
                                                                        {row[item.name as keyof typeof row]}
                                                                    </Typography.Body6>
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Card.GreyBorderCard>
                                );
                            })}
                        </Box>
                    </Box>
                    <>
                        {tableTotalRows && (
                            <Box
                                justifyContent='space-between'
                                bgcolor={theme.palette.primary.scrollbarBackground}
                                p='8px 40px'
                                display='flex'
                                gap={10}
                            >
                                <Typography.Body4>סהייכ</Typography.Body4>
                                <Typography.Body4>
                                    &#8362;{tableTotalRows?.gross_income ? tableTotalRows.gross_income : tableTotalRows.income}
                                </Typography.Body4>
                            </Box>
                        )}
                        {npvRow && (
                            <Box
                                bgcolor='primary.lightPurple'
                                p='16px 40px'
                                display='flex'
                                flexDirection='column'
                                gap={10}
                                borderRadius={`0 0 ${theme.borderRadius.large}  ${theme.borderRadius.large}`}
                            >
                                <Box display='flex' justifyContent='space-between'>
                                    <Typography.Body4>NPV</Typography.Body4>
                                    <Typography.Body4>&#8362;{npvRow?.gross_income ? npvRow.gross_income : npvRow.income}</Typography.Body4>
                                </Box>
                            </Box>
                        )}
                    </>
                </AccordionDetails>
            </Accordion>
            {expanded && (
                <Box dir='ltr' sx={{ display: 'flex', justifyContent: 'center', my: '16px' }}>
                    <Pagination count={totalPages} page={page} onChange={(e, value) => setPage(value)} />
                </Box>
            )}
        </Box>
    );
};

export default EconomicModelTableMobile;
