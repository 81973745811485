import React, { FC, Fragment } from 'react';
import { useTheme } from '@mui/material';
import { Button, Typography, Box, Divider } from 'components/atoms';
import { Orientation } from 'components/atoms/Divider';
import { IAppBarData } from 'models/AppBarData';
import { useNavigate } from 'react-router-dom';

interface IProps {
    data: IAppBarData[];
}

const RightAppBar: FC<IProps> = ({ data }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Box display='flex' height='100%' alignItems='center' gap='17px' width='350px'>
            {data.map((item, index, rightSection) => {
                return item.render ? (
                    <Fragment key={index}>
                        <Button.Text
                            onClick={() => {
                                navigate(item.link);
                            }}
                        >
                            <Typography.ThinTitle font={theme.fontFamilies.regular}>{item.title}</Typography.ThinTitle>
                        </Button.Text>
                        {index !== rightSection.length - 1 && (
                            <Box height='50%' alignSelf='start'>
                                <Divider orientation={Orientation.Vertical} />
                            </Box>
                        )}
                    </Fragment>
                ) : null;
            })}
        </Box>
    );
};

export default RightAppBar;
