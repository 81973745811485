import { isVatIncludes } from 'utils/isVatIncludes';
import { buildElectricityConnectionData, buildPurchaseEconomicModelData } from 'data/EconomicModelData';
import { GetProject } from 'models/CalculatorModel.model';
import { GetProjectRes } from 'models/api/calculator';

export const processGetCalculatorResData = (data?: GetProjectRes): GetProject => {
    if (!data) return {} as GetProject;
    const electricity_production = buildElectricityConnectionData(data?.item);
    const {
        ac_apr,
        ac_aug,
        ac_dec,
        ac_feb,
        ac_jan,
        ac_jul,
        ac_jun,
        ac_mar,
        ac_may,
        ac_nov,
        ac_oct,
        ac_sep,
        economic_model: economicModel,
        entrepreneur_economic_model: entrepreneurEconomicModel,
        vat_included: vatIncluded,
        ...rest
    } = data.item;
    const vat_included = isVatIncludes(vatIncluded);
    const economic_model = buildPurchaseEconomicModelData(economicModel);
    const entrepreneur_economic_model = buildPurchaseEconomicModelData(entrepreneurEconomicModel);
    return { ...rest, economic_model, entrepreneur_economic_model, electricity_production, vat_included };
};
