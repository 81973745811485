import { useMediaQuery, useTheme } from '@mui/material';
import { Box, Typography } from 'components/atoms';
import ReadMore from 'components/atoms/ReadMore';
import { FC } from 'react';

const headerText = ` יש להזין את המחירים המוצעים על ידכם בשדה המחיר, עד גבול מחיר מינימום או מקסימום המופיעים בסימן שאלה ליד כל שדה, שימו לב
לתיאור הטכני המפורט בסימן שאלה בצמוד ליד סוג התוספת. חלק מהתוספות יחושבו באופן אוטומטי בהצעת המחיר, ויהיו תקפים לזמן תוקף
הצעת המחיר.`;

interface IProps {
    isContractor?: boolean;
}

const Header: FC<IProps> = ({ isContractor = false }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const headerTitle = isContractor ? 'מחירון תוספות קבלני' : 'מחירון תוספות יזמי';
    return (
        <Box display='flex' flexDirection='column' alignItems='center' textAlign='center' rowGap={'18px'} mb={'22px'} whiteSpace='unset'>
            {isDesktop ? (
                <Typography.XLargeTitle color={theme.palette.primary.purple} font={theme.fontFamilies.bold}>
                    {headerTitle}
                </Typography.XLargeTitle>
            ) : (
                <Typography.Body2 color={theme.palette.primary.purple} font={theme.fontFamilies.bold}>
                    {headerTitle}
                </Typography.Body2>
            )}
            {isDesktop ? (
                <Typography.Body3 color={theme.palette.primary.black} font={theme.fontFamilies.regular}>
                    {headerText}
                </Typography.Body3>
            ) : (
                <ReadMore maxLetters={109} text={headerText}>
                    <Typography.Body4 color={theme.palette.primary.black} font={theme.fontFamilies.regular}>
                        <Box component='span' sx={{ whiteSpace: 'normal' }}>
                            {}
                        </Box>
                    </Typography.Body4>
                </ReadMore>
            )}
        </Box>
    );
};

export default Header;
