import React, { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    FormLabel,
    Icon,
    MuiTypography,
    Typography,
} from 'components/atoms';
import ManagementAccordionSummary from 'components/molecules/pricing/management/ManagementAccordionSummary';
import { useFormContext } from 'react-hook-form';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { signLabels } from 'data/ManagementData';
import ControllerTextField from 'components/organisms/ControllerTextField';
import { useTheme } from '@mui/material';
import SaveAsDraftButton from 'components/molecules/pricing/management/SaveAsDraftButton';
import { IUploadCompanyDetails } from 'models/api/common';
import ControllerUploadFile from 'components/organisms/ControllerUploadFile';
import RegulationsModal from 'components/molecules/pricing/management/RegulationsModal';
import SignCheckboxes from 'components/molecules/pricing/management/SignCheckBoxes';
import { IConfirmDetails, IRegulationsOpened } from 'models/management';
import texts from 'texts.json';
interface IProps {
    disabledForm: boolean;
    isConfirmedDetails: IConfirmDetails;
    setIsConfirmedDetails: Dispatch<SetStateAction<IConfirmDetails>>;
    onSubscriptionSectionOpen: () => void;
    handleSaveAsDraft: () => void;
}

const ManagementAccordionSign: FC<IProps> = ({
    disabledForm,
    isConfirmedDetails,
    setIsConfirmedDetails,
    onSubscriptionSectionOpen,
    handleSaveAsDraft,
}) => {
    const { payAttention } = texts.management.accordions.sign;
    const isRegulationsOpened = useRef<IRegulationsOpened>({
        regulations_text: false,
        accession_agreement_text: false,
        website_privacy_text: false,
    });
    const { control } = useFormContext();
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const [openDocumentsModal, setOpenDocumentsModal] = useState<string | null | undefined>(null);

    return (
        <Accordion unmountOnExit={false}>
            <AccordionSummary>
                <ManagementAccordionSummary char='10' label='חתימה והצהרה' />
            </AccordionSummary>
            <AccordionDetails>
                <Box px={isDesktop ? '40px' : '20px'}>
                    <Card.GreyBorderCard showBorder={isDesktop}>
                        <Box width={isDesktop ? '60%' : '100%'} p={isDesktop ? '40px' : 0}>
                            <SignCheckboxes
                                isDisabled={disabledForm}
                                isRegulationsOpened={isRegulationsOpened}
                                setOpenDocumentsModal={setOpenDocumentsModal}
                                isConfirmedDetails={isConfirmedDetails}
                                setIsConfirmedDetails={setIsConfirmedDetails}
                                onSubscriptionSectionOpen={onSubscriptionSectionOpen}
                            />
                            <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                                rowGap='15px'
                                mb={isDesktop ? '70px' : '20px'}
                                flexDirection={isDesktop ? 'row' : 'column'}
                            >
                                {signLabels.map((item) => (
                                    <Box width={isDesktop ? '185px' : '100%'} key={item.name}>
                                        <FormLabel required={true}>
                                            <Typography.Body4>{item.label}</Typography.Body4>
                                        </FormLabel>
                                        <ControllerTextField
                                            control={control}
                                            name={item.name}
                                            rules={item.rules}
                                            disabled={disabledForm}
                                        />
                                    </Box>
                                ))}
                            </Box>
                            <Box
                                display='flex'
                                alignItems={isDesktop ? 'center' : 'flex-start'}
                                gap={isDesktop ? '40px' : '15px'}
                                mb='30px'
                                flexDirection={isDesktop ? 'row' : 'column'}
                            >
                                <Button.Text
                                    onClick={() => {
                                        document.title = 'תעודת זהות חברה';
                                        window.print();
                                    }}
                                >
                                    <Box display='flex' alignItems='center' gap={10}>
                                        <Icon.Printer />
                                        <Typography.Body4>הדפסה / שמירה כ- PDF</Typography.Body4>
                                    </Box>
                                </Button.Text>
                                {!disabledForm && <SaveAsDraftButton handleSaveAsDraft={handleSaveAsDraft} />}
                            </Box>
                            <Typography.Body3>{payAttention}</Typography.Body3>
                            <Box my='10px'>
                                <MuiTypography fontFamily={theme.fontFamilies.regular} fontSize={isDesktop ? '20px' : '16px'}>
                                    <b>הורד מסמך </b>לאחר השלמת כל הנתונים והעלאת כל הקבצים, <br /> הורד את הקובץ, <b>בצע אימות</b> חתימה
                                    מול עו"ד, <b>העלה את הקובץ חתום ושלח.</b>
                                </MuiTypography>
                            </Box>
                            <Box width={isDesktop ? '276px' : '100%'} display='flex'>
                                <FormLabel required={true} />
                                <ControllerUploadFile
                                    name={IUploadCompanyDetails.ATTORNEYS_AFFIDAVIT}
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    disabled={disabledForm}
                                />
                            </Box>
                        </Box>
                    </Card.GreyBorderCard>
                </Box>
            </AccordionDetails>
            {openDocumentsModal && (
                <RegulationsModal open={!!openDocumentsModal} onClose={() => setOpenDocumentsModal(null)} text={openDocumentsModal} />
            )}
        </Accordion>
    );
};

export default ManagementAccordionSign;
