import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Loader, Paper } from 'components/atoms';
import ContentSection from 'components/molecules/ContentSection';
import { useTheme } from '@mui/material';
import texts from 'texts.json';
import { ButtonSize } from 'components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import Description from 'components/molecules/Description';
import { useSetProjectCalculator } from 'api/queries/calculator';
import ContentSectionMobileQuestionnaire from 'components/molecules/ContentSectionMobileQuestionnaire';
import { MapContext } from 'contexts/MapContext';
import { ApiResponseYesNo } from 'models/api/common';
import { AuthContext } from 'contexts/AuthContext';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { ISavedProjectAddress } from 'models/MapData';

const Funding = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();

    const { calculator, setCalculator, isIframeDone } = useContext(MapContext);
    const { user } = useContext(AuthContext);

    const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    console.log('🚀 ~ Funding ~ isIframeDone:', isIframeDone, new Date());
    console.log('🚀 ~ Funding ~ user: AUTH:', user);
    const { mutate: setProject } = useSetProjectCalculator();

    const { button, YN_buttons, descriptionTitle, descriptionText, title } = texts.calculator.stepQuestionnaire.funding;
    const { yes, no, link } = YN_buttons;
    const yesButtonBackgroundColor =
        calculator?.funding === ApiResponseYesNo.YN_ANSWER_YES ? theme.palette.primary.goldenYellow : theme.palette.primary.white;
    const noButtonBackgroundColor =
        calculator?.funding === ApiResponseYesNo.YN_ANSWER_NO ? theme.palette.primary.goldenYellow : theme.palette.primary.white;

    const onSetProject = useCallback(
        (calculator: ISavedProjectAddress) => {
            if (calculator?.projectId && calculator?.roofAccess && calculator?.funding) {
                setProject(
                    {
                        itemId: calculator.projectId,
                        roofAccess: calculator.roofAccess,
                        financingOffer: calculator.funding,
                    },
                    {
                        onSuccess: async () => {
                            setIsLoading(false);
                            navigate(link);
                        },
                    }
                );
            }
        },
        [link, navigate, setProject]
    );

    const handleChange = (financingOffer: ApiResponseYesNo) => {
        if (calculator?.projectId && calculator?.roofAccess) {
            setCalculator({ ...calculator, funding: financingOffer });
        }
    };

    const handleClick = () => {
        setIsButtonClicked(true);
        setIsLoading(true);
    };

    useEffect(() => {
        if (!!user && !!calculator?.funding && isIframeDone && isButtonClicked) {
            console.log('🚀 ~ useEffect ~ onSetProject: USER', calculator, new Date());
            onSetProject(calculator);
        }
    }, [calculator, isButtonClicked, isIframeDone, onSetProject, user]);

    if (isLoading) {
        return (
            <Box position='fixed' top={89} right={0} width={'100%'} height={'100%'} zIndex={1}>
                <Loader />
            </Box>
        );
    }

    return (
        <Box
            width='100%'
            height='100%'
            sx={{
                overflow: {
                    xs: 'hidden',
                    md: 'visible',
                },
            }}
        >
            {isDesktop ? (
                <ContentSection title={title} onClick={handleClick} buttonText={button.text} disabled={!calculator?.funding}>
                    <Box display='flex' gap='11px' width='100%' margin='0 auto'>
                        <Button.Outlined
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            size={ButtonSize.xLARGE}
                            borderRadius={theme.borderRadius.xLarge}
                            onClick={() => handleChange(ApiResponseYesNo.YN_ANSWER_YES)}
                            backgroundColor={yesButtonBackgroundColor}
                        >
                            {yes}
                        </Button.Outlined>
                        <Button.Outlined
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            size={ButtonSize.xLARGE}
                            borderRadius={theme.borderRadius.xLarge}
                            onClick={() => handleChange(ApiResponseYesNo.YN_ANSWER_NO)}
                            backgroundColor={noButtonBackgroundColor}
                        >
                            {no}
                        </Button.Outlined>
                    </Box>
                    <Box mt={'25px'} height={300} sx={{ direction: 'rtl' }}>
                        <Paper.Calculator>
                            <Description title={descriptionTitle} description={descriptionText} />
                        </Paper.Calculator>
                    </Box>
                </ContentSection>
            ) : (
                <ContentSectionMobileQuestionnaire
                    linkText={descriptionTitle}
                    title={title}
                    descriptionTitle={descriptionTitle}
                    descriptionContent={descriptionText}
                    onClick={handleClick}
                    buttonText={button.text}
                    disabled={!calculator?.funding}
                >
                    <Box display='flex' flexDirection='column' gap='12px' width='80%' m='20px auto'>
                        <Button.Outlined
                            size={ButtonSize.MEDIUM}
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            onClick={() => handleChange(ApiResponseYesNo.YN_ANSWER_YES)}
                            backgroundColor={yesButtonBackgroundColor}
                        >
                            {yes}
                        </Button.Outlined>
                        <Button.Outlined
                            size={ButtonSize.MEDIUM}
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            onClick={() => handleChange(ApiResponseYesNo.YN_ANSWER_NO)}
                            backgroundColor={noButtonBackgroundColor}
                        >
                            {no}
                        </Button.Outlined>
                    </Box>
                </ContentSectionMobileQuestionnaire>
            )}
        </Box>
    );
};

export default Funding;
