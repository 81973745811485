import { Button as MuiButton, IconButton as MuiIconButton, styled } from '@mui/material';
import { ButtonSize, IAuthButton, VariantType } from 'components/atoms/Button';
import { IButtonBase } from 'models/button';

export const ButtonContainedBase: any = styled(MuiButton, {
    shouldForwardProp: (prop: string) =>
        prop !== 'size' && prop !== 'font' && prop !== 'borderRadius' && prop !== 'backgroundColor' && prop !== 'color',
})<IButtonBase>(({ theme, size, font, borderRadius, backgroundColor, color }) => ({
    height: size ? size : ButtonSize.REGULAR,
    fontFamily: font ? font : theme.fontFamilies.regular,
    fontSize: size === ButtonSize.LARGE ? theme.fontSizes.xLarge : theme.fontSizes.large,
    backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.purple,
    color: color ? color : theme.palette.primary.white,
    borderRadius: borderRadius ? borderRadius : theme.borderRadius.regular,
    minWidth: 110,
    '&:hover': {
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.purple,
    },
    '&:disabled': {
        backgroundColor: theme.palette.primary.pinkishGrey,
    },
    [theme.breakpoints.down('md')]: {
        height: size ? size : ButtonSize.SMALL,
        fontSize: theme.fontSizes.normal,
    },
}));

export const ButtonOutlinedBase: any = styled(MuiButton, {
    shouldForwardProp: (prop: string) =>
        prop !== 'size' &&
        prop !== 'font' &&
        prop !== 'color' &&
        prop !== 'borderColor' &&
        prop !== 'borderRadius' &&
        prop !== 'backgroundColor',
})<IButtonBase>(({ theme, size, font, color, borderColor, borderRadius, backgroundColor }) => ({
    height: size ? size : ButtonSize.SMALL,
    fontFamily: font ? font : theme.fontFamilies.regular,
    fontSize: theme.fontSizes.large,
    color: color ? color : theme.palette.primary.white,
    border: `2px solid ${borderColor ? borderColor : theme.palette.primary.purple}`,
    borderRadius: borderRadius ? borderRadius : theme.borderRadius.regular,
    backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.white,
    lineHeight: 'normal',
    '&:hover': {
        border: `2px solid ${borderColor ? borderColor : theme.palette.primary.purple}`,
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.white,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: theme.fontSizes.medium,
    },
}));

export const ButtonTextBase: any = styled(MuiButton, {
    shouldForwardProp: (prop: string) => prop !== 'font' && prop !== 'backgroundColor',
})<IButtonBase>(({ theme, backgroundColor }) => ({
    width: 'auto',
    height: 'auto',
    padding: 0,
    whiteSpace: 'nowrap',
    minWidth: 0,
    '&:hover': {
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.white,
    },
}));

export const IconButtonBase = styled(MuiIconButton)<IButtonBase>(() => ({
    padding: 0,
}));

export const MobileButtonBase: any = styled(MuiButton, {
    shouldForwardProp: (prop: string) => prop !== 'color' && prop !== 'backgroundColor' && prop !== 'size' && prop !== 'justifyContent',
})<IButtonBase>(({ theme, color, backgroundColor, size, justifyContent }) => ({
    height: size ? size : ButtonSize.MEDIUM,
    backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.purple,
    color: color ? color : theme.palette.primary.white,
    fontFamily: theme.fontFamilies.regular,
    borderRadius: 0,
    fontSize: theme.fontSizes.medium,
    justifyContent: justifyContent ? justifyContent : 'center',
    lineHeight: 'normal',
    '&:hover': {
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.purple,
    },
    '&:disabled': {
        backgroundColor: theme.palette.primary.pinkishGrey,
    },
}));

export const AuthButtonBase: any = styled(MuiButton, {
    shouldForwardProp: (prop: string) => prop !== 'color',
})<IAuthButton>(({ theme, color, variant }) => ({
    '&.MuiButton-root': {
        backgroundColor: color ? color : theme.palette.primary.white,
        color: variant === VariantType.Contained ? theme.palette.primary.white : theme.palette.primary.black,
        height: ButtonSize.REGULAR,
        fontSize: theme.fontSizes.large,
        fontFamily: theme.fontFamilies.bold,
        borderRadius: theme.borderRadius.regular,
        textTransform: 'none',
        border: variant === VariantType.Contained ? 'none' : `1px solid ${theme.palette.primary.grey}`,
    },
    '&:hover': {
        backgroundColor: color ? color : theme.palette.primary.white,
    },
    '& .MuiButton-startIcon': {
        marginRight: 0,
        marginLeft: 0,
        left: 16, //left changes to right when direction is rtl
        position: 'absolute',
    },
    [theme.breakpoints.down('md')]: {
        '&.MuiButton-root': {
            height: ButtonSize.MEDIUM,
            fontSize: theme.fontSizes.medium,
        },
        '& .MuiButton-startIcon': {
            left: 10,
        },
    },
}));

export const DownArrowButtonBase = styled(MuiButton)<IButtonBase>(({ theme }) => ({
    backgroundColor: theme.palette.primary.purple,
    color: theme.palette.primary.white,
    height: ButtonSize.REGULAR,
    fontSize: theme.fontSizes.large,
    borderRadius: theme.borderRadius.regular,
    fontFamily: theme.fontFamilies.regular,
    '&:hover': {
        backgroundColor: theme.palette.primary.purple,
    },
    [theme.breakpoints.down('md')]: {
        '&.MuiButton-root': {
            height: ButtonSize.SMALL,
            fontSize: theme.fontSizes.medium,
            lineHeight: 'normal',
        },
    },
}));

export const UploadFileButtonBase = styled(ButtonOutlinedBase)<IButtonBase>(({ theme }) => ({
    backgroundColor: theme.palette.primary.white,
    borderColor: theme.palette.primary.greyBackground,
    borderRadius: theme.borderRadius.roundedSides,
    boxShadow: 'none',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
        borderColor: theme.palette.primary.greyBackground,
        boxShadow: 'none',
    },
    '& .icon-file': {
        backgroundColor: theme.palette.primary.purple,
        height: '100%',
        position: 'absolute',
        right: 0,
        width: '72px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
