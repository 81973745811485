import React, { FC } from 'react';
import { Paper as MuiPaper, styled } from '@mui/material';

interface IPaperBase {
    children: React.ReactNode;
    overflowY?: 'visible' | 'auto';
    border?: boolean;
    sx?: Object;
}

const PaperCalculatorBase = styled(MuiPaper, {
    shouldForwardProp: (prop) => prop !== 'overflowY',
})<IPaperBase>(({ theme, overflowY }) => ({
    overflowY: overflowY ? overflowY : 'auto',
    padding: '30px',
    height: '100%',
    // direction: 'rtl', - uncomment this to change position of the scrollbar
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '40px 20px',
    },
}));

const PaperCalculator: FC<IPaperBase> = ({ children, overflowY }) => {
    return (
        <PaperCalculatorBase elevation={1} overflowY={overflowY}>
            {children}
        </PaperCalculatorBase>
    );
};

const PaperWithInfoBase = styled(MuiPaper, {
    shouldForwardProp: (prop) => prop !== 'border',
})<IPaperBase>(({ theme, border }) => ({
    height: '100%',
    padding: '15px',
    borderRadius: border ? '20px' : 0,
    // direction: 'rtl', - uncomment this to change position of the scrollbar
    // textAlign: 'center',
    width: '100%',
}));

const PaperWithInfo: FC<IPaperBase> = ({ children, border = true }) => {
    return (
        <PaperWithInfoBase elevation={border ? 1 : 0} border={border}>
            {children}
        </PaperWithInfoBase>
    );
};

const PaperRegularBase = styled(MuiPaper)<IPaperBase>(({ theme }) => ({
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.grey}`,
    padding: '20px 0',
    [theme.breakpoints.down('md')]: {
        padding: '10px 0',
    },
}));

const PaperRegular: FC<IPaperBase> = ({ children, sx }) => {
    return (
        <PaperRegularBase sx={sx} elevation={0}>
            {children}
        </PaperRegularBase>
    );
};

const Paper = {
    Calculator: PaperCalculator,
    WithInfo: PaperWithInfo,
    Regular: PaperRegular,
};

export default Paper;
