import { styled, TableCell } from '@mui/material';

interface IProps {
    isTotalSection?: boolean;
}
const PurpleHeaderTableCell = styled(TableCell, {
    shouldForwardProp: (prop: string) => prop !== 'isTotalSection',
})<IProps>(({ theme, isTotalSection = false }) => ({
    fontSize: theme.fontSizes.small,
    backgroundColor: theme.palette.primary.lightPurple,
    verticalAlign: 'top',
    fontFamily: isTotalSection ? theme.fontFamilies.regular : theme.fontFamilies.bold,
    color: isTotalSection ? theme.palette.primary.main : theme.palette.primary.black,
    lineHeight: 1.2,
    minWidth: 80,
}));

const PurpleTotalTableCell = styled(TableCell, {
    shouldForwardProp: (prop: string) => prop !== 'isTotalSection',
})<IProps>(({ theme, isTotalSection = false }) => ({
    fontSize: theme.fontSizes.normal,
    backgroundColor: theme.palette.primary.lightPurple,
    verticalAlign: 'top',
    fontFamily: isTotalSection ? theme.fontFamilies.regular : theme.fontFamilies.bold,
    color: isTotalSection ? theme.palette.primary.main : theme.palette.primary.black,
    lineHeight: 1.2,
    minWidth: 80,
}));

const MainTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: theme.fontFamilies.light,
    color: theme.palette.primary.black,
    padding: 16,
    lineHeight: '30px',
    minWidth: 80,
}));

const TotalTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: theme.fontFamilies.bold,
    color: theme.palette.primary.main,
    minWidth: 80,
    padding: 16,
}));

const TableComponents = {
    PurpleHeaderCell: PurpleHeaderTableCell,
    MainCell: MainTableCell,
    TotalCell: TotalTableCell,
    TotalPurpleCell: PurpleTotalTableCell,
};

export default TableComponents;
