import { Response, PriceListStatuses } from 'models/api/common';
import { EPropertyType } from 'models/propertyType.model';

export interface GetContractorPriceListsRes extends Response {
    itemsFound: number;
    items: Item[];
}

export interface Item {
    item_id: string;
    from_power: string;
    to_power: string;
    status: PriceListStatuses;
    manpower: string;
    black_matter: string;
    electrical_equipment: string;
    roof_construction: string;
    transport_and_hoisting: string;
    panels: string;
    panels_currency: string;
    usd_rate_type: USDExchangeRateTypes;
    usd_rate_manually: USDExchangeRateTypes;
    converters_price: string;
    converters_price_calc: string;
    profitability_percentage: string;
    recommended_panel_type: string;
    panels_warranty_years: string;
    recommended_construction: string;
    converters_company: string;
    converters_warranty_years: string;
    construction_warranty_years: string;
    installation_warranty_years: string;
    advance_payment: string;
    permit_and_construction: string;
    panels_and_converters: string;
    finish_and_test: string;
    system_connection: string;
    roof_addition_concrete: string;
    roof_addition_tiles: string;
    roof_addition_insulated_panel: string;
    roof_addition_clzip: string;
    roof_addition_vaulted: string;
    roof_addition_lockable: string;
    roof_addition_asbestos: string;
    roof_addition_shingles: string;
    roof_addition_concrete_canisters: string;
    roof_addition_pvc_sealing: string;
    converters_addition: string;
    extra_installation_warranty: string;
    solar_edge_addition: string;
    solar_edge_warranty: string;
    ladder: string;
    standard_lifeline: string;
    converter_cage: string;
    ground_excavation: string;
    sand: string;
    limestone: string;
    excavation_in_asphalt: string;
    roof_sealing_sheets: string;
    roof_sealing_fix: string;
    maintenance_manual_rinses: string;
    maintenance_sprayers: string;
    maintenance_periodic_inspection: string;
    maintenance_service_call: string;
    full_maintenance: string;
    monitoring: string;
    consultation_safety: string;
    consultation_radiation: string;
    transport_addition: string;
    hoisting_addition: string;
    raising_construction: string;
    subsequent_construction: string;
    moving_small_solar_water_heater: string;
    moving_large_solar_water_heater: string;
    moving_small_airconditioner: string;
    moving_large_airconditioner: string;
    aerial_photo_order: string;
    full_maintenance_warranty: string;
    price_per_kw: string;
    property_types: EPropertyType[];
    hybrid_converter: string;
    lithium_storage_battery: string;
}

export type ItemWithoutUsdRateManually = Omit<Item, 'usd_rate_manually'>;

export enum USDExchangeRateTypes {
    USD_RATE_TYPE_EXCHANGE_RATE = 'USD_RATE_TYPE_EXCHANGE_RATE',
    USD_RATE_TYPE_MANUALLY = 'USD_RATE_TYPE_MANUALLY',
}

export enum ConvertersPriceCalcTypes {
    CONVERTERS_PRICE_CALC_KW = 'CONVERTERS_PRICE_CALC_KW',
    CONVERTERS_PRICE_CALC_PRICE_LIST = 'CONVERTERS_PRICE_CALC_PRICE_LIST',
}

export interface CreateContractorPriceListReq {
    item: Item;
}

export interface SetContractorPriceListReq {
    priceListId: string;
    item: {
        [key in keyof Item]?: string;
    };
}

export interface GetContractorSimulatorRes extends Response {
    item: GetContractorSimulatorItem;
}

export interface GetContractorSimulatorItem {
    item_id: string;
    s_system_connection: string;
    s_finish_and_test: string;
    s_panels_and_converters: string;
    s_permit_and_construction: string;
    s_converters_company: string;
    s_advance_payment: string;
    s_installation_warranty_years: string;
    s_construction_warranty_years: string;
    s_recommended_construction: string;
    s_panels_warranty_years: string;
    s_converters_warranty_years: string;
    s_recommended_panel_type: string;
    s_price_per_kw: string;
    s_total_price: string;
    s_profitability: string;
    s_profitability_percentage: string;
    s_converters_price: string;
    s_panels_calculated_price: string;
    s_transport_and_hoisting: string;
    s_roof_construction: string;
    s_electrical_equipment: string;
    s_black_matter: string;
    s_manpower: string;
}
