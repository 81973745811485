import React, { FC } from 'react';
import { Box, Rating, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';

interface IProps {
    text: string;
    rating: number;
    ratersNumber?: number;
    isAverage?: boolean;
}

const RatingCard: FC<IProps> = ({ text, rating, ratersNumber, isAverage }) => {
    const theme = useTheme();
    return (
        <Box>
            <Box display='flex' justifyContent='space-between'>
                <Typography.Body5>{text}</Typography.Body5>
                <Typography.Body1 color={isAverage ? theme.palette.primary.purple : theme.palette.primary.black}>{rating}</Typography.Body1>
            </Box>
            <Box mt='10px'>
                <Rating value={rating} />
            </Box>
            <Box>
                <Typography.Body5>({ratersNumber} דירוגים)</Typography.Body5>
            </Box>
        </Box>
    );
};

export default RatingCard;
