import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { API_ROUTES } from 'api/apiRoutes';

const ignoreQueries = [API_ROUTES.GET_COMPANY_PROJECT_IMAGES, API_ROUTES.GET_REMAINING_PROJECTS];

const ignoreMutations = [
    API_ROUTES.CREATE_PROJECT_IMAGE,
    API_ROUTES.SET_PROJECT_IMAGE,
    API_ROUTES.DELETE_PROJECT_IMAGE,
    API_ROUTES.UPLOAD_PROJECT_IMAGE,
    // API_ROUTES.CREATE_CONTACT_MESSAGE,
    // API_ROUTES.CREATE_CLIENT,
    // API_ROUTES.LOGIN,
];

const useLoading = () => {
    const isFetching = useIsFetching({
        predicate: (mutation) => !ignoreQueries.includes(mutation.options.queryKey?.[0] as string),
    });

    const isMutation = useIsMutating({
        predicate: (mutation) => !ignoreMutations.includes(mutation.options.mutationKey?.[0] as string),
    });

    return !!isFetching || !!isMutation;
};

export default useLoading;
