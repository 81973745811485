import React, { FC, Fragment, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Divider, FormLabel, Typography } from 'components/atoms';
import ManagementAccordionSummary from 'components/molecules/pricing/management/ManagementAccordionSummary';
import { useFormContext, useWatch } from 'react-hook-form';
import { companyExperienceLabels } from 'data/ManagementData';
import FormLabelWithInput from 'components/molecules/pricing/management/FormLabelWithInput';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { TextFieldType } from 'components/atoms/TextField';
import texts from 'texts.json';
interface IProps {
    disabledForm: boolean;
}

const ManagementAccordionExperience: FC<IProps> = ({ disabledForm }) => {
    const { title, yearsInTheField, numberOfProjectsMaintenance, totalOfProjectsMaintenanceKw } =
        texts.management.accordions.companyExperience;
    const { control, setValue } = useFormContext();
    const isDesktop = useIsDesktop();
    const flexDirection = isDesktop ? 'row' : 'column';
    const inputWidth = isDesktop ? '165px' : '100%';

    const companyType = useWatch({ name: 'type' });

    // set fields to 0 if companyType is not included in the type array
    useEffect(() => {
        if (companyType) {
            companyExperienceLabels.forEach((item) => {
                if (!companyType?.includes(item.type)) {
                    item.fields.forEach((field) => {
                        setValue(field.name, '0');
                    });
                    setValue(item.name + '_total_systems', '0');
                }
            });
        }
    }, [companyType, setValue]);

    return (
        <Accordion unmountOnExit={false}>
            <AccordionSummary>
                <ManagementAccordionSummary char='3' label={title} />
            </AccordionSummary>
            <AccordionDetails>
                <Box px={isDesktop ? '40px' : '20px'} display='flex' flexDirection='column' gap={isDesktop ? '50px' : '10px'}>
                    <Box
                        display='flex'
                        alignItems={isDesktop ? 'center' : 'start'}
                        flexDirection={flexDirection}
                        gap={isDesktop ? '45px' : '5px'}
                    >
                        <FormLabelWithInput
                            control={control}
                            name='years_in_the_field'
                            label={yearsInTheField}
                            placeholder='שנים'
                            rules={{ required: true }}
                            inputWidth={inputWidth}
                            disabled={disabledForm}
                            type={TextFieldType.Number}
                        />
                    </Box>
                    {!isDesktop && (
                        <Box my='20px'>
                            <Divider />
                        </Box>
                    )}
                    {companyExperienceLabels.map((item) => {
                        if (companyType?.includes(item.type)) {
                            return (
                                <Fragment key={item.name}>
                                    <Card.GreyBorderCard showBorder={isDesktop}>
                                        <Box p={isDesktop ? '40px' : ''}>
                                            <FormLabel required={true}>
                                                <Typography.Body4>{item.header}</Typography.Body4>
                                            </FormLabel>
                                            <Box
                                                mt='10px'
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='space-between'
                                                flexDirection={flexDirection}
                                            >
                                                {item.fields.map((field) => (
                                                    <Box width={isDesktop ? '165px' : '100%'} key={field.name}>
                                                        <FormLabelWithInput
                                                            type={field.type}
                                                            control={control}
                                                            name={field.name}
                                                            label={field.label}
                                                            rules={{
                                                                required: true,
                                                            }}
                                                            placeholder='מספר מערכות'
                                                            disabled={disabledForm}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                            <Box
                                                display='flex'
                                                alignItems='center'
                                                gap='20px'
                                                mt='30px'
                                                width={isDesktop ? '1000px' : '100%'}
                                                flexDirection={flexDirection}
                                            >
                                                <FormLabelWithInput
                                                    control={control}
                                                    name={`${item.name}_total_systems`}
                                                    placeholder='KWp מצטבר'
                                                    label={item.total}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    inputWidth={inputWidth}
                                                    disabled={disabledForm}
                                                    type={TextFieldType.Number}
                                                />
                                            </Box>
                                        </Box>
                                    </Card.GreyBorderCard>
                                    {!isDesktop && (
                                        <Box my='20px'>
                                            <Divider />
                                        </Box>
                                    )}
                                </Fragment>
                            );
                        } else return null;
                    })}
                    <Card.GreyBorderCard showBorder={isDesktop}>
                        <Box p={isDesktop ? '40px' : ''} display='flex' flexDirection='column' gap='30px'>
                            <Box display='flex' alignItems='center' flexDirection={flexDirection} gap='20px'>
                                <FormLabelWithInput
                                    control={control}
                                    rules={{ required: true }}
                                    name='total_systems_maintained'
                                    placeholder='מספר מערכות'
                                    label={numberOfProjectsMaintenance}
                                    inputWidth={inputWidth}
                                    disabled={disabledForm}
                                    type={TextFieldType.Number}
                                />
                            </Box>
                            <Box display='flex' alignItems='center' flexDirection={flexDirection} gap='20px'>
                                <FormLabelWithInput
                                    control={control}
                                    rules={{ required: true }}
                                    name='total_systems_maintained_kwp'
                                    placeholder='KWp מצטבר'
                                    label={totalOfProjectsMaintenanceKw}
                                    inputWidth={inputWidth}
                                    disabled={disabledForm}
                                    type={TextFieldType.Number}
                                />
                            </Box>
                        </Box>
                    </Card.GreyBorderCard>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ManagementAccordionExperience;
