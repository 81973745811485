import React, { FC, ReactNode } from 'react';
import { Box, Typography } from 'components/atoms';
import { useMediaQuery, useTheme } from '@mui/material';

interface IProps {
    icon: ReactNode;
    text?: string;
    boldText: string;
}

const BoldTextWIthIcon: FC<IProps> = ({ icon, text, boldText }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box display='flex' gap={{ md: '6px', xs: 0 }} alignItems='center' mb={{ xs: '12px', md: 0 }}>
            {isDesktop ? (
                <>
                    {icon}
                    {text && <Typography.Body5 font={theme.fontFamilies.regular}>{text}</Typography.Body5>}
                </>
            ) : (
                <Box display='flex' gap='7px' width='50%'>
                    {icon}
                    {text && <Typography.Body5 font={theme.fontFamilies.regular}>{text}</Typography.Body5>}
                </Box>
            )}
            <Typography.Body4>{boldText}</Typography.Body4>
        </Box>
    );
};

export default BoldTextWIthIcon;
