export enum ContractTypeModel {
    PURCHASE = 'PURCHASE',
    RENT = 'RENT',
}

export enum ITermsOfPayment {
    QUARTER = '64e309083cef0ff9c9089b4d',
    MONTHLY = '64e30918b7faa3dff6058555',
    YEARLY = '64e309053cef0ff9c9089b47',
}

export enum ITermsOfPaymentProduction {
    QUARTER = '654801514476e6a7ff0f5bc7',
    MONTHLY = '654801474476e6a7ff0f5bc4',
    YEARLY = '654801554476e6a7ff0f5bc9',
}

export interface IElectricityProduction {
    ac_apr: string;
    ac_aug: string;
    ac_dec: string;
    ac_feb: string;
    ac_jan: string;
    ac_jul: string;
    ac_jun: string;
    ac_mar: string;
    ac_may: string;
    ac_nov: string;
    ac_oct: string;
    ac_sep: string;
}

export interface IElectricityProductionItem {
    month: string;
    value: string;
}

export interface IElectricityProductionData {
    electricityProduction: IElectricityProductionItem[];
    annualDcGeneration: string;
}

export interface IRoofPreview {
    height: number;
    width: number;
    src: string;
}
