import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, FormLabel, Link, Typography } from 'components/atoms';
import ManagementAccordionSummary from 'components/molecules/pricing/management/ManagementAccordionSummary';
import { yesNoCheckboxes } from 'data/ManagementData';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material';
import ControllerCheckbox from 'components/molecules/pricing/ControllerCheckbox';
import { useIsDesktop } from 'hooks/useIsDesktop';
import ControllerUploadFile from 'components/organisms/ControllerUploadFile';
import { IDownloadSettingsFilesFieldsReq, IUploadCompanyDetails } from 'models/api/common';
import texts from 'texts.json';

interface IProps {
    disabledForm: boolean;
    onDownloadCompanySettingsHandler: (file: IDownloadSettingsFilesFieldsReq, fileName: string) => void;
}

const ManagementAccordionFinancialStability: FC<IProps> = ({ disabledForm, onDownloadCompanySettingsHandler }) => {
    const { control } = useFormContext();
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const {
        management: {
            accordions: {
                financialStability: { title, text, accounterApprovalFileName },
            },
        },
    } = texts;
    return (
        <Accordion unmountOnExit={false}>
            <AccordionSummary>
                <ManagementAccordionSummary char='6' label={title} />
            </AccordionSummary>
            <AccordionDetails>
                <Box px={isDesktop ? '40px' : '20px'}>
                    <Card.GreyBorderCard showBorder={isDesktop}>
                        <Box width={isDesktop ? '50%' : '100%'} p={isDesktop ? '40px' : ''}>
                            <FormLabel required={true}>{text}</FormLabel>
                            <Box display='flex' gap='40px' mt={isDesktop ? '5px' : '10px'}>
                                {yesNoCheckboxes.map((checkbox, index) => (
                                    <Box display='flex' gap='20px' key={index}>
                                        <Typography.Body4>{checkbox.label}</Typography.Body4>
                                        <ControllerCheckbox
                                            control={control}
                                            name={`annual_revenue`}
                                            checkboxValue={checkbox.value}
                                            rules={{ required: true }}
                                            disabled={disabledForm}
                                        />
                                    </Box>
                                ))}
                            </Box>
                            <Box mt='30px'>
                                <FormLabel required={true}>
                                    <Typography.Body4>אישור רו"ח</Typography.Body4>
                                </FormLabel>
                                <Box display='flex' gap='15px' flexDirection={isDesktop ? 'row' : 'column'}>
                                    <Box width={isDesktop ? '272px' : '100%'}>
                                        <ControllerUploadFile
                                            name={IUploadCompanyDetails.CPA_APPROVAL}
                                            control={control}
                                            disabled={disabledForm}
                                            rules={{ required: true }}
                                        />
                                    </Box>
                                    <Link
                                        textDecorationColor={theme.palette.primary.purple}
                                        onClick={() => {
                                            onDownloadCompanySettingsHandler(
                                                IDownloadSettingsFilesFieldsReq.CPA_APPROVAL_FORM,
                                                `${accounterApprovalFileName}.pdf`
                                            );
                                        }}
                                    >
                                        <Typography.Body4 color={theme.palette.primary.purple}>להורדת הנוסח</Typography.Body4>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Card.GreyBorderCard>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ManagementAccordionFinancialStability;
