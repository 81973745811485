import { Button, ButtonSize, Dialog, Typography } from 'components/atoms';
import { FC, useState } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import texts from 'texts.json';
import { useLocation } from 'react-router-dom';
import { IRoofPreview } from 'models/common';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    roof_preview: IRoofPreview;
}

const LegendItem = styled('div', {
    shouldForwardProp: (prop: string) => prop !== 'backgroundColor' && prop !== 'label',
})<{ backgroundColor: string }>(({ backgroundColor }) => ({
    width: 12,
    height: 12,
    backgroundColor,
}));

const ImageWrapper = styled('img')({
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '65vh',
});

const PanelsLayoutModal: FC<IProps> = ({ roof_preview }) => {
    const theme = useTheme();
    const [, entity] = useLocation().pathname.split('/');
    const isDesktop = useIsDesktop();
    const [panelsLayoutUrl, setPanelsLayoutUrl] = useState<string | null>(null);
    const isOffersPage = entity === 'offers';

    const onPanelsLayout = () => {
        setPanelsLayoutUrl('layout');
    };

    const { button, text, legend } = texts.panelsLayout;
    const { smallConditioner, bigConditioner, smallWaterHeater, bigWaterHeater, antenna, chimney } = legend;
    const { lightBlue, darkBlue, darkGrey_2, lightGrey, lightOrange, darkOrange } = theme.palette.primary;
    const legendProps = [
        { color: lightBlue, label: smallConditioner },
        { color: darkGrey_2, label: chimney },
        { color: lightOrange, label: smallWaterHeater },
        { color: darkBlue, label: bigConditioner },
        { color: lightGrey, label: antenna },
        { color: darkOrange, label: bigWaterHeater },
    ];

    return (
        <>
            {!isOffersPage ? (
                <Button.Outlined
                    font={theme.fontFamilies.regular}
                    color={theme.palette.primary.black}
                    size={ButtonSize.SMALL}
                    onClick={onPanelsLayout}
                >
                    {button}
                </Button.Outlined>
            ) : (
                <Button.Contained size={ButtonSize.LARGE} onClick={onPanelsLayout}>
                    <Typography.Body4 color={theme.palette.primary.white}> {button}</Typography.Body4>
                </Button.Contained>
            )}
            {panelsLayoutUrl && (
                <Dialog open={!!panelsLayoutUrl} isEconomicModel onClose={() => setPanelsLayoutUrl(null)} desktopWidth={600}>
                    <Box
                        display={'flex'}
                        width={isDesktop ? 'fit-content' : '100%'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'end'}
                        rowGap={'10px'}
                        p={'0 20px 20px'}
                    >
                        <Typography.Body2 color={theme.palette.primary.purple}>{button}</Typography.Body2>
                        <ImageWrapper src={roof_preview?.src} alt='Panels layout' />
                        <Box alignSelf={'flex-start'}>
                            {isDesktop ? (
                                <Typography.Body3 color={theme.palette.primary.black}>{text}</Typography.Body3>
                            ) : (
                                <Typography.Body6 font={theme.fontFamilies.bold} color={theme.palette.primary.black}>
                                    {text}
                                </Typography.Body6>
                            )}
                        </Box>
                        <Box width={{ md: '65%', xs: '100%' }} alignSelf={'flex-start'} display={'flex'} flexWrap={'wrap'}>
                            {legendProps.map(({ color, label }) => (
                                <Box display={'flex'} alignItems={'center'} columnGap={'3px'} width={95}>
                                    <LegendItem key={label} backgroundColor={color} />
                                    <Typography.Body4 font={theme.fontFamilies.regular}>{label}</Typography.Body4>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Dialog>
            )}
        </>
    );
};

export default PanelsLayoutModal;
