import { useTheme } from '@mui/material';
import { Box, Dialog } from 'components/atoms';
import HeaderWithFullscreen from 'components/molecules/pricing/pricingEntrepreneur/HeaderWithFullscreen';
import EconomicModelTable from 'components/templates/EconomicModelTable';
import EconomicModelTableMobile from 'components/templates/EconomicModelTableMobile';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC } from 'react';
import texts from 'texts.json';

interface IProps {
    economicModel: any;
    onClose(): void;
}
const EntrepreneurEconomicModel: FC<IProps> = ({ economicModel, onClose }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const { tableTitle, tableSubtitle } = texts.entrepreneurEconomicModel;

    return (
        <Dialog
            isEconomicModel
            open={!!economicModel}
            onClose={onClose}
            backgroundColor={isDesktop ? '' : theme.palette.primary.greyBackground}
            isFullScreen={!isDesktop}
        >
            {isDesktop ? (
                <Box width='490px' p={'0 40px'}>
                    <HeaderWithFullscreen title={tableTitle} subtitle={{ main: tableSubtitle }}>
                        <EconomicModelTable
                            tableTotalRows={economicModel.data.total}
                            economicModel={economicModel.data.years}
                            tableDataCategories={economicModel?.categories}
                        />
                    </HeaderWithFullscreen>
                </Box>
            ) : (
                <Box p={'20px'}>
                    <EconomicModelTableMobile
                        mobileLabels={economicModel.categories}
                        headMobileTitles={economicModel.categories}
                        isExpanded={true}
                        title={tableTitle}
                        economicModel={economicModel.data.years}
                        isIcon={false}
                        tableTotalRows={economicModel.data.total}
                    />
                </Box>
            )}
        </Dialog>
    );
};

export default EntrepreneurEconomicModel;
