import React, { FC, ReactNode } from 'react';
import { Box, InfoTooltip, Paper, Typography } from 'components/atoms';

interface IProps {
    title?: ReactNode;
    boldTitle?: ReactNode;
    icon: ReactNode;
    text?: string;
    info?: string;
}

const CardWithInfoAbout: FC<IProps> = ({ text, icon, title, boldTitle, info }) => {
    return (
        <Paper.WithInfo>
            <Box display='flex' alignItems={'baseline'} justifyContent={'space-between'} columnGap={'4px'} height={'100%'}>
                <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
                    <Box>
                        {title && <Typography.Body5>{title}</Typography.Body5>}
                        {boldTitle && title && <>&nbsp;</>}
                        {boldTitle && <Typography.Body4>{boldTitle}</Typography.Body4>}
                    </Box>
                    <Box display='flex' gap='3px'>
                        {icon}
                        {text && <Typography.Body4>{text}</Typography.Body4>}
                    </Box>
                </Box>
                <InfoTooltip firstText={info} />
            </Box>
        </Paper.WithInfo>
    );
};

export default CardWithInfoAbout;
