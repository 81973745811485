import React, { FC, ReactNode, useState } from 'react';
import { Box, Button, Typography, Icon } from 'components/atoms';
import { renderText } from 'utils/renderText';
import { useTheme } from '@mui/material';
import DescriptionWithBack from 'components/molecules/descriptionWithBack';

const sx = {
    root: {
        width: '100%',
        height: '100%',
        px: '20px',
        textAlign: 'center',
    },
    children: {
        width: '100%',
        height: '100%',
    },
};

interface IProps {
    title: string;
    children?: ReactNode;
    text?: string;
    buttonText?: string;
    disabled?: boolean;
    onClick?: () => void;
    linkText: string;
    descriptionTitle: string;
    descriptionContent: string;
}

const ContentSectionMobileQuestionnaire: FC<IProps> = ({
    children,
    title,
    text,
    buttonText,
    disabled = false,
    onClick,
    linkText,
    descriptionTitle,
    descriptionContent,
}) => {
    const theme = useTheme();
    const [showDescription, setShowDescription] = useState(false);

    return (
        <Box sx={sx.root}>
            {!showDescription ? (
                <Box>
                    <Box mb={'10px'}>
                        <Typography.Body2 font={theme.fontFamilies.xBold}>{renderText(title)}</Typography.Body2>
                    </Box>
                    {text && (
                        <Box>
                            <Typography.Body5>{renderText(text)}</Typography.Body5>
                        </Box>
                    )}
                    <Box sx={sx.children}>{children}</Box>
                    <Button.Text onClick={() => setShowDescription(true)}>
                        <Box display='flex' gap='6px'>
                            <Typography.Body5>{linkText}</Typography.Body5>
                            <Icon.Help />
                        </Box>
                    </Button.Text>
                </Box>
            ) : (
                <Box width='100%' maxHeight='100%' overflow='auto' padding={'5px'} sx={{ direction: 'rtl' }}>
                    <DescriptionWithBack
                        title={descriptionTitle}
                        content={descriptionContent}
                        onBackClick={() => setShowDescription(false)}
                    />
                </Box>
            )}
            {onClick && (
                <Box position='absolute' bottom='0' right='0' width='100%'>
                    <Button.Mobile disabled={disabled} onClick={onClick}>
                        {buttonText}
                    </Button.Mobile>
                </Box>
            )}
        </Box>
    );
};

export default ContentSectionMobileQuestionnaire;
