import React, { FC, useContext } from 'react';
import { Box, Loader } from 'components/atoms';
import { Outlet } from 'react-router-dom';
import PricingSideBar from 'components/organisms/pricing/PricingSideBar';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { AuthContext } from 'contexts/AuthContext';
import useLoading from 'hooks/useLoding.hook';
import { useGetCompanySettings } from 'api/queries/common';

const Pricing: FC = () => {
    const isDesktop = useIsDesktop();
    const { user } = useContext(AuthContext);
    const isLoading = useLoading();
    useGetCompanySettings(!!user?.user?.owner_company);

    return (
        <Box height='100%'>
            <Box display='flex' height='100%'>
                {isDesktop && (
                    <Box width='300px' className='pricing_side_bar print_hide'>
                        <PricingSideBar />
                    </Box>
                )}
                <Box width='100%'>
                    {isLoading && <Loader />}
                    <Box
                        id='scrollable'
                        className='print_hide_background'
                        bgcolor='primary.greyBackground'
                        flex={1}
                        height='100%'
                        sx={{ overflowY: 'auto' }}
                        position='relative'
                    >
                        <Box className='print_hide_margin' mx={{ md: '81px', xs: '20px' }} py={{ xs: '40px', md: '50px' }}>
                            <Outlet />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Pricing;
