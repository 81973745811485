import { ChartData } from 'chart.js';
import { Box, Chart, Dialog, Typography } from 'components/atoms';
import { FC } from 'react';
import { parseToNumber } from 'utils/parseToNumber';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { IElectricityProductionData, IElectricityProductionItem } from 'models/common';
import texts from 'texts.json';
import { replaceNewline } from 'utils/replaceNewline';

interface IProps {
    onClose(): void;
    open?: boolean;
    data: IElectricityProductionData;
}

export const chartData = (data: IElectricityProductionItem[]): ChartData<'bar', number[]> => ({
    labels: data.map((item) => item.month),
    datasets: [
        {
            data: data.map((item) => parseToNumber(item.value)),
            backgroundColor: '#fcd004',
            barThickness: 8,
        },
    ],
});

const ElectricityProductionModal: FC<IProps> = ({ open, onClose, data }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const chartLabels = { xAxisLabel: 'חודש', yAxisLabel: 'קוט"ש' };

    const { title, data: kWtH } = texts.results.systemData.annualDcGenerationField;

    const elements = (
        <>
            <Box display={'flex'} flexDirection={'column'} rowGap={'16px'}>
                <Typography.Body2 color={theme.palette.primary.purple}>הערכת ייצור חשמל שנתי</Typography.Body2>
                <Typography.Body4 color={theme.palette.primary.black} font={theme.fontFamilies.regular}>{`${replaceNewline(title)}: ${
                    data.annualDcGeneration
                } ${kWtH}`}</Typography.Body4>
            </Box>
            <Chart chartLabels={chartLabels} isLegend={false} isFullScreen data={chartData(data.electricityProduction)} />
        </>
    );
    return (
        <Dialog open={!!data} onClose={onClose} isFullScreen={!isDesktop}>
            {isDesktop ? (
                <Box display={'flex'} flexDirection={'column'} rowGap={'52px'} height={705} width={611} p='0 30px 60px 60px'>
                    {elements}
                </Box>
            ) : (
                <Box display={'flex'} flexDirection={'column'} rowGap={'52px'} height={'100%'} width={'100%'} p='0 0px 20px 28px'>
                    {elements}
                </Box>
            )}
        </Dialog>
    );
};

export default ElectricityProductionModal;
