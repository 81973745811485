import React, { ChangeEvent, FC, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import MuiSwitch from '@mui/material/Switch';

interface IProps {
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const SwitchBase = styled(MuiSwitch)(({ theme, checked }) => ({
    width: 93,
    height: 33,
    padding: 0,
    [theme.breakpoints.down('md')]: {
        width: 75,
        height: 33,
        fontSize: theme.fontSizes.small,
    },
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        color: theme.palette.primary.greyBackground,
        transform: 'translateX(63px) translateY(4.5px)',
        '& .MuiSwitch-input': {
            left: '-64px !important',
            [theme.breakpoints.down('md')]: {
                left: '-7px !important',
            },
        },
        '&.Mui-checked': {
            transform: 'translateX(6px) translateY(4.5px)',
            color: theme.palette.primary.white,
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.purple,
                opacity: 1,
            },
            '& .MuiSwitch-thumb': {
                [theme.breakpoints.down('md')]: {
                    margin: -3,
                },
            },
        },
    },
    '& .MuiSwitch-track': {
        borderRadius: theme.borderRadius.regular,
        opacity: 1,
        backgroundColor: theme.palette.primary.SwitchColor,
        '&:before, &:after': {
            color: 'white',
            fontSize: theme.fontSizes.normal,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            [theme.breakpoints.down('md')]: {
                fontSize: theme.fontSizes.small,
            },
        },
        '&:before': {
            content: !checked ? '"מושהה"' : '""',
            right: 40,
            color: theme.palette.primary.black,
            [theme.breakpoints.down('md')]: {
                right: 30,
            },
        },
        '&:after': {
            content: checked ? '"פעיל"' : '""',
            left: 45,
            [theme.breakpoints.down('md')]: {
                left: 40,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 28,
        height: 28,
        margin: -3,
        [theme.breakpoints.down('md')]: {
            margin: '-3px -3px -3px -20px',
        },
    },
    '& .MuiSwitch-input': {
        width: '93px !important',
        left: '-7px !important',
        height: '33px',
        top: '-5px',
        [theme.breakpoints.down('md')]: {
            width: '75px !important',
            right: '-7px !important',
        },
    },
}));

const Switch: FC<IProps> = ({ checked, onChange }) => {
    return <SwitchBase checked={checked} onChange={onChange} onClick={(e: MouseEvent<HTMLButtonElement>) => e.stopPropagation()} />;
};

export default Switch;
