import React, { FC } from 'react';
import { Box, Link, Typography, Title } from 'components/atoms';
import { useTheme } from '@mui/material';
import { IBuiltComparisonData } from 'models/OffersData';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    offer: IBuiltComparisonData;
    removeOfferFromCompare: (offerId: string) => void;
}

const headerData = [
    {
        title: 'תשואת פרויקט צפויה',
        name: 'result_irr',
    },
    {
        title: 'זמן החזר השקעה',
        name: 'investment_return_time',
    },
];

const OffersComparisonTableHeaderCard: FC<IProps> = ({ offer, removeOfferFromCompare }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    return (
        <Box>
            {isDesktop ? (
                <Box display='flex' gap={10} alignItems='center'>
                    <Box>
                        <Title text={`הצעה מס ${offer.offerNumber}`} color={theme.palette.primary.black} />
                    </Box>
                    <Box>
                        <Link
                            onClick={() => {
                                removeOfferFromCompare(offer.offerId);
                            }}
                            textDecorationColor={theme.palette.primary.purple}
                        >
                            <Typography.Body5 color={theme.palette.primary.purple}>הסר הצעה</Typography.Body5>
                        </Link>
                    </Box>
                </Box>
            ) : (
                <Typography.Body3 font={theme.fontFamilies.xBold}>הצעה מס {offer.offerNumber}</Typography.Body3>
            )}
            <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: '10px', md: '25px' }} alignItems='center' mt='10px'>
                {offer?.data?.header?.map((item: any, index: number) => (
                    <Box key={index}>
                        <Typography.Body5>{headerData[index].title}</Typography.Body5>
                        &nbsp;
                        <Typography.Body4>{item.value}</Typography.Body4>
                    </Box>
                ))}
            </Box>
            {!isDesktop && (
                <Box mt='5px'>
                    <Link
                        onClick={() => {
                            removeOfferFromCompare(offer.offerId);
                        }}
                        textDecorationColor={theme.palette.primary.purple}
                    >
                        <Typography.Body5 color={theme.palette.primary.purple}>הסר הצעה</Typography.Body5>
                    </Link>
                </Box>
            )}
        </Box>
    );
};

export default OffersComparisonTableHeaderCard;
