import { useIsDesktop } from 'hooks/useIsDesktop';
import { useCallback, useEffect, useState } from 'react';
// https://www.strictmode.io/articles/react-pull-to-refresh
import IconDownArrow from 'assets/icons/icon-pulldown.svg';

function addPullIndicator(el: HTMLDivElement) {
    const indicator = el?.querySelector('.pull-indicator');
    if (indicator) {
        // already difference added
        // make sure the arrow is not flipped
        if (indicator.classList.contains('flip')) {
            indicator.classList.remove('flip');
        }
        return;
    }

    const pullIndicator = document.createElement('div');
    pullIndicator.className = 'pull-indicator';
    const img = document.createElement('img');
    img.className = 'pull-indicator__img';
    img.src = IconDownArrow;
    pullIndicator.innerHTML = img.outerHTML;
    el.appendChild(pullIndicator);
}

function removePullIndicator(el: HTMLDivElement) {
    const pullIndicator = el?.querySelector('.pull-indicator');
    if (pullIndicator) {
        pullIndicator.remove();
    }
}

function flipArrow(el: HTMLDivElement) {
    const pullIndicator = el?.querySelector('.pull-indicator');
    if (pullIndicator && !pullIndicator.classList.contains('flip')) {
        pullIndicator.classList.add('flip');
    }
}

function appr(x: number) {
    const MAX = 128;
    const k = 0.4;
    return MAX * (1 - Math.exp((-k * x) / MAX));
}

const usePullToRefresh = (ref: React.RefObject<HTMLDivElement>, onTrigger: () => void) => {
    const [isScrollable, setIsScrollable] = useState(false);
    const [initialY, setInitialY] = useState<null | number>(null);
    const isDesktop = useIsDesktop();
    const TRIGGER_THRESHOLD = 100;
    const SHOW_INDICATOR_THRESHOLD = 25;

    function onScroll(e: any) {
        if (e.target.scrollTop > 0) {
            setIsScrollable(true);
        } else {
            setIsScrollable(false);
        }
    }

    const onTransitionEnd = useCallback(() => {
        const el = ref.current;
        if (!el) return;
        removePullIndicator(el.parentNode as HTMLDivElement);
        // remove transition
        el.style.transition = '';
        el.style.transform = '';
    }, [ref]);

    const handleTouchEnd = useCallback(
        (endEvent: TouchEvent) => {
            const el = ref.current;
            if (!el) return;

            // return the element to its initial position
            el.style.transform = '';
            el.style.removeProperty('transform');
            removePullIndicator(el.parentNode as HTMLDivElement);

            // add transition
            el.style.transition = 'transform 0.2s';

            if (!initialY) return;
            const y = endEvent.changedTouches[0].clientY;
            const difference = y - initialY;
            if (difference > TRIGGER_THRESHOLD) {
                onTrigger();
                // onTransitionEnd();
            }
            onTransitionEnd();
            // onTransitionEnd();
        },
        [initialY, onTransitionEnd, onTrigger, ref]
    );

    const handleTouchMove = useCallback(
        (moveEvent: TouchEvent) => {
            const el = ref.current;
            if (!el) return;

            // get the current Y position
            const currentY = moveEvent.touches[0].clientY;

            // get the difference
            if (!initialY) return;
            const difference = currentY - initialY;

            if (difference <= 0) return;

            const parentEl = el.parentNode as HTMLDivElement;

            if (difference > TRIGGER_THRESHOLD) {
                flipArrow(parentEl);
            } else if (difference > SHOW_INDICATOR_THRESHOLD) {
                addPullIndicator(parentEl);
            } else {
                removePullIndicator(parentEl);
            }

            // now we are using the `appr` function
            el.style.transform = `translateY(${appr(difference)}px)`;
        },
        [initialY, ref]
    );

    const handleTouchStart = useCallback(
        (startEvent: TouchEvent) => {
            const el = ref.current;
            if (!el) return;
            // get the initial Y position
            const initialY = startEvent.touches[0].clientY;
            setInitialY(initialY);
        },
        [ref]
    );

    useEffect(() => {
        if (isDesktop) return;

        const scrollableElement = document.getElementById('scrollable');

        if (scrollableElement) {
            scrollableElement?.addEventListener('scroll', onScroll, { passive: false });
        }

        if (isScrollable) return;

        const el = ref?.current;
        if (!el) return;

        // attach the event listener
        el.addEventListener('touchstart', handleTouchStart, { passive: false });
        el.addEventListener('touchmove', handleTouchMove);
        el.addEventListener('touchend', handleTouchEnd);

        return () => {
            el.style.transform = '';
            removePullIndicator(el.parentNode as HTMLDivElement);
            el.removeEventListener('scroll', onScroll);
            el.removeEventListener('touchstart', handleTouchStart);
            el.removeEventListener('touchmove', handleTouchMove);
            el.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handleTouchEnd, handleTouchMove, handleTouchStart, isDesktop, isScrollable, onTransitionEnd, onTrigger, ref]);
};

export default usePullToRefresh;
