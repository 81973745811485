import { useTheme } from '@mui/material';
import TextWithLink from 'components/molecules/TextWithLink';
import { Typography, Box } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import texts from 'texts.json';

const ContactUsHeader = () => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { problemText, answersToQuestions, linkText, link } = texts.contactUsModal.headerElement;

    return (
        <Box textAlign='center' display='flex' flexDirection='column' gap='8px' mt={'16px'}>
            {isDesktop ? (
                <Typography.Body2 font={theme.fontFamilies.regular}>{problemText}</Typography.Body2>
            ) : (
                <Typography.Body3 font={theme.fontFamilies.regular}>{problemText}</Typography.Body3>
            )}

            <TextWithLink
                data={{
                    text: answersToQuestions,
                    linkText: linkText,
                    onClick: () => window.open(link, '_blank', 'noopener noreferrer'),
                }}
                colorLink={theme.palette.primary.purple}
            />
        </Box>
    );
};

export default ContactUsHeader;
