import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from 'utils/envUtils';

const options = (): AxiosRequestConfig<any> => {
    return {
        baseURL: API_URL,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
};

const axiosInstance = axios.create(options());

export const axiosGet = async (api: string, config?: AxiosRequestConfig) => {
    return await axiosInstance.get(api, config);
};

export const axiosPost = async (api: string, data?: unknown, config?: AxiosRequestConfig) => {
    return await axiosInstance.post(api, data, config);
};
