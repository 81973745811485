import { useTheme } from '@mui/material';
import { Button, ButtonSize, Box } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { ContractTypeModel } from 'models/common';
import { FC, Fragment } from 'react';

const buttons = (buyOffersCount: number, rentOffersCount: number) => [
    {
        name: ContractTypeModel.PURCHASE,
        text: `הצעות לרכישה  (${buyOffersCount})`,
        onClick: () => {},
    },
    {
        name: ContractTypeModel.RENT,
        text: `הצעות לשכירות (${rentOffersCount})`,
        onClick: () => {},
    },
];

interface IProps {
    buyOffersCount?: number;
    rentOffersCount?: number;
    offersModel: ContractTypeModel;
    onClick: (model: ContractTypeModel) => void;
}

const OffersModelTypeButtons: FC<IProps> = ({ buyOffersCount = 0, rentOffersCount = 0, onClick, offersModel }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    return (
        <Box width={{ md: '490px', xs: '100%' }} display='flex' gap='12px'>
            {buttons(buyOffersCount, rentOffersCount).map((item) => (
                <Fragment key={item.name}>
                    {offersModel === ContractTypeModel[item.name] ? (
                        <Button.DownArrow onClick={() => {}}>{item.text}</Button.DownArrow>
                    ) : (
                        <Button.Outlined
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.white}
                            backgroundColor={theme.palette.primary.white}
                            size={isDesktop ? ButtonSize.REGULAR : ButtonSize.SMALL}
                            onClick={() => {
                                onClick(ContractTypeModel[item.name]);
                            }}
                        >
                            {item.text}
                        </Button.Outlined>
                    )}
                </Fragment>
            ))}
        </Box>
    );
};

export default OffersModelTypeButtons;
