import { ClickAwayListener, useTheme } from '@mui/material';
import { TOOLTIP_POSITION, Box, Typography, Icon, Tooltip } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import React, { ReactNode, useState } from 'react';
import { FC } from 'react';

interface IProps {
    firstTitle?: string;
    firstText?: string;
    secondTitle?: string;
    secondText?: string;
    fill?: string;
    isUnderline?: boolean;
}

interface ITooltip {
    placement?: TOOLTIP_POSITION;
    title: ReactNode;
    fill?: string;
}

const IconComponent: FC<{ fill?: string; onClick?: () => void }> = React.forwardRef((props, ref) => {
    return (
        <Box
            ref={ref}
            display='flex'
            sx={{
                '& :hover': {
                    cursor: 'pointer',
                },
            }}
            {...props}
        >
            <Icon.Info fill={props.fill} onClick={props?.onClick} />
        </Box>
    );
});

const DesktopTooltip: FC<ITooltip> = ({ placement, title, fill }) => {
    return (
        <Tooltip placement={placement} title={title}>
            <IconComponent fill={fill} />
        </Tooltip>
    );
};

const MobileTooltip: FC<ITooltip> = ({ title, fill }) => {
    const [open, setOpen] = useState(false);
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box>
                <Tooltip
                    onClose={() => setOpen(false)}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={title}
                >
                    <IconComponent fill={fill} onClick={() => setOpen(true)} />
                </Tooltip>
            </Box>
        </ClickAwayListener>
    );
};

const InfoTooltip: FC<IProps> = ({ firstTitle, firstText, secondTitle, secondText, fill, isUnderline = false }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const title = (
        <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='column' rowGap={'10px'} mb={'8px'}>
                {firstTitle && (
                    <Typography.Body4 font={theme.fontFamilies.bold} color={theme.palette.primary.black}>
                        {firstTitle}
                    </Typography.Body4>
                )}
                {firstText && (
                    <Typography.Body4 font={theme.fontFamilies.light} color={theme.palette.primary.black}>
                        {firstText}
                    </Typography.Body4>
                )}
            </Box>
            <Box display='flex' flexDirection='column' rowGap={'8px'}>
                {secondTitle && (
                    <Typography.Body4 font={theme.fontFamilies.bold} color={theme.palette.primary.black}>
                        {secondTitle}
                    </Typography.Body4>
                )}
                {secondText && (
                    <Typography.Body4 textUnderline={isUnderline} font={theme.fontFamilies.light} color={theme.palette.primary.black}>
                        {secondText}
                    </Typography.Body4>
                )}
            </Box>
        </Box>
    );

    return isDesktop ? (
        <DesktopTooltip placement={TOOLTIP_POSITION.LEFT} title={title} fill={fill} />
    ) : (
        <MobileTooltip title={title} fill={fill} />
    );
};

export default InfoTooltip;
