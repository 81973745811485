/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react';
import { Box } from 'components/atoms';
import ManagementHeader from 'components/molecules/pricing/management/ManagementHeader';
import ManagementAccordions from 'components/organisms/pricing/management/ManagementAccordions';
import ManagementFooter from 'components/molecules/pricing/management/ManagementFooter';
import { FieldErrorsImpl, FormProvider, useForm } from 'react-hook-form';
import texts from 'texts.json';
import {
    useCreateRecommendation,
    useDeleteRecommendation,
    useGetCompanyDetails,
    useSetRecommendation,
    useUploadCompanyDetails,
    useSetCompanyDetails,
    useCompanyRegistrationReady,
} from 'api/queries/management';
import { CompanyStatus, Item, Recommendation } from 'models/api/management';
import { isFile } from 'utils/isFile';
import { IDownloadSettingsFilesFieldsReq } from 'models/api/common';
import { enqueueSnackbar } from 'notistack';
import { ToastType } from 'components/atoms/Toast';
import { filterObject } from 'utils/filterObject';
import { isEmptyObject } from 'utils/isEmptyObject';
import { ErrorLabels } from 'data/ManagementData';
import { isSameObject } from 'utils/isSameObject';
import { downloadFile } from 'utils/downloadFile';
import { useDownloadSettingsFiles } from 'api/queries/common';
import { IConfirmDetails } from 'models/management';
import Deposition from 'assets/deposition.jpg';
import appLogo from 'assets/images/app-logo.svg';
import { uploadFile } from 'utils/uploadFile';

const initConfirmDetails = {
    privacy: false,
    joiningAgreement: false,
    confirmationOfInformation: false,
};

const Management: FC = () => {
    const { data, refetch: getCompanyDetails } = useGetCompanyDetails();
    const { mutateAsync: mutateUploadCompanyDetails } = useUploadCompanyDetails();
    const { mutateAsync: mutateCreateRecommendation } = useCreateRecommendation();
    const { mutateAsync: mutateSetRecommendation } = useSetRecommendation();
    const { mutateAsync: mutateDeleteRecommendation } = useDeleteRecommendation();
    const { mutate: onDownloadSettingsFile } = useDownloadSettingsFiles();

    const { mutateAsync: mutateSetCompanyDetails } = useSetCompanyDetails();
    const { mutateAsync: mutateCompanyRegistrationReady } = useCompanyRegistrationReady();
    const companyDetails = data?.data.item;

    const [disabledForm, setDisabledForm] = useState(false);
    const [isConfirmedDetails, setIsConfirmedDetails] = useState<IConfirmDetails>(initConfirmDetails);
    const methods = useForm({
        defaultValues: companyDetails,
        mode: 'onChange',
    });
    const {
        formState: { errors },
    } = methods;

    const [certificate_of_incorporation, authorized_dealer, cpa_approval, attorneys_affidavit, logo] = methods.watch([
        'certificate_of_incorporation',
        'authorized_dealer',
        'cpa_approval',
        'attorneys_affidavit',
        'logo',
    ]);

    const isAllFilesAttached = [certificate_of_incorporation, authorized_dealer, attorneys_affidavit, cpa_approval, logo].every(
        (file) => !!file
    );

    useEffect(() => {
        const fileInputs = [
            { field: 'certificate_of_incorporation', file: certificate_of_incorporation },
            { field: 'authorized_dealer', file: authorized_dealer },
            { field: 'cpa_approval', file: cpa_approval },
            { field: 'attorneys_affidavit', file: attorneys_affidavit },
            { field: 'logo', file: logo },
        ];
        for (const input of fileInputs) {
            if (isFile(input.file)) {
                uploadFile(undefined, input.file, input.field, mutateUploadCompanyDetails).then(() => {
                    const file = input.file as unknown as File;
                    methods.resetField(input.field as keyof Item, { defaultValue: file.name });
                });
            }
        }
    }, [
        certificate_of_incorporation,
        authorized_dealer,
        cpa_approval,
        attorneys_affidavit,
        logo,
        mutateUploadCompanyDetails,
        getCompanyDetails,
        methods,
    ]);

    useEffect(() => {
        methods.reset(companyDetails);
        if (companyDetails?.status === CompanyStatus.SETTING_UP) {
            setDisabledForm(false);
            setIsConfirmedDetails({
                privacy: false,
                joiningAgreement: false,
                confirmationOfInformation: false,
            });
        } else {
            setDisabledForm(true);
            setIsConfirmedDetails({
                privacy: true,
                joiningAgreement: true,
                confirmationOfInformation: true,
            });
        }
    }, [companyDetails, methods]);

    if (!companyDetails) return null;

    const onSubmit = async (data: Item, isReady: boolean, isDraft = false) => {
        const {
            recommendations_app,
            status,
            attorneys_affidavit,
            authorized_dealer,
            logo,
            cpa_approval,
            certificate_of_incorporation,
            ...otherData
        } = data;

        const isRegulationsApproved = isConfirmedDetails.privacy;

        const filterOtherData = filterObject(
            otherData,
            ([key, value]) => JSON.stringify(value) !== JSON.stringify(companyDetails[key as keyof Item])
        );

        if ((!disabledForm && isRegulationsApproved) || isDraft) {
            try {
                if (!isEmptyObject(filterOtherData)) {
                    await mutateSetCompanyDetails(filterOtherData);
                }
                if (!isReady) {
                    // to save recommendations in draft
                    const existRecommendations = recommendations_app.filter(({ item_id, ...recommendation }) =>
                        Object.values(recommendation).every((key) => !!key)
                    );
                    await setRecommendations(existRecommendations);
                }
                if (isReady) {
                    await setRecommendations(recommendations_app);
                    await mutateCompanyRegistrationReady();
                }
                await getCompanyDetails();
                enqueueSnackbar(texts.common.successText || '', {
                    variant: 'toast',
                    type: ToastType.SUCCESS,
                });
            } catch (err) {
                console.log(err);
            }
        } else {
            const errorText = isRegulationsApproved ? texts.management.regulationError : texts.management.editError;
            enqueueSnackbar(errorText || '', {
                variant: 'toast',
                type: ToastType.ERROR,
            });
        }
    };

    const setRecommendations = async (recommendations: Recommendation[]) => {
        const deletedRecommendations = companyDetails.recommendations_app.filter((companyRecommendation) =>
            recommendations.every((recommendation) => recommendation.item_id !== companyRecommendation.item_id)
        );

        const editedRecommendations = recommendations.filter((recommendation) => {
            if (!recommendation?.item_id || recommendation?.item_id === '') return true;
            const companyRecommendation = companyDetails.recommendations_app.find(
                (companyRecommendation) => companyRecommendation.item_id === recommendation.item_id
            );
            return !isSameObject(recommendation, companyRecommendation);
        });
        const deletePromises = deletedRecommendations.map((recommendation) => mutateDeleteRecommendation(recommendation.item_id));

        const setPromises = editedRecommendations.map((recommendation) => {
            const { item_id, ...rest } = recommendation;
            // new recommendation
            if (!recommendation?.item_id || recommendation.item_id === '') return mutateCreateRecommendation(rest);
            // edited recommendation
            return mutateSetRecommendation({ item_id, item: rest });
        });

        await Promise.all([...deletePromises, ...setPromises]);
    };

    const handleSaveAsDraft = () => {
        const data = methods.getValues();
        onSubmit(data, false, true);
    };

    const onDownloadCompanySettingsHandler = (field: IDownloadSettingsFilesFieldsReq, fileName: string) => {
        onDownloadSettingsFile(field, {
            onSuccess: (data) => downloadFile(data.data, fileName),
        });
    };

    const onError = (errors: Partial<FieldErrorsImpl<Item>>) => {
        Object.keys(errors).forEach((key) => {
            if (ErrorLabels[key as keyof typeof ErrorLabels]) {
                const errorText = ErrorLabels[key as keyof typeof ErrorLabels] + ' הוא שדה חובה!';
                enqueueSnackbar(errorText || '', {
                    variant: 'toast',
                    type: ToastType.ERROR,
                });
            }
        });
    };
    const isConfirmed = Object.values(isConfirmedDetails).every(Boolean);

    return (
        <FormProvider {...methods}>
            <Box className='print-logo' sx={{ display: 'none' }}>
                <img src={appLogo} width={450} alt='לוגו' style={{ margin: 'auto' }} />
            </Box>
            <Box
                component='form'
                onSubmit={methods.handleSubmit(
                    (data) => onSubmit(data, true),
                    (errors) => onError(errors)
                )}
            >
                <ManagementHeader handleSaveAsDraft={handleSaveAsDraft} disabledForm={disabledForm} />
                <ManagementAccordions
                    disabledForm={disabledForm}
                    isConfirmedDetails={isConfirmedDetails}
                    setIsConfirmedDetails={setIsConfirmedDetails}
                    onDownloadCompanySettingsHandler={onDownloadCompanySettingsHandler}
                    handleSaveAsDraft={handleSaveAsDraft}
                />
                <Box className='print_hide'>
                    <ManagementFooter
                        handleSaveAsDraft={handleSaveAsDraft}
                        disabled={disabledForm || !isConfirmed || Object.keys(errors).length > 0 || !isAllFilesAttached}
                    />
                </Box>
            </Box>
            <Box className='print-deposition' sx={{ display: 'none', marginTop: 40 }}>
                <img width={'auto'} height={'100%'} src={Deposition} alt='' style={{ margin: 'auto' }} />
            </Box>
        </FormProvider>
    );
};

export default Management;
