import React, { FC } from 'react';
import { Box, Button, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import SaveAsDraftButton from 'components/molecules/pricing/management/SaveAsDraftButton';
import { ButtonSize, ButtonType } from 'components/atoms/Button';
import { useIsDesktop } from 'hooks/useIsDesktop';
import texts from 'texts.json';

interface IProps {
    handleSaveAsDraft: () => void;
    disabled: boolean;
}

const ManagementFooter: FC<IProps> = ({ handleSaveAsDraft, disabled }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const footerText = texts.management.footer;

    return (
        <Box mt={{ md: '40px', xs: '10px' }} width={{ md: '100%', xs: '80%' }} mx='auto' className='footer'>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                gap={{ md: '27px', xs: '10px' }}
                mb='10px'
                flexDirection={{
                    xs: 'column',
                    md: 'row',
                }}
            >
                <Box width={{ md: '205px', xs: '80%' }}>
                    <Button.Contained size={ButtonSize.MEDIUM} disabled={disabled} type={ButtonType.SUBMIT}>
                        <Typography.Body2 color={theme.palette.primary.white}>סיום ושליחה</Typography.Body2>
                    </Button.Contained>
                </Box>
                <Box>
                    <SaveAsDraftButton handleSaveAsDraft={handleSaveAsDraft} />
                </Box>
            </Box>
            <Box textAlign='center'>
                {isDesktop ? (
                    <Typography.Body2 color={theme.palette.primary.purple} font={theme.fontFamilies.regular}>
                        {footerText}
                    </Typography.Body2>
                ) : (
                    <Typography.Body5 color={theme.palette.primary.purple}>{footerText}</Typography.Body5>
                )}
            </Box>
        </Box>
    );
};

export default ManagementFooter;
