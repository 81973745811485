import React, { FC } from 'react';
import { Box, Button, Typography } from 'components/atoms';
import { renderText } from 'utils/renderText';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import CalculatorVideoSection from 'components/molecules/CalculatorVideoSection';

const sx = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
    },
    children: {
        width: '100%',
    },
};

interface IProps {
    title: string;
    children?: React.ReactNode;
    text?: string;
    media?: string;
    buttonText?: string;
    disabled?: boolean;
    onClick?: () => void;
}

const ContentSection: FC<IProps> = ({ children, title, text, media, buttonText, disabled = false, onClick }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    return (
        <Box sx={sx.root}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Box mb={'16px'}>
                    <Typography.Body1 font={theme.fontFamilies.xBold}>{renderText(title)}</Typography.Body1>
                </Box>
                {text && (
                    <Box>
                        <Typography.Body2>{renderText(text)}</Typography.Body2>
                    </Box>
                )}
                {children && (
                    <Box mt={'20px'} sx={sx.children}>
                        {children}
                    </Box>
                )}
                {media && <CalculatorVideoSection src={media} />}
            </Box>
            {onClick && (
                <Box mb={{ md: '76px', xs: 0 }} mt={{ md: '30px' }} width={{ md: '100%', xs: 0 }}>
                    {isDesktop ? (
                        <Button.Contained disabled={disabled} onClick={onClick}>
                            {buttonText}
                        </Button.Contained>
                    ) : (
                        <Box position='absolute' bottom='0' right='0' width='100%'>
                            <Button.Mobile disabled={disabled} onClick={onClick}>
                                {buttonText}
                            </Button.Mobile>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ContentSection;
