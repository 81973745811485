import React, { FC, useEffect, useState } from 'react';
import { Box, Dialog } from 'components/atoms';
import PricingSimulatorHeader from 'components/molecules/pricing/PricingSimulatorHeader';
import { useIsDesktop } from 'hooks/useIsDesktop';
import PricingContractorSimulatorDetails from 'components/molecules/pricing/pricingContractor/PricingContractorSimulatorDetails';
import { GetContractorSimulatorItem } from 'models/api/pricingContractor';
import texts from 'texts.json';
interface IProps {
    getContractorSimulator: (value: string) => void;
    contractorSimulatorData?: GetContractorSimulatorItem;
}

const PricingContractorSimulator: FC<IProps> = ({ getContractorSimulator, contractorSimulatorData }) => {
    const { title } = texts.pricing.pricingContractor.simulator;
    const [open, setOpen] = useState<boolean>(false);
    const isDesktop = useIsDesktop();

    const calculate = (value: any) => {
        getContractorSimulator(value?.toString() || '');
    };

    useEffect(() => {
        if (contractorSimulatorData) {
            setOpen(true);
        }
    }, [contractorSimulatorData]);

    return (
        <Box mx={{ md: 'auto' }} bgcolor='primary.white' borderRadius={(theme) => theme.borderRadius.large} mb='140px' overflow='auto'>
            <Box position={isDesktop ? 'static' : 'fixed'} bottom={0} right={0} width='100%'>
                <PricingSimulatorHeader
                    title={title}
                    buttonText='בדיקה'
                    placeholder='DC הקלידו הספק פנלים'
                    open={open}
                    calculate={calculate}
                />
            </Box>
            {open && contractorSimulatorData && (
                <Box p={{ md: '50px 0 60px 30px' }}>
                    {isDesktop ? (
                        <>
                            <PricingContractorSimulatorDetails contractorSimulatorData={contractorSimulatorData} />
                        </>
                    ) : (
                        <Dialog open={open} onClose={() => setOpen(false)} isFullScreen={!isDesktop}>
                            <Box bgcolor='primary.greyBackground' height='100%'>
                                <Box display='flex' flexDirection='column' gap={20} p='20px' bgcolor='primary.greyBackground'>
                                    <Box mx={{ xs: 'auto', md: 0 }} width={'100%'}>
                                        {<PricingContractorSimulatorDetails contractorSimulatorData={contractorSimulatorData} />}
                                    </Box>
                                </Box>
                            </Box>
                        </Dialog>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PricingContractorSimulator;
