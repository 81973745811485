import React, { FC, Fragment } from 'react';
import { Box, Button, Card, Icon, Link, Typography, Divider } from 'components/atoms';
import { useFormContext } from 'react-hook-form';
import { useIsDesktop } from 'hooks/useIsDesktop';
import EditButtonSection from 'components/molecules/pricing/EditButtonSection';
import { useTheme } from '@mui/material';
import { ButtonSize } from 'components/atoms/Button';
import RangeTitle from 'components/molecules/pricing/pricingEntrepreneur/RangeTitle';
import PricingContractorAccordionDetailsPaymentTerms from 'components/molecules/pricing/pricingContractor/PricingContractorAccordionDetailsPaymentTerms';
import PricingContractorAccordionDetailsMobile from 'components/molecules/pricing/pricingContractor/PricingContractorAccordionDetailsMobile';
import PricingContractorAccordionDetailsCosts from 'components/molecules/pricing/pricingContractor/PricingContractorAccordionDetailsCosts';
import PricingContractorAccordionEquipmentAndWarranty from 'components/molecules/pricing/pricingContractor/PricingContractorAccordionEquipmentAndWarranty';
import { IPricingPanelData } from 'components/molecules/pricing/pricingContractor/PricingContractorRanges';
import { PricingContractorRangesData } from 'models/PricingData';
import PricingCopyPastePanel from 'components/molecules/pricing/PricingCopyPastePanel';
import texts from 'texts.json';
import PricingPropertyType from 'components/molecules/pricing/PricingPropertyType';

interface IProps {
    data: PricingContractorRangesData;
    isEdit: boolean;
    showRange?: boolean;
    fieldIndex: number;
    isItemToDelete: boolean;
    setPricingPanelData: ({ data, fieldIndex }: IPricingPanelData) => void;
    downloadTechSpecifications: () => void;
    setIsEdit: (value: string) => void;
    removeRange: (id: string) => void;
    updateRange: (rangeId: string) => void;
    onCopy?: () => void;
    onPaste?: () => void;
    onDuplicate?: () => void;
    handleUpdateRange: (id: string) => void;
}

const PricingContractorAccordionDetails: FC<IProps> = ({
    data,
    removeRange,
    isEdit,
    setIsEdit,
    showRange,
    fieldIndex,
    updateRange,
    setPricingPanelData,
    downloadTechSpecifications,
    isItemToDelete,
    onCopy,
    onPaste,
    onDuplicate,
    handleUpdateRange,
}) => {
    const { costTitle, equipmentTitle, paymentTitle } = texts.pricing.pricingContractor.range;
    const { control, reset } = useFormContext();
    const isDesktop = useIsDesktop();
    const theme = useTheme();

    const handleSave = () => {
        handleUpdateRange(data.id);
    };

    const accordionElements = [
        {
            title: costTitle,
            component: (
                <PricingContractorAccordionDetailsCosts data={data.costs} fieldIndex={fieldIndex} control={control} isEdit={isEdit} />
            ),
        },
        {
            title: equipmentTitle,
            component: (
                <PricingContractorAccordionEquipmentAndWarranty
                    data={data.equipmentAndWarranty}
                    fieldIndex={fieldIndex}
                    control={control}
                    isEdit={isEdit}
                />
            ),
        },
        {
            title: paymentTitle,
            component: (
                <PricingContractorAccordionDetailsPaymentTerms
                    data={data.paymentTerms}
                    fieldIndex={fieldIndex}
                    control={control}
                    isEdit={isEdit}
                />
            ),
        },
    ];
    return (
        <>
            {!isDesktop && (
                <Box sx={{ position: 'absolute', right: 80, top: 30, display: 'flex', gap: '10px' }}>
                    <PricingCopyPastePanel onCopy={onCopy} onDuplicate={onDuplicate} onPaste={onPaste} />
                </Box>
            )}
            <Box mb='25px'>
                <Box display='flex' alignItems='center' gap='30px' justifyContent='end'>
                    <EditButtonSection
                        dataId={data.id}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        onSave={handleSave}
                        onCancel={() => {
                            reset();
                            setIsEdit('');
                        }}
                    />
                    {isItemToDelete && (
                        <Box>
                            {isDesktop ? (
                                <Link
                                    textDecorationColor={theme.palette.primary.purple}
                                    onClick={() => {
                                        removeRange(data.id);
                                    }}
                                >
                                    <Typography.Body4 color={theme.palette.primary.purple}>מחיקת טווח</Typography.Body4>
                                </Link>
                            ) : (
                                <Button.Icon
                                    onClick={() => {
                                        removeRange(data.id);
                                    }}
                                >
                                    <Icon.Trash />
                                </Button.Icon>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {showRange && (
                <Box p='10px 0 20px 0' display='flex' justifyContent='center' alignItems='center'>
                    <RangeTitle size='regular' data={data} isEdit={isEdit} fieldIndex={fieldIndex} />
                </Box>
            )}
            <PricingPropertyType isEdit={isEdit} index={fieldIndex} />
            {isDesktop ? (
                <Card.GreyBorderCard>
                    {accordionElements.map((element, index) => (
                        <Fragment key={index}>
                            {index !== 0 && (
                                <Box width='95%' m='45px auto'>
                                    <Divider />
                                </Box>
                            )}
                            <Box>
                                <Box p='15px'>
                                    <Typography.Body2 color={theme.palette.primary.purple}>{element.title}</Typography.Body2>
                                </Box>
                                <Box>{element.component}</Box>
                            </Box>
                        </Fragment>
                    ))}
                </Card.GreyBorderCard>
            ) : (
                <Box display='flex' flexDirection='column' gap={10}>
                    {accordionElements.map((element, index) => (
                        <PricingContractorAccordionDetailsMobile key={index} title={element.title}>
                            {element.component}
                        </PricingContractorAccordionDetailsMobile>
                    ))}
                </Box>
            )}
            <Box
                display='flex'
                flexDirection={isDesktop ? 'row' : 'column'}
                gap='11px'
                p={{ xs: '30px 0 30px 0', md: '20px 0 0 0' }}
                width={{ xs: '100%', md: '290px' }}
            >
                <Button.Contained
                    onClick={() => {
                        setPricingPanelData({
                            fieldIndex,
                            data,
                        });
                    }}
                    size={isDesktop ? ButtonSize.xSmall : ButtonSize.MEDIUM}
                >
                    <Typography.Body4 color={theme.palette.primary.white}>מחירון תוספות</Typography.Body4>
                </Button.Contained>
                <Button.Contained onClick={downloadTechSpecifications} size={isDesktop ? ButtonSize.xSmall : ButtonSize.MEDIUM}>
                    <Typography.Body4 color={theme.palette.primary.white}>מפרט טכני מלא</Typography.Body4>
                </Button.Contained>
            </Box>
        </>
    );
};

export default PricingContractorAccordionDetails;
