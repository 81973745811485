import { TableContainer, Paper, Table, TableHead, TableRow, TableBody, useTheme } from '@mui/material';
import { TableComponents, Box, Typography, InfoTooltip, Loader } from 'components/atoms';
import { TextFieldSize, TextFieldType } from 'components/atoms/TextField';
import { IAddOnPricingPanelData } from 'components/molecules/addOnPricingPanel/AddOnPricingPanel';
import ControllerTextField from 'components/organisms/ControllerTextField';
import { useIsDesktop } from 'hooks/useIsDesktop';
import useLoading from 'hooks/useLoding.hook';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { Symbols } from 'utils/symbols';

interface IProps {
    data: IAddOnPricingPanelData | null;
    isEdit: boolean;
    control: Control;
}

const tableHeaderTitles = ['סוג תוספת', 'תמחור לפי', `מחיר ${Symbols.SHEKEL}`];
const mobileHeaderTitles = tableHeaderTitles.filter((title, index) => index !== 1);

const AddOnPricingTable: FC<IProps> = ({ data, isEdit, control }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const headerTitles = isDesktop ? tableHeaderTitles : mobileHeaderTitles;
    const isLoading = useLoading();

    if (isLoading) return <Loader />;

    return (
        <TableContainer component={Paper} sx={{ borderTop: `3px solid ${theme.palette.primary.goldenYellow}` }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {headerTitles.map((title) => (
                            <TableComponents.PurpleHeaderCell key={title}>{title}</TableComponents.PurpleHeaderCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.data.map((row, index: number) => (
                        <TableRow key={row.name}>
                            {isDesktop ? (
                                <>
                                    <TableComponents.MainCell>
                                        <Typography.Body4 color={theme.palette.primary.black} font={theme.fontFamilies.bold}>
                                            {row.title.label}
                                        </Typography.Body4>
                                    </TableComponents.MainCell>
                                    <TableComponents.MainCell>{row.title.pricePer}</TableComponents.MainCell>
                                </>
                            ) : (
                                <TableComponents.MainCell>
                                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                                        <Box display='flex' flexDirection='column'>
                                            <Typography.Body4 color={theme.palette.primary.black} font={theme.fontFamilies.bold}>
                                                {row.title.label}
                                            </Typography.Body4>
                                            <span>{row.title.pricePer}</span>
                                        </Box>
                                        <InfoTooltip
                                            firstTitle={row?.title?.info?.firstTitle}
                                            firstText={row?.title?.info?.firstText}
                                            secondTitle={row?.title?.info?.secondTitle}
                                            secondText={row?.title?.info?.secondText}
                                        />
                                    </Box>
                                </TableComponents.MainCell>
                            )}

                            <TableComponents.MainCell width={'30%'}>
                                <Box display='flex' justifyContent='space-between' gap='10px'>
                                    <Box display='flex' alignItems='center'>
                                        <Box>
                                            {isEdit ? (
                                                <ControllerTextField
                                                    rules={row.rules}
                                                    control={control}
                                                    name={`rangesData.${data.fieldIndex}.additions.${row.name}`}
                                                    size={TextFieldSize.XSmall}
                                                    type={TextFieldType.Number}
                                                />
                                            ) : (
                                                <span>{row.value}</span>
                                            )}
                                        </Box>
                                    </Box>
                                    {isDesktop && (
                                        <InfoTooltip
                                            firstTitle={row?.title?.info?.firstTitle}
                                            firstText={row?.title?.info?.firstText}
                                            secondTitle={row?.title?.info?.secondTitle}
                                            secondText={row?.title?.info?.secondText}
                                        />
                                    )}
                                </Box>
                            </TableComponents.MainCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AddOnPricingTable;
