import { useTheme } from '@mui/material';
import { Box, Typography } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC } from 'react';
import texts from 'texts.json';

interface IProps {
    name: string;
    isClient?: boolean;
}

const Title: FC<IProps> = ({ name, isClient }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { yourProperties, yourQuotations } = texts.myOffers;
    const text = isClient ? yourProperties : yourQuotations;
    const title = `${name}${text}`;
    return (
        <Box width={'fit-content'} mb={'20px'}>
            {isDesktop ? (
                <Typography.Body1 color={theme.palette.primary.purple}>{title}</Typography.Body1>
            ) : (
                <Typography.Body2 color={theme.palette.primary.purple}>{title}</Typography.Body2>
            )}
        </Box>
    );
};

export default Title;
