import React, { FC } from 'react';
import RenderResultData from 'components/molecules/RenderResultData';
import { Grid, Paper } from 'components/atoms';
import texts from 'texts.json';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { replaceNewline } from 'utils/replaceNewline';

interface IProps {
    power?: string;
    panels_number?: string;
    annual_dc_generation?: string;
    light_hours?: string;
    vat_included?: boolean;
}

const SystemData: FC<IProps> = ({ annual_dc_generation, panels_number, power, light_hours, vat_included }) => {
    const {
        results: { systemData: systemDataTexts },
        greenEnergyRoute: { title, info },
    } = texts;
    const isDesktop = useIsDesktop();

    const systemDataArray = [
        {
            title: isDesktop ? replaceNewline(systemDataTexts.powerField.title) : systemDataTexts.powerField.title,
            result: `${power} ${systemDataTexts.powerField.data}`,
            info: systemDataTexts.powerField.info,
            greenRoute: vat_included ? { title, info } : null,
        },
        {
            title: isDesktop ? replaceNewline(systemDataTexts.panelsNumberField.title) : systemDataTexts.panelsNumberField.title,
            result: `${systemDataTexts.panelsNumberField.data} ${panels_number} ${systemDataTexts.panelsNumberField.data2}`,
            info: systemDataTexts.panelsNumberField.info,
        },
        {
            title: isDesktop
                ? replaceNewline(systemDataTexts.annualDcGenerationField.title)
                : systemDataTexts.annualDcGenerationField.title,
            result: `${annual_dc_generation} ${systemDataTexts.annualDcGenerationField.data}`,
            info: systemDataTexts.annualDcGenerationField.info,
        },
        {
            title: isDesktop ? replaceNewline(systemDataTexts.lightHoursField.title) : systemDataTexts.lightHoursField.title,
            result: `${light_hours} ${systemDataTexts.lightHoursField.data}`,
            info: systemDataTexts.lightHoursField.info,
        },
    ];

    return (
        <>
            {systemDataArray.map((item) => (
                <Grid item key={item.title} xs={6} md={3} height={isDesktop ? '112px' : 'auto'}>
                    <Paper.WithInfo border>
                        <RenderResultData greenRoute={item.greenRoute} firstText={item.info} title={item.title} result={item.result} />
                    </Paper.WithInfo>
                </Grid>
            ))}
        </>
    );
};

export default SystemData;
