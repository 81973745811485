import { CssBaseline } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'api/apiClient';
import SnackBarProvider from 'components/atoms/SnackBarProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import router from 'Router';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <SnackBarProvider>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                <CssBaseline />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </SnackBarProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
