import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Checkbox, FormLabel, InfoTooltip, Typography } from 'components/atoms';
import ManagementAccordionSummary from 'components/molecules/pricing/management/ManagementAccordionSummary';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { personalDetailsLabels } from 'data/ManagementData';
import ControllerTextField from 'components/organisms/ControllerTextField';
import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldSize } from 'components/atoms/TextField';
import { useTheme } from '@mui/material';
import ControllerUploadFile from 'components/organisms/ControllerUploadFile';
import texts from 'texts.json';
interface IProps {
    disabledForm: boolean;
}

const ManagementAccordionPersonalDetails: FC<IProps> = ({ disabledForm }) => {
    const { title, companyInterestedToSubscribe } = texts.management.accordions.personalDetails;
    const isDesktop = useIsDesktop();
    const { control } = useFormContext();
    const theme = useTheme();
    const { above, left, files, right, bottom } = personalDetailsLabels();
    const flexDirection = isDesktop ? 'row' : 'column';
    const gap = isDesktop ? 0 : '15px';

    const renderFormLabelWithInput = (item: any) => (
        <>
            <FormLabel required={item.rules.required}>
                <Typography.Body4>{item.label}</Typography.Body4>
            </FormLabel>
            <ControllerTextField control={control} name={item.name} rules={item.rules} disabled={disabledForm} />
        </>
    );

    return (
        <Accordion unmountOnExit={false}>
            <AccordionSummary>
                <ManagementAccordionSummary char='2' label={title} />
            </AccordionSummary>
            <AccordionDetails>
                <Box px={isDesktop ? '40px' : '20px'}>
                    <Card.GreyBorderCard showBorder={isDesktop}>
                        <Box p={isDesktop ? '40px' : ''}>
                            <Box display='flex' justifyContent='space-between' flexDirection={flexDirection} gap={gap}>
                                {above.map((item, index) => (
                                    <Box key={index} width={isDesktop ? '170px' : '100%'}>
                                        {renderFormLabelWithInput(item)}
                                    </Box>
                                ))}
                            </Box>
                            <Box display='flex' flexDirection={flexDirection} justifyContent='space-between'>
                                <Box width={isDesktop ? '57%' : '100%'}>
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                        my={isDesktop ? '40px' : '15px'}
                                        flexDirection={flexDirection}
                                        gap={gap}
                                    >
                                        {right.map((item, index) => (
                                            <Box key={index} width={isDesktop ? '170px' : '100%'}>
                                                {renderFormLabelWithInput(item)}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box width={isDesktop ? '35.2%' : '100%'} mt={isDesktop ? '40px' : '15px'} order={isDesktop ? 2 : 1}>
                                    <Box width='100%'>
                                        <Box display={'flex'} columnGap={'8px'}>
                                            <FormLabel required={!!left.rules.required}>
                                                <Typography.Body4>{left.label}</Typography.Body4>
                                            </FormLabel>
                                            <InfoTooltip firstText={left.info} />
                                        </Box>
                                        <ControllerTextField
                                            control={control}
                                            name={left.name}
                                            size={TextFieldSize.xLarge}
                                            multiline={true}
                                            rows={4}
                                            rules={left.rules}
                                            disabled={disabledForm}
                                            maxLength={260}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                mt={isDesktop ? '40px' : 'unset'}
                                display='flex'
                                justifyContent='space-between'
                                flexDirection={flexDirection}
                                gap={isDesktop ? '80px' : '15px'}
                            >
                                {files.map((item, index) => (
                                    <Box key={index} width='100%'>
                                        <FormLabel required={!!item.rules.required}>
                                            <Typography.Body4>{item.label}</Typography.Body4>
                                        </FormLabel>
                                        <ControllerUploadFile
                                            control={control}
                                            name={item.name}
                                            rules={item.rules}
                                            disabled={disabledForm}
                                            accept={item.accept}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Card.GreyBorderCard>
                    <Box mt='20px' display='flex' flexDirection={flexDirection} alignItems='center'>
                        <FormLabel required={true}>
                            {isDesktop ? (
                                <Typography.Body1 font={theme.fontFamilies.regular}>{companyInterestedToSubscribe}</Typography.Body1>
                            ) : (
                                <Typography.Body3 font={theme.fontFamilies.regular}>{companyInterestedToSubscribe}</Typography.Body3>
                            )}
                        </FormLabel>
                        &nbsp;
                        <Box display='flex' gap='30px'>
                            {bottom.map((item, index) => (
                                <Box display='flex' alignItems='center' justifyContent='center' gap={10} key={index}>
                                    <FormLabel>
                                        {isDesktop ? (
                                            <Typography.Body1 font={theme.fontFamilies.bold}>{item.label}</Typography.Body1>
                                        ) : (
                                            <Typography.Body3 font={theme.fontFamilies.bold}>{item.label} </Typography.Body3>
                                        )}
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        name={item.name}
                                        render={({ field }) => {
                                            return (
                                                <Checkbox
                                                    checked={field.value?.includes?.(item.value) || false}
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                                ? [...field.value, item.value]
                                                                : field.value.filter((companyType: string) => companyType !== item.value)
                                                        )
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ManagementAccordionPersonalDetails;
