import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from 'components/atoms';
import ManagementAccordionSummary from 'components/molecules/pricing/management/ManagementAccordionSummary';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import texts from 'texts.json';
interface IProps {}

const ManagementAccordionInstructions: FC<IProps> = () => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { title, text, boldText, payAttention, list, goodLuck } = texts.management.accordions.instructions;

    const getTitle = (text: string, bold = false) => {
        const isBold = bold ? theme.fontFamilies.bold : theme.fontFamilies.regular;
        return isDesktop ? (
            <Typography.Body2 font={isBold}>{text}</Typography.Body2>
        ) : (
            <Typography.Body4 font={isBold}>{text}</Typography.Body4>
        );
    };

    return (
        <Accordion unmountOnExit={false}>
            <AccordionSummary>
                <ManagementAccordionSummary char='1' label={title} />
            </AccordionSummary>
            <AccordionDetails>
                <Box width={isDesktop ? '67%' : '100%'} px={isDesktop ? '40px' : '20px'}>
                    {getTitle(text)}
                    <br />
                    {getTitle(boldText, true)}
                    <Box my={'12px'}>{getTitle(payAttention)}</Box>
                    <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                        {Object.values(list).map((item, index) => (
                            <Box key={index}>{getTitle(item, false)}</Box>
                        ))}
                    </Box>
                    {getTitle(goodLuck)}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ManagementAccordionInstructions;
