import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { Box, Button, Typography } from 'components/atoms';
import { IAppBarData } from 'models/AppBarData';
// import { useNavigate } from 'react-router-dom';

interface IProps {
    data: IAppBarData[];
    isCalculatorButtonDisabled: boolean;
    onNavigateToCalculator: () => void;
}

const LeftSideBar: FC<IProps> = ({ data, onNavigateToCalculator, isCalculatorButtonDisabled }) => {
    const theme = useTheme();
    // const navigate = useNavigate();
    const [calculator, login] = data;

    return (
        <Box display='flex' height='100%' alignItems='center' gap='40px'>
            {/* <Button.Text onClick={() => navigate(guide.link)}>
                {guide.icon}
                &nbsp;
                <Typography.ThinTitle font={theme.fontFamilies.regular}>{guide.title}</Typography.ThinTitle>
            </Button.Text> */}
            <Box width={181}>
                <Button.Outlined
                    disabled={isCalculatorButtonDisabled}
                    color={theme.palette.primary.black}
                    onClick={onNavigateToCalculator}
                    borderRadius={theme.borderRadius.roundedSides}
                >
                    {calculator.title}
                </Button.Outlined>
            </Box>
            <Button.Text onClick={login.onClick}>
                {login.icon}
                &nbsp;
                <Typography.ThinTitle font={theme.fontFamilies.regular}>{login.title}</Typography.ThinTitle>
            </Button.Text>
        </Box>
    );
};

export default LeftSideBar;
