import React, { FC } from 'react';
import { Box, Loader } from 'components/atoms';

interface IProps {
    mapLoading: boolean;
}

const Map: FC<IProps> = ({ mapLoading }) => {
    return (
        <Box height='100%'>
            {mapLoading && <Loader />}
            <Box height='100%' width='100%' id='map'></Box>
        </Box>
    );
};

export default Map;
