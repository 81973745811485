import { Box, Map } from 'components/atoms';
import RoofIframe from 'components/molecules/RoofIframe';
import TipPopUP from 'components/molecules/TipPopUp';
import { FC } from 'react';
import { MOBILE_BUTTON } from 'theme/sizes';

interface IProps {
    activeStep: number;
    mapLoading: boolean;
}

const disabledIframeSx = (disabledIframe: boolean) => ({
    overflow: disabledIframe ? 'hidden' : '',
    pointerEvents: disabledIframe ? 'none' : '',
    width: '100%',
    height: '100%',
});

const MapSection: FC<IProps> = ({ activeStep, mapLoading }) => {

    const renderComponent = () => {
        switch (activeStep) {
            case 1:
                return <Map mapLoading={mapLoading} />;
            case 2:
            case 3:
                return (
                    <Box sx={disabledIframeSx(activeStep === 3)}>
                        <RoofIframe />
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Box width='100%' height={{ md: '100%', xs: `calc(100% - ${MOBILE_BUTTON}px)` }} position={'relative'}>
            {renderComponent()}
            <TipPopUP />
        </Box>
    );
};

export default MapSection;
