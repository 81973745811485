import React, { FC } from 'react';
import { Box, InfoTooltip, ToggleButton, ToggleButtonGroup, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import PricingHeader from 'components/molecules/pricing/PricingHeader';
import texts from 'texts.json';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { ChosenConstructionPriceList } from 'models/api/common';


interface IProps {
    isConstractor?: boolean;
    priceListType?: ChosenConstructionPriceList;
    onPriceListType: (value: ChosenConstructionPriceList) => void;
}

const PricingEntrepreneurHeader: FC<IProps> = ({ priceListType, onPriceListType, isConstractor }) => {
    const { title, description, choosePriceType, priceType } = texts.pricing.pricingEntrepreneur;
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const buttonsGroup = [
        {
            value: ChosenConstructionPriceList.ENTREPRENEUR_CONSTRUCTION_PRICE_LIST_MINE,
            text: priceType.contractor,
        },
        {
            value: ChosenConstructionPriceList.ENTREPRENEUR_CONSTRUCTION_PRICE_LIST_DEFAULT,
            text: priceType.entrepreneur,
        },
    ];
    return (
        <Box>
            <PricingHeader title={title} text={description} />
            <Box
                display='flex'
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: ' ', md: 'center' }}
                py={{ xs: '25px', md: '40px' }}
                gap={{ xs: '10px', md: '20px' }}
            >
                <Box display='flex' justifyContent={{ xs: 'space-between', md: 'unset' }} gap={{ md: 8 }} alignItems='center'>
                    <Box>
                        <Typography.Body3 color={theme.palette.primary.black} font={theme.fontFamilies.light}>
                            {choosePriceType}
                        </Typography.Body3>
                    </Box>
                    <InfoTooltip firstText={priceType.description} />
                </Box>
                {priceListType && (
                    <Box width={{ md: '210px', xs: '90%' }} height='40px' mx={{ xs: 'auto', md: 0 }}>
                        <ToggleButtonGroup
                            disabled={!isConstractor}
                            value={priceListType}
                            onChange={(_, value: ChosenConstructionPriceList) => value !== null && onPriceListType(value)}
                        >
                            {buttonsGroup.map((button) => (
                                <ToggleButton value={button.value} key={button.value}>
                                    {isDesktop ? (
                                        <Typography.Body6 color='inherit' font={theme.fontFamilies.bold}>
                                            {button.text}
                                        </Typography.Body6>
                                    ) : (
                                        <Typography.Body4 color='inherit'>{button.text}</Typography.Body4>
                                    )}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default PricingEntrepreneurHeader;
