import { Box } from 'components/atoms';
import DefaultAppBar from 'components/organisms/appBar/DefaultAppBar';
import { Dialogs } from 'models/api/auth';
import { FC } from 'react';

interface IProps {
    setShowDialog: (value: Dialogs | null) => void;
}

const AppBar: FC<IProps> = ({ setShowDialog }) => {
    return (
        <Box bgcolor={(theme) => theme.palette.primary.white} className='print_hide'>
            <DefaultAppBar setShowDialog={setShowDialog} />
        </Box>
    );
};

export default AppBar;
