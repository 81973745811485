export const Symbols = {
    DOLLAR: '$',
    SHEKEL: '₪',
    EURO: '€',
    PERCENT: '%',
    AGORA: `אג'`,
};

export const addSymbol = (data: string | undefined | null) => {
    return data ? `${Symbols.SHEKEL}${data}` : '';
};
