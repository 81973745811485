import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import './App.scss';
import { Outlet } from 'react-router-dom';
import RTL from './RTL';
import { Box } from 'components/atoms';
import SignIn from 'pages/SignIn';

import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { useEffect } from 'react';
import { TOKEN_STORAGE_KEY, CALCULATOR_STORAGE_KEY } from 'consts/storage.const';
import AuthContext from 'contexts/AuthContext';

const appToken = process.env.REACT_APP_TEMP_PASS;

const App = () => {
    const [token, setToken] = useLocalStorage(TOKEN_STORAGE_KEY, null);
    const [, setCalculator] = useLocalStorage(CALCULATOR_STORAGE_KEY, null);

    const onPasswordHandler = (password: any) => {
        setToken(password);
    };

    const entries: any = performance.getEntriesByType('navigation');

    useEffect(() => {
        const isReloaded = entries[0].type === 'reload';
        const isNavigate = entries[0].type === 'navigate';
        if (isReloaded || isNavigate) {
            return;
        }
        setCalculator(null);
    }, [setCalculator, entries]);

    return (
        <RTL>
            <ThemeProvider theme={theme}>
                {(token && token === appToken) || !appToken ? (
                    <Box>
                        <AuthContext>
                            <Outlet />
                        </AuthContext>
                    </Box>
                ) : (
                    <SignIn onPasswordHandler={onPasswordHandler} />
                )}
            </ThemeProvider>
        </RTL>
    );
};

export default App;
