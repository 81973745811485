import { useTheme } from '@mui/material';
import { useSetProjectCalculator } from 'api/queries/calculator';
import { Box, Typography } from 'components/atoms';
import ContentSection from 'components/molecules/ContentSection';
import ContentSectionMobile from 'components/molecules/ContentSectionMobile';
import { MapContext } from 'contexts/MapContext';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import texts from 'texts.json';
import { renderLinkInText } from 'utils/renderText';

const Type: FC = () => {
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const { setFooterButtonData, setShowMapSection, getIframeDataFunction, setIsIframeDone, calculator } = useContext(MapContext);
    const { mutate: setProject } = useSetProjectCalculator();
    const { text, explanationLink, explanationLinkText, button, title, subtitle, media } = texts.calculator.stepRoof.type;

    const handleClick = () => {
        if (calculator?.projectId && (calculator?.roofAccess || calculator?.funding)) {
            setProject(
                {
                    itemId: calculator.projectId,
                },
                {
                    onSuccess: async () => {
                        onMoveToNextStep();
                    },
                }
            );
            return;
        }
        onMoveToNextStep();
        console.log('🚀 ~ handleClick ~ getIframeDataFunction:', getIframeDataFunction, 'TYPE');
    };

    const onMoveToNextStep = () => {
        setIsIframeDone(false);
        getIframeDataFunction();
        navigate({
            pathname: texts.calculator.stepRoof.type.button.link,
        });
    };

    const handleMobileClick = () => {
        setShowMapSection(true);
        setFooterButtonData({
            text: button.text,
            onClick: handleClick,
        });
    };


    return (
        <Box width='100%' height='100%'>
            {isDesktop ? (
                <ContentSection onClick={handleClick} buttonText={button.text} title={title} text={subtitle} media={media.video}>
                    <Box textAlign='start'>
                        <Typography.DangerouslyHtml font={theme.fontFamilies.bold}>
                            {renderLinkInText(text, explanationLinkText, explanationLink)}
                        </Typography.DangerouslyHtml>
                    </Box>
                </ContentSection>
            ) : (
                <ContentSectionMobile
                    onClick={handleMobileClick}
                    buttonText={texts.calculator.stepAddress.address.button.text}
                    title={title}
                    text={subtitle}
                    media={media.video}
                >
                    <Box width='80%' mx='auto' mt='24px' textAlign='start'>
                        <Typography.DangerouslyHtml font={theme.fontFamilies.regular}>
                            {renderLinkInText(text, explanationLinkText, explanationLink)}
                        </Typography.DangerouslyHtml>
                    </Box>
                </ContentSectionMobile>
            )}
        </Box>
    );
};

export default Type;
