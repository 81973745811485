import { Alert } from '@mui/material';
import { CustomContentProps } from 'notistack';
import { FC, forwardRef } from 'react';

export enum ToastType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

interface IProps extends CustomContentProps {
    message: string;
    type: ToastType;
}

const Toast: FC<IProps> = forwardRef<HTMLDivElement, IProps>(({ message, type }, ref) => {
    return (
        <Alert sx={{ direction: 'ltr', margin: '0', columnGap: '12px' }} ref={ref} severity={type} variant='filled'>
            {message}
        </Alert>
    );
});

export default Toast;
