import { styled, useTheme } from '@mui/material';
import { IMyOffer } from 'models/myOffers';
import { FC } from 'react';
import { Divider, Box, Icon, Typography, Button, ButtonSize } from 'components/atoms';
import { Orientation } from 'components/atoms/Divider';
import { useIsDesktop } from 'hooks/useIsDesktop';
import texts from 'texts.json';

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    padding: '30px 50px 26px 50px',
    borderRadius: theme.borderRadius.xLarge,
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.down('md')]: {
        padding: '30px 20px 20px 20px',
        flexDirection: 'column',
    },
}));

const AddressWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    rowGap: '10px',
    [theme.breakpoints.down('md')]: {
        alignSelf: 'flex-start',
        rowGap: '20px',
        marginBottom: '25px',
    },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 247,
    marginLeft: '50px',
    rowGap: '12px',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: '20px',
        marginLeft: 'unset',
    },
}));

interface IProps {
    offer: IMyOffer;
    isDownloadQuotation?: boolean;
    mainButtonHandler: () => void;
    secondaryButtonHandler: () => void;
}

const MyOfferCard: FC<IProps> = ({ offer, mainButtonHandler, secondaryButtonHandler, isDownloadQuotation = false }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const { issueDate, toProjectPage, downloadFullReport, downloadQuotation } = texts.myOffers;

    const { address, tc_item_creation, project } = offer;
    return (
        <Wrapper>
            <AddressWrapper>
                <Box display={'flex'} alignItems={'center'} columnGap={'10px'}>
                    <Icon.Location />
                    {isDesktop ? (
                        <Typography.Body1 color={theme.palette.primary.main}>{address || project}</Typography.Body1>
                    ) : (
                        <Typography.Body3 color={theme.palette.primary.main}>{address || project}</Typography.Body3>
                    )}
                </Box>
                <Typography.Body4 font={theme.fontFamilies.light} color={theme.palette.primary.main}>
                    {issueDate}&nbsp;
                    {tc_item_creation}
                </Typography.Body4>
            </AddressWrapper>
            <Divider orientation={isDesktop ? Orientation.Vertical : Orientation.Horizontal} />
            <ButtonsWrapper>
                <Button.Contained size={ButtonSize.SMALL} onClick={mainButtonHandler}>
                    {isDownloadQuotation ? downloadQuotation : toProjectPage}
                </Button.Contained>
                <Box
                    display={{ md: isDownloadQuotation ? 'flex' : 'none', xs: 'flex' }}
                    justifyContent={'center'}
                    height={{ xs: 18, md: 'unset' }}
                >
                    {isDownloadQuotation && (
                        <Button.Text size={ButtonSize.SMALL} onClick={secondaryButtonHandler}>
                            <Typography.Body4 textUnderline font={theme.fontFamilies.light} color={theme.palette.primary.purple}>
                                {downloadFullReport}
                            </Typography.Body4>
                        </Button.Text>
                    )}
                </Box>
            </ButtonsWrapper>
        </Wrapper>
    );
};

export default MyOfferCard;
