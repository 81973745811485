import { useTheme } from '@mui/material';
import { Box, Typography } from 'components/atoms';
import TextWithLink from 'components/molecules/TextWithLink';
import { AuthContext } from 'contexts/AuthContext';
import { Dialogs } from 'models/api/auth';
import { FC, useContext } from 'react';
import texts from 'texts.json';

interface IProps {}

const LoginFooter: FC<IProps> = () => {
    const { notSignedUp, signUpUser, signUpCompany } = texts.login.footer;

    const theme = useTheme();
    const { setShowDialog } = useContext(AuthContext);

    return (
        <Box textAlign='center' display='flex' flexDirection='column' gap='8px'>
            <Typography.Body2 font={theme.fontFamilies.regular}>{notSignedUp}</Typography.Body2>
            <TextWithLink
                data={{
                    text: signUpUser.text,
                    linkText: signUpUser.linkText,
                    onClick: () => setShowDialog(Dialogs.SIGN_UP),
                }}
                colorLink={theme.palette.primary.purple}
            />
            <TextWithLink
                data={{
                    text: signUpCompany.text,
                    linkText: signUpCompany.linkText,
                    onClick: () => window.open(signUpCompany.link, '_blank', 'noopener noreferrer'),
                }}
                colorLink={theme.palette.primary.purple}
            />
        </Box>
    );
};

export default LoginFooter;
