import React, { FC } from 'react';
import { Box, Select } from 'components/atoms';
import { Control, Controller } from 'react-hook-form';
import { SelectSize } from 'components/atoms/Select';
import { FormHelperText, useTheme } from '@mui/material';

interface IProps {
    control: Control<any>;
    name: string;
    size?: SelectSize;
    multiline?: boolean;
    rows?: number;
    rules?: any;
    placeholder?: string;
    options: any[];
    disabled?: boolean;
    onBlur?: () => void;
}

const ControllerSelect: FC<IProps> = ({ control, name, size = SelectSize.Small, rules, options, disabled, onBlur }) => {
    const theme = useTheme();
    return (
        <Box onClick={(e) => e.stopPropagation()} width='100%'>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        <Select
                            size={size}
                            value={value || ''}
                            onChange={onChange}
                            options={options}
                            borderRadius={theme.borderRadius.regular}
                            disabled={disabled}
                            onBlur={onBlur}
                            error={!!error?.message}
                        />
                        <FormHelperText error sx={{ fontFamily: theme.fontFamilies.bold }}>
                            {error?.message}
                        </FormHelperText>
                    </>
                )}
            />
        </Box>
    );
};

export default ControllerSelect;
