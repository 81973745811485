import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { IOfferPaymentTerms } from 'models/OfferData.model';
import texts from 'texts.json';

interface IProps {
    data: IOfferPaymentTerms[];
    isContractor: boolean;
    isVatIncluded: boolean;
}

const AccordionTermsOfPayment: FC<IProps> = ({ data, isContractor, isVatIncluded }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const vatIncluded = texts.vat.pricesIncludesVat;
    const title = texts.offers.contractor.accordions.paymentTerms.title;

    return (
        <Accordion>
            <AccordionSummary>
                {isDesktop ? (
                    <Typography.Body1 color={theme.palette.primary.purple}>{title}</Typography.Body1>
                ) : (
                    <Typography.Body3 color={theme.palette.primary.purple}>{title}</Typography.Body3>
                )}
            </AccordionSummary>
            <AccordionDetails>
                {isVatIncluded && (
                    <Box pl={'16px'} mb={'4px'}>
                        <Typography.Body4 color={theme.palette.primary.black}>{vatIncluded}</Typography.Body4>
                    </Box>
                )}
                <Box display='flex' flexDirection={{ md: 'row', xs: 'column' }} gap={'8px'} px={{ xs: '16px' }}>
                    {data.map((item, index) => (
                        <Card.GreyCard key={index}>
                            <Box
                                display={'flex'}
                                flexDirection={{ md: 'column', xs: 'row' }}
                                gap={'6px'}
                                justifyContent={{ xs: 'space-between', md: 'unset' }}
                            >
                                {isContractor ? (
                                    <Typography.Body5>{item.title}</Typography.Body5>
                                ) : (
                                    <Typography.Body4>{item.title}</Typography.Body4>
                                )}
                                <Box display='flex' flexDirection='column' gap={'6px'} width={{ xs: '25%', md: 'auto' }}>
                                    {item.expectedReturn && (
                                        <Typography.Body4 color={theme.palette.primary.purple}>{item.expectedReturn}%</Typography.Body4>
                                    )}
                                    <Typography.Body5>{item.value}</Typography.Body5>
                                </Box>
                            </Box>
                        </Card.GreyCard>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionTermsOfPayment;
