import React, { FC } from 'react';
import { Box, Divider, Typography } from 'components/atoms';
import CardWithInfo from 'components/molecules/CardWithInfo';
import { IAccordionOfferDetailsDataItem } from 'models/OffersData';
import texts from 'texts.json';

interface IProps {
    data: IAccordionOfferDetailsDataItem;
}

const sx = {
    '& :first-child': {
        width: '66.666%',
    },
};

const IS_COSTS = 'עלויות';

const getWidth = (index: number, category: string) => {
    if (category === IS_COSTS) return index === 0 ? '60%' : '40%';
    return index === 2 ? '20%' : '40%';
};

const CardWithInfoOfferDetails: FC<IProps> = ({ data }) => {
    const vatIncluded = texts.vat.vatIncluded;
    return (
        <CardWithInfo firstText={data.info} title={data.title} isSpaceBetween>
            <Box mt={data?.categories ? '20px' : '44px'} width='100%'>
                {data?.categories && (
                    <Box display='flex' width='60%' sx={sx} ml='auto'>
                        {data?.categories?.map((category: any) => (
                            <Box key={category}>{category}</Box>
                        ))}
                    </Box>
                )}
                <Box mb='10px'>
                    <Divider />
                </Box>
                <Box display='flex' flexDirection='column' gap={10}>
                    {data?.items?.map((item: any) => (
                        <Box key={item.name} display='flex' justifyContent='space-between'>
                            {Object.entries(item).map(([key, value]: any, index) => {
                                const isDisplayUnderTitle = data.title === 'עלויות תחזוקה - אופציונלי';
                                const isVatDisplay = isDisplayUnderTitle ? key !== 'first' : true;
                                return (
                                    <Box key={index} width={getWidth(index, data?.title)}>
                                        {index === 0 ? (
                                            <Typography.Body5>{value}</Typography.Body5>
                                        ) : (
                                            <Box display={'flex'} flexDirection={'column'}>
                                                <Typography.Body4>{value}</Typography.Body4>
                                                {data.isVat && isVatDisplay && <Typography.XSmall>{`${vatIncluded}`}</Typography.XSmall>}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    ))}
                </Box>
            </Box>
        </CardWithInfo>
    );
};

export default CardWithInfoOfferDetails;
