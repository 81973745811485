import { styled, useTheme } from '@mui/material';
import { Box, Typography, Button } from 'components/atoms';
import { useRef, useState, FC } from 'react';
import texts from 'texts.json';
import { ReactComponent as PlayButton } from 'assets/images/play-button.svg';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    src: string;
}

const Video = styled('video')(({ theme }) => ({
    borderRadius: '20px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    '&::-webkit-media-controls': {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

const CalculatorVideoSection: FC<IProps> = ({ src }) => {
    const theme = useTheme();
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [pause, setPause] = useState(true);
    const isDesktop = useIsDesktop();

    const handlePlayVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setPause(false);
        }
    };

    const handlePauseVideo = (e: React.MouseEvent<HTMLVideoElement> | undefined) => {
        if (isDesktop) return;
        if (e?.currentTarget) {
            e.currentTarget.pause();
            setPause(true);
        }
    };

    return (
        <Box position={'relative'} mt={'30px'} mb={{ xs: '30px' }} height={{ md: 309, xs: 'auto' }} width={{ xs: '90%', md: 399 }}>
            <Video
                src={src}
                ref={videoRef}
                onPause={() => setPause(true)}
                onPlay={() => setPause(false)}
                loop
                muted
                autoPlay={false}
                controls={true}
                controlsList='nodownload'
                disablePictureInPicture
                playsInline
                onClick={handlePauseVideo}
            ></Video>
            {pause && (
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#00000049',
                        borderRadius: '20px',
                        zIndex: 1000,
                    }}
                >
                    <Box display={'flex'} flexDirection={'column'} rowGap={'12px'} mb={'18px'}>
                        <Typography.Body4 color={theme.palette.primary.white} font={theme.fontFamilies.bold}>
                            {texts.videoPlayBtnTitle}
                        </Typography.Body4>

                        <Button.Icon onClick={handlePlayVideo}>
                            <PlayButton width={70} height={70} />
                        </Button.Icon>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default CalculatorVideoSection;
