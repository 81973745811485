import { FC, ReactNode } from 'react';
import Icon from 'components/atoms/Icon';
import { Box, Typography } from 'components/atoms';
import {
    AuthButtonBase,
    ButtonContainedBase,
    ButtonOutlinedBase,
    ButtonTextBase,
    DownArrowButtonBase,
    IconButtonBase,
    MobileButtonBase,
    UploadFileButtonBase,
} from 'styles/button';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { IBackButton, IButtonBase } from 'models/button';

export enum ButtonSize {
    xSmall = '38px',
    SMALL = '43px',
    MEDIUM = '47px',
    REGULAR = '53px',
    LARGE = '62px',
    xLARGE = '74px',
}

export enum ButtonType {
    SUBMIT = 'submit',
    BUTTON = 'button',
}

const ContainedButton: FC<IButtonBase> = ({ children, onClick, type, size, font, disabled, borderRadius, backgroundColor, color }) => {
    return (
        <ButtonContainedBase
            size={size}
            variant='contained'
            onClick={onClick}
            font={font}
            type={type}
            disabled={disabled}
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}
            color={color}
            fullWidth
        >
            {children}
        </ButtonContainedBase>
    );
};

const OutlinedButton: FC<IButtonBase> = ({
    children,
    size,
    onClick,
    type,
    color,
    borderColor,
    font,
    borderRadius,
    backgroundColor,
    disabled,
}) => {
    return (
        <ButtonOutlinedBase
            size={size}
            variant='outlined'
            onClick={onClick}
            font={font}
            type={type}
            color={color}
            borderColor={borderColor}
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}
            fullWidth
            disabled={disabled}
        >
            {children}
        </ButtonOutlinedBase>
    );
};

const TextButton: FC<IButtonBase> = ({ children, onClick, type, font, backgroundColor, disabled }) => {
    return (
        <ButtonTextBase
            variant='text'
            onClick={onClick}
            type={type}
            font={font}
            backgroundColor={backgroundColor}
            disabled={disabled}
            fullWidth
        >
            {children}
        </ButtonTextBase>
    );
};

const IconButton: FC<IButtonBase> = ({ children, onClick, disabled }) => {
    return (
        <IconButtonBase onClick={onClick} disabled={disabled} disableRipple>
            {children}
        </IconButtonBase>
    );
};

const MobileButton: FC<IButtonBase> = ({ children, onClick, disabled, color, backgroundColor, size, justifyContent }) => {
    return (
        <MobileButtonBase
            variant='contained'
            onClick={onClick}
            disabled={disabled}
            fullWidth
            color={color}
            size={size}
            backgroundColor={backgroundColor}
            justifyContent={justifyContent}
        >
            {children}
        </MobileButtonBase>
    );
};

export enum VariantType {
    Contained = 'contained',
    Outlined = 'outlined',
}

export interface IAuthButton extends IButtonBase {
    icon: ReactNode;
    variant?: VariantType;
}

const AuthButton: FC<IAuthButton> = ({ children, onClick, color, icon, variant = 'outlined' }) => {
    return (
        <AuthButtonBase variant={variant} onClick={onClick} color={color} startIcon={icon} fullWidth>
            {children}
        </AuthButtonBase>
    );
};

const DownArrowButton: FC<IButtonBase> = ({ children, onClick }) => {
    return (
        <Box position='relative' width='100%'>
            <DownArrowButtonBase variant='text' onClick={onClick} fullWidth>
                {children}
            </DownArrowButtonBase>
            <Box position='absolute' right='55%' left='45%' bottom='-16px'>
                <Icon.DownArrowForButton />
            </Box>
        </Box>
    );
};

interface IUploadFileButton {
    onChange: (value?: File) => void;
    disabled?: boolean;
    name?: string;
    accept?: string;
}

const UploadFileButton: FC<IUploadFileButton> = ({ onChange, disabled, name, accept = 'image/*,.pdf' }) => {
    return (
        <UploadFileButtonBase component='label' variant='contained' disabled={disabled} fullWidth>
            <input
                hidden
                accept={accept}
                type='file'
                onChange={(e) => {
                    onChange(e?.target?.files?.[0]);
                }}
                disabled={disabled}
            />
            {name && (
                <Box width='70%' mr='auto' overflow='hidden'>
                    <Typography.Body4>{name}</Typography.Body4>
                </Box>
            )}
            <Box className='icon-file'>
                <Icon.File />
            </Box>
        </UploadFileButtonBase>
    );
};

export const BackButton: FC<IBackButton> = ({ text, color, link, onClick }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    return (
        <Button.Text onClick={() => (onClick ? onClick() : !!link ? navigate(link) : navigate(-1))}>
            <Box display='flex' alignItems='center' columnGap='4px'>
                <Box display='flex' alignItems='center' mt='2px'>
                    <Icon.BackAppBar fill={color} />
                </Box>
                <Typography.Body3 font={theme.fontFamilies.bold} color={color}>
                    {text}
                </Typography.Body3>
            </Box>
        </Button.Text>
    );
};

const Button = {
    Contained: ContainedButton,
    Outlined: OutlinedButton,
    Text: TextButton,
    Icon: IconButton,
    Mobile: MobileButton,
    Auth: AuthButton,
    DownArrow: DownArrowButton,
    UploadFile: UploadFileButton,
    Back: BackButton,
};

export default Button;
