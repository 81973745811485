import React, { FC, ReactNode } from 'react';
import { Box } from 'components/atoms';

interface IProps {
    icon: ReactNode;
    children: ReactNode;
}

const TitleWithIcon: FC<IProps> = ({ icon, children }) => {
    return (
        <Box display='flex' gap='10px' alignItems='center'>
            {icon}
            {children}
        </Box>
    );
};

export default TitleWithIcon;
