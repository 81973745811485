import { useMediaQuery, useTheme } from '@mui/material';
import { Box, Button, Drawer, Typography, Icon, Dialog } from 'components/atoms';
import AddOnPricingTable from 'components/molecules/addOnPricingPanel/AddOnPricingTable';
import Header from 'components/molecules/addOnPricingPanel/Header';
import { IDataCategoriesTitle } from 'models/economicModel.model';
import { FC, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form';

export interface IAddOnPricingPanelData {
    id: string;
    fieldIndex: number;
    data: {
        title: IDataCategoriesTitle;
        value: number;
        name: string;
        rules?: Object;
    }[];
}

interface IProps {
    open: boolean;
    onClose: () => void;
    isContractor?: boolean;
    data: IAddOnPricingPanelData | null;
    control: Control<{ rangesData: any }>;
    onSaveHandler?: () => void;
    handleUpdateRange?: (id: string) => void;
}

const sx = (isDesktop: boolean) => ({
    width: isDesktop ? '526px' : '100%',
    marginTop: isDesktop ? '40px' : '20px',
    px: isDesktop ? '' : '20px',
    maxHeight: isDesktop ? '95%' : '90%',
    marginBottom: isDesktop ? '' : '20px',
});

const AddOnPricingPanel: FC<IProps> = ({ open, onClose, isContractor, data, onSaveHandler }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { control } = useFormContext();
    const [isEdit, setIsEdit] = useState(false);

    const iconsProps = {
        width: '19px',
        height: '19px',
        fill: theme.palette.primary.purple,
    };

    const onClickHandler = () => {
        if (isEdit) {
            onSaveHandler?.();
        } else {
            setIsEdit(true);
        }
    };

    const panelContent = (
        <Box display='flex' flexDirection='column' sx={sx(isDesktop)}>
            <Header isContractor={isContractor} />
            <Box display='flex' justifyContent='flex-end' mb={'8px'}>
                <Button.Text onClick={onClickHandler}>
                    <Box display='flex' columnGap={'8px'} alignItems='flex-end'>
                        {isEdit ? <Icon.Edit {...iconsProps} /> : <Icon.Save {...iconsProps} />}
                        <Typography.Body4 font={theme.fontFamilies.bold} color={theme.palette.primary.purple}>
                            {isEdit ? 'שמירה' : 'עריכה'}
                        </Typography.Body4>
                    </Box>
                </Button.Text>
            </Box>
            <AddOnPricingTable data={data} isEdit={isEdit} control={control} />
        </Box>
    );

    return isDesktop ? (
        <Drawer.Horizontal
            open={open}
            onClose={() => {
                onClose();
                setIsEdit(false);
            }}
        >
            {panelContent}
        </Drawer.Horizontal>
    ) : (
        <Dialog
            hideBackdrop
            open={open}
            onClose={() => {
                onClose();
                setIsEdit(false);
            }}
            backgroundColor={theme.palette.primary.white}
            isFullScreen={!isDesktop}
        >
            {panelContent}
        </Dialog>
    );
};

export default AddOnPricingPanel;
