import { RemainingSearchesContext } from 'contexts/RemainingSearchesContext';
import useGetCalculatorBackPath from 'hooks/useGetCalculatorBackPath.hook';
import { PATH } from 'paths';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useCalculatorBackButton = () => {
    const path = useLocation().pathname;
    const navigate = useNavigate();

    const { onOpen: onOpenRemainingSearchesModal } = useContext(RemainingSearchesContext);
    const backPath = useGetCalculatorBackPath();
    const isMarkingPage = path === `/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.MARKING}`;

    const onBackButtonHandler = () => {
        if (isMarkingPage) {
            onOpenRemainingSearchesModal();
            return;
        }
        navigate(backPath);
    };

    return { onBackButtonHandler };
};

export default useCalculatorBackButton;
