import { useTheme } from '@mui/material';
import { Typography } from 'components/atoms';
import React, { FC } from 'react';

interface IProps {
    text: string;
    color?: string;
}

const Title: FC<IProps> = ({ text, color }) => {
    const theme = useTheme();

    return (
        <Typography.Body1 color={color ? color : theme.palette.primary.purple} font={theme.fontFamilies.xBold}>
            {text}
        </Typography.Body1>
    );
};

export default Title;
