import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTheme, Paper } from '@mui/material';
import { TableComponents, Box, InfoTooltip } from 'components/atoms';
import { IDataCategories, PurchaseEconomicModelObject, IRentEconomicModelObject } from 'models/economicModel.model';
import { Symbols } from 'utils/symbols';
import { EconomicModel } from 'models/api/pricingEntrepreneur';
import texts from 'texts.json';

interface IProps {
    fullscreen?: boolean;
    tableDataCategories: IDataCategories[];
    economicModel: PurchaseEconomicModelObject[] | IRentEconomicModelObject[];
    tableTotalRows?: PurchaseEconomicModelObject | IRentEconomicModelObject | EconomicModel;
    npvRow?: PurchaseEconomicModelObject | IRentEconomicModelObject | EconomicModel;
}

const EconomicModelTable: FC<IProps> = ({ fullscreen, economicModel, tableDataCategories, tableTotalRows, npvRow }) => {
    const theme = useTheme();

    return (
        <Box height={682}>
            <TableContainer
                component={Paper}
                sx={{
                    maxHeight: fullscreen ? '64vh' : '83%',
                    borderTop: `3px solid ${theme.palette.primary.goldenYellow}`,
                    borderRadius: '10px 10px 0 0',
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {tableDataCategories.map((item: IDataCategories) => (
                                <TableComponents.PurpleHeaderCell key={item.name}>
                                    <Box display='flex' sx={{ maxWidth: 95 }}>
                                        {item.title.label}
                                        &nbsp;
                                        <InfoTooltip
                                            firstTitle={item.title.info.firstTitle}
                                            firstText={item.title.info.firstText}
                                            secondTitle={item.title.info.secondTitle}
                                            secondText={item.title.info.secondText}
                                        />
                                    </Box>
                                </TableComponents.PurpleHeaderCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {economicModel?.map((row) => (
                            <TableRow key={row.item_id}>
                                {tableDataCategories.map((item, index) => (
                                    <TableComponents.MainCell key={item.name}>
                                        <Box display='flex'>
                                            {item.specialChar === Symbols.PERCENT && <Box>{item?.specialChar}</Box>}
                                            <Box sx={{ display: 'flex', direction: 'rtl' }}>{row[item.name as keyof typeof row]}</Box>
                                            {item.specialChar === Symbols.SHEKEL && <Box>{item?.specialChar}</Box>}
                                        </Box>
                                    </TableComponents.MainCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {(tableTotalRows || npvRow) && (
                <TableContainer component={Paper} sx={{ borderRadius: ' 0 0 10px 10px' }}>
                    <Table>
                        <TableHead sx={{ backgroundColor: theme.palette.primary.scrollbarBackground, visibility: 'collapse' }}>
                            {/* header to fit cells with upper table */}
                            <TableRow>
                                {tableDataCategories.map((item, index) => (
                                    <TableComponents.PurpleHeaderCell key={index}>
                                        <Box display='flex' sx={{ maxWidth: 95 }}>
                                            {item.title.label}
                                            &nbsp;
                                            <InfoTooltip
                                                firstTitle={item.title.info.firstTitle}
                                                firstText={item.title.info.firstText}
                                                secondTitle={item.title.info.secondTitle}
                                                secondText={item.title.info.secondText}
                                            />
                                        </Box>
                                    </TableComponents.PurpleHeaderCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableTotalRows && (
                                <TableRow sx={{ backgroundColor: theme.palette.primary.scrollbarBackground }}>
                                    {tableDataCategories.map((item, index) => (
                                        <TableComponents.TotalCell key={index}>
                                            {item.name === 'year' ? (
                                                <Box sx={{ display: 'flex' }}>{texts.common.economic_model.total_rows.total}</Box>
                                            ) : (
                                                <Box display='flex'>
                                                    {item.specialChar === Symbols.PERCENT && <Box>{item?.specialChar}</Box>}
                                                    <Box sx={{ display: 'flex', direction: 'rtl' }}>
                                                        {tableTotalRows[item.name as keyof typeof tableTotalRows]}
                                                    </Box>
                                                    {item.specialChar === Symbols.SHEKEL && <Box>{item?.specialChar}</Box>}
                                                </Box>
                                            )}
                                        </TableComponents.TotalCell>
                                    ))}
                                </TableRow>
                            )}
                            {npvRow && (
                                <TableRow sx={{ backgroundColor: theme.palette.primary.lightPurple, fontSize: 14 }}>
                                    {tableDataCategories.map((item, index) => (
                                        <TableComponents.TotalPurpleCell key={index} isTotalSection={true}>
                                            <Box display='flex'>
                                                <Box sx={{ display: 'flex', direction: 'rtl' }}>
                                                    {npvRow && npvRow[item.name as keyof typeof npvRow]}
                                                </Box>
                                                <Box>{item?.specialChar}</Box>
                                            </Box>
                                        </TableComponents.TotalPurpleCell>
                                    ))}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default EconomicModelTable;
