export const PATH = {
    //calculator page
    CALCULATOR: 'calculator',
    ADDRESS: 'address',
    GET_ADDRESS: 'get-address',
    ROOF: 'roof',
    MARKING: 'marking',
    PLACEMENT_ELEMENTS: 'placement-elements',
    TYPE: 'type',
    QUESTIONNAIRE: 'questionnaire',
    ELECTRICITY_CONNECTION: 'electricity-connection',
    ROOF_ACCESS: 'roof-access',
    FUNDING: 'funding',
    RESULTS: 'results',
    SIGNUP: 'signup',
    CONTRACTOR_OFFERS: 'offers/contractor',
    ENTREPRENEUR_OFFERS: 'offers/entrepreneur',
    OFFER_ID: ':offerId',
    PRICING: 'pricing',
    PRICING_ENTREPRENEUR: 'pricing-entrepreneur',
    PRICING_TRANSFORMERS: 'pricing-transformers',
    PRICING_CONTRACTOR: 'pricing-contractor',
    PERSONAL_AREA: 'personal-area',
    PRICING_MY_OFFERS: 'my-offers',
    MANAGEMENT: 'management',
};
