import { useDownloadEntrepreneurQuotation, useDownloadQuotation } from 'api/queries/offers';
import { IDownloadCompanyFileFieldsReq } from 'models/api/common';
import { ContractTypeModel } from 'models/common';
import { downloadFile } from 'utils/downloadFile';

const useDownloadProjectQuotations = (quotationType: ContractTypeModel) => {
    const { mutate: onDownloadQuotation } = useDownloadQuotation();
    const { mutate: onDownloadEntrepreneurQuotation } = useDownloadEntrepreneurQuotation();

    const onDownloadProjectQuotation = (itemId: string, downloadType: IDownloadCompanyFileFieldsReq, clientName = '') => {
        const fileName =
            downloadType === IDownloadCompanyFileFieldsReq.QUOTATION_PDF ? `הצעת מחיר ${clientName}.pdf` : `דוח מלא ${clientName}.pdf`;
        if (quotationType === ContractTypeModel.PURCHASE) {
            onDownloadQuotation(
                { itemId, type: downloadType },
                {
                    onSuccess: (data) => {
                        return downloadFile(data?.data, fileName);
                    },
                }
            );
        } else {
            onDownloadEntrepreneurQuotation(
                { itemId, type: downloadType },
                {
                    onSuccess: (data) => {
                        return downloadFile(data?.data, fileName);
                    },
                }
            );
        }
        // return quotationType === ContractTypeModel.PURCHASE ? onDownloadQuotation : onDownloadEntrepreneurQuotation;
    };

    return { onDownloadProjectQuotation };
};

export default useDownloadProjectQuotations;
