import { useMutation } from '@tanstack/react-query';
import { API_ROUTES } from 'api/apiRoutes';
import { axiosPost } from 'api/axios';
import { AxiosResponse } from 'axios';
import { ApiResponseYesNo, Response, SetCreateRes } from 'models/api/common';
import { IUser, LoginParams, LoginRes, SignUpParams } from 'models/api/auth';
import { DEV_AUTH_TOKEN } from 'utils/envUtils';
import { getToken } from 'utils/getToken';
import { getApiUrlByUserType } from 'utils/getApiUrlByUserType';

export const useLogin = () => {
    const { data, mutate } = useMutation(
        [API_ROUTES.LOGIN],
        (body: LoginParams): Promise<AxiosResponse<LoginRes>> =>
            axiosPost(`?action=token&token=${DEV_AUTH_TOKEN}&call=${API_ROUTES.LOGIN}`, body)
    );
    return { data, mutate };
};

export const useLogout = () => {
    const { data, mutate } = useMutation(
        [API_ROUTES.LOGOUT],
        (): Promise<AxiosResponse<Response>> => axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.LOGOUT}`, {})
    );
    return { data, mutate };
};

export const useSignUp = () => {
    const { data, mutate, isLoading } = useMutation(
        [API_ROUTES.CREATE_CLIENT],
        (data: SignUpParams): Promise<AxiosResponse<SetCreateRes>> =>
            axiosPost(`?action=token&token=${getToken() || DEV_AUTH_TOKEN}&call=${API_ROUTES.CREATE_CLIENT}`, { item: data })
    );
    return { data, mutate, isLoading };
};

export const useSetRegulationsApproval = () => {
    let key = '';
    const { data, mutate, isLoading } = useMutation(
        [key],
        (data: { regulation: ApiResponseYesNo; user: IUser }): Promise<AxiosResponse<SetCreateRes>> => {
            const params = getApiUrlByUserType(data.user);
            if (!params) return Promise.reject('No user found');
            key = params.url;

            return axiosPost(`?action=token&token=${getToken() || DEV_AUTH_TOKEN}`, {
                call: params.url,
                item_id: params.itemId,
                item: { regulations_approval: data.regulation },
            });
        }
    );
    return { data, mutate, isLoading };
};
