import React, { ChangeEvent, FC, RefObject } from 'react';
import { styled, TextField as MuiTextField } from '@mui/material';
import { RefCallBack } from 'react-hook-form';

export enum TextFieldSize {
    XSmall = '27px',
    Small = '40px',
    Standard = '47px',
    Medium = '53px',
    Large = '95px',
    xLarge = '140px',
}

export enum TextFieldType {
    Text = 'text',
    Password = 'password',
    Email = 'email',
    Number = 'number',
}

interface ITextFieldBase {
    value?: string;
    size?: TextFieldSize;
    placeholder?: string;
    font?: string;
    type?: TextFieldType;
    textAlign?: 'center' | 'start' | 'end';
    inputRef?: RefObject<HTMLInputElement> | RefCallBack;
    name?: string;
    multiline?: boolean;
    rows?: number;
    helperText?: string;
    disabled?: boolean;
    direction?: 'rtl' | 'ltr';
    step?: number;
    maxLength?: number;
    inputProps?: Object;
    onBlur?: () => void;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextFieldBase: any = styled(MuiTextField, {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'font' && prop !== 'textAlign' && prop !== 'direction',
})<ITextFieldBase>(({ theme, size, font, textAlign, direction }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.primary.white,
        borderRadius:
            size === TextFieldSize.Large
                ? theme.borderRadius.xLarge
                : size === TextFieldSize.XSmall
                ? theme.borderRadius.small
                : theme.borderRadius.regular,
        borderColor: theme.palette.primary.grey,
        fontFamily: font ? font : theme.fontFamilies.regular,
        color: theme.palette.primary.black,
        fontSize:
            size === TextFieldSize.Large
                ? theme.fontSizes.xLarge
                : size === TextFieldSize.XSmall
                ? theme.fontSizes.normal
                : theme.fontSizes.large,
        fontWeight: theme.fontWeights.small,
        height: size ? size : '47px',
        direction: direction ?? 'ltr', // changes to rtl
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.fontSizes.medium,
        },
        '& .MuiInputBase-input': {
            textAlign: textAlign ? textAlign : 'center',
        },
        '&.Mui-focused': {
            '& fieldset': {
                borderWidth: '1px',
            },
        },
        '& ::placeholder': {
            opacity: 0.9,
            textAlign: textAlign ? textAlign : 'center',
        },
    },
    '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: theme.fontFamilies.bold,
    },
}));

const TextField: FC<ITextFieldBase> = ({
    value,
    onChange,
    size,
    placeholder,
    font,
    type = 'text',
    textAlign,
    inputRef,
    name,
    multiline,
    rows,
    helperText,
    disabled,
    onBlur,
    direction,
    step,
    maxLength,
    inputProps,
}) => {
    return (
        <TextFieldBase
            inputRef={inputRef}
            value={value}
            onChange={onChange}
            size={size}
            placeholder={placeholder}
            type={type}
            font={font}
            textAlign={textAlign}
            name={name}
            multiline={multiline}
            rows={rows}
            helperText={helperText}
            error={!!helperText}
            disabled={disabled}
            onBlur={onBlur}
            direction={direction}
            inputProps={{
                step: step ? step : 1,
                maxLength,
                ...inputProps,
            }}
        />
    );
};

export default TextField;
