import React, { FC } from 'react';
import { Box, Grid, InfoTooltip, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';

interface IProps {
    data: any;
    title: string;
    columns: number;
    info: string;
}

const AccordionContentDesktop: FC<IProps> = ({ data, title, columns, info }) => {
    const theme = useTheme();
    return (
        <Grid container columns={columns} columnSpacing={20} height={'40px'}>
            <Grid item md={2} height='100%'>
                <Box bgcolor='primary.lightPurple' position='relative' display='flex' alignItems='center' height='100%' pl={40}>
                    <Box>
                        <Typography.Body5>{title}</Typography.Body5>
                    </Box>
                    <Box
                        position='absolute'
                        right='16px'
                        sx={{
                            zIndex: 1,
                        }}
                    >
                        <InfoTooltip firstText={info} />
                    </Box>
                </Box>
            </Grid>
            {data.map((item: any, index: number) => {
                return (
                    <Grid item md={3} key={index} height='100%'>
                        <Box bgcolor='primary.lightPurple' height='100%' display='flex' justifyContent='center' alignItems='center'>
                            <Typography.XLarge font={theme.fontFamilies.regular}>{item}</Typography.XLarge>
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default AccordionContentDesktop;
