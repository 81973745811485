import { Checkbox } from 'components/atoms';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

interface IProps {
    control: Control;
    name: string;
    rules: any;
    checkboxValue: string;
    disabled?: boolean;
}

const ControllerCheckbox: FC<IProps> = ({ control, name, rules, checkboxValue, disabled }) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange, value } }) => (
                <Checkbox
                    rounded={true}
                    checked={value === checkboxValue}
                    onChange={(_, checked) => {
                        onChange(checked ? checkboxValue : '');
                    }}
                    value={checkboxValue}
                    disabled={disabled}
                />
            )}
        />
    );
};

export default ControllerCheckbox;
