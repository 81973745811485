import { useTheme } from '@mui/material';
import { Icon, Button, Box, Typography } from 'components/atoms';
import { FC, MouseEvent } from 'react';

interface IProps {
    onCopy?: () => void;
    onPaste?: () => void;
    onDuplicate?: () => void;
}

const PricingCopyPastePanel: FC<IProps> = ({ onCopy, onDuplicate, onPaste }) => {
    const theme = useTheme();

    const copyPasteElements = [
        {
            icon: <Icon.Plus width='18px' height='18px' />, // TODO: change to duplicate icon
            text: 'שכפל טווח',
            onClick: () => onDuplicate?.(),
        },
        {
            icon: <Icon.CopyPaste />,
            text: 'העתקה',
            onClick: () => onCopy?.(),
        },
        {
            icon: <Icon.CopyPasteColorful />,
            text: 'הדבקה',
            onClick: () => onPaste?.(),
        },
    ];

    return (
        <>
            {copyPasteElements.map((item, index) => (
                <Button.Text
                    key={index}
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        item.onClick();
                    }}
                >
                    <Box display='flex' flexDirection='column' alignItems='center'>
                        {item.icon}
                        <Typography.Body6 font={theme.fontFamilies.bold}>{item.text}</Typography.Body6>
                    </Box>
                </Button.Text>
            ))}
        </>
    );
};

export default PricingCopyPastePanel;
