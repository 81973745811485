import { useTheme } from '@mui/material';
import { Box, Checkbox, Typography } from 'components/atoms';
import PropertyTypeCheckboxLabel from 'components/molecules/PropertyTypeCheckboxLabel';
import { EPropertyType } from 'models/propertyType.model';
import { FC } from 'react';
import { getPropertyTypes } from 'utils/getPropertyTypes';

interface IProps {
    propertyType: EPropertyType | null;
    setPropertyType: (value: EPropertyType) => void;
}

const ChoosePropertyType: FC<IProps> = ({ propertyType, setPropertyType }) => {
    const theme = useTheme();
    const { checkboxesProps, title } = getPropertyTypes();

    return (
        <Box display={'flex'} flexDirection={'column'} marginTop={'30px'} rowGap={'30px'} marginBottom={{ xs: '100px', md: 'unset' }}>
            <Typography.Body1 font={theme.fontFamilies.xBold}>{title}</Typography.Body1>
            <Box display='flex' width={'100%'} justifyContent={'space-between'}>
                {checkboxesProps.map((prop) => (
                    <Box key={prop.value} display={'flex'} flexDirection={'column'} alignItems={'center'} rowGap={'12px'}>
                        <Checkbox
                            size={30}
                            checked={propertyType === prop.value}
                            onChange={() => setPropertyType(prop.value)}
                            borderColor={theme.palette.primary.borderGrey}
                        />
                        <PropertyTypeCheckboxLabel label={prop.label} isCalculator />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default ChoosePropertyType;
