import React, { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { Box } from 'components/atoms';
import ManagementAccordionInstructions from 'components/molecules/pricing/management/ManagementAccordionInstructions';
import ManagementAccordionPersonalDetails from 'components/molecules/pricing/management/ManagementAccordionPersonalDetails';
import ManagementAccordionSystemImages from 'components/molecules/pricing/management/ManagementAccordionSystemImages';
import ManagementAccordionExperience from 'components/molecules/pricing/management/ManagementAccordionExperience';
import ManagementAccordionProfessionals from 'components/molecules/pricing/management/ManagementAccordionProfessionals';
import ManagementAccordionFinancialStability from 'components/molecules/pricing/management/ManagementAccordionFinancialStability';
import ManagementAccordionCertificates from 'components/molecules/pricing/management/ManagementAccordionCertificates';
import ManagementAccordionRecommendations from 'components/molecules/pricing/management/ManagementAccordionRecommendations';
import ManagementAccordionSign from 'components/molecules/pricing/management/ManagementAccordionSign';
import { IDownloadSettingsFilesFieldsReq } from 'models/api/common';
import ManagementAccordionSubscriptions from 'components/molecules/pricing/management/ManagementAccordionSubscriptions';
import { IConfirmDetails } from 'models/management';

interface IProps {
    disabledForm: boolean;
    isConfirmedDetails: IConfirmDetails;
    setIsConfirmedDetails: Dispatch<SetStateAction<IConfirmDetails>>;
    onDownloadCompanySettingsHandler: (file: IDownloadSettingsFilesFieldsReq, fileName: string) => void;
    handleSaveAsDraft: () => void;
}

const ManagementAccordions: FC<IProps> = ({
    disabledForm,
    isConfirmedDetails,
    setIsConfirmedDetails,
    onDownloadCompanySettingsHandler,
    handleSaveAsDraft,
}) => {
    const [openSubscription, setOpenSubscription] = useState<boolean>(false);
    const subscriptionsRef = useRef<HTMLDivElement>(null);

    const onSubscriptionSectionOpen = () => {
        if (subscriptionsRef?.current) {
            subscriptionsRef.current.scrollIntoView({ behavior: 'smooth' });
            setOpenSubscription(true);
        }
    };

    return (
        <Box py='20px' display='flex' flexDirection='column' gap={10}>
            <ManagementAccordionInstructions />
            <ManagementAccordionPersonalDetails disabledForm={disabledForm} />
            <ManagementAccordionExperience disabledForm={disabledForm} />
            <ManagementAccordionProfessionals disabledForm={disabledForm} />
            <ManagementAccordionCertificates disabledForm={disabledForm} />
            <ManagementAccordionFinancialStability
                onDownloadCompanySettingsHandler={onDownloadCompanySettingsHandler}
                disabledForm={disabledForm}
            />
            <ManagementAccordionSystemImages disabledForm={disabledForm} />
            <ManagementAccordionRecommendations disabledForm={disabledForm} />
            <Box ref={subscriptionsRef}>
                <ManagementAccordionSubscriptions
                    openSubscription={openSubscription}
                    setOpenSubscription={setOpenSubscription}
                    disabledForm={disabledForm}
                />
            </Box>
            <ManagementAccordionSign
                disabledForm={disabledForm}
                isConfirmedDetails={isConfirmedDetails}
                setIsConfirmedDetails={setIsConfirmedDetails}
                onSubscriptionSectionOpen={onSubscriptionSectionOpen}
                handleSaveAsDraft={handleSaveAsDraft}
            />
        </Box>
    );
};

export default ManagementAccordions;
