import { useState } from 'react';

export const useLocalStorage = (localStorageKey: string, defaultValue: unknown) => {
    const [storedValue, setStoredValue] = useState(() => {
        const localStorageItem = localStorage.getItem(localStorageKey);
        if (localStorageItem === null) {
            return defaultValue;
        }
        try {
            return JSON.parse(localStorageItem);
        } catch (e) {
            return defaultValue;
        }
    });

    const setValue = (value: any) => {
        setStoredValue(value);
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    };
    return [storedValue, setValue];
};
