import React, { FC } from 'react';
import { Box, Button, Divider, Icon, Typography } from 'components/atoms';
import { Orientation } from 'components/atoms/Divider';
import { IAppBarData } from 'models/AppBarData';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'paths';

interface IProps {
    isActive: boolean;
    tab: IAppBarData;
    index: number;
    onClick?: () => void;
}

const TabSideBar: FC<IProps> = ({ isActive, tab, index, onClick }) => {
    const navigate = useNavigate();
    const activeBg = 'linear-gradient(to right, #fff 0%, #f5f5f5 50%, #fff 100%)';
    return (
        <>
            <Button.Text
                onClick={() => {
                    navigate(`/${PATH.PRICING}/${tab.link}`);
                    onClick?.();
                }}
                disabled={tab.disabled}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    gap='15px'
                    height='70px'
                    width={{ md: '240px', xs: '100vw' }}
                    sx={{ backgroundImage: isActive ? activeBg : 'primary.white' }}
                    px={{ xs: '30px', md: 0 }}
                >
                    {tab.icon}
                    <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
                        {isActive ? (
                            <Typography.Body2>{tab.title}</Typography.Body2>
                        ) : (
                            <Typography.ThinTitle>{tab.title}</Typography.ThinTitle>
                        )}
                        {isActive && (
                            <Box sx={{ transform: 'rotate(90deg)' }} display='flex' alignItems='center'>
                                <Icon.DownArrow width='20' height='20' />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Button.Text>
            {tab.link !== PATH.MANAGEMENT && <Divider orientation={Orientation.Horizontal} />}
        </>
    );
};

export default TabSideBar;
