import { styled, useTheme } from '@mui/material';
import { Typography, Box, Button } from 'components/atoms';
import { RemainingSearchesContext } from 'contexts/RemainingSearchesContext';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC, useContext } from 'react';
import texts from 'texts.json';

const BaseTable = styled('table')(({ theme }) => ({
    marginLeft: '26px',
    color: theme.palette.primary.black,
    fontFamily: theme.fontFamilies.regular,
    borderSpacing: 0,
    borderCollapse: 'collapse',
    '& th, td': {
        padding: '4px 10px',
        border: '1px solid black',
    },
    '& td:not(:first-child)': {
        textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: 'unset',

        fontSize: '14px',
    },
}));

const TableWrapper = styled('div')({
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
});

interface SubscriptionOption {
    serviceType: string;
    contractorSubscription: boolean;
    developerSubscription: boolean;
    combinedSubscription: boolean;
}

interface SubscriptionsData {
    header: string;
    columns: string[];
    options: SubscriptionOption[];
    footer: string[];
}

const TableTitle: FC<{ title: string }> = ({ title }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    return isDesktop ? (
        <Typography.Body2 font={theme.fontFamilies.regular}>{title}</Typography.Body2>
    ) : (
        <Typography.Body5 font={theme.fontFamilies.regular}>{title}</Typography.Body5>
    );
};

const SubscriptionsTable: FC<SubscriptionsData> = ({ header, columns, options, footer }) => {
    return (
        <TableWrapper>
            <TableTitle title={header} />
            <BaseTable>
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {options.map((type, index) => (
                        <tr key={index}>
                            <td>{type.serviceType}</td>
                            <td>{type.contractorSubscription ? '+' : '-'}</td>
                            <td>{type.developerSubscription ? '+' : '-'}</td>
                            <td>{type.combinedSubscription ? '+' : '-'}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        {footer.map((column, index) => (
                            <td key={index}>{column}</td>
                        ))}
                    </tr>
                </tfoot>
            </BaseTable>
        </TableWrapper>
    );
};

interface PackageOption {
    numberOfTests: number;
    costPerTest: number;
    totalCost: string;
}

interface PackagesData {
    header: string;
    columns: string[];
    options: PackageOption[];
}

const PackagesTable: FC<PackagesData> = ({ header, columns, options }) => {
    return (
        <TableWrapper>
            <TableTitle title={header} />
            <BaseTable>
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {options.map((option, index) => (
                        <tr key={index}>
                            <td>{option.numberOfTests}</td>
                            <td>{option.costPerTest}</td>
                            <td>{option.totalCost}</td>
                        </tr>
                    ))}
                </tbody>
            </BaseTable>
        </TableWrapper>
    );
};

const ManagementSubscriptionsTable = () => {
    const { subscriptions, packages, vatNotIncluded, purchasePackage } = texts.management.accordions.subscriptions.tables;
    const { setContactUsModal } = useContext(RemainingSearchesContext);
    const theme = useTheme();

    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={'20px'}>
            <SubscriptionsTable {...subscriptions} />
            <PackagesTable {...packages} />
            <Box mt={'20px'}>
                <Button.Text onClick={() => setContactUsModal(true)}>
                    <Typography.Body4 textUnderline color={theme.palette.primary.purple}>
                        {purchasePackage}
                    </Typography.Body4>
                </Button.Text>
            </Box>
            <TableTitle title={vatNotIncluded} />
        </Box>
    );
};

export default ManagementSubscriptionsTable;
