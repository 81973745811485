import { FC, ReactNode } from 'react';
import { Swiper as SwiperComponent } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { styled } from '@mui/material';

// Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

interface ISwiperProps {
    children: ReactNode;
    onSlideChangeTransitionStart?: (swiper: any) => void;
}

const SwiperCalculatorBase = styled(SwiperComponent)<ISwiperProps>(({ theme }) => ({
    '--swiper-navigation-size': '14px',
    '--swiper-pagination-color': 'black',
    '--swiper-theme-color': 'black',
    '& .swiper-pagination-bullets': {
        bottom: '-40px',
    },
    '& .swiper-pagination-bullet': {
        background: `${theme.palette.primary.purple}`,
    },
}));

const SwiperCalculator: FC<ISwiperProps> = ({ children }) => {
    return (
        <SwiperCalculatorBase
            modules={[Pagination, Navigation]}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            slidesPerView={1}
            style={{
                overflow: 'visible',
                textAlign: 'center',
            }}
        >
            {children}
        </SwiperCalculatorBase>
    );
};

const SwiperOffersBase = styled(SwiperComponent)<ISwiperProps>(({ theme }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.white,
    '--swiper-navigation-size': '10px',
    '--swiper-pagination-color': 'black',
    '--swiper-theme-color': 'black',
    '& .swiper-button-prev': {
        width: '20px',
        height: '20px',
        backgroundColor: theme.palette.primary.white,
        borderRadius: theme.borderRadius.full,
        fontWeight: theme.fontWeights.xBold,
    },
    '& .swiper-button-next': {
        fontWeight: theme.fontWeights.xBold,
        width: '20px',
        height: '20px',
        backgroundColor: theme.palette.primary.white,
        borderRadius: theme.borderRadius.full,
    },
    // '& .swiper-pagination-bullet': {
    //     background: `${theme.palette.primary.purple}`,
    // },
}));

const SwiperOffers: FC<ISwiperProps> = ({ onSlideChangeTransitionStart, children }) => {
    return (
        <SwiperOffersBase
            loop={true}
            modules={[Navigation]}
            navigation={true}
            slidesPerView={1}
            onSlideChangeTransitionStart={onSlideChangeTransitionStart}
        >
            {children}
        </SwiperOffersBase>
    );
};

const SwiperRegularBase = styled(SwiperComponent)<ISwiperProps>(({ theme }) => ({
    '--swiper-navigation-size': '14px',
    '--swiper-pagination-color': 'black',
    '--swiper-theme-color': 'black',
    '& .swiper-pagination-bullet': {
        background: `${theme.palette.primary.purple}`,
    },
}));

const SwiperRegular: FC<ISwiperProps> = ({ children }) => {
    return (
        <SwiperRegularBase
            modules={[Pagination, Navigation]}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            slidesPerView={1}
        >
            {children}
        </SwiperRegularBase>
    );
};

const Swiper = {
    SwiperCalculator,
    SwiperOffers,
    SwiperRegular,
};

export default Swiper;
