import { IPricingPanelData } from 'components/molecules/pricing/pricingContractor/PricingContractorRanges';
import { PriceListStatuses } from 'models/api/common';
import { GetContractorSimulatorItem, Item } from 'models/api/pricingContractor';
import { PricingContractorRangesData, PricingContractorSimulatorDataForUi } from 'models/PricingData';
import { parseToNumber } from 'utils/parseToNumber';
import texts from 'texts.json';
import { ICompanySettings } from 'models/api/companySettings.model';

export const contractorRangesMinMax = (minMax: ICompanySettings | undefined) => {
    if (!minMax) return {};
    return {
        from: {
            required: 'חובה להזין ערך',
            min: {
                value: parseToNumber(minMax?.project_power_min),
                message: `${texts.common.minErrorText}-${minMax?.project_power_min}`,
            },
            max: {
                value: parseToNumber(minMax?.project_power_max),
                message: `${texts.common.maxErrorText}-${minMax?.project_power_max}`,
            },
        },
        to: {
            required: 'חובה להזין ערך',
            min: {
                value: parseToNumber(minMax?.project_power_min),
                message: `${texts.common.minErrorText}-${minMax?.project_power_min}`,
            },
            max: {
                value: parseToNumber(minMax?.project_power_max),
                message: `${texts.common.maxErrorText}-${minMax?.project_power_max}`,
            },
        },
    };
};

export const contractorAccordionPaymentLabels = () => {
    const { advancePayment, permitAndConstruction, panelsAndConverters, finishAndTest, systemConnection } =
        texts.pricing.pricingContractor.range.payment;
    return [
        {
            name: 'advance_payment',
            title: advancePayment,
        },
        {
            name: 'permit_and_construction',
            title: permitAndConstruction,
        },
        {
            name: 'panels_and_converters',
            title: panelsAndConverters,
        },
        {
            name: 'finish_and_test',
            title: finishAndTest,
        },
        {
            name: 'system_connection',
            title: systemConnection,
        },
    ];
};

export const contractorAccordionCostsLabels = (minMax: ICompanySettings | undefined) => {
    const {
        manPower,
        blackMatter,
        electricalEquipment,
        roofConstruction,
        transportAndHoisting,
        profitabilityPercentage,
        convertersPriceCalc,
        panels,
        usdRateType,
        pricePerKw,
    } = texts.pricing.pricingContractor.range.costTitles;
    if (!minMax) return [];
    return [
        {
            name: 'manpower',
            title: manPower,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.manpower_min),
                    message: `${texts.common.minErrorText}-${minMax?.manpower_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.manpower_max),
                    message: `${texts.common.maxErrorText}-${minMax?.manpower_max}`,
                },
            },
        },
        {
            name: 'black_matter',
            title: blackMatter,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.black_matter_min),
                    message: `${texts.common.minErrorText}-${minMax?.black_matter_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.black_matter_max),
                    message: `${texts.common.maxErrorText}-${minMax?.black_matter_max}`,
                },
            },
        },
        {
            name: 'electrical_equipment',
            title: electricalEquipment,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.electrical_equipment_min),
                    message: `${texts.common.minErrorText}-${minMax?.electrical_equipment_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.electrical_equipment_max),
                    message: `${texts.common.maxErrorText}-${minMax?.electrical_equipment_max}`,
                },
            },
        },
        {
            name: 'roof_construction',
            title: roofConstruction,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.roof_construction_min),
                    message: `${texts.common.minErrorText}-${minMax?.roof_construction_min}`,
                },
                max: {
                    value: minMax?.roof_construction_max,
                    message: `${texts.common.maxErrorText}-${minMax?.roof_construction_max}`,
                },
            },
        },
        {
            name: 'transport_and_hoisting',
            title: transportAndHoisting,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.transport_and_hoisting_min),
                    message: `${texts.common.minErrorText}-${minMax?.transport_and_hoisting_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.transport_and_hoisting_max),
                    message: `${texts.common.maxErrorText}-${minMax?.transport_and_hoisting_max}`,
                },
            },
        },
        {
            name: 'profitability_percentage',
            title: profitabilityPercentage,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.profitability_percentage_min),
                    message: `${texts.common.minErrorText}-${minMax?.profitability_percentage_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.profitability_percentage_max),
                    message: `${texts.common.maxErrorText}-${minMax?.profitability_percentage_max}`,
                },
            },
        },
        {
            name: 'converters_price_calc',
            title: convertersPriceCalc,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.converters_price_min),
                    message: `${texts.common.minErrorText}-${minMax?.converters_price_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.converters_price_max),
                    message: `${texts.common.maxErrorText}-${minMax?.converters_price_max}`,
                },
            },
        },
        {
            name: 'panels',
            title: panels,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.panels_min),
                    message: `${texts.common.minErrorText}-${minMax?.panels_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.panels_max),
                    message: `${texts.common.maxErrorText}-${minMax?.panels_max}`,
                },
            },
        },
        {
            name: 'usd_rate_type', // usd_rate_manually
            title: usdRateType,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.usd_rate_min),
                    message: `${texts.common.minErrorText}-${minMax?.usd_rate_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.usd_rate_max),
                    message: `${texts.common.maxErrorText}-${minMax?.usd_rate_max}`,
                },
            },
        },
        {
            name: 'price_per_kw',
            title: pricePerKw,
        },
    ];
};

export const contractorAccordionEquipmentLabels = (minMax?: ICompanySettings) => {
    const {
        recommendedPanelType,
        recommendedConstruction,
        convertersCompany,
        panelsWarrantyYears,
        constructionWarrantyYears,
        convertersWarrantyYears,
        installationWarrantyYears,
    } = texts.pricing.pricingContractor.range.equipment;
    if (!minMax) return [];
    return [
        {
            name: 'recommended_panel_type',
            title: recommendedPanelType,
            order: 0,
            rules: {
                required: 'חובה להזין ערך',
            },
        },
        {
            name: 'recommended_construction',
            title: recommendedConstruction,
            order: 2,
            rules: {
                required: 'חובה להזין ערך',
            },
        },
        {
            name: 'converters_company',
            title: convertersCompany,
            order: 4,
            rules: {
                required: 'חובה להזין ערך',
            },
        },
        {
            name: 'panels_warranty_years',
            title: panelsWarrantyYears,
            order: 1,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.panels_warranty_years_min),
                    message: `${texts.common.minErrorText}-${minMax?.panels_warranty_years_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.panels_warranty_years_max),
                    message: `${texts.common.maxErrorText}-${minMax?.panels_warranty_years_max}`,
                },
            },
        },
        {
            name: 'construction_warranty_years',
            title: constructionWarrantyYears,
            order: 3,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.construction_warranty_years_min),
                    message: `${texts.common.minErrorText}-${minMax?.construction_warranty_years_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.construction_warranty_years_max),
                    message: `${texts.common.maxErrorText}-${minMax?.construction_warranty_years_max}`,
                },
            },
        },
        {
            name: 'converters_warranty_years',
            title: convertersWarrantyYears,
            order: 5,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.converters_warranty_years_min),
                    message: `${texts.common.minErrorText}-${minMax?.converters_warranty_years_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.converters_warranty_years_max),
                    message: `${texts.common.maxErrorText}-${minMax?.converters_warranty_years_max}`,
                },
            },
        },
        {
            name: 'installation_warranty_years',
            title: installationWarrantyYears,
            order: 6,
            rules: {
                required: 'חובה להזין ערך',
                min: {
                    value: parseToNumber(minMax?.installation_warranty_years_min),
                    message: `${texts.common.minErrorText}-${minMax?.installation_warranty_years_min}`,
                },
                max: {
                    value: parseToNumber(minMax?.installation_warranty_years_max),
                    message: `${texts.common.maxErrorText}-${minMax?.installation_warranty_years_max}`,
                },
            },
        },
    ];
};

export const buildPricingContractorData = (data: Item[]): PricingContractorRangesData[] => {
    return data
        .map((item) => ({
            id: item.item_id,
            min: parseToNumber(item.from_power),
            max: parseToNumber(item.to_power),
            status: item.status === PriceListStatuses.PRICE_LIST_STATUS_ACTIVE ? true : false,
            property_types: item.property_types || [],
            costs: {
                manpower: parseToNumber(item.manpower),
                black_matter: parseToNumber(item.black_matter),
                electrical_equipment: parseToNumber(item.electrical_equipment),
                roof_construction: parseToNumber(item.roof_construction),
                transport_and_hoisting: parseToNumber(item.transport_and_hoisting),
                profitability_percentage: parseToNumber(item.profitability_percentage),
                panels: parseToNumber(item.panels),
                usd_rate_type: item.usd_rate_type,
                usd_rate_manually: parseToNumber(item.usd_rate_manually),
                converters_price: parseToNumber(item.converters_price),
                converters_price_calc: item.converters_price_calc,
                price_per_kw: item.price_per_kw,
            },
            equipmentAndWarranty: {
                panels_warranty_years: parseToNumber(item.panels_warranty_years),
                construction_warranty_years: parseToNumber(item.construction_warranty_years),
                installation_warranty_years: parseToNumber(item.installation_warranty_years),
                recommended_panel_type: item.recommended_panel_type,
                recommended_construction: item.recommended_construction,
                converters_company: item.converters_company,
                converters_warranty_years: parseToNumber(item.converters_warranty_years),
            },
            paymentTerms: {
                advance_payment: parseToNumber(item.advance_payment),
                permit_and_construction: parseToNumber(item.permit_and_construction),
                panels_and_converters: parseToNumber(item.panels_and_converters),
                finish_and_test: parseToNumber(item.finish_and_test),
                system_connection: parseToNumber(item.system_connection),
            },
            additions: {
                roof_addition_concrete: parseToNumber(item.roof_addition_concrete),
                roof_addition_tiles: parseToNumber(item.roof_addition_tiles),
                roof_addition_insulated_panel: parseToNumber(item.roof_addition_insulated_panel),
                // roof_addition_asbestos: parseToNumber(item.roof_addition_asbestos),
                // roof_addition_lockable: parseToNumber(item.roof_addition_lockable),
                // roof_addition_shingles: parseToNumber(item.roof_addition_shingles),
                // roof_addition_concrete_canisters: parseToNumber(item.roof_addition_concrete_canisters),
                roof_addition_pvc_sealing: parseToNumber(item.roof_addition_pvc_sealing),
                extra_installation_warranty: parseToNumber(item.extra_installation_warranty),
                solar_edge_addition: parseToNumber(item.solar_edge_addition),
                solar_edge_warranty: parseToNumber(item.solar_edge_warranty),
                ladder: parseToNumber(item.ladder),
                standard_lifeline: parseToNumber(item.standard_lifeline),
                converter_cage: parseToNumber(item.converter_cage),
                ground_excavation: parseToNumber(item.ground_excavation),
                sand: parseToNumber(item.sand),
                limestone: parseToNumber(item.limestone),
                excavation_in_asphalt: parseToNumber(item.excavation_in_asphalt),
                // roof_sealing_sheets: parseToNumber(item.roof_sealing_sheets),
                // roof_sealing_fix: parseToNumber(item.roof_sealing_fix),
                // maintenance_manual_rinses: parseToNumber(item.maintenance_manual_rinses),
                maintenance_sprayers: parseToNumber(item.maintenance_sprayers),
                maintenance_periodic_inspection: parseToNumber(item.maintenance_periodic_inspection),
                maintenance_service_call: parseToNumber(item.maintenance_service_call),
                full_maintenance: parseToNumber(item.full_maintenance),
                consultation_safety: parseToNumber(item.consultation_safety),
                consultation_radiation: parseToNumber(item.consultation_radiation),
                transport_addition: parseToNumber(item.transport_addition),
                hoisting_addition: parseToNumber(item.hoisting_addition),
                // raising_construction: parseToNumber(item.raising_construction),
                // subsequent_construction: parseToNumber(item.subsequent_construction),
                aerial_photo_order: parseToNumber(item.aerial_photo_order),
                monitoring: parseToNumber(item.monitoring),
                moving_large_airconditioner: parseToNumber(item.moving_large_airconditioner),
                moving_small_airconditioner: parseToNumber(item.moving_small_airconditioner),
                moving_large_solar_water_heater: parseToNumber(item.moving_large_solar_water_heater),
                moving_small_solar_water_heater: parseToNumber(item.moving_small_solar_water_heater),
                lithium_storage_battery: parseToNumber(item.lithium_storage_battery),
                hybrid_converter: parseToNumber(item.hybrid_converter),
            },
        }))
        .sort((a, b) => a.min - b.min);
};

export const buildAdditionsData = (
    { data: { additions, id }, fieldIndex }: IPricingPanelData,
    minMaxAdditions: ICompanySettings | undefined
) => {
    const {
        roofAdditionsConcrete,
        roofAdditionsTiles,
        roofAdditionInsulatedPanel,
        // roofAdditionAsbestos,
        // roofAdditionLockable,
        // roofAdditionShingles,
        // roofAdditionConcreteCanisters,
        roofAdditionPvcSealing,
        extraInstallationWarranty,
        solarEdgeAddition,
        // solarEdgeWarranty,
        standardLifeline,
        converterCage,
        groundExcavation,
        sand,
        limestone,
        excavationInAsphalt,
        // roofSealingSheets,
        // roofSealingFix,
        maintenanceManualRinses,
        maintenanceSprayers,
        maintenancePeriodicInspection,
        maintenanceServiceCall,
        fullMaintenance,
        monitoring,
        consultationSafety,
        consultationRadiation,
        transportAddition,
        hoistingAddition,
        // raisingConstruction,
        // subsequentConstruction,
        aerialPhotoOrder,
        movingSmallSolarWaterHeater,
        movingLargeSolarWaterHeater,
        movingSmallAirconditioner,
        movingLargeAirconditioner,
        ladder,
        hybridConverter,
        lithiumStorageBattery,
    } = texts.pricing.pricingContractor.additions.priceList;
    if (!minMaxAdditions) {
        return { id: id, fieldIndex, data: [] };
    }
    return {
        id: id,
        fieldIndex,
        data: [
            {
                title: roofAdditionsConcrete,
                value: additions.roof_addition_concrete,
                name: 'roof_addition_concrete',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.roof_addition_concrete_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_addition_concrete_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.roof_addition_concrete_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_addition_concrete_max}`,
                    },
                },
            },
            {
                title: roofAdditionsTiles,
                value: additions.roof_addition_tiles,
                name: 'roof_addition_tiles',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.roof_addition_tiles_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_addition_tiles_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.roof_addition_tiles_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_addition_tiles_max}`,
                    },
                },
            },
            {
                title: roofAdditionInsulatedPanel,
                value: additions.roof_addition_insulated_panel,
                name: 'roof_addition_insulated_panel',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.roof_addition_insulated_panel_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_addition_insulated_panel_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.roof_addition_insulated_panel_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_addition_insulated_panel_max}`,
                    },
                },
            },
            // {
            //     title: roofAdditionAsbestos,
            //     value: additions.roof_addition_asbestos,
            //     name: 'roof_addition_asbestos',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.roof_addition_asbestos_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_addition_asbestos_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.roof_addition_asbestos_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_addition_asbestos_max}`,
            //         },
            //     },
            // },
            // {
            //     title: roofAdditionLockable,
            //     value: additions.roof_addition_lockable,
            //     name: 'roof_addition_lockable',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.roof_addition_lockable_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_addition_lockable_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.roof_addition_lockable_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_addition_lockable_max}`,
            //         },
            //     },
            // },
            // {
            //     title: roofAdditionShingles,
            //     value: additions.roof_addition_shingles,
            //     name: 'roof_addition_shingles',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.roof_addition_shingles_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_addition_shingles_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.roof_addition_shingles_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_addition_shingles_max}`,
            //         },
            //     },
            // },
            // {
            //     title: roofAdditionConcreteCanisters,
            //     value: additions.roof_addition_concrete_canisters,
            //     name: 'roof_addition_concrete_canisters',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.roof_addition_concrete_canisters_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_addition_concrete_canisters_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.roof_addition_concrete_canisters_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_addition_concrete_canisters_max}`,
            //         },
            //     },
            // },
            {
                title: roofAdditionPvcSealing,
                value: additions.roof_addition_pvc_sealing,
                name: 'roof_addition_pvc_sealing',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.roof_addition_pvc_sealing_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_addition_pvc_sealing_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.roof_addition_pvc_sealing_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_addition_pvc_sealing_max}`,
                    },
                },
            },
            {
                title: extraInstallationWarranty,
                value: additions.extra_installation_warranty,
                name: 'extra_installation_warranty',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.extra_installation_warranty_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.extra_installation_warranty_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.extra_installation_warranty_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.extra_installation_warranty_max}`,
                    },
                },
            },
            {
                title: solarEdgeAddition,
                value: additions.solar_edge_addition,
                name: 'solar_edge_addition',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.solar_edge_addition_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.solar_edge_addition_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.solar_edge_addition_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.solar_edge_addition_max}`,
                    },
                },
            },
            // {
            //     title: solarEdgeWarranty,
            //     value: additions.solar_edge_warranty,
            //     name: 'solar_edge_warranty',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.solar_edge_warranty_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.solar_edge_warranty_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.solar_edge_warranty_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.solar_edge_warranty_max}`,
            //         },
            //     },
            // },
            {
                title: ladder,
                value: additions.ladder,
                name: 'ladder',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.ladder_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.ladder_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.ladder_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.ladder_max}`,
                    },
                },
            },
            {
                title: standardLifeline,
                value: additions.standard_lifeline,
                name: 'standard_lifeline',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.standard_lifeline_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.standard_lifeline_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.standard_lifeline_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.standard_lifeline_max}`,
                    },
                },
            },
            {
                title: converterCage,
                value: additions.converter_cage,
                name: 'converter_cage',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.converter_cage_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.converter_cage_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.converter_cage_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.converter_cage_max}`,
                    },
                },
            },
            {
                title: groundExcavation,
                value: additions.ground_excavation,
                name: 'ground_excavation',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.ground_excavation_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.ground_excavation_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.ground_excavation_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.ground_excavation_max}`,
                    },
                },
            },
            {
                title: sand,
                value: additions.sand,
                name: 'sand',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.sand_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.sand_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.sand_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.sand_max}`,
                    },
                },
            },
            {
                title: limestone,
                value: additions.limestone,
                name: 'limestone',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.limestone_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.limestone_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.limestone_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.limestone_max}`,
                    },
                },
            },
            {
                title: excavationInAsphalt,
                value: additions.excavation_in_asphalt,
                name: 'excavation_in_asphalt',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.excavation_in_asphalt_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.excavation_in_asphalt_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.excavation_in_asphalt_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.excavation_in_asphalt_max}`,
                    },
                },
            },
            // {
            //     title: roofSealingSheets,
            //     value: additions.roof_sealing_sheets,
            //     name: 'roof_sealing_sheets',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.roof_sealing_sheets_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_sealing_sheets_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.roof_sealing_sheets_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_sealing_sheets_max}`,
            //         },
            //     },
            // },
            // {
            //     title: roofSealingFix,
            //     value: additions.roof_sealing_fix,
            //     name: 'roof_sealing_fix',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.roof_sealing_fix_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.roof_sealing_fix_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.roof_sealing_fix_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.roof_sealing_fix_max}`,
            //         },
            //     },
            // },
            // {
            //     title: maintenanceManualRinses,
            //     value: additions.maintenance_manual_rinses,
            //     name: 'maintenance_manual_rinses',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.maintenance_manual_rinses_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.maintenance_manual_rinses_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.maintenance_manual_rinses_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.maintenance_manual_rinses_max}`,
            //         },
            //     },
            // },
            {
                title: maintenanceSprayers,
                value: additions.maintenance_sprayers,
                name: 'maintenance_sprayers',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.maintenance_sprayers_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.maintenance_sprayers_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.maintenance_sprayers_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.maintenance_sprayers_max}`,
                    },
                },
            },
            {
                title: maintenancePeriodicInspection,
                value: additions.maintenance_periodic_inspection,
                name: 'maintenance_periodic_inspection',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.maintenance_periodic_inspection_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.maintenance_periodic_inspection_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.maintenance_periodic_inspection_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.maintenance_periodic_inspection_max}`,
                    },
                },
            },
            {
                title: maintenanceServiceCall,
                value: additions.maintenance_service_call,
                name: 'maintenance_service_call',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.maintenance_service_call_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.maintenance_service_call_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.maintenance_service_call_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.maintenance_service_call_max}`,
                    },
                },
            },
            {
                title: fullMaintenance,
                value: additions.full_maintenance,
                name: 'full_maintenance',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.full_maintenance_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.full_maintenance_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.full_maintenance_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.full_maintenance_max}`,
                    },
                },
            },
            {
                title: monitoring,
                value: additions.monitoring,
                name: 'monitoring',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.monitoring_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.monitoring_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.monitoring_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.monitoring_max}`,
                    },
                },
            },
            {
                title: consultationSafety,
                value: additions.consultation_safety,
                name: 'consultation_safety',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.consultation_safety_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.consultation_safety_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.consultation_safety_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.consultation_safety_max}`,
                    },
                },
            },
            {
                title: consultationRadiation,
                value: additions.consultation_radiation,
                name: 'consultation_radiation',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.consultation_radiation_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.consultation_radiation_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.consultation_radiation_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.consultation_radiation_max}`,
                    },
                },
            },
            {
                title: transportAddition,
                value: additions.transport_addition,
                name: 'transport_addition',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.transport_addition_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.transport_addition_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.transport_addition_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.transport_addition_max}`,
                    },
                },
            },
            {
                title: hoistingAddition,
                value: additions.hoisting_addition,
                name: 'hoisting_addition',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.hoisting_addition_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.hoisting_addition_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.hoisting_addition_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.hoisting_addition_max}`,
                    },
                },
            },
            // {
            //     title: raisingConstruction,
            //     value: additions.raising_construction,
            //     name: 'raising_construction',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.raising_construction_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.raising_construction_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.raising_construction_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.raising_construction_max}`,
            //         },
            //     },
            // },
            // {
            //     title: subsequentConstruction,
            //     value: additions.subsequent_construction,
            //     name: 'subsequent_construction',
            //     pricing_by: 'מחר',
            //     rules: {
            //         required: 'חובה להזין ערך',
            //         min: {
            //             value: parseToNumber(minMaxAdditions?.subsequent_construction_min),
            //             message: `${texts.common.minErrorText}-${minMaxAdditions?.subsequent_construction_min}`,
            //         },
            //         max: {
            //             value: parseToNumber(minMaxAdditions?.subsequent_construction_max),
            //             message: `${texts.common.maxErrorText}-${minMaxAdditions?.subsequent_construction_max}`,
            //         },
            //     },
            // },
            {
                title: aerialPhotoOrder,
                value: additions.aerial_photo_order,
                name: 'aerial_photo_order',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.aerial_photo_order_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.aerial_photo_order_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.aerial_photo_order_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.aerial_photo_order_max}`,
                    },
                },
            },
            {
                title: movingSmallSolarWaterHeater,
                value: additions.moving_small_solar_water_heater,
                name: 'moving_small_solar_water_heater',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.moving_small_solar_water_heater_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.moving_small_solar_water_heater_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.moving_small_solar_water_heater_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.moving_small_solar_water_heater_max}`,
                    },
                },
            },
            {
                title: movingLargeSolarWaterHeater,
                value: additions.moving_large_solar_water_heater,
                name: 'moving_large_solar_water_heater',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.moving_large_solar_water_heater_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.moving_large_solar_water_heater_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.moving_large_solar_water_heater_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.moving_large_solar_water_heater_max}`,
                    },
                },
            },
            {
                title: movingSmallAirconditioner,
                value: additions.moving_small_airconditioner,
                name: 'moving_small_airconditioner',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.moving_small_airconditioner_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.moving_small_airconditioner_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.moving_small_airconditioner_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.moving_small_airconditioner_max}`,
                    },
                },
            },
            {
                title: movingLargeAirconditioner,
                value: additions.moving_large_airconditioner,
                name: 'moving_large_airconditioner',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.moving_large_airconditioner_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.moving_large_airconditioner_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.moving_large_airconditioner_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.moving_large_airconditioner_max}`,
                    },
                },
            },
            {
                title: hybridConverter,
                value: additions.hybrid_converter,
                name: 'hybrid_converter',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.hybrid_converter_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.hybrid_converter_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.hybrid_converter_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.hybrid_converter_max}`,
                    },
                },
            },
            {
                title: lithiumStorageBattery,
                value: additions.lithium_storage_battery,
                name: 'lithium_storage_battery',
                rules: {
                    required: 'חובה להזין ערך',
                    min: {
                        value: parseToNumber(minMaxAdditions?.lithium_storage_battery_min),
                        message: `${texts.common.minErrorText}-${minMaxAdditions?.lithium_storage_battery_min}`,
                    },
                    max: {
                        value: parseToNumber(minMaxAdditions?.lithium_storage_battery_max),
                        message: `${texts.common.maxErrorText}-${minMaxAdditions?.lithium_storage_battery_max}`,
                    },
                },
            },
        ],
    };
};

export const buildSimulatorData = (item: GetContractorSimulatorItem, settings?: ICompanySettings): PricingContractorSimulatorDataForUi => ({
    costs: {
        s_manpower: item.s_manpower,
        s_black_matter: item.s_black_matter,
        s_electrical_equipment: item.s_electrical_equipment,
        s_roof_construction: item.s_roof_construction,
        s_transport_and_hoisting: item.s_transport_and_hoisting,
        s_panels_calculated_price: item.s_panels_calculated_price,
        s_converters_price: item.s_converters_price,
        // unexpectedExpenses: '2,240',
        s_profitability: item.s_profitability,
        s_profitability_percentage: item.s_profitability_percentage,
        s_total_price: item.s_total_price,
        s_price_per_kw: item.s_price_per_kw,
    },
    equipmentAndWarranty: {
        s_converters_company: item.s_converters_company,
        s_converters_warranty_years: item.s_converters_warranty_years,
        s_recommended_construction:
            settings?.construction_type_links?.find((construction) => construction.item_id === item.s_recommended_construction)?.title ??
            '',
        s_construction_warranty_years: item.s_construction_warranty_years,
        s_recommended_panel_type: settings?.panel_type_links?.find((panel) => panel.item_id === item.s_recommended_panel_type)?.title ?? '',
        s_panels_warranty_years: item.s_panels_warranty_years,
        s_installation_warranty_years: item.s_installation_warranty_years,
    },
    paymentTerms: {
        s_advance_payment: item.s_advance_payment,
        s_permit_and_construction: item.s_permit_and_construction,
        s_panels_and_converters: item.s_panels_and_converters,
        s_finish_and_test: item.s_finish_and_test,
        s_system_connection: item.s_system_connection,
        total: item.s_total_price,
    },
});

export const simulatorDataCategories = () => {
    const {
        manPower,
        blackMatter,
        electricalEquipment,
        roofConstruction,
        transportAndHoisting,
        profitabilityPercentage,
        convertersPriceCalc,
        panels,
        totalPrice,
        pricePerKw,
        profitability,
    } = texts.pricing.pricingContractor.simulator.costTitles;
    const {
        recommendedPanelType,
        recommendedConstruction,
        convertersCompany,
        panelsWarrantyYears,
        constructionWarrantyYears,
        convertersWarrantyYears,
        installationWarrantyYears,
    } = texts.pricing.pricingContractor.simulator.equipment;
    const { advancePayment, permitAndConstruction, panelsAndConverters, finishAndTest, systemConnection, total } =
        texts.pricing.pricingContractor.simulator.payment;
    return {
        costs: [
            {
                name: 's_manpower',
                title: manPower,
            },
            {
                name: 's_black_matter',
                title: blackMatter,
            },
            { name: 's_electrical_equipment', title:electricalEquipment },
            {
                name: 's_roof_construction',
                title: roofConstruction,
            },
            {
                name: 's_transport_and_hoisting',
                title: transportAndHoisting,
            },
            {
                name: 's_panels_calculated_price',
                title: panels,
            },
            { name: 's_converters_price', title: convertersPriceCalc },
            // {
            //     name: 'unexpectedExpenses',
            //     title: 'בצ"מ',
            // },
            {
                name: 's_profitability',
                title: profitability,
            },
            {
                name: 's_profitability_percentage',
                title: profitabilityPercentage,
            },
            {
                name: 's_total_price',
                title: totalPrice,
            },
            {
                name: 's_price_per_kw',
                title: pricePerKw,
            },
        ],
        equipmentAndWarranty: [
            {
                name: 's_converters_company',
                title: convertersCompany,
            },
            {
                name: 's_converters_warranty_years',
                title: convertersWarrantyYears,
            },
            {
                name: 's_recommended_construction',
                title: recommendedConstruction,
            },
            {
                name: 's_construction_warranty_years',
                title: constructionWarrantyYears,
            },
            {
                name: 's_recommended_panel_type',
                title: recommendedPanelType,
            },
            {
                name: 's_panels_warranty_years',
                title: panelsWarrantyYears,
            },
            {
                name: 's_installation_warranty_years',
                title: installationWarrantyYears,
            },
        ],
        paymentTerms: [
            { name: 's_advance_payment', title: advancePayment },
            { name: 's_permit_and_construction', title: permitAndConstruction },
            { name: 's_panels_and_converters', title: panelsAndConverters },
            { name: 's_finish_and_test', title: finishAndTest },
            { name: 's_system_connection', title: systemConnection },
            { name: 'total', title: total },
        ],
    };
};
