import React, { FC } from 'react';
import { Box, Button, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { ButtonSize } from 'components/atoms/Button';

interface IProps {
    isEdit: boolean;
    setIsEdit: (value: string) => void;
    onSave: () => void;
    dataId: string;
    onCancel?: () => void;
}

const EditButtonSection: FC<IProps> = ({ isEdit, setIsEdit, onSave, dataId, onCancel }) => {
    const theme = useTheme();
    return (
        <Box>
            {!isEdit ? (
                <Box width='130px'>
                    <Button.Outlined
                        borderColor={theme.palette.primary.sunflowerYellow}
                        borderRadius={theme.borderRadius.roundedSides}
                        size={ButtonSize.xSmall}
                        onClick={() => setIsEdit(dataId)}
                    >
                        <Typography.Body4 color={theme.palette.primary.purple}>עריכה</Typography.Body4>
                    </Button.Outlined>
                </Box>
            ) : (
                <Box display='flex' gap='10px'>
                    <Box width={{ md: '130px', xs: '110px' }}>
                        <Button.Outlined
                            borderColor={theme.palette.primary.greyBackground}
                            borderRadius={theme.borderRadius.roundedSides}
                            size={ButtonSize.xSmall}
                            onClick={() => onCancel && onCancel()}
                        >
                            <Typography.Body4 color={theme.palette.primary.purple}>ביטול</Typography.Body4>
                        </Button.Outlined>
                    </Box>
                    <Box width={{ md: '130px', xs: '110px' }}>
                        <Button.Outlined
                            borderColor={theme.palette.primary.sunflowerYellow}
                            borderRadius={theme.borderRadius.roundedSides}
                            size={ButtonSize.xSmall}
                            onClick={() => {
                                onSave();
                            }}
                        >
                            <Typography.Body4 color={theme.palette.primary.purple}>שמירה</Typography.Body4>
                        </Button.Outlined>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default EditButtonSection;
