import React, { FC } from 'react';
import { Box, Button, Icon, Step, Typography } from 'components/atoms';
import texts from 'texts.json';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { PATH } from 'paths';
import { useIsDesktop } from 'hooks/useIsDesktop';
import useCalculatorBackButton from 'hooks/useCalculatorBackButton';

interface IProps {
    activeStep: number;
}

const Stepper: FC<IProps> = ({ activeStep }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const path = useLocation().pathname;
    const { onBackButtonHandler } = useCalculatorBackButton();
    const isAddressPage = path === `/${PATH.CALCULATOR}/${PATH.ADDRESS}`;

    return (
        <Box
            display='flex'
            alignItems='center'
            mb={{ md: '46px', xs: '50px' }}
            width={{ md: '100%', xs: '90%' }}
            maxWidth={{ md: 528 }}
            mx='auto'
        >
            {isDesktop && !isAddressPage && (
                <Button.Icon onClick={onBackButtonHandler}>
                    <Icon.Back />
                </Button.Icon>
            )}
            <Box display='flex' justifyContent={'space-between'} width={isDesktop ? '420px' : '90%'} mx='auto'>
                {texts.calculator.common.stepper.map((text: string, index: number) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <Step step={index + 1} activeStep={activeStep} />
                        {isDesktop ? (
                            <Typography.Body3 font={theme.fontFamilies.bold}>{text}</Typography.Body3>
                        ) : (
                            <Typography.Body6 font={theme.fontFamilies.bold}>{text}</Typography.Body6>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Stepper;
