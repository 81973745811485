import React, { FC } from 'react';
import { Box, Dialog } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    open: boolean;
    text: string;
    onClose: () => void;
}

const RegulationsModal: FC<IProps> = ({ open, onClose, text }) => {
    const isDesktop = useIsDesktop();
    return (
        <Dialog open={open} onClose={onClose} isFullScreen={!isDesktop}>
            <Box
                dangerouslySetInnerHTML={{ __html: text }}
                sx={{ width: { md: 1400 }, height: { md: 800, xs: '100%' }, padding: { md: '70px', xs: '20px' }, overflow: 'auto' }}
            />
        </Dialog>
    );
};

export default RegulationsModal;
