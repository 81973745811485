import React, { FC } from 'react';
import { Box, Grid, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
interface IProps {
    title: string;
    isExpanded?: boolean;
    data: any;
    columns: number;
    vat: string;
}

const AccordionHeaderDesktop: FC<IProps> = ({ title, isExpanded, data, columns, vat }) => {
    const theme = useTheme();
    return (
        <Grid container columns={columns} columnSpacing={20}>
            <Grid item md={2}>
                <Box
                    height='34px'
                    display='flex'
                    alignItems='end'
                    pl='40px'
                    bgcolor={isExpanded ? 'primary.lightPurple' : 'primary.greyBackground'}
                    columnGap={'4px'}
                >
                    <Typography.Body4 font={theme.fontFamilies.xBold} color={theme.palette.primary.purple}>
                        {title}
                    </Typography.Body4>
                    {isExpanded && vat && <Typography.XSmall>{`${vat}`}</Typography.XSmall>}
                </Box>
                {!isExpanded && (
                    <Box height='34px' pl='40px' bgcolor={isExpanded ? 'primary.lightPurple' : 'primary.greyBackground'}>
                        <Typography.Body5>סה"כ ניקוד</Typography.Body5>
                    </Box>
                )}
            </Grid>
            {data.map((total: number) => (
                <Grid item md={3}>
                    <Box
                        bgcolor={isExpanded ? 'primary.lightPurple' : 'primary.greyBackground'}
                        height='100%'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                    >
                        {!isExpanded && <Typography.XLarge font={theme.fontFamilies.regular}>{total}</Typography.XLarge>}
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default AccordionHeaderDesktop;
