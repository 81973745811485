import React from 'react';
import { styled } from '@mui/material/styles';
import { Rating as MuiRating } from '@mui/material';
import { Icon } from 'components/atoms';

const RatingBase = styled(MuiRating)(({ theme }) => ({
    '& .MuiRating-iconFilled': {
        fill: theme.palette.primary.goldenYellow,
    },
    '& .MuiRating-iconEmpty': {
        fill: theme.palette.primary.grey,
    },
}));

interface IProps {
    value: number;
}

export const Rating: React.FC<IProps> = ({ value }) => {
    return (
        <RatingBase name='simple-controlled' value={value} precision={0.5} readOnly={true} icon={<Icon.Sun />} emptyIcon={<Icon.Sun />} />
    );
};

export default Rating;
