import { Box } from '@mui/material';
import { Typography } from 'components/atoms';
import React, { FC } from 'react';

interface IProps {
    title: string;
    description: string;
}

const Description: FC<IProps> = ({ title, description }) => {
    return (
        <Box width='100%' height={'100%'} sx={{ direction: 'ltr' }}>
            <Typography.Body2>{title}</Typography.Body2>
            <br />
            <br />
            <Box textAlign='start'><Typography.Body5>{description}</Typography.Body5></Box>
        </Box>
    );
};

export default Description;
