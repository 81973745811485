import { useEffect } from 'react';

const useBackButtonListener = (callback: (e: any) => void, condition = false) => {
    useEffect(() => {
        if (condition) {
            window.history.pushState(null, '', window.location.pathname);
            window.addEventListener('popstate', callback);
        }
    });

    useEffect(() => {
        return () => {
            if (condition) {
                window.removeEventListener('popstate', callback);
            }
        };
    }, [callback, condition]);
};

export default useBackButtonListener;
