import React, { FC } from 'react';
import { Box, Typography } from 'components/atoms';
import PricingHeader from 'components/molecules/pricing/PricingHeader';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useTheme } from '@mui/material';
import SaveAsDraftButton from 'components/molecules/pricing/management/SaveAsDraftButton';
import texts from 'texts.json';

interface IProps {
    handleSaveAsDraft: () => void;
    disabledForm: boolean;
}


const ManagementHeader: FC<IProps> = ({ handleSaveAsDraft, disabledForm }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const { headerTitle, description } = texts.management;

    return (
        <>
            {isDesktop ? (
                <Box display='flex' justifyContent='space-between'>
                    <PricingHeader title={headerTitle} text={description} />
                    {!disabledForm && <SaveAsDraftButton handleSaveAsDraft={handleSaveAsDraft} />}
                </Box>
            ) : (
                <Box>
                    <Box mb={{ md: '15px', xs: '5px' }} display='flex' justifyContent='space-between'>
                        <Typography.Body2 color={theme.palette.primary.purple}>{headerTitle}</Typography.Body2>
                        {!disabledForm && <SaveAsDraftButton handleSaveAsDraft={handleSaveAsDraft} />}{' '}
                    </Box>
                    <Box width={{ md: '67%' }}>
                        <Typography.Body5>{description}</Typography.Body5>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ManagementHeader;
