import React, { cloneElement, FC, ReactElement, useState } from 'react';
import { Box } from 'components/atoms';
import { useTheme } from '@mui/material';

interface IProps {
    text: string;
    maxLetters: number;
    children: ReactElement;
}

const ReadMore: FC<IProps> = ({ text, maxLetters, children }) => {
    const theme = useTheme();
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore((prev) => !prev);
    };

    const textElement =
        text.length <= maxLetters ? (
            text
        ) : (
            <>
                {isReadMore ? `${text.slice(0, maxLetters)}...` : text}
                &nbsp;
                <Box
                    component='span'
                    onClick={toggleReadMore}
                    color={theme.palette.primary.black}
                    style={{ cursor: 'pointer', textDecoration: 'black underline' }}
                >
                    <br />
                    {isReadMore ? 'קרא עוד' : 'פחות'}
                </Box>
                &nbsp;
            </>
        );

    return cloneElement(children, {}, textElement);
};

export default ReadMore;
