export const buildPricingAccordionData = (data: any, labels: any) => {
    return labels.map((label: any) => ({
        value: data[label.name],
        title: label.title,
        name: label.name,
        order: label.order,
        rules: label?.rules,
        specialChar: label?.specialChar,
    }));
};
