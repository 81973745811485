import React, { FC } from 'react';
import { Box } from 'components/atoms';

interface IProps {
    src: string;
    alt: string;
    style: Object;
}

const Image: FC<IProps> = ({ src, alt, style }) => {
    return <Box component='img' src={src} alt={alt} style={style} />;
};

export default Image;
