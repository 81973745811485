import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, Grid } from 'components/atoms';
import { useTheme } from '@mui/material';
import CardWithInfoOfferDetails from 'components/molecules/contractor/CardWithInfoOfferDetails';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { IAccordionOfferDetailsData } from 'models/OffersData';
import texts from 'texts.json';
interface IProps {
    data: IAccordionOfferDetailsData;
    isContractor: boolean;
    isVatIncluded: boolean;
}

const AccordionOfferDetails: FC<IProps> = ({ data, isContractor, isVatIncluded }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { header, details } = data;
    const title = texts.offers.contractor.accordions.offerDetails.title;
    const vatIncluded = texts.vat.vatIncluded;
    const vat = isVatIncluded && <Typography.XSmall>{`${vatIncluded}`}</Typography.XSmall>;
    return (
        <Accordion>
            <AccordionSummary>
                <Box display='flex' alignItems='center' width='100%' gap={'40px'}>
                    <Box mr='20px'>
                        {isDesktop ? (
                            <Typography.Body1 color={theme.palette.primary.purple}>{title}</Typography.Body1>
                        ) : (
                            <Typography.Body3 color={theme.palette.primary.purple}>{title}</Typography.Body3>
                        )}
                    </Box>
                    {isDesktop &&
                        header.map((item, index) => (
                            <Box key={index} display='flex' alignItems='center' gap={10} m={index === 2 ? '0 20px 0 auto' : 0}>
                                {item.icon && (
                                    <Box display='flex' alignItems='center' gap={10}>
                                        {item.icon}
                                    </Box>
                                )}
                                <Box display='flex' flexDirection='column'>
                                    <Typography.Body4>{item.title}</Typography.Body4>
                                    <Typography.Body1 color={theme.palette.primary.purple}>{item.value}</Typography.Body1>
                                    {item.isVat && <Box display={'flex'}>{vat}</Box>}
                                </Box>
                            </Box>
                        ))}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box px={{ xs: '16px' }}>
                    {!isDesktop && (
                        <Box display='flex' flexDirection='column' gap='12px' mb='27px'>
                            {header.map((item, index) => (
                                <Box display='flex' alignItems='center' justifyContent='space-between' key={index}>
                                    <Typography.Body4>{item.title}</Typography.Body4>
                                    <Box>
                                        <Typography.Body2 color={theme.palette.primary.purple}>{item.value}</Typography.Body2>
                                        {item.isVat && <Box display={'flex'}>{vat}</Box>}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    )}
                    <Grid container spacing={8}>
                        {isContractor
                            ? details.map((item, index) => (
                                  <Grid item xs={12} md={4} key={index}>
                                      <CardWithInfoOfferDetails data={item} />
                                  </Grid>
                              ))
                            : details.map((item, index) => (
                                  <Grid item xs={12} md={2} key={index}>
                                      <Box
                                          display='flex'
                                          flexDirection={{
                                              xs: 'row',
                                              md: 'column',
                                          }}
                                          gap={{ md: 10 }}
                                          justifyContent={{
                                              xs: 'space-between',
                                          }}
                                      >
                                          <Typography.Body4>{item.title}</Typography.Body4>
                                          <Typography.Body5>{item.value}</Typography.Body5>
                                      </Box>
                                  </Grid>
                              ))}
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionOfferDetails;
