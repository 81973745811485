import { Box, Button, Dialog, TextField, Typography } from 'components/atoms';
import { FC, useState } from 'react';
import AppLogo from 'assets/images/app-logo.svg';
import { useTheme } from '@mui/material';
import texts from 'texts.json';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (clientName: string) => any;
}

const ProjectClientNameModal: FC<IProps> = ({ open, onClose, onSubmit }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { info, placeholder, button, note } = texts.results.projectClientNameModal;
    const [clientName, setClientName] = useState('');

    return (
        <Dialog open={open} onClose={onClose} desktopWidth={701} isCloseIcon={true} isFullScreen={!isDesktop}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} p={'0 58px 85px'}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} rowGap={'30px'} mb={'30px'}>
                    <img src={AppLogo} alt='logo' width={214} height={'auto'} />
                    <Typography.Body2 color={theme.palette.primary.darkGrey}>{info} </Typography.Body2>
                    <TextField value={clientName} onChange={(e) => setClientName(e.target.value)} placeholder={placeholder} />
                    <Button.Contained onClick={() => onSubmit(clientName)} disabled={!clientName}>
                        {button}
                    </Button.Contained>
                </Box>
                <Typography.Body3 color={theme.palette.primary.darkGrey}>{note}</Typography.Body3>
            </Box>
        </Dialog>
    );
};

export default ProjectClientNameModal;
