import { FC } from 'react';
import { MenuItem, Select as MuiSelect, styled } from '@mui/material';
import { Icon } from 'components/atoms';
import { Theme } from '@mui/material/styles';

export enum SelectSize {
    XSmall = '27px',
    Small = '42px',
    Regular = '47px',
    Large = '53px',
    xLarge = '95px',
}

interface ISelectBase {
    onChange: (event: any, valueField: any) => void;
    options: any;
    value: string;
    disabled?: boolean;
    size?: SelectSize;
    fontSize?: string;
    borderRadius?: string;
    placeholder?: string;
    error?: boolean;
    isCenteredInputValue?: boolean;
    onBlur?: () => void;
}

const SelectBase: any = styled(MuiSelect, {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'fontSize' && prop !== 'borderRadius' && prop !== 'isCenteredInputValue',
})<ISelectBase>(({ theme, size, fontSize, borderRadius, isCenteredInputValue }) => {
    return {
        height: size ? size : SelectSize.Small,
        borderRadius: borderRadius ? borderRadius : theme.borderRadius.large,
        backgroundColor: theme.palette.primary.white,
        '& .MuiInputBase-input': {
            fontFamily: theme.fontFamilies.regular,
            fontSize: fontSize ? fontSize : theme.fontSizes.normal,
            textAlign: isCenteredInputValue ? 'center' : '',
        },
        '& svg': {
            width: '15px',
            right: '16px',
            position: 'absolute',
        },
    };
});

const Select: FC<ISelectBase> = ({
    placeholder,
    options,
    onChange,
    value,
    size,
    fontSize,
    borderRadius,
    disabled,
    onBlur,
    error,
    isCenteredInputValue = false,
}) => {
    return (
        <SelectBase
            fullWidth
            defaultValue={value}
            value={value}
            onChange={onChange}
            IconComponent={() => <Icon.DownArrow />}
            size={size}
            fontSize={fontSize}
            borderRadius={borderRadius}
            disabled={disabled}
            onBlur={onBlur}
            displayEmpty
            error={error}
            isCenteredInputValue={isCenteredInputValue}
            MenuProps={{
                sx: {
                    '&& .Mui-selected': {
                        backgroundColor: (theme: Theme) => theme.palette.primary.goldenYellow,
                    },
                    '&& .Mui-disabled': {
                        backgroundColor: (theme: Theme) => (placeholder ? 'inherit' : theme.palette.primary.goldenYellow),
                    },
                },
            }}
        >
            {placeholder && (
                <MenuItem disabled value=''>
                    {placeholder}
                </MenuItem>
            )}
            {options.map((option: any) => (
                <MenuItem key={option.label} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </SelectBase>
    );
};

export default Select;
