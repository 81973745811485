import React, { FC } from 'react';
import { Box, Card, Divider, Typography } from 'components/atoms';
import CardWithInfo from 'components/molecules/CardWithInfo';
import { Orientation } from 'components/atoms/Divider';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { EntrepreneurSimulatorDetails } from 'models/PricingEntrepreneur.model';
import { entrepreneurSimulatorDetails } from 'data/PricingEntrepreneurDataProcessing';
import { Symbols } from 'utils/symbols';

interface IProps {
    data: EntrepreneurSimulatorDetails;
}

const PriceSimulatorDetails: FC<IProps> = ({ data }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    return (
        <Card.GreyBorderCard>
            <Box
                display='flex'
                width='100%'
                bgcolor='primary.white'
                justifyContent='center'
                flexDirection='row'
                py='15px'
                flexWrap={{ md: 'nowrap', xs: 'wrap' }}
                rowGap={{ xs: '10px', md: 0 }}
                columnGap={{ xs: '8px', md: 0 }}
                sx={{
                    '& > *': {
                        [theme.breakpoints.down('md')]: {
                            width: '45%',
                        },
                    },
                }}
            >
                {entrepreneurSimulatorDetails().map((category, index) => {
                    // const IrrOrNpv = category.name === 'result_irr' || category.name === 'npv';
                    return (
                        <Box key={index} display={'flex'} minHeight={120}>
                            {index !== 0 && isDesktop && (
                                <Box display='flex' height={'100%'} my='auto'>
                                    <Divider orientation={Orientation.Vertical} />
                                </Box>
                            )}
                            <CardWithInfo
                                title={category.title.label}
                                border={isDesktop ? false : true}
                                firstTitle={category.title.info?.firstTitle}
                                firstText={category.title.info?.firstText}
                                secondTitle={category.title.info?.secondTitle}
                                secondText={category.title.info?.secondText}
                            >
                                <Box display='flex' height={'100%'}>
                                    <Box sx={{ display: 'flex', columnGap: '5px', alignSelf: 'flex-end' }}>
                                        <Typography.Body2 font={theme.fontFamilies.light}>
                                            {data[category.name as keyof typeof data]}
                                        </Typography.Body2>
                                        <Box sx={{ order: category.specialChar === Symbols.PERCENT ? '-1' : '' }}>
                                            <Typography.Body2 font={theme.fontFamilies.light}>{category.specialChar}</Typography.Body2>
                                        </Box>
                                    </Box>
                                </Box>
                            </CardWithInfo>
                        </Box>
                    );
                })}
            </Box>
        </Card.GreyBorderCard>
    );
};

export default PriceSimulatorDetails;
