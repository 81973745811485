import { useMediaQuery, useTheme } from '@mui/material';
import { Box, Button, Divider, Grid, InfoTooltip, Paper, Typography } from 'components/atoms';
import { Orientation } from 'components/atoms/Divider';
import React, { FC } from 'react';

interface IProps {
    data: any;
}

const PaperResultsPurchase: FC<IProps> = ({ data }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Paper.Calculator overflowY='visible'>
            <Grid container spacing={40} columnSpacing={{ xs: 20 }}>
                {data.results.map((item: any, index: number) => (
                    <Grid container item key={index} md={4} xs={6}>
                        <Box
                            display='flex'
                            flexDirection='column'
                            gap={{ md: '13px', xs: '7px' }}
                            height='100%'
                            m={'0 auto 0 0'}
                            textAlign={'left'}
                        >
                            <Box display={'flex'} alignItems={'baseline'} columnGap={'6px'}>
                                {isDesktop ? (
                                    <Typography.ThinTitle>{item.title}</Typography.ThinTitle>
                                ) : (
                                    <Typography.Body5>{item.title}</Typography.Body5>
                                )}
                                {item.titleInfo && <InfoTooltip firstText={item.titleInfo.firstText} />}
                            </Box>
                            <Box display={'flex'} flexDirection={isDesktop ? 'row' : 'column'} alignItems={{ md: 'end' }} columnGap={'4px'}>
                                {isDesktop ? (
                                    <Typography.Body1>{item.result}</Typography.Body1>
                                ) : (
                                    <Typography.Body3 font={theme.fontFamilies.bold}>{item.result}</Typography.Body3>
                                )}
                                <Box display={'flex'} alignItems={'baseline'} columnGap={'4px'}>
                                    {item.helperText && (
                                        <Typography.Body6
                                            font={theme.fontFamilies.regular}
                                            color={item.info ? theme.palette.primary.gold : theme.palette.primary.black}
                                        >
                                            {item.helperText}
                                        </Typography.Body6>
                                    )}
                                    {item.info && <InfoTooltip firstText={item.info} fill={theme.palette.primary.gold} />}
                                </Box>
                            </Box>
                        </Box>
                        {isDesktop && index !== 2 && (
                            <Box display='flex'>
                                <Divider orientation={Orientation.Vertical} />
                            </Box>
                        )}
                        {!isDesktop && index !== 1 && index !== 3 && (
                            <Box display='flex'>
                                <Divider orientation={Orientation.Vertical} />
                            </Box>
                        )}
                    </Grid>
                ))}
                <Grid container item md={4} xs={12} alignItems='center' rowGap='10px'>
                    {data.buttons.map((item: any) => (
                        <Grid key={item.title} item md={5} xs={10} mx={'auto'}>
                            <Button.Outlined
                                color={theme.palette.primary.black}
                                borderColor={theme.palette.primary.goldenYellow}
                                onClick={item.onClick}
                                disabled={item.disabled}
                            >
                                <Typography.Body5> {item.title}</Typography.Body5>
                            </Button.Outlined>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Paper.Calculator>
    );
};

export default PaperResultsPurchase;
