/* eslint-disable max-lines */
import React, { useCallback, useContext, useState } from 'react';
import { Box, MobileSwipeableDrawer } from 'components/atoms';
import LeftSection from 'pages/offers/LeftSection';
import OffersFoldablePanel from 'components/organisms/offers/offersList/OffersFoldablePanel';
import {
    useGetProjectComparison,
    useGetProjectQuotations,
    useElectContractorQuotation,
    useElectEntrepreneurQuotation,
} from 'api/queries/offers';
import OffersList from 'pages/offers/OffersList';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IBuiltComparisonData, IElectQuotation } from 'models/OffersData';
import { buildOffersComparisonData } from 'data/offersData';
import { useIsDesktop } from 'hooks/useIsDesktop';
import DownloadQuotationModal from 'components/organisms/offers/offersList/DownloadQuotationModal';
import { ContractTypeModel } from 'models/common';
import useBackButtonListener from 'hooks/useBackButtonListener';
import { useTheme } from '@mui/material';
import { CALCULATOR_STORAGE_KEY } from 'consts/storage.const';
import { AuthContext } from 'contexts/AuthContext';
import { IDownloadCompanyFileFieldsReq } from 'models/api/common';
import { isDefaultQuotation } from 'utils/isDefaultQuotation';
import useDownloadProjectQuotations from 'hooks/useDownloadProjectQuotations.hook';
import { RemainingSearchesContext } from 'contexts/RemainingSearchesContext';
import { PATH } from 'paths';

const Offers = () => {
    const [calculator] = useLocalStorage(CALCULATOR_STORAGE_KEY, null);
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const { offerId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);
    const { onOpen: onOpenRemainingSearchesModal } = useContext(RemainingSearchesContext);

    const isContractor = location.pathname.includes('contractor');
    const contractType = isContractor ? ContractTypeModel.PURCHASE : ContractTypeModel.RENT;
    const isAgent = !!user?.user.owner_agent;

    const { data: projectQuotations, refetch: projectQuotationsRefetch } = useGetProjectQuotations(
        calculator?.projectId,
        !!calculator?.projectId
    );
    const { data: comparisonDataRes, refetch: comparisonDataResRefetch } = useGetProjectComparison(
        calculator?.projectId,
        !!calculator?.projectId
    );

    const { mutate: electContractorQuotation } = useElectContractorQuotation();
    const { mutate: electEntrepreneurQuotation } = useElectEntrepreneurQuotation();

    const [offersForCompare, setOffersForCompare] = useState<IBuiltComparisonData[]>([]);
    const [openDownloadQuotationModal, setOpenDownloadQuotationModal] = useState<ContractTypeModel | null>(null);
    const [electQuotation, setElectQuotation] = useState<IElectQuotation | null>(null);
    const [offersModel, setOffersModel] = useState(contractType);

    const { onDownloadProjectQuotation } = useDownloadProjectQuotations(offersModel);

    const onBackButtonEvent = useCallback(
        (e: any) => {
            e.preventDefault();
            onOpenRemainingSearchesModal();
        },
        [onOpenRemainingSearchesModal]
    );
    const isFromMyOffersPage = location.state?.from === PATH.PRICING_MY_OFFERS;
    useBackButtonListener(onBackButtonEvent, !isFromMyOffersPage);

    const handleElectQuotation = (quotationId: string, modelType: ContractTypeModel) => {
        if (modelType === ContractTypeModel.PURCHASE) {
            electContractorQuotation(quotationId, {
                onSuccess: (data) => {
                    setElectQuotation({ itemId: data.data.item.item_id, id: data.data.item.id, details: data.data.item.constractor });
                    setOpenDownloadQuotationModal(modelType);
                    projectQuotationsRefetch();
                    comparisonDataResRefetch();
                },
            });
        } else {
            electEntrepreneurQuotation(quotationId, {
                onSuccess: (data) => {
                    setElectQuotation({ itemId: data.data.item.item_id, id: data.data.item.id, details: data.data.item.entrepreneur });
                    setOpenDownloadQuotationModal(modelType);
                    projectQuotationsRefetch();
                },
            });
        }
    };

    const addOfferForCompare = (offerId: string) => {
        const offerComparisonData: IBuiltComparisonData | undefined = buildOffersComparisonData(
            comparisonData?.client_quotations_compare.find((item) => item.item_id === offerId)
        );
        if (offerComparisonData && offersForCompare.length === 3) {
            return;
        }
        offerComparisonData && setOffersForCompare((prev) => [...prev, offerComparisonData]);
    };

    const removeOfferFromCompare = (offerId: string) => {
        setOffersForCompare((prev) => prev.filter((offer) => offer.offerId !== offerId));
    };

    const resetOffersForCompare = () => {
        setOffersForCompare([]);
    };

    const onDownloadQuotation = (type: ContractTypeModel) => {
        if (!electQuotation) return;
        onDownloadProjectQuotation(electQuotation.itemId, IDownloadCompanyFileFieldsReq.QUOTATION_PDF, electQuotation?.details.name);
        setOpenDownloadQuotationModal(null);
    };

    const onDownloadFullReport = () => {
        const itemId =
            offersModel === ContractTypeModel.PURCHASE
                ? projectQuotations?.default_quotation
                : projectQuotations?.default_entrepreneur_quotation;
        onDownloadProjectQuotation(itemId, IDownloadCompanyFileFieldsReq.EXPANDED_QUOTATION_PDF, electQuotation?.details.name);
    };

    const onSetOffersModel = (model: ContractTypeModel) => {
        setOffersModel(model);
        const getPath = model === ContractTypeModel.PURCHASE ? `/${PATH.CONTRACTOR_OFFERS}` : `/${PATH.ENTREPRENEUR_OFFERS}`;
        navigate(getPath);
    };

    if (!projectQuotations) {
        return null;
    }

    const comparisonData = comparisonDataRes?.data?.item;
    const showComparisonTable = comparisonData && offersForCompare.length !== 0;
    const isVatIncluded = projectQuotations?.vat_included;

    const leftSection = (
        <LeftSection
            data={projectQuotations}
            offersModel={offersModel}
            isVatIncluded={isVatIncluded}
            onDownloadFullReport={onDownloadFullReport}
            isFullReport={isAgent && isDefaultQuotation(offersModel, projectQuotations)}
        />
    );

    return (
        <Box display='flex' height={showComparisonTable ? 'calc(100% - 62px)' : '100%'}>
            <Box
                id='scrollable'
                display={{ md: 'flex' }}
                flex={1}
                bgcolor='primary.greyBackground'
                height='100%'
                sx={{ overflow: 'auto' }}
                dir='ltr'
                pb={{ xs: '46px' }}
            >
                {/*  direction for scrollbar */}
                <Box dir='rtl' mx={{ md: 'auto' }}>
                    {!offerId && !!projectQuotations && (
                        <OffersList
                            offersModel={offersModel}
                            setOffersModel={onSetOffersModel}
                            quotations={projectQuotations}
                            addOfferForCompare={addOfferForCompare}
                            offersForCompare={offersForCompare}
                            removeOfferFromCompare={removeOfferFromCompare}
                            handleElectQuotation={handleElectQuotation}
                            resetOffersForCompare={resetOffersForCompare}
                        />
                    )}
                    {offerId && <Outlet context={{ handleElectQuotation }} />}
                    {showComparisonTable && (
                        <OffersFoldablePanel
                            comparisonData={comparisonData}
                            offersForCompare={offersForCompare}
                            removeOfferFromCompare={removeOfferFromCompare}
                            handleElectQuotation={handleElectQuotation}
                        />
                    )}
                </Box>
            </Box>
            {isDesktop ? (
                <Box
                    height={'100%'}
                    sx={{
                        width: 375,
                        paddingBottom: '40px',
                        overflow: 'auto',
                        [theme.breakpoints.up(1900)]: {
                            width: 536,
                        },
                    }}
                >
                    {leftSection}
                </Box>
            ) : (
                !showComparisonTable && <MobileSwipeableDrawer address={calculator?.address}>{leftSection}</MobileSwipeableDrawer>
            )}
            {!!openDownloadQuotationModal && electQuotation && (
                <DownloadQuotationModal
                    open={!!openDownloadQuotationModal}
                    onClose={() => {
                        setOpenDownloadQuotationModal(null);
                    }}
                    electDetails={electQuotation}
                    onQuotationDownload={() => onDownloadQuotation(openDownloadQuotationModal)}
                />
            )}
        </Box>
    );
};

export default Offers;
