import { PaletteOptions } from '@mui/material';

type HEX = `#${string}`;

type primaryColors =
    | 'purple'
    | 'black'
    | 'sunflowerYellow'
    | 'pinkishGrey'
    | 'goldenYellow'
    | 'main'
    | 'grey'
    | 'white'
    | 'borderGrey'
    | 'scrollbarBackground'
    | 'greyBackground'
    | 'divider'
    | 'blue'
    | 'SwitchColor'
    | 'lightPurple'
    | 'yellowBackground'
    | 'darkGrey'
    | 'red'
    | 'green'
    | 'gold'
    | 'greyBackground2'
    | 'yellowBackground2'
    | 'lightBlue'
    | 'darkBlue'
    | 'darkGrey_2'
    | 'lightGrey'
    | 'lightOrange'
    | 'darkOrange';

type IPrimary = Record<primaryColors, HEX | string>;

const primary: IPrimary = {
    main: '#000000',
    black: '#1d1d1b',
    purple: '#ac4385',
    sunflowerYellow: '#fcd304',
    pinkishGrey: '#cfcfcf',
    goldenYellow: '#fcd20f',
    grey: '#e1e1e1',
    white: '#ffffff',
    borderGrey: '#0000003b',
    scrollbarBackground: '#eaeaea',
    greyBackground: '#f5f5f5',
    greyBackground2: '#E9E9E9',
    divider: '#e6e6e6',
    blue: '#4267b2',
    SwitchColor: '#d2d2d2',
    lightPurple: '#f6ecf2',
    yellowBackground: '#fff8da',
    yellowBackground2: '#FCD101',
    darkGrey: '#7a7a79',
    red: '#ff0000',
    green: '#4E8153',
    gold: '#9F8938',

    // panels layout colors
    lightBlue: '#7CC7FF',
    darkBlue: '#2A8BD3',
    darkGrey_2: '#494949',
    lightGrey: '#7C7C7C',
    lightOrange: '#FD9377',
    darkOrange: '#D34F3D',
};

// see https://mui.com/material-ui/customization/palette/#adding-new-colors
declare module '@mui/material/styles' {
    interface Palette {
        // extra palette sections can be added here
        // someSectionName: ISomeSectionName;
    }
    interface PaletteOptions {
        // extra palette sections can be added here
        // someSectionName: ISomeSectionName;
    }

    interface SimplePaletteColorOptions {
        // uncomment to set custom section properties
        // light?: string;
        // main: string;
        // dark?: string;
        // contrastText?: string;
    }
    interface PaletteColor {
        white: string;
        black: string;
        purple: string;
        sunflowerYellow: string;
        pinkishGrey: string;
        goldenYellow: string;
        grey: string;
        borderGrey: string;
        scrollbarBackground: string;
        greyBackground2: string;
        greyBackground: string;
        divider: string;
        blue: string;
        SwitchColor: string;
        lightPurple: string;
        darkGrey: string;
        red: string;
        green: string;
        gold: string;
        yellowBackground2: string;
        yellowBackground: string;
        lightBlue: string;
        darkBlue: string;
        lightGrey: string;
        darkGrey_2: string;
        lightOrange: string;
        darkOrange: string;
    }
}

// https://mui.com/material-ui/customization/palette/
export const palette: PaletteOptions = {
    primary,
};
