import React, { FC, useState } from 'react';
import { Box, Button, Icon, Select, TextField, Typography, Collapse } from 'components/atoms';
import { useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ButtonSize } from 'components/atoms/Button';
import { TextFieldSize, TextFieldType } from 'components/atoms/TextField';
import { SelectSize } from 'components/atoms/Select';

interface IProps {
    open: boolean;
    calculate: () => void;
    calculateValue: number | null;
    setCalculateValue: (value: number | null) => void;
    inModal?: boolean;
    selectValue: string;
    setSelectValue: (value: string) => void;
}

export const selectOptions = [
    { label: '5 שנים', value: '5' },
    { label: '10 שנים', value: '10' },
    { label: '15 שנים', value: '15' },
    { label: '20 שנים', value: '20' },
];

const PricingTransformersSimulatorHeader: FC<IProps> = ({
    open,
    calculate,
    calculateValue,
    setCalculateValue,
    inModal,
    selectValue,
    setSelectValue,
}) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Box
            display='flex'
            flexDirection={{ md: 'row', xs: 'column' }}
            alignItems={{ md: 'center' }}
            justifyContent={{ xs: 'center', md: 'unset' }}
            bgcolor='primary.lightPurple'
            py={{ md: '26px', xs: '30px' }}
            px={{ md: '30px' }}
            gap={{ md: '20px', xs: '6px' }}
            borderRadius={(theme) =>
                isDesktop ? (open ? `${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0` : theme.borderRadius.medium) : 'none'
            }
        >
            <Box
                mx={{ xs: 'auto', md: 0 }}
                mt={inModal ? '10px' : 0}
                display={{ xs: 'flex', md: 'block' }}
                alignItems={'center'}
                onClick={() => setExpanded((prev) => !prev)}
            >
                <>
                    {isDesktop ? (
                        <Typography.Body2>סימולטור ממירים</Typography.Body2>
                    ) : (
                        <Typography.Body3>סימולטור ממירים</Typography.Body3>
                    )}
                    {!isDesktop && !inModal && (
                        <Box
                            position='absolute'
                            right={10}
                            sx={{
                                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                        >
                            <Icon.DownArrow />
                        </Box>
                    )}
                </>
            </Box>
            <Box
                display='flex'
                alignItems='center'
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={expanded || isDesktop || inModal ? '20px' : 0}
            >
                <Box width={{ md: '330px', xs: '95%' }}>
                    <TextField
                        placeholder='הקלידו הספק פנלים DC'
                        type={TextFieldType.Number}
                        textAlign='start'
                        value={calculateValue?.toString()}
                        onChange={(e) => setCalculateValue(+e.target.value)}
                        size={isDesktop ? TextFieldSize.Medium : TextFieldSize.Standard}
                    />
                </Box>
                <Collapse in={expanded || isDesktop || inModal}>
                    <Box
                        display='flex'
                        alignItems='center'
                        flexDirection={{ xs: 'column', md: 'row' }}
                        gap='20px'
                        mx={{ xs: 'auto', md: 0 }}
                    >
                        <Box width={{ md: '330px', xs: '95%' }} order={0}>
                            <Select
                                fontSize={theme.fontSizes.large}
                                borderRadius={theme.borderRadius.regular}
                                size={isDesktop ? SelectSize.Large : SelectSize.Regular}
                                options={selectOptions}
                                value={selectValue?.toString() || ''}
                                onChange={(e) => setSelectValue(e.target.value)}
                            />
                        </Box>
                        <Box width={{ md: '140px', xs: '95%' }} order={{ xs: 2, md: 1 }}>
                            <Button.Contained onClick={calculate} size={isDesktop ? ButtonSize.REGULAR : ButtonSize.MEDIUM}>
                                {isDesktop ? (
                                    <Typography.Body2 color={theme.palette.primary.white}>חישוב</Typography.Body2>
                                ) : (
                                    <Typography.Body3 color={theme.palette.primary.white}>חישוב</Typography.Body3>
                                )}
                            </Button.Contained>
                        </Box>
                        <Box width={{ md: '345px', xs: '95%' }} order={{ xs: 1, md: 2 }}>
                            <Typography.Body5>
                                המערכת מחשבת באופן אוטומטי את הספק הממירים AC ע"פ יחס העמסה בקירוב של 35% מהספק הפאנלים (DC)
                            </Typography.Body5>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </Box>
    );
};

export default PricingTransformersSimulatorHeader;
