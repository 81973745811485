import React, { FC } from 'react';
import { Box, Button, Link, Typography, Grid } from 'components/atoms';
import { runThreeTimes } from 'utils/runThreeTimes';
import { useTheme } from '@mui/material';
import { ButtonSize } from 'components/atoms/Button';
import { IBuiltComparisonData } from 'models/OffersData';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useNavigate } from 'react-router-dom';

interface IProps {
    offersForCompare: IBuiltComparisonData[];
    handleElectQuotation: (quotationId: string) => void;
    onCloseComparisonTable: () => void;
}

const OffersComparisonTableFooter: FC<IProps> = ({ offersForCompare, handleElectQuotation, onCloseComparisonTable }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const navigate = useNavigate();
    const footerData = offersForCompare.map((offer: IBuiltComparisonData) => {
        return { offerId: offer.offerId, contractorId: offer.contractorId, ...offer.data.footer };
    });

    return (
        <Box position='sticky' bottom='0' bgcolor='primary.white' mt={{ xs: '20px', md: 2 }} textAlign='center'>
            {!isDesktop && (
                <Box py='10px'>
                    <Typography.Body3>סה"כ ניקוד משוקלל מחיר, איכות והתאמה:</Typography.Body3>
                </Box>
            )}
            <Grid container columns={{ md: 11, xs: 12 }} columnSpacing={{ xs: 4, md: 20 }}>
                {isDesktop && (
                    <Grid item md={2}>
                        <Box bgcolor='primary.yellowBackground' display='flex' alignItems='center' height='100%' px='5px' pl='20px'>
                            <Typography.Body4 font={theme.fontFamilies.xBold}>סה"כ ניקוד משוקלל מחיר, איכות והתאמה:</Typography.Body4>
                        </Box>
                    </Grid>
                )}
                {runThreeTimes.map((num: any, index: number) => (
                    <Grid key={num} item xs={4} md={3}>
                        <Box
                            bgcolor={footerData[num] || !isDesktop ? 'primary.yellowBackground' : 'primary.white'}
                            key={index}
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            height='100%'
                        >
                            {footerData[num] ? (
                                <Box
                                    display='flex'
                                    flexDirection={{ xs: 'column', md: 'row' }}
                                    gap={{ xs: 10, md: 0 }}
                                    height={{ xs: '120px', md: '70px' }}
                                    justifyContent={{ xs: 'center', md: 'space-between' }}
                                    alignItems='center'
                                    width='100%'
                                    px={{ xs: 0, md: 20 }}
                                >
                                    {!isDesktop && (
                                        <Typography.Body4 key={index} font={theme.fontFamilies.xBold}>
                                            {footerData[num]?.value}
                                        </Typography.Body4>
                                    )}
                                    <Link
                                        onClick={() => {
                                            navigate(`${footerData[num].offerId}`);
                                            onCloseComparisonTable();
                                        }}
                                    >
                                        <Typography.Body5>{isDesktop ? 'מידע נוסף אודות החברה' : 'מידע נוסף'}</Typography.Body5>
                                    </Link>
                                    {isDesktop && (
                                        <Typography.Body1 key={index} font={theme.fontFamilies.xBold}>
                                            {footerData[num]?.value}
                                        </Typography.Body1>
                                    )}

                                    <Box width={{ xs: '95%', md: '125px' }} mx={{ xs: 'auto', md: 0 }}>
                                        <Button.Contained
                                            borderRadius={theme.borderRadius.large}
                                            onClick={() => {
                                                handleElectQuotation(footerData[num].offerId);
                                            }}
                                            size={ButtonSize.SMALL}
                                        >
                                            בחירת הצעה
                                        </Button.Contained>
                                    </Box>
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OffersComparisonTableFooter;
