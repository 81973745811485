import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, TextField } from 'components/atoms';
import { TextFieldSize } from 'components/atoms/TextField';
import texts from 'texts.json';
import { useTheme } from '@mui/material';
import { MapContext } from 'contexts/MapContext';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IValue {
    plot: string;
    block: string;
}

interface IField {
    label: string;
    field: string;
}

const SearchByPlot = () => {
    const [value, setValue] = useState<IValue>({
        plot: '',
        block: '',
    });
    const { searchByPlot, calculator } = useContext(MapContext);
    const isDesktop = useIsDesktop();
    const theme = useTheme();

    useEffect(() => {
        if (calculator?.lot && calculator?.parsel) {
            setValue({ block: calculator.lot, plot: calculator.parsel });
        }
    }, [calculator?.lot, calculator?.parsel]);

    return (
        <>
            <Box
                display={'flex'}
                flexDirection={{ md: 'row', xs: 'column' }}
                gap='15px'
                width={{ md: '66%', xs: '100%' }}
                justifyContent='center'
                mx='auto'
            >
                {texts.calculator.stepAddress.address.searchByPlot.fields.map((field: IField, index: number) => {
                    return (
                        <Box key={index}>
                            <TextField
                                value={value[field.field as keyof IValue]}
                                size={isDesktop ? TextFieldSize.Large : TextFieldSize.Standard}
                                placeholder={field.label}
                                onChange={(e) => setValue((prev) => ({ ...prev, [field.field]: e.target.value }))}
                                font={theme.fontFamilies.light}
                            />
                        </Box>
                    );
                })}
            </Box>
            {calculator?.noResults && <Box my={'8px'}>אין תוצאות</Box>}
            <Box width='66%' margin='0 auto' mt='10px'>
                <Button.Contained disabled={!value.block && !value.plot} onClick={() => searchByPlot(value)}>
                    מצא
                </Button.Contained>
            </Box>
        </>
    );
};

export default SearchByPlot;
