import React, { FC, ReactNode } from 'react';
import { Box } from 'components/atoms';

const sx = {
    root: {
        bgcolor: 'primary.goldenYellow',
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        px: '20px',
        borderRadius: '0px 50px 50px 0px',
    },
};

interface IProps {
    children: ReactNode;
}

const TextOnImage: FC<IProps> = ({ children }) => {
    return <Box sx={sx.root}>{children}</Box>;
};

export default TextOnImage;
