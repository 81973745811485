/* eslint-disable max-lines */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Typography } from 'components/atoms';
import PricingAccordionSectionHeader from 'components/molecules/pricing/PricingAccordionSectionHeader';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import PricingAccordion from 'components/organisms/pricing/PricingAccordion';
import { DevTool } from '@hookform/devtools';
import { PRODUCTION } from 'utils/envUtils';
import RangeTitle from 'components/molecules/pricing/pricingEntrepreneur/RangeTitle';
import PricingContractorAccordionDetails from 'components/molecules/pricing/pricingContractor/PricingContractorAccordionDetails';
import { Item, SetContractorPriceListReq } from 'models/api/pricingContractor';
import { buildAdditionsData, buildPricingContractorData, contractorRangesMinMax } from 'data/PricingContractorDataPrecessing';
import { PricingContractorRangesData } from 'models/PricingData';
import { getFlattenedProps } from 'utils/getFlattenedProps';
import { PriceListStatuses } from 'models/api/common';
import AddOnPricingPanel from 'components/molecules/addOnPricingPanel/AddOnPricingPanel';
import InfoModal from 'components/molecules/InformationModal';
import { useGetCompanySettings } from 'api/queries/common';

export interface IPricingPanelData {
    fieldIndex: number;
    data: PricingContractorRangesData;
}

interface IProps {
    items: Item[];
    isEdit: string;
    setContractorPriceList: (params: SetContractorPriceListReq) => void;
    createContractorPriceList: (item: Item) => void;
    deleteContractorPriceList: (id: string) => void;
    downloadTechSpecifications: () => void;
    setIsEdit: (id: string) => void;
}

const PricingContractorRanges: FC<IProps> = ({
    items,
    setContractorPriceList,
    createContractorPriceList,
    deleteContractorPriceList,
    downloadTechSpecifications,
    isEdit,
    setIsEdit,
}) => {
    const isDesktop = useIsDesktop();
    const { data: settings } = useGetCompanySettings();
    const [pricingPanelData, setPricingPanelData] = useState<IPricingPanelData | null>(null);
    const [copyPasteState, setCopyPasteState] = useState<PricingContractorRangesData | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const methods = useForm<{ rangesData: PricingContractorRangesData[] }>({
        defaultValues: {
            rangesData: [],
        },
        mode: 'onChange',
    });
    const { isDirty } = methods.formState;

    const { append, remove } = useFieldArray({
        keyName: 'key',
        control: methods.control,
        name: 'rangesData',
    });

    const watchFieldArray = methods.watch('rangesData');

    const resetForm = useCallback(
        (items: Item[]) => {
            if (items) {
                methods.reset({
                    rangesData: buildPricingContractorData(items),
                });
                if (pricingPanelData) {
                    setPricingPanelData({
                        fieldIndex: pricingPanelData.fieldIndex,
                        data: watchFieldArray[pricingPanelData.fieldIndex],
                    });
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [methods]
    );

    const handleUpdateRange = (id: string) => {
        methods.handleSubmit(
            () => {
                updateRange(id);
            },
            (errors: any) => console.log(errors)
        )();
    };

    useEffect(() => {
        resetForm(items);
    }, [items, resetForm]);

    const updateRange = (rangeId: string) => {
        if (!isDirty) return; // if no changes, do not update
        const range = items?.some((item) => item.item_id === rangeId); // check if range is not new
        const { min, max, status, id, ...otherData } = watchFieldArray.find((item) => item.id === rangeId) || {};

        const data = {
            from_power: min,
            to_power: max,
            ...otherData,
        };
        try {
            if (range) {
                setContractorPriceList({
                    priceListId: rangeId,
                    item: getFlattenedProps(data),
                });
            } else {
                createContractorPriceList(getFlattenedProps(data) as Item);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onSwitchChangeHandler = (field: PricingContractorRangesData) => {
        const range = items?.some((item) => item.item_id === field.id); // check if range is not new
        if (range) {
            return setContractorPriceList({
                priceListId: field.id,
                item: {
                    status: field.status ? PriceListStatuses.PRICE_LIST_STATUS_ACTIVE : PriceListStatuses.PRICE_LIST_STATUS_INACTIVE,
                },
            });
        }
        return;
    };

    const onCopy = (rangeID: string) => {
        setCopyPasteState(watchFieldArray.find((field) => field.id === rangeID) || null);
    };

    const onPaste = async (rangeID: string) => {
        const rangeIndex = watchFieldArray.findIndex((field) => field.id === rangeID);
        if (rangeIndex === -1 || !copyPasteState) return;
        const { id, min, max, ...otherData } = copyPasteState;
        setIsEdit(rangeID);
        methods.setValue(`rangesData.${rangeIndex}`, { id: rangeID, min: 0, max: 0, ...otherData }); // without await, the field is not focused
        methods.setFocus(`rangesData.${rangeIndex}.min`);
    };

    const onDuplicate = (rangeID: string) => {
        if (isEdit) {
            setOpen(true);
            return;
        }
        const rangeIndex = watchFieldArray.findIndex((field) => field.id === rangeID);
        if (rangeIndex === -1) return;
        const { id, min, max, ...otherData } = watchFieldArray[rangeIndex];
        const newRangeId = crypto.randomUUID();
        append({ id: newRangeId, min: 0, max: 0, ...otherData }, { shouldFocus: true });
        setIsEdit(newRangeId);
    };

    const onRangeRemove = (id: string, index: number) => {
        const isExistRange = items.some((item) => item.item_id === id);
        if (!isExistRange) {
            remove(index);
        } else {
            return deleteContractorPriceList(id);
        }
    };

    const onEdit = (id: string) => {
        if (isEdit && !!id) {
            setOpen(true);
        } else {
            setIsEdit(id);
        }
    };

    return (
        <FormProvider {...methods}>
            <Box mt='50px'>
                <PricingAccordionSectionHeader checked={true} onCheckboxChange={() => {}} checkboxLabel='טווחי ההספק KWp' />
            </Box>
            <Box display='flex' flexDirection='column' gap='10px' mb='20px'>
                {watchFieldArray.length > 0 &&
                    watchFieldArray.map((field, index) => (
                        <PricingAccordion
                            data={field}
                            fieldIndex={index}
                            key={field.id}
                            isEdit={isEdit}
                            onCopy={onCopy}
                            onPaste={onPaste}
                            setIsEdit={onEdit}
                            onDuplicate={onDuplicate}
                            onSwitchChangeHandler={() => onSwitchChangeHandler(field)}
                            switchName={`rangesData.${index}.status`}
                            summaryElement={
                                <RangeTitle
                                    rules={contractorRangesMinMax(settings?.data.item)}
                                    data={field}
                                    size={isDesktop ? 'regular' : 'small'}
                                    fieldIndex={index}
                                    isEdit={isEdit === field.id}
                                />
                            }
                            detailsElement={
                                <PricingContractorAccordionDetails
                                    onCopy={() => onCopy(field.id)}
                                    onPaste={() => onPaste(field.id)}
                                    onDuplicate={() => onDuplicate(field.id)}
                                    data={field}
                                    removeRange={(id) => onRangeRemove(id, index)}
                                    isEdit={isEdit === field.id}
                                    setIsEdit={onEdit}
                                    fieldIndex={index}
                                    showRange={isDesktop ? false : true}
                                    updateRange={updateRange}
                                    setPricingPanelData={setPricingPanelData}
                                    downloadTechSpecifications={downloadTechSpecifications}
                                    isItemToDelete={watchFieldArray.length > 1}
                                    handleUpdateRange={handleUpdateRange}
                                />
                            }
                        />
                    ))}
            </Box>
            {pricingPanelData && (
                <AddOnPricingPanel
                    open={!!pricingPanelData}
                    data={pricingPanelData && buildAdditionsData(pricingPanelData, settings?.data.item)}
                    onClose={() => setPricingPanelData(null)}
                    control={methods.control}
                    isContractor={true}
                    onSaveHandler={pricingPanelData ? () => handleUpdateRange(pricingPanelData.data.id) : undefined}
                />
            )}
            {!PRODUCTION && <DevTool control={methods.control} />}
            {open && (
                <InfoModal open={open} onClose={() => setOpen(false)}>
                    <Typography.Body1>לא ניתן לערוך טווח בעת עריכת טווח אחר</Typography.Body1>
                </InfoModal>
            )}
        </FormProvider>
    );
};

export default PricingContractorRanges;
