import React, { FC } from 'react';
import { Box, InfoTooltip, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    title?: string;
    result: string;
    firstText: string;
    greenRoute?: { title: string; info: string } | null;
}

const RenderResultData: FC<IProps> = ({ title, result, firstText, greenRoute }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    return (
        <Box display='flex' flexDirection='column' justifyContent={'space-between'} height={{ md: '100%', xs: 'auto' }}>
            <Box display={'flex'} alignItems={'baseline'} justifyContent={'space-between'}>
                <Box>{isDesktop ? <Typography.ThinTitle>{title}</Typography.ThinTitle> : <Typography.Body5>{title}</Typography.Body5>}</Box>
                <InfoTooltip firstText={firstText} />
            </Box>
            <Box display={isDesktop ? 'flex' : 'row'} columnGap={'4px'}>
                {isDesktop ? (
                    <Typography.Body2>{result}</Typography.Body2>
                ) : (
                    <Typography.Body3 font={theme.fontFamilies.bold}>{result}</Typography.Body3>
                )}
                {greenRoute && (
                    <Box display={'flex'} alignItems={'baseline'} columnGap={'4px'}>
                        <Typography.Body6 color={theme.palette.primary.green}>{greenRoute.title}</Typography.Body6>
                        <InfoTooltip firstText={greenRoute.info} fill={theme.palette.primary.green} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default RenderResultData;
