import LoginDialogs from 'components/organisms/authentication/LoginDialogs';
import React, { FC, ReactNode, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getUser } from 'utils/getUser';
import { Dialogs, IAuthContext, LoginRes } from 'models/api/auth';
import AppBar from 'components/organisms/appBar/AppBar';
import { Box, Loader } from 'components/atoms';
import useLoading from 'hooks/useLoding.hook';
import { appBarHeight } from 'theme/sizes';
import { useLocation } from 'react-router-dom';
import { PATH } from 'paths';
import usePullToRefresh from 'hooks/usePullToRefresh.hook';
import queryClient from 'api/apiClient';
import { USER_STORAGE_KEY } from 'consts/storage.const';
import storageService from 'services/storage.service';
import ScreenResolutionModal from 'components/molecules/ScreenResolutionModal';
import RemainingSearchesProvider from 'contexts/RemainingSearchesContext';

interface IProps {
    children: ReactNode;
}

export const AuthContext = createContext<IAuthContext>({
    user: undefined,
    showDialog: null,
    setShowDialog: () => {},
    handleLogin: (user: LoginRes, saveToStorage: boolean, callback: () => void) => {},
    handleLogout: () => {},
});

const AuthProvider: FC<IProps> = ({ children }) => {
    const isLoading = useLoading();
    const path = useLocation().pathname;

    const [user, setUser] = useState<LoginRes | undefined>(getUser());
    const [showDialog, setShowDialog] = useState<Dialogs | null>(null);

    const isPricing = path.includes(PATH.PRICING);
    const isFreePath = path.includes(PATH.ADDRESS) || path.includes(PATH.GET_ADDRESS) || path === '/';
    const ref = useRef<HTMLDivElement>(null);

    const onTrigger = async () => {
        await queryClient.refetchQueries();
    };

    usePullToRefresh(ref, onTrigger);

    const handleLogin = useCallback((user: LoginRes, saveToStorage: boolean, callback: Function) => {
        storageService.set(USER_STORAGE_KEY, user, saveToStorage);
        setUser(user);
        callback();
    }, []);

    const handleLogout = useCallback(() => {
        storageService.clear(USER_STORAGE_KEY);
        setUser(undefined);
    }, []);

    useEffect(() => {
        if (!user && !!getUser()) {
            setUser(getUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isFreePath && !user?.user) {
            setShowDialog(Dialogs.LOGIN);
        }
    }, [isFreePath, user?.user]);

    const values = useMemo(
        () => ({
            user,
            showDialog,
            setShowDialog,
            handleLogin,
            handleLogout,
        }),
        [user, showDialog, setShowDialog, handleLogin, handleLogout]
    );

    return (
        <AuthContext.Provider value={values}>
            {user?.user || (!user?.user && isFreePath) ? (
                <RemainingSearchesProvider>
                    <div ref={ref}>
                        <AppBar setShowDialog={setShowDialog} />
                        <Box height={{ md: `calc(100vh - ${appBarHeight}px)`, xs: 'calc(100dvh - 49px)' }} className='container'>
                            <Box height={'100%'}>
                                {!isPricing && isLoading && <Loader />}
                                {children}
                                <ScreenResolutionModal />
                            </Box>
                        </Box>
                    </div>
                </RemainingSearchesProvider>
            ) : null}
            {showDialog && <LoginDialogs showDialog={showDialog} setShowDialog={setShowDialog} />}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
