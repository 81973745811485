import React, { FC } from 'react';
import CardWithInfo from 'components/molecules/CardWithInfo';
import RenderEnvironmentalData from 'components/molecules/RenderEnvironmentalData';
import TitleWithIcon from 'components/molecules/TitleWithIcon';
import { Box, Grid, Icon, Title, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import texts from 'texts.json';
import { useIsDesktop } from 'hooks/useIsDesktop';
interface IProps {
    carbon_emissions?: string;
    electric_vehicle_charging_cycles?: string;
    electricity_consumption?: string;
}

const EnvironmentalData: FC<IProps> = ({ carbon_emissions, electric_vehicle_charging_cycles, electricity_consumption }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { title, electricity_consumption_field, carbon_emissions_field, electric_vehicle_charging_cycles_field, legalNote } =
        texts.results.environmental_data;
    const environmentalData = [
        {
            title: electricity_consumption_field.title,
            result: `${electricity_consumption_field.data} - ${electricity_consumption || ''} ${electricity_consumption_field.data_2}`,
            icon: <Icon.HouseElectricity />,
            info: electricity_consumption_field.info,
        },
        {
            title: carbon_emissions_field.title,
            result: `${carbon_emissions || ''} ${carbon_emissions_field.data}`,
            info: carbon_emissions_field.info,
            icon: <Icon.Chimney />,
        },
        {
            title: electric_vehicle_charging_cycles_field.title,
            result: `${electric_vehicle_charging_cycles || ''} ${electric_vehicle_charging_cycles_field.data}`,
            icon: <Icon.ElectricCar />,
            info: electric_vehicle_charging_cycles_field.info,
        },
    ];

    return (
        <Box
            width={{
                md: '1140px',
                xs: '90%',
            }}
            mx='auto'
            pt={{ md: '55px', xs: '30px' }}
            pb={{ md: '55px', xs: '215px' }}
        >
            <Box display='flex' justifyContent='center'>
                <TitleWithIcon icon={<Icon.Leaves />}>
                    {isDesktop ? <Title color={theme.palette.primary.black} text={title} /> : <Typography.Body2>{title}</Typography.Body2>}
                </TitleWithIcon>
            </Box>
            <Grid container pt={{ md: '38px', xs: '30px' }} columnSpacing={22} rowSpacing={10}>
                {environmentalData.map((item) => (
                    <Grid item key={item.title} xs={12} md={4} height={{ md: '112px', xs: '100px' }}>
                        <CardWithInfo firstText={item.info} title={item.title} isSpaceBetween>
                            <RenderEnvironmentalData title={item.title} result={item.result} icon={item.icon} />
                        </CardWithInfo>
                    </Grid>
                ))}
            </Grid>
            <Box pt={'32px'}>
                <Typography.Body4 font={theme.fontFamilies.light}>{legalNote}</Typography.Body4>
            </Box>
        </Box>
    );
};

export default EnvironmentalData;
