import { FC, ReactNode } from 'react';
import { styled, Tabs as MuiTabs } from '@mui/material';

interface ITabsBase {
    value: string;
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
    children: ReactNode;
    color?: string | null;
}

const TabsBase = styled(MuiTabs, {
    shouldForwardProp: (prop: string) => prop !== 'color',
})<ITabsBase>(({ theme, color }) => ({
    '&.MuiTabs-root': {
        minHeight: '30px',
        height: '30px',
        width: '100%',
        color: color ? color : theme.palette.primary.black,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: color ? color : theme.palette.primary.sunflowerYellow,
    },
}));

const Tabs: FC<ITabsBase> = ({ value, onChange, children, color }) => {
    return (
        <TabsBase value={value} onChange={onChange} color={color ?? undefined} centered>
            {children}
        </TabsBase>
    );
};

export default Tabs;
