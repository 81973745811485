import React, { FC, Fragment, useEffect } from 'react';
import { Typography, Grid, Divider, Box } from 'components/atoms';
import { contractorAccordionPaymentLabels } from 'data/PricingContractorDataPrecessing';
import { FormHelperText, useTheme } from '@mui/material';
import PricingCardWithInfo from 'components/molecules/pricing/PricingCardWithInfo';
import { Control, useFormContext } from 'react-hook-form';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { TextFieldType } from 'components/atoms/TextField';
import { PricingContractorRangesData } from 'models/PricingData';
import { buildPricingAccordionData } from 'data/pricing';

interface IProps {
    data: PricingContractorRangesData['paymentTerms'];
    fieldIndex: number;
    control: Control;
    isEdit: boolean;
}

const PricingContractorAccordionDetailsPaymentTerms: FC<IProps> = ({ data, fieldIndex, control, isEdit }) => {
    const paymentTermsData: any = buildPricingAccordionData(data, contractorAccordionPaymentLabels());
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { setError, formState, watch, clearErrors } = useFormContext();
    const { errors } = formState;
    const paymentTerms = watch(`rangesData.${fieldIndex}.paymentTerms`);
    const total = Object.values(paymentTerms)?.reduce((sum: number, item: any) => sum + +item, 0);
    const error: any = errors?.rangesData;

    useEffect(() => {
        if (isEdit && total !== 100) {
            setError(`rangesData.${fieldIndex}.paymentTerms`, {
                type: 'validate',
                message: 'סה"כ חייב להיות 100%',
            });
        } else {
            clearErrors(`rangesData.${fieldIndex}.paymentTerms`);
        }
    }, [clearErrors, fieldIndex, setError, total, isEdit]);

    return (
        <Grid container bgcolor='primary.white'>
            {paymentTermsData.map((item: any, index: number) => (
                <Fragment key={index}>
                    <Grid item md={2} xs={12}>
                        <PricingCardWithInfo
                            title={item.title.label}
                            info={item.title.info}
                            value={`${item.value}%`}
                            control={control}
                            isEdit={isEdit}
                            name={`rangesData.${fieldIndex}.paymentTerms.${item.name}`}
                            type={TextFieldType.Number}
                        />
                    </Grid>
                    {!isDesktop && (
                        <Box width='95%' m='10px auto'>
                            <Divider />
                        </Box>
                    )}
                </Fragment>
            ))}
            <Grid item md={2} xs={12} display='flex' flexDirection='column' gap='20px' p='15px'>
                <Typography.Body4>סה"כ</Typography.Body4>
                <Typography.Body2 font={theme.fontFamilies.light}>{total || 0}%</Typography.Body2>
                {isEdit && (
                    <Box display={'flex'} columnGap={'8px'}>
                        <FormHelperText error sx={{ fontFamily: theme.fontFamilies.bold }}>
                            {error?.[fieldIndex]?.paymentTerms?.message as string}
                        </FormHelperText>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default PricingContractorAccordionDetailsPaymentTerms;
