import React, { FC } from 'react';
import { Box } from 'components/atoms';

interface IProps {}

const PricingPersonalArea: FC<IProps> = () => {
    return <Box>PricingPersonalArea</Box>;
};

export default PricingPersonalArea;
