import React, { FC, ReactNode } from 'react';
import { Box, Dialog, Divider, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import TextWithLink from 'components/molecules/TextWithLink';
import appLogo from 'assets/images/app-logo.svg';
import { IAuthDialogData } from 'models/DialogData';
import { useIsDesktop } from 'hooks/useIsDesktop';

const sx = {
    children: {
        mt: { xs: '25px', md: '38px' },
    },
    title: {
        width: { md: '530px', xs: '100%' },
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        mx: 'auto',
    },
    divider: {
        mt: { md: '56px', xs: '31px' },
        mb: { md: '35px', xs: '27px' },
    },
    content: {
        width: { md: '400px', xs: '90%' },
        mx: 'auto',
    },
    logo: {
        width: { md: '214px', xs: '134px' },
        verticalAlign: 'text-bottom',
    },
};

interface IProps {
    children: ReactNode;
    data: IAuthDialogData;
    open: boolean;
    isFooter?: boolean;
    isCloseIcon?: boolean;
    onClose: (e?: any, reason?: any) => void;
}

const AuthDialog: FC<IProps> = ({ children, data, open, onClose, isFooter, isCloseIcon }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    const { title } = data.header;

    return (
        <Dialog open={open} onClose={onClose} desktopWidth={906} isCloseIcon={isCloseIcon} isFullScreen={!isDesktop}>
            {/* Header */}
            <Box sx={{ ...sx.title, columnGap: '12px' }} pt={!isCloseIcon ? { md: '40px', xs: '30px ' } : undefined}>
                <Box component='img' src={appLogo} sx={sx.logo} />
                {isDesktop ? (
                    <Typography.XLargeTitle color={theme.palette.primary.purple}>{title}</Typography.XLargeTitle>
                ) : (
                    <Typography.LargeTitle color={theme.palette.primary.purple}>{title}</Typography.LargeTitle>
                )}
            </Box>
            {/* Header Element */}
            {data.headerElement && <Box sx={sx.content}>{data.headerElement}</Box>}
            {/* Children */}
            <Box sx={sx.content}>
                <Box sx={sx.children}>{children}</Box>
            </Box>
            {/* Footer */}
            {!isFooter && (
                <>
                    <Box sx={sx.divider}>
                        <Divider />
                    </Box>
                    <Box sx={sx.content} mb='35px'>
                        {data.footerElement
                            ? data.footerElement
                            : data.footer && <TextWithLink data={data.footer} colorLink={theme.palette.primary.purple} />}
                    </Box>
                </>
            )}
        </Dialog>
    );
};

export default AuthDialog;
