import { useTheme } from '@mui/material';
import { Box, Typography, Link, Title } from 'components/atoms';
import { RemainingSearchesContext } from 'contexts/RemainingSearchesContext';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { PATH } from 'paths';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import texts from 'texts.json';

const ButtonLink: FC<{ title: string; isBackToCalculator?: boolean }> = ({ title, isBackToCalculator = false }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { onOpen: onOpenRemainingSearchesModal } = useContext(RemainingSearchesContext);
    const roofTypeUrl = `/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.TYPE}`;

    const onClickHandler = () => {
        if (isBackToCalculator) {
            navigate(roofTypeUrl);
        } else {
            onOpenRemainingSearchesModal();
        }
    };

    return (
        <Link onClick={onClickHandler}>
            <Box sx={{ whiteSpace: 'nowrap' }}>
                <Typography.Body5 color={theme.palette.primary.black}>{title}</Typography.Body5>
            </Box>
        </Link>
    );
};

interface IProps {
    address: string | undefined;
    isCompanyQuotationUnlocked: boolean;
}

const Header: FC<IProps> = ({ address = '', isCompanyQuotationUnlocked }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    const {
        buttons: { backToCalculator, newSearch },
        title,
    } = texts.results;

    const buttonProps = [
        {
            title: backToCalculator,
            display: isCompanyQuotationUnlocked,
            isBackToCalculator: true,
        },
        {
            title: newSearch,
            display: true,
            isBackToCalculator: false,
        },
    ];

    return !isDesktop ? (
        <Box display={'flex'} flexDirection={'column'} my={'16px'} rowGap={'16px'}>
            <Box display='flex' justifyContent={'space-between'}>
                {buttonProps.map((button, index) => (
                    <Box key={index}>
                        {button.display && <ButtonLink key={index} title={button.title} isBackToCalculator={button.isBackToCalculator} />}
                    </Box>
                ))}
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <Typography.Body2 color={theme.palette.primary.purple}>{`${title} \n ${address}`}</Typography.Body2>
            </Box>
        </Box>
    ) : (
        <Box py='40px' display='flex' alignItems='center' justifyContent={'space-between'} position='relative'>
            <Box>{isCompanyQuotationUnlocked && <ButtonLink title={backToCalculator} isBackToCalculator />}</Box>
            <Title text={`${title} - ${address}`} />
            <ButtonLink title={newSearch} />
        </Box>
    );
};

export default Header;
