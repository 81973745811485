import { useCreateProject } from 'api/queries/calculator';
import { Box } from 'components/atoms';
import ContentSection from 'components/molecules/ContentSection';
import { MapContext } from 'contexts/MapContext';
import { RemainingSearchesContext } from 'contexts/RemainingSearchesContext';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { EAmbassadorType } from 'models/api/common';
import { useContext, useEffect, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import texts from 'texts.json';
import { AuthContext } from 'contexts/AuthContext';
import { Dialogs } from 'models/api/auth';
import { useGetRemainingProjects } from 'api/queries/common';
import { useGetCompanyDetails } from 'api/queries/management';
import { CompanyStatus } from 'models/api/management';

const GetAddress = () => {
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();
    const [searchParams] = useSearchParams();
    const { setShowMapSection, setFooterButtonData, calculator, setCalculator } = useContext(MapContext);
    const { onOpen: onOpenRemainingSearchesModal, setOnApprove } = useContext(RemainingSearchesContext);
    const { user, setShowDialog } = useContext(AuthContext);

    const isCompany = !!user?.user.owner_company;

    const { mutate: createProject } = useCreateProject();
    const { data: companyDetailsData } = useGetCompanyDetails(isCompany);
    const { data: remainingSearches } = useGetRemainingProjects(true);

    const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

    const remainingProjects = remainingSearches?.data.item.remaining_projects ?? 0;
    const isCompanyNotApproved = isCompany && companyDetailsData?.data.item.status !== CompanyStatus.APPROVED;

    const onCreateProjectHandler = useCallback(() => {
        if (calculator?.itemEast && calculator?.itemNorth && calculator?.propertyType) {
            const { address, itemEast, itemNorth, plot, propertyType } = calculator;
            const params = Object.fromEntries(searchParams.entries());
            createProject(
                {
                    itemEast,
                    itemNorth,
                    address: (address || plot) as string,
                    propertyType,
                    ambassador_type: params?.amb_type as EAmbassadorType,
                    ambassador: params?.amb_id,
                },
                {
                    onSuccess: (projectId) => {
                        setCalculator({ ...calculator, projectId: projectId?.data?.item?.item_id, step: 2 });
                        navigate(texts.calculator.stepAddress.getAddress.button.link);
                    },
                }
            );
        }
    }, [calculator, createProject, navigate, searchParams, setCalculator]);

    const checkRemainingProjects = useCallback(() => {
        if (remainingProjects <= 1) {
            onOpenRemainingSearchesModal();
        } else {
            onCreateProjectHandler();
        }
    }, [onCreateProjectHandler, onOpenRemainingSearchesModal, remainingProjects]);

    const onClick = useCallback(() => {
        if (!user?.user) {
            setShowDialog(Dialogs.SIGN_UP);
            setIsButtonClicked(true);
        } else {
            checkRemainingProjects();
        }
    }, [checkRemainingProjects, setShowDialog, user?.user]);

    useEffect(() => {
        if (!isDesktop) {
            setShowMapSection(true);
            setFooterButtonData({
                text: texts.calculator.stepAddress.getAddress.button.text,
                onClick: onClick,
            });
        }
        // eslint-disable-next-line
    }, [isDesktop, calculator]);

    useEffect(() => {
        if (isButtonClicked && !!user?.user) {
            if (isCompanyNotApproved) {
                return;
            }
            checkRemainingProjects();
        }
    }, [checkRemainingProjects, isButtonClicked, isCompanyNotApproved, user]);

    useEffect(() => {
        setOnApprove(() => () => onCreateProjectHandler());
    }, [onCreateProjectHandler, setOnApprove]);

    return (
        <Box width='100%' height='100%'>
            <ContentSection
                text={texts.calculator.stepAddress.getAddress.text}
                title={`${texts.calculator.stepAddress.getAddress.title}\n ${calculator?.address || calculator?.plot || ''}`}
                media={texts.calculator.stepAddress.getAddress.media.video}
                buttonText={texts.calculator.stepAddress.getAddress.button.text}
                onClick={onClick}
            />
        </Box>
    );
};

export default GetAddress;
