import { useAgentSetProjectClientName, useCreateProjectQuotations } from 'api/queries/offers';
import useDownloadProjectQuotations from 'hooks/useDownloadProjectQuotations.hook';
import { IDownloadCompanyFileFieldsReq } from 'models/api/common';
import { ContractTypeModel } from 'models/common';
import { PATH } from 'paths';
import { useNavigate } from 'react-router-dom';

const useAgentQuotationDownload = (
    projectId: string,
    contractType: ContractTypeModel,
    companyName: string | undefined,
    quotations: { RENT: string; PURCHASE: string }
) => {
    const navigate = useNavigate();

    const { mutate: agentsSetProjectClientName } = useAgentSetProjectClientName();
    const { onDownloadProjectQuotation } = useDownloadProjectQuotations(contractType);
    const { mutate: createProjectQuotations } = useCreateProjectQuotations();

    const onCreateProjectQuotations = () => {
        createProjectQuotations(projectId, {
            onSuccess: () => {
                navigate(`/${PATH.CONTRACTOR_OFFERS}`);
            },
        });
    };
    const downloadQuotation = (itemId: string, quotationType: IDownloadCompanyFileFieldsReq) => {
        onDownloadProjectQuotation(itemId, quotationType, companyName);
    };

    const onAgentSetClientName = (name: string, quotationType: IDownloadCompanyFileFieldsReq) => {
        agentsSetProjectClientName(
            { itemId: projectId, clientName: name },
            {
                onSuccess: () => {
                    if (quotationType === IDownloadCompanyFileFieldsReq.QUOTATION_PDF) {
                        onCreateProjectQuotations();
                    } else {
                        downloadQuotation(quotations[contractType], quotationType);
                    }
                },
            }
        );
    };

    const onDownloadAgentQuotation = (clientName: string, quotationType: IDownloadCompanyFileFieldsReq) => {
        if (clientName) {
            onAgentSetClientName(clientName, quotationType);
            return;
        } else {
            if (quotationType === IDownloadCompanyFileFieldsReq.EXPANDED_QUOTATION_PDF) {
                downloadQuotation(quotations[contractType], quotationType);
            } else {
                onCreateProjectQuotations();
            }
        }
    };

    return { onDownloadAgentQuotation };
};

export default useAgentQuotationDownload;
