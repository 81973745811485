import React, { FC } from 'react';
import { Box, TextOnImage, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import ImagesSwiper from 'components/molecules/pricing/ImagesSwiper';
import { IOffersCardRightSection } from 'models/OffersData';

interface IProps {
    rightSection: IOffersCardRightSection;
}

const OffersMainCardRight: FC<IProps> = ({ rightSection }) => {
    const { images, text, value, specialChar } = rightSection;

    const theme = useTheme();
    return (
        <Box position='relative'>
            <Box width='147px' position='absolute' top='30px' zIndex={99}>
                <TextOnImage>
                    <Typography.Body6 font={theme.fontFamilies.bold}>{`${text} ${value}${specialChar}`}</Typography.Body6>
                </TextOnImage>
            </Box>
            <Box display={'flex'} height={{ md: '204px', xs: '174px' }} width={{ md: '319px' }}>
                <ImagesSwiper images={images} isMainCard={true} />
            </Box>
        </Box>
    );
};

export default OffersMainCardRight;
