import React, { FC, useState } from 'react';
import { Box, Button, Card, Typography, Grid, Icon, Divider, Link, ToggleButtonGroup, ToggleButton } from 'components/atoms';
import { useTheme } from '@mui/material';
import EditButtonSection from 'components/molecules/pricing/EditButtonSection';
import { useFormContext } from 'react-hook-form';
import ControllerTextField from 'components/organisms/ControllerTextField';
import {addCommaToNumber} from 'utils/addCommaToNumber';
import { Symbols } from 'utils/symbols';

enum CurrencyType {
    SHEKEL = 'SHEKEL',
    DOLLAR = 'DOLLAR',
    EURO = 'EURO',
}

// TODO: get values from API
export const buttonGroup = [
    {
        name: CurrencyType.SHEKEL,
        title: 'שקל',
        symbol: `${Symbols.SHEKEL}`,
        value: 1,
    },
    {
        name: CurrencyType.DOLLAR,
        title: 'דולר',
        symbol: `${Symbols.DOLLAR}`,
        value: 3.5,
    },
    {
        name: CurrencyType.EURO,
        title: 'יורו',
        symbol: `${Symbols.EURO}`,
        value: 4.2,
    },
];

export const categories = [
    {
        title: 'הספק ממיר',
        name: 'KW',
    },
    {
        title: 'עלות עם אחריות ל-5 שנים',
        name: 'priceWithWarrantyFiveYears',
    },
    {
        title: 'עלות עם אחריות ל-10 שנים',
        name: 'priceWithWarrantyTenYears',
    },
    {
        title: 'עלות עם אחריות ל-15 שנים',
        name: 'priceWithWarrantyFifteenYears',
    },
    {
        title: 'עלות עם אחריות ל-20 שנים',
        name: 'priceWithWarrantyTwentyYears',
    },
];

interface IProps {
    data: any;
    isEdit: boolean;
    setIsEdit: (value: string) => void;
    fieldIndex: number;
}

const PricingTransformersAccordionDetails: FC<IProps> = ({ data, isEdit, setIsEdit, fieldIndex }) => {
    const theme = useTheme();
    const { control, handleSubmit } = useFormContext();
    const [currencyType, setCurrencyType] = useState(buttonGroup[0]);

    return (
        <Card.GreyBorderCard>
            <Box display='flex' flexDirection='column' gap='20px' p='25px'>
                <Typography.Body2 font={theme.fontFamilies.light}>
                    לשינוי סוג המטבע לתמחור, יש לעבור למצב עריכה, המערכת תוציא אוטומטית מחירים לפי מטבע שקלי:
                </Typography.Body2>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box width='400px' height='40px'>
                        <ToggleButtonGroup value={currencyType} onChange={(_, value: any) => setCurrencyType(value)} disabled={!isEdit}>
                            {buttonGroup.map((item) => (
                                <ToggleButton value={item} key={item.name}>
                                    <Typography.Body4 color='inherit'>{item.title}</Typography.Body4>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Box>
                    <EditButtonSection
                        dataId={data.id}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        onSave={() => handleSubmit((data) => console.log(data))()}
                    />
                </Box>
                <Box>
                    <Grid
                        container
                        columns={10}
                        height='40px'
                        bgcolor={theme.palette.primary.greyBackground}
                        borderRadius={theme.borderRadius.medium}
                        alignItems='center'
                    >
                        {categories.map((category) => (
                            <Grid item md={2} key={category.name} display='flex' gap='6px'>
                                <Box>
                                    <Box ml='20px'>
                                        <Typography.Body4>{category.title}</Typography.Body4>
                                    </Box>
                                </Box>
                                {category.name !== 'KW' && (
                                    <Button.Icon onClick={() => {}}>
                                        <Icon.Info />
                                    </Button.Icon>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container columns={10} alignItems='center'>
                        {data.data?.map((item: any, index: number) => (
                            <>
                                {index !== 0 && (
                                    <Box width='100%'>
                                        <Divider />
                                    </Box>
                                )}
                                {categories.map((category, catIndex) => (
                                    <Grid item md={2} key={catIndex}>
                                        <Box display='flex' alignItems='center' height='66px'>
                                            {!isEdit ? (
                                                <Box ml='20px'>
                                                    <Typography.Body2 font={theme.fontFamilies.light}>
                                                        {category.name !== 'KW'
                                                            ? `${currencyType.symbol}${addCommaToNumber(
                                                                  item[category.name] * currencyType.value
                                                              )}`
                                                            : item[category.name]}
                                                    </Typography.Body2>
                                                </Box>
                                            ) : (
                                                <>
                                                    <Box display='flex' alignItems='center' ml='20px'>
                                                        {category.name === 'KW' ? (
                                                            <Typography.Body2 font={theme.fontFamilies.light}>
                                                                {item[category.name]}
                                                            </Typography.Body2>
                                                        ) : (
                                                            <>
                                                                <ControllerTextField
                                                                    control={control}
                                                                    name={`transformersData.${fieldIndex}.data.${index}.${category.name}`}
                                                                />
                                                                &nbsp;
                                                                {currencyType.symbol}
                                                            </>
                                                        )}
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                ))}
                            </>
                        ))}
                    </Grid>
                </Box>
                <Box>
                    <Link onClick={() => {}} textDecorationColor={theme.palette.primary.purple}>
                        <Typography.Body2 color={theme.palette.primary.purple}>עלות רכיבי תקשורת</Typography.Body2>
                    </Link>
                </Box>
            </Box>
        </Card.GreyBorderCard>
    );
};

export default PricingTransformersAccordionDetails;
