import { styled } from '@mui/material';
import { Box, Select } from 'components/atoms';
import MyCompanyProjects from 'components/organisms/myOffers/MyCompanyProjects';
import MyCompanyQuotations from 'components/organisms/myOffers/MyCompanyQuotations';
import Title from 'components/organisms/myOffers/Title';
import { FC, useState } from 'react';

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '50px',
    marginBottom: '40px',
    [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginBottom: '40px',
        columnGap: '0',
    },
}));

interface IProps {
    name: string;
}

enum EQuotationType {
    PROJECT_QUOTATION = 'project_quotation',
    ELECTED_QUOTATION = 'elected_quotation',
}

const options = [
    { value: EQuotationType.PROJECT_QUOTATION, label: 'הצעות שהופקו על ידי' },
    { value: EQuotationType.ELECTED_QUOTATION, label: 'הצעות זירת מסחר' },
];

const CompanyProjects: FC<IProps> = ({ name }) => {
    const [quotationType, setQuotationType] = useState(EQuotationType.PROJECT_QUOTATION);

    return (
        <>
            <Wrapper>
                <Title name={name} />
                <Box width={{ md: '195px', xs: '100%' }} pt={{ md: 'unset', xs: '20px' }}>
                    <Select
                        options={options}
                        onChange={(e) => {
                            setQuotationType(e.target.value);
                        }}
                        value={quotationType}
                    />
                </Box>
            </Wrapper>
            {quotationType === EQuotationType.PROJECT_QUOTATION ? <MyCompanyProjects /> : <MyCompanyQuotations />}
        </>
    );
};

export default CompanyProjects;
