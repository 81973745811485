import React, { FC, forwardRef, ReactElement, ReactNode, Ref } from 'react';
import { DialogContent, Dialog as DialogMui, Slide, styled } from '@mui/material';
import { Icon, Box, Button } from 'components/atoms';
import { TransitionProps } from '@mui/material/transitions';

interface IDialogBase {
    open: boolean;
    children: ReactNode;
    transition?: boolean;
    backgroundColor?: string;
    hideBackdrop?: boolean;
    isEconomicModel?: boolean;
    desktopWidth?: number;
    isCloseIcon?: boolean;
    disableEscapeKeyDown?: boolean;
    isFullScreen?: boolean;
    onClose: (e?: any, reason?: any) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const DialogBase: any = styled(DialogMui, {
    shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'desktopWidth' && prop !== 'isEconomicModel',
})<IDialogBase>(({ theme, backgroundColor, desktopWidth }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.white,
        position: 'relative',
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDialog-paper': {
            width: desktopWidth ? desktopWidth : 'fit-content',
            height: 'auto',
            margin: 0,
            borderRadius: theme.borderRadius.xLarge,
            boxShadow: 'unset',
            maxWidth: 'unset',
            overflow: 'hidden',
        },
    },
}));

const Dialog: FC<IDialogBase> = ({
    children,
    open,
    onClose,
    transition,
    backgroundColor,
    hideBackdrop = false,
    isEconomicModel,
    desktopWidth,
    isCloseIcon = true,
    disableEscapeKeyDown = false,
    isFullScreen = false,
}) => {
    return (
        <DialogBase
            disableEscapeKeyDown={disableEscapeKeyDown}
            TransitionComponent={transition ? Transition : transition}
            open={open}
            desktopWidth={desktopWidth}
            onClose={onClose}
            fullScreen={isFullScreen}
            backgroundColor={backgroundColor ? backgroundColor : 'primary.white'}
            hideBackdrop={hideBackdrop}
            isEconomicModel={isEconomicModel}
            scroll={'paper'}
        >
            {isCloseIcon && (
                <Box sx={{ alignSelf: 'end' }} p={{ md: '40px 40px 20px 40px', xs: '30px 30px 20px 30px' }}>
                    <Button.Icon onClick={onClose}>
                        <Icon.Close width='19px' height='19px' />
                    </Button.Icon>
                </Box>
            )}
            <DialogContent sx={{ padding: 0 }}>{children}</DialogContent>
        </DialogBase>
    );
};

export default Dialog;
