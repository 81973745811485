import { styled } from '@mui/material/styles';
import { Tooltip as MuiTooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

export enum TOOLTIP_POSITION {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
}

interface IProps extends TooltipProps {
    title: ReactNode;
    children: ReactElement;
    placement?: TOOLTIP_POSITION;
}

const Tooltip = styled(({ className, ...props }: IProps) => <MuiTooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.primary.white,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.primary.white,
            borderRadius: theme.borderRadius.regular,
            padding: '20px 20px 20px 25px',
            boxShadow: `-2.7px 1.3px 10.8px 2.2px rgba(0, 1, 0, 0.31)`,
        },
    })
);

export default Tooltip;
