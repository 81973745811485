import { ContractTypeModel } from 'models/common';

export const isDefaultQuotation = (
    contractType: ContractTypeModel,
    data: { default_quotation?: string; default_entrepreneur_quotation?: string }
) => {
    return (
        (contractType === ContractTypeModel.PURCHASE && !!data?.default_quotation) ||
        (contractType === ContractTypeModel.RENT && !!data?.default_entrepreneur_quotation)
    );
};
