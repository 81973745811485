import React, { FC, ReactNode } from 'react';
import { Drawer as MuiDrawer, styled } from '@mui/material';
import { Button, Box, Icon } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    open: boolean;
    onClose: () => void;
    children: ReactNode;
    fullWidth?: boolean;
    padding?: string;
}

const DrawerVerticalBase = styled(MuiDrawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        borderRadius: '0',
        paddingTop: '80px',
        maxHeight: '90%',
    },
}));

const DrawerHorizontalBase = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'padding',
})<IProps>(({ theme, fullWidth, padding }) => ({
    '& .MuiDrawer-paper': {
        width: fullWidth ? '100%' : 'auto',
        borderRadius: '0',
        padding: padding ? padding : '40px 30px 30px 40px',
    },
}));

const VerticalDrawer: FC<IProps> = ({ open, onClose, children }) => {
    return (
        <DrawerVerticalBase open={open} onClose={onClose} anchor='bottom'>
            <Box position='absolute' right='160px' top='80px'>
                <Button.Icon onClick={onClose}>
                    <Icon.Close width='19px' height='19px' />
                </Button.Icon>
            </Box>
            {children}
        </DrawerVerticalBase>
    );
};

const HorizontalDrawer: FC<IProps> = ({ open, onClose, children, fullWidth, padding }) => {
    const isDesktop = useIsDesktop();
    const size = isDesktop ? '27px' : '19px';
    return (
        <DrawerHorizontalBase open={open} onClose={onClose} anchor='left' fullWidth={fullWidth} padding={padding}>
            <Box position='absolute' right='40px' top='40px'>
                <Button.Icon onClick={onClose}>
                    <Icon.Close width={size} height={size} />
                </Button.Icon>
            </Box>
            {children}
        </DrawerHorizontalBase>
    );
};

const Drawer = {
    Vertical: VerticalDrawer,
    Horizontal: HorizontalDrawer,
};

export default Drawer;
