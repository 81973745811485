import { Box, Dialog, Typography } from 'components/atoms';
import { FC, ReactNode } from 'react';
import AppLogo from 'assets/images/app-logo.svg';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    open: boolean;
    children: ReactNode;
    isCloseIcon?: boolean;
    disableEscapeKeyDown?: boolean;
    onClose: (e?: any, reason?: any) => void;
}

const InfoModal: FC<IProps> = ({ open, onClose, children, isCloseIcon = false, disableEscapeKeyDown = false }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    return (
        <Dialog
            disableEscapeKeyDown={disableEscapeKeyDown}
            open={open}
            onClose={onClose}
            desktopWidth={701}
            isCloseIcon={isCloseIcon}
            isFullScreen={!isDesktop}
        >
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} p={{ md: '58px 58px 85px', xs: '53px 37px 58px' }}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} rowGap={'20px'}>
                    <img src={AppLogo} alt='logo' width={214} height={'auto'} />
                    <Typography.XLargeTitle color={theme.palette.primary.purple}>לתשומת ליבך,</Typography.XLargeTitle>
                </Box>
                {children}
            </Box>
        </Dialog>
    );
};

export default InfoModal;
