import { FC, ReactNode } from 'react';
import { Typography as Typo, styled } from '@mui/material';

interface IText {
    [property: string]: FC<ITypographyBase>;
}

interface ITypographyBase {
    color?: string;
    font?: string;
    children: ReactNode;
    variant?: string;
    textUnderline?: boolean;
}

const TypographyBase: any = styled(Typo, {
    shouldForwardProp: (prop: string) => prop !== 'color' && prop !== 'font' && prop !== 'textUnderline',
})<ITypographyBase>(({ theme, color, font, textUnderline }) => ({
    color: color ? color : theme.palette.primary.black,
    fontFamily: font,
    whiteSpace: 'pre-line',
    textDecoration: textUnderline ? 'underline' : 'none',
}));

const Typography: IText = {
    Body1: ({ children, color, font, textUnderline }) => (
        <TypographyBase textUnderline={textUnderline} variant='h1' component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    Body2: ({ children, color, font, textUnderline }) => (
        <TypographyBase textUnderline={textUnderline} variant='h2' component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    Body3: ({ children, color, font, textUnderline }) => (
        <TypographyBase variant='h3' textUnderline={textUnderline} component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    Body4: ({ children, color, font, textUnderline }) => (
        <TypographyBase variant='h4' textUnderline={textUnderline} component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    Body5: ({ children, color, font, textUnderline }) => (
        <TypographyBase variant='h5' textUnderline={textUnderline} component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    Body6: ({ children, color, font, textUnderline }) => (
        <TypographyBase variant='h6' textUnderline={textUnderline} component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    TabText: ({ children, color, font, textUnderline }) => (
        <TypographyBase textUnderline={textUnderline} variant='tab-text' component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    ThinTitle: ({ children, color, font, textUnderline }) => (
        <TypographyBase textUnderline={textUnderline} variant='thin-title' component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    DangerouslyHtml: ({ children, color, font, textUnderline, variant }) => (
        <TypographyBase
            color={color}
            font={font}
            textUnderline={textUnderline}
            variant={variant ? variant : 'h5'}
            dangerouslySetInnerHTML={{
                __html: children,
            }}
        ></TypographyBase>
    ),
    LargeTitle: ({ children, color, font, textUnderline }) => (
        <TypographyBase textUnderline={textUnderline} variant='large-title' component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    XLargeTitle: ({ children, color, font, textUnderline }) => (
        <TypographyBase textUnderline={textUnderline} variant='xLarge-title' component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    XSmall: ({ children, color, font, textUnderline }) => (
        <TypographyBase textUnderline={textUnderline} variant='xSmall' component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
    XLarge: ({ children, color, font, textUnderline }) => (
        <TypographyBase textUnderline={textUnderline} variant='xLarge' component='span' color={color} font={font}>
            {children}
        </TypographyBase>
    ),
};

export default Typography;
