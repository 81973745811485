import React, { FC, useEffect, useState } from 'react';
import { Box, Button, CircularLoader, Icon, Typography } from 'components/atoms';
import { FormHelperText, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SetFieldValue, UseFormRegister, UseFormWatch, useFormContext } from 'react-hook-form';
import { isFile } from 'utils/isFile';
import { IImage } from 'models/api/management';

interface IProps {
    image: string | File;
    name: string;
    disabled?: boolean;
    required?: any;
    register: UseFormRegister<{ images: IImage[] | undefined }>;
    setValue: SetFieldValue<any>;
    index: number;
    watch: UseFormWatch<{
        images: IImage[] | undefined;
    }>;
    onBlur?: () => void;
}

const sx = (theme: Theme, disabled?: boolean) => ({
    hoverBox: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
    },
    label: {
        position: 'relative',
        cursor: disabled ? 'unset' : 'pointer',
    },
    boxImage: {
        borderRadius: theme.borderRadius.regular,
        border: `1px solid ${theme.palette.primary.grey}`,
        height: '85px',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    replaceImage: {
        textDecoration: 'underline',
        display: 'flex',
        alignItems: 'center',
        gap: '3px',
    },
});

const UploadImage: FC<IProps> = ({ image, name, disabled, required, register, setValue, onBlur, index, watch }) => {
    const theme = useTheme();
    // main form context
    const { setError, formState, clearErrors } = useFormContext();
    const [isHover, setIsHover] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const styles = sx(theme, disabled);
    const { errors }: any = formState;
    const imageFields = watch(`images.${index}`);

    const hoverButtons = [
        {
            icon: <Icon.Replace />,
            text: 'החלף תמונה',
            onClick: async () => {
                clearErrors(name);
                await setValue(name, '', {
                    shouldDirty: true,
                });
                onBlur?.();
            },
        },
        {
            icon: <Icon.Trash width='12px' height='12px' />,
            text: 'מחק',
            onClick: async () => {
                clearErrors(name);
                setTimeout(async () => {
                    await setValue(name, '', {
                        shouldDirty: true,
                    });
                    onBlur?.();
                }, 0);
            },
        },
    ];

    const [fileDataURL, setFileDataURL] = useState<string | null>(null);

    useEffect(() => {
        if (image) {
            const file = image;
            if (isFile(file)) {
                setLoader(true);
                const reader = new FileReader();
                reader.readAsDataURL(file as File);
                reader.onloadend = () => {
                    setFileDataURL(reader.result as string);
                };
            } else {
                setFileDataURL(image as string);
                setLoader(false);
                setIsHover(false);
            }
        }
    }, [image]);

    const errorMessage = errors?.images?.[index as any]?.image?.src.message;

    return (
        <Box component='label' sx={styles.label}>
            <Box sx={styles.boxImage} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                {image ? (
                    <Box width={'100%'} height={'100%'}>
                        <img
                            src={fileDataURL || ''}
                            alt='img'
                            style={{ opacity: isHover && !disabled ? 0.2 : 1 }}
                            width={'100%'}
                            height={'100%'}
                        />
                        {isHover && !disabled && !loader && (
                            <Box sx={styles.hoverBox}>
                                {hoverButtons.map((item) => (
                                    <Button.Text onClick={item.onClick} backgroundColor='transparent' key={item.text}>
                                        <Box sx={styles.replaceImage}>
                                            {item.icon}
                                            <Typography.XSmall>{item.text}</Typography.XSmall>
                                        </Box>
                                    </Button.Text>
                                ))}
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
                        <input
                            hidden
                            accept='image/*,.pdf'
                            type='file'
                            {...register(name as any, {
                                onChange: async (e) => {
                                    const file = e.target.files[0];
                                    const image = new Image();
                                    if (file) {
                                        image.src = URL.createObjectURL(file);
                                        image.onload = async () => {
                                            const width = image.width;
                                            const height = image.height;
                                            if (width < 559 || height < 264) {
                                                setError(name, {
                                                    type: 'validate',
                                                    message: 'גודל התמונה קטן מידי',
                                                });
                                            } else {
                                                clearErrors(name);
                                                await setValue(name, file);
                                                onBlur?.();
                                            }
                                        };
                                    }
                                },
                                required,
                            })}
                            disabled={disabled}
                        />
                        <Icon.Image />
                    </Box>
                )}
            </Box>
            {errorMessage && (
                <FormHelperText error sx={{ fontFamily: theme.fontFamilies.bold }}>
                    {errorMessage}
                </FormHelperText>
            )}
            {loader && imageFields?.power && imageFields?.roof_type && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    position='absolute'
                >
                    <CircularLoader />
                </Box>
            )}
        </Box>
    );
};

export default UploadImage;
